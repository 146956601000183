import React, { useEffect, useState } from 'react'
import CardLayout from '../../components/utils/CardLayout'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../components/utils/Button'
import ArrowDownFillIcon from 'remixicon-react/ArrowDownFillIcon'
import JarActions from '../../redux/jar/actions';
import authActions from "../../redux/auth/actions";
import Alert from '../../components/utils/Alert'

const { uploadJarFile, getJarFile } = JarActions;
const { getUserDataByToken, } = authActions;

const UploadJar = () => {
    const dispatch = useDispatch();
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertText, setAlertText] = useState('');
    const { loading, jarUploadData, message } = useSelector((state) => state.jarUpload);
    const { tokenData } = useSelector((state) => state.auth);



    useEffect(() => {
        dispatch(getJarFile());
    }, []);


    useEffect(() => {
        // if (addNetworkError !== null && addNetworkError !== undefined) {
        //     displayAlert(addNetworkError, 'alert-danger');
        // }
        if (message !== null && message !== undefined) {
            displayAlert(message, 'alert-success');
            dispatch(getUserDataByToken());
        }


    }, [message]);

    const displayAlert = (text, type) => {
        setAlertText(text);
        setAlertType(type);
        setShowAlert(true);
    };

    const alertCancel = () => {
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
    }


    const onSubmit = (data) => {
        data.jarfile = data.jarfile[0];
        dispatch(uploadJarFile(data));
        reset({
            jarfile: '',
            Version: '',
            Remark: '',
        })
    }

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }, reset
    } = useForm();

    return (
        <CardLayout title="JAR Upload">

            {
                jarUploadData && jarUploadData.length > 0 ?
                    <div className="row justify-content-center">
                        <div className="col-md-6 mb-3 d-flex align-item-center justify-content-between rounded-sm" style={{
                            background: '#fff',
                            boxShadow: "rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px",
                            padding: '35px 20px'
                        }}>
                            <div>
                                <h3><strong style={{ color: "black", fontSize: "30px" }}>Existing JAR</strong>
                                    <span style={styles.version}>
                                        {tokenData ? tokenData[0].JARVersion : 'Loading'}
                                    </span></h3>
                            </div>
                            <div>
                                <a href={jarUploadData[0].url} className="btn btn-primary">Download</a>
                            </div>

                        </div>
                    </div> : <div className="row justify-content-center">
                        <div className="col-md-6 mb-3 d-flex align-item-center justify-content-between rounded-sm" style={{
                            background: '#fff',
                            boxShadow: "rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px",
                            padding: '35px 20px'
                        }}>
                            <div>
                                <h3><strong style={{ color: "black", fontSize: "30px" }}>Existing JAR</strong></h3>
                            </div>
                            <div>
                                <p className='mt-3'>No Jar Found</p>
                            </div>

                        </div>
                    </div>
            }


            <div className='row justify-content-center'>
                <div className='col-md-6 d-flex align-item-center justify-content-between flex-column rounded-sm' style={{
                    background: '#fff',
                    boxShadow: "rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px",
                    padding: '20px 20px'
                }}>

                    {showAlert && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}

                    <h3 className='text-center mb-3'><strong style={{ color: "black", fontSize: "30px" }}>Upload New JAR</strong></h3>


                    <div className='col-md-12'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="col-md-12 mb-3">
                                <input type="text" class="form-control" placeholder="Enter Version Here" name="Version" {...register("Version", {
                                    required: true,
                                })} />
                                {errors.version?.type === "required" && (<div class="invalid-feedback">Version is required !</div>)}
                            </div>
                            <div className="col-md-12 mb-3">
                                <textarea type="text" class="form-control" style={{ height: '200px' }} placeholder="Enter Remark Here" name="Remark" {...register("Remark", {
                                    required: true,
                                })} />
                                {errors.remark?.type === "required" && (<div class="invalid-feedback">Reamrk is required !</div>)}
                            </div>
                            <div className="row justify-content-between px-4">
                                <div className="col-md-4 mb-3">
                                    <input type="file" name="jarfile" accept=".jar"
                                        {...register('jarfile', {
                                            required: true,
                                        })
                                        } />
                                    {errors.remark?.type === "required" && (
                                        <div class="invalid-feedback">Please Select a required !</div>
                                    )}
                                </div>
                                <div className='col-lg-4 col-md-4 text-right'>
                                    <Button loading={loading} title="Upload" style="minWidth:80px" buttonClass="btn btn-primary" buttonType='submit' /></div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>




        </CardLayout>
    )
}

const styles = {
    version: {
        fontSize: '16px',
        fontWeight: '400',
        letterSpacing: '1px',
    },
}

export default UploadJar
