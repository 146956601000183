import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData, getList, deleteData, editData } from '../../helpers/fackBackend_Helper';

function* getDealerListResponse() {
    try {
        const response = yield call(getList, '/dealer');
        if (response.response.Status) {
            yield put(
                actions.getDealerSuccess(
                    response.response.Data
                ));
        } else {
            yield put(actions.getDealerFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getDealerFailed(error));
    }
}

function* addDealerDataResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/dealer', data);
        if (response.response.Status) {
            const responseList = yield call(getList, '/dealer');
            if (responseList.response.Status) {
                yield put(
                    actions.addDealerSuccess(
                        response.response.Message, responseList.response.Data
                    ));
            } else {
                yield put(actions.addDealerFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.addDealerFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.addDealerFailed(error));
    }
}

function* editDealerDataResponse({ payload: { dealerId, data } }) {
    try {
        const response = yield call(editData, '/dealer/' + dealerId, data);
        if (response.response.Status) {
            const responseList = yield call(getList, '/dealer');
            if (responseList.response.Status) {
                yield put(
                    actions.editDealerSuccess(
                        response.response.Message, responseList.response.Data
                    ));
            } else {
                yield put(actions.addDealerFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.addDealerFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.addDealerFailed(error));
    }
}

function* deleteDealerDataResponse({ payload: { dealerId, status } }) {
    try {
        const response = yield call(deleteData, `/dealer/${dealerId}/${status}`);
        if (response.response.Status) {
            const responseList = yield call(getList, '/dealer');
            if (responseList.response.Status) {
                yield put(
                    actions.deleteDealerSuccess(
                        response.response.Message, responseList.response.Data
                    ));
            } else {
                yield put(actions.getDealerFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.getDealerFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getDealerFailed(error));
    }
}

function* getDealerLevelResponse() {
    try {
        const response = yield call(getList, '/checkDealerLevel');
        if (response.response.Status) {
            yield put(
                actions.getDealerLevelSuccessfull(
                    response.response.Data, response.response.Data[0]
                ));
        } else {
            yield put(actions.getDealerFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getDealerFailed(error));
    }
}

function* getDealerAllowCreationResponse({ payload: { dealerId } }) {
    try {
        const response = yield call(getList, '/checkAllowDealerCreation/' + dealerId);
        if (response.response.Status) {
            yield put(
                actions.getDealerAllowCreationSuccess(
                    response.response.Dealer,
                ));
        } else {
            yield put(actions.getDealerFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getDealerFailed(error));
    }
}

function* getTariffAllowCreationResponse({ payload: { dealerId } }) {
    try {
        const response = yield call(getList, '/checkAllowTariffCreation/' + dealerId);
        if (response.response.Status) {
            yield put(
                actions.getTariffAllowCreationSuccess(
                    response.response.Tariff,
                ));
        } else {
            yield put(actions.getDealerFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getDealerFailed(error));
    }
}

function* getDomainListResponse() {
    try {
        const response = yield call(getList, '/getEmailPostFixList');
        if (response.Status) {
            yield put(actions.getEmailDomainsSuccess(response.Domains));
        } else {
            yield put(actions.getEmailDomainsFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.getEmailDomainsFailed(error));
    }
}

function* resetDealerPasswordResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/changeUserPassword', data);
        if (response.response.Status) {
            const responseList = yield call(getList, '/dealer');
            if (responseList.response.Status) {
                yield put(
                    actions.dealerPasswordResetSuccess(
                        response.response.Message, responseList.response.Data
                    ));
            } else {
                yield put(actions.dealerPasswordResetFailed(response.response.Message));
            }
        } else {
            yield put(actions.dealerPasswordResetFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.dealerPasswordResetFailed(error));
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.GET_EMAIL_DOMAINS, getDomainListResponse)]);
    yield all([takeEvery(actions.GET_DEALER, getDealerListResponse)]);
    yield all([takeEvery(actions.ADD_DEALER, addDealerDataResponse)]);
    yield all([takeEvery(actions.EDIT_DEALER, editDealerDataResponse)]);
    yield all([takeEvery(actions.DELETE_DEALER, deleteDealerDataResponse)]);
    yield all([takeEvery(actions.GET_DEALER_LEVEL, getDealerLevelResponse)]);
    yield all([takeEvery(actions.GET_DEALER_ALLOW_CREATION, getDealerAllowCreationResponse)]);
    yield all([takeEvery(actions.GET_TARIFF_ALLOW_CREATION, getTariffAllowCreationResponse)]);
    yield all([takeEvery(actions.DEALER_PASSWORD_RESET, resetDealerPasswordResponse)]);
}
