import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isAdmin, isAdminOrAgent, isAdminOrDealer, isDealer,allowDealerCreation } from '../../helpers/commonFunction'
import Loader from './Loader';

const AdminRoute = ({ children }) => {
    const { tokenData, getUserDetailsLoading } = useSelector((state) => state.auth);
    if (getUserDetailsLoading) {
        return <Loader />
    } else {
        if (tokenData.length > 0) {
            if (isAdmin(tokenData)) {
                return <>{children}</>
            } else {
                return <Navigate to='/404' />
            }
        } else {
            return <Loader />
        }
    }

}

const AdminOrAgentRoute = ({ children }) => {
    const { tokenData, getUserDetailsLoading } = useSelector((state) => state.auth);
    if (getUserDetailsLoading) {
        return <Loader />
    } else {
        if (tokenData.length > 0) {
            if (isAdminOrAgent(tokenData)) {
                return <>{children}</>
            } else {
                return <Navigate to='/404' />
            }
        } else {
            return <Loader />
        }
    }

}

const AdminOrDealerRoute = ({ children }) => {
    const { tokenData, getUserDetailsLoading } = useSelector((state) => state.auth);
    if (getUserDetailsLoading) {
        return <Loader />
    } else {
        if (tokenData.length > 0) {
            if (isAdminOrDealer(tokenData)) {
                return <>{children}</>
            } else {
                return <Navigate to='/404' />
            }
        } else {
            return <Loader />
        }
    }

}

const DealerRoute = ({ children }) => {
    const { tokenData, getUserDetailsLoading } = useSelector((state) => state.auth);

    if (getUserDetailsLoading) {
        return <Loader />
    } else {
        if (tokenData.length > 0) {
            if (isDealer(tokenData)) {
                return <>{children}</>
            } else {
                return <Navigate to='/404' />
            }
        } else {
            return <Loader />
        }
    }
}

const AdminOrDealerWhoHaveAllowDealerCreationRoute = ({ children }) => {
    const { tokenData, getUserDetailsLoading } = useSelector((state) => state.auth);
    if (getUserDetailsLoading) {
        return <Loader />
    } else {
        if (tokenData.length > 0) {
            if (isAdmin(tokenData) || allowDealerCreation(tokenData)) {
                return <>{children}</>
            } else {
                return <Navigate to='/404' />
            }
        } else {
            return <Loader />
        }
    }

}

export { AdminRoute, AdminOrAgentRoute, DealerRoute, AdminOrDealerRoute,AdminOrDealerWhoHaveAllowDealerCreationRoute };