import actions from "./actions";

const initState = {
    planWiseMappingList: [],
    planWiseMappingLoading: false,
    planWiseMappingError: null,
    planWiseMappingMessage: null,
    loading: false,
    buttonLoading: false,
    planPriceMappingError: null,
    planPriceMappingSuccess: null,
    PlanPriceMappingData: [],
    rechargeMappingLoading: false,
    rechargeMappingError: null,
    rechargeMappingMessage: null,
    rechargeMappingData: [],
    updateRechargeMappingLoading: false,
    updateRechargeMappingError: null,
    updateRechargeMappingMessage: null,
    checkRechargeMappingExistsLoading: false,
    checkRechargeMappingExistsError: null,
    checkRechargeMappingExistsMessage: null,
    planChildPriceMappingError: null,
    planChildPriceMappingSuccess: null,
    dailyPlanMappingLoading: false,
    dailyPlanMappingError: null,
    dailyPlanMappingMessage: null,
    dailyPlanMappingData: [],
    updateDailyPlanMappingLoading: false,
    updateDailyPlanMappingError: null,
    updateDailyPlanMappingMessage: null,
};

export default function reducer(state = initState, action) {
    switch (action.type) {

        case actions.UPDATE_DAILY_PLAN_MAPPING_DATA:
            return {
                ...state,
                updateDailyPlanMappingLoading: true,
                updateDailyPlanMappingError: null,
                updateDailyPlanMappingMessage: null,
            };
        case actions.UPDATE_DAILY_PLAN_MAPPING_DATA_SUCCESS:
            return {
                ...state,
                updateDailyPlanMappingLoading: false,
                updateDailyPlanMappingError: null,
                updateDailyPlanMappingMessage: action.payload.message,
            };
        case actions.UPDATE_DAILY_PLAN_MAPPING_DATA_FAILED:
            return {
                ...state,
                updateDailyPlanMappingLoading: false,
                updateDailyPlanMappingError: action.payload.error,
                updateDailyPlanMappingMessage: null,
            };


        case actions.GET_DAILY_PLAN_MAPPING_DATA:
            return {
                ...state,
                dailyPlanMappingLoading: true,
                dailyPlanMappingError: null,
                dailyPlanMappingMessage: null,
                dailyPlanMappingData: []
            };
        case actions.GET_DAILY_PLAN_MAPPING_DATA_SUCCESS:
            return {
                ...state,
                dailyPlanMappingLoading: false,
                dailyPlanMappingError: null,
                dailyPlanMappingMessage: null,
                dailyPlanMappingData: action.payload.data
            };
        case actions.GET_DAILY_PLAN_MAPPING_DATA_FAILED:
            return {
                ...state,
                dailyPlanMappingLoading: false,
                dailyPlanMappingError: action.payload.error,
                dailyPlanMappingMessage: null,
                dailyPlanMappingData: []
            };

        case actions.UPDATE_PLAN_CHILD_PRICE_MAPPING_DATA:
            return {
                ...state,
                loading: true,
                buttonLoading: true,
                planChildPriceMappingError: null,
                planChildPriceMappingSuccess: null,
            };
        case actions.UPDATE_PLAN_CHILD_PRICE_MAPPING_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                buttonLoading: false,
                planChildPriceMappingError: null,
                planChildPriceMappingSuccess: action.message,
                PlanPriceMappingData: action.data,
            };
        case actions.UPDATE_PLAN_CHILD_PRICE_MAPPING_DATA_FAILED:
            return {
                ...state,
                loading: false,
                buttonLoading: false,
                planChildPriceMappingError: action.serror,
                planChildPriceMappingSuccess: null,
            };

        case actions.CHECK_RECHARGE_MAPPING_EXISTS:
            return {
                ...state,
                checkRechargeMappingExistsLoading: true,
                checkRechargeMappingExistsError: null,
                checkRechargeMappingExistsMessage: null,
            };
        case actions.CHECK_RECHARGE_MAPPING_EXISTS_SUCCESS:
            return {
                ...state,
                checkRechargeMappingExistsLoading: false,
                checkRechargeMappingExistsError: null,
                checkRechargeMappingExistsMessage: action.payload.message,
            };
        case actions.CHECK_RECHARGE_MAPPING_EXISTS_FAILED:
            return {
                ...state,
                checkRechargeMappingExistsLoading: false,
                checkRechargeMappingExistsError: action.payload.error,
                checkRechargeMappingExistsMessage: null,
            };

        case actions.UPDATE_RECHARGE_MAPPING_DATA:
            return {
                ...state,
                updateRechargeMappingLoading: true,
                updateRechargeMappingError: null,
                updateRechargeMappingMessage: null,
            };
        case actions.UPDATE_RECHARGE_MAPPING_DATA_SUCCESS:
            return {
                ...state,
                updateRechargeMappingLoading: false,
                updateRechargeMappingError: null,
                updateRechargeMappingMessage: action.payload.message,
            };
        case actions.UPDATE_RECHARGE_MAPPING_DATA_FAILED:
            return {
                ...state,
                updateRechargeMappingLoading: false,
                updateRechargeMappingError: action.payload.error,
                updateRechargeMappingMessage: null,
            };

        case actions.GET_RECHARGE_MAPPING_DATA:
            return {
                ...state,
                rechargeMappingLoading: true,
                rechargeMappingError: null,
                rechargeMappingMessage: null,
                rechargeMappingData: []
            };

        case actions.GET_RECHARGE_MAPPING_DATA_SUCCESS:
            return {
                ...state,
                rechargeMappingLoading: false,
                rechargeMappingError: null,
                rechargeMappingMessage: null,
                rechargeMappingData: action.payload.data
            };

        case actions.GET_RECHARGE_MAPPING_DATA_FAILED:
            return {
                ...state,
                rechargeMappingLoading: false,
                rechargeMappingError: action.payload.error,
                rechargeMappingMessage: null,
                rechargeMappingData: []
            };


        case actions.GET_PLAN_WISE_MAPPING:
            return {
                ...state,
                planWiseMappingLoading: true,
                planWiseMappingError: null,
                planWiseMappingMessage: null,
            };

        case actions.GET_PLAN_WISE_MAPPING_SUCCESS:
            return {
                ...state,
                planWiseMappingLoading: false,
                planWiseMappingError: null,
                planWiseMappingMessage: null,
                planWiseMappingList: action.payload.data,
            };

        case actions.GET_PLAN_WISE_MAPPING_FAILED:
            return {
                ...state,
                planWiseMappingLoading: false,
                planWiseMappingError: action.payload.error,
                planWiseMappingMessage: null,
            };

        case actions.GET_ALL_PLAN_PRICE_MAPPING_DATA:
            return {
                ...state,
                loading: true,
                planPriceMappingError: null,
                planPriceMappingSuccess: null,
            };

        case actions.GET_ALL_PLAN_PRICE_MAPPING_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                planPriceMappingError: null,
                planPriceMappingSuccess: null,
                PlanPriceMappingData: action.payload.data,
            };

        case actions.GET_ALL_PLAN_PRICE_MAPPING_DATA_FAILED:
            return {
                ...state,
                loading: false,
                planPriceMappingError: action.payload.error,
                planPriceMappingSuccess: null,
            };

        case actions.UPDATE_PLAN_PRICE_MAPPING_DATA:
            return {
                ...state,
                loading: true,
                buttonLoading: true,
                planPriceMappingError: null,
                planPriceMappingSuccess: null,
            };

        case actions.UPDATE_PLAN_PRICE_MAPPING_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                buttonLoading: false,
                planPriceMappingError: null,
                planPriceMappingSuccess: action.message,
                PlanPriceMappingData: action.data,
            };

        case actions.UPDATE_PLAN_PRICE_MAPPING_DATA_FAILED:
            return {
                ...state,
                loading: false,
                buttonLoading: false,
                planPriceMappingError: action.serror,
                planPriceMappingSuccess: null,
            };
        case actions.RESET_PLAN_PRICE_MAPPING_MESSAGES:
            return {
                ...state,
                loading: false,
                buttonLoading: false,
                planPriceMappingError: null,
                planPriceMappingSuccess: null,
            };
        default:
            return state;
    }
}