const actions = {
    GET_DAILY_PLAN_REQUEST_LIST: "GET_DAILY_PLAN_REQUEST_LIST",
    GET_DAILY_PLAN_REQUEST_LIST_SUCCESSFUL: " GET_DAILY_PLAN_REQUEST_LIST_SUCCESSFUL",
    GET_DAILY_PLAN_REQUEST_LIST_FAILED: " GET_DAILY_PLAN_REQUEST_LIST_FAILED",
    PREPARE_DAILY_PLAN_REQUEST_MODAL: "PREPARE_DAILY_PLAN_REQUEST_MODAL",
    CLOSE_DAILY_PLAN_REQUEST_MODAL: "CLOSE_DAILY_PLAN_REQUEST_MODAL",
    CREATE_DAILY_PLAN_REQUEST: "CREATE_DAILY_PLAN_REQUEST",
    CREATE_DAILY_PLAN_SUCCESSFUL: "CREATE_DAILY_PLAN_SUCCESSFUL",
    CREATE_DAILY_PLAN_FAILED: "CREATE_DAILY_PLAN_FAILED",
    RESET_DAILY_PLAN: "RESET_DAILY_PLAN",
    LOCK_DAILY_PLAN: "LOCK_DAILY_PLAN",
    LOCK_DAILY_PLAN_SUCCESSFUL: "LOCK_DAILY_PLAN_SUCCESSFUL",
    LOCK_DAILY_PLAN_FAILED: "LOCK_DAILY_PLAN_FAILED",
    UNLOCK_DAILY_PLAN: "UNLOCK_DAILY_PLAN",
    UNLOCK_DAILY_PLAN_SUCCESSFUL: "UNLOCK_DAILY_PLAN_SUCCESSFUL",
    UNLOCK_DAILY_PLAN_FAILED: "UNLOCK_DAILY_PLAN_FAILED",
    PREPARE_CANCEL_MODEL: "PREPARE_CANCEL_MODEL",
    CLOSE_CANCEL_MODAL: "CLOSE_CANCEL_MODAL",
    GET_MOST_ELIGIBLE_REQUEST: "GET_MOST_ELIGIBLE_REQUEST",
    GET_MOST_ELIGIBLE_REQUEST_SUCCESSFUL: "GET_MOST_ELIGIBLE_REQUEST_SUCCESSFUL",
    GET_MOST_ELIGIBLE_REQUEST_FAILED: "GET_MOST_ELIGIBLE_REQUEST_FAILED",
    CANCEL_DAILY_PLAN: "CANCEL_DAILY_PLAN",
    CANCEL_DAILY_PLAN_SUCCESSFUL: "CANCEL_DAILY_PLAN_SUCCESSFUL",
    CANCEL_DAILY_PLAN_FAILED: "CANCEL_DAILY_PLAN_FAILED",
    RESET_COMPLETE_FORM: "RESET_COMPLETE_FORM",
    CREATE_ACTIVATION_FOR_DAILY_PLAN: "CREATE_ACTIVATION_FOR_DAILY_PLAN",
    CREATE_ACTIVATION_FOR_DAILY_PLAN_SUCCESSFUL: "CREATE_ACTIVATION_FOR_DAILY_PLAN_SUCCESSFUL",
    CREATE_ACTIVATION_FOR_DAILY_PLAN_FAILED: "CREATE_ACTIVATION_FOR_DAILY_PLAN_FAILED",
    GET_DAILY_PLAN_OTP: "GET_DAILY_PLAN_OTP",
    GET_DAILY_PLAN_OTP_SUCCESSFUL: "GET_DAILY_PLAN_OTP_SUCCESSFUL",
    GET_DAILY_PLAN_OTP_FAILED: "GET_DAILY_PLAN_OTP_FAILED",
    CHECK_DAILY_PLAN_COMPLETION_STATUS: "CHECK_DAILY_PLAN_COMPLETION_STATUS",
    CHECK_DAILY_PLAN_COMPLETION_STATUS_SUCCESSFUL: "CHECK_DAILY_PLAN_COMPLETION_STATUS_SUCCESSFUL",
    CHECK_DAILY_PLAN_COMPLETION_STATUS_FAILED: "CHECK_DAILY_PLAN_COMPLETION_STATUS_FAILED",
    COMPLETE_DAILY_PLAN_REQUEST: "COMPLETE_DAILY_PLAN_REQUEST",
    COMPLETE_DAILY_PLAN_REQUEST_SUCCESSFUL: "COMPLETE_DAILY_PLAN_REQUEST_SUCCESSFUL",
    COMPLETE_DAILY_PLAN_REQUEST_FAILED: "COMPLETE_DAILY_PLAN_REQUEST_FAILED",
    CREATE_BULK_ACTIVATION_FOR_DAILY_PLAN: "CREATE_BULK_ACTIVATION_FOR_DAILY_PLAN",
    CREATE_BULK_ACTIVATION_FOR_DAILY_PLAN_SUCCESSFUL: "CREATE_BULK_ACTIVATION_FOR_DAILY_PLAN_SUCCESSFUL",
    CREATE_BULK_ACTIVATION_FOR_DAILY_PLAN_FAILED: "CREATE_BULK_ACTIVATION_FOR_DAILY_PLAN_FAILED",
    CHECK_VALID_SERIAL_NUMBER: "CHECK_VALID_SERIAL_NUMBER",
    CHECK_VALID_SERIAL_NUMBER_SUCCESSFUL: "CHECK_VALID_SERIAL_NUMBER_SUCCESSFUL",
    CHECK_VALID_SERIAL_NUMBER_FAILED: "CHECK_VALID_SERIAL_NUMBER_FAILED",

    checkValidSerialNumber: (data) => ({
        type: actions.CHECK_VALID_SERIAL_NUMBER,
        payload: { data }
    }),
    checkValidSerialNumberSuccessful: (data) => ({
        type: actions.CHECK_VALID_SERIAL_NUMBER_SUCCESSFUL,
        payload: { data }
    }),
    checkValidSerialNumberFailed: (error) => ({
        type: actions.CHECK_VALID_SERIAL_NUMBER_FAILED,
        payload: { error }
    }),

    createBulkActivationForDailyPlan: (data, searchData) => ({
        type: actions.CREATE_BULK_ACTIVATION_FOR_DAILY_PLAN,
        payload: { data, searchData }
    }),
    createBulkActivationForDailyPlanSuccessful: (message) => ({
        type: actions.CREATE_BULK_ACTIVATION_FOR_DAILY_PLAN_SUCCESSFUL,
        payload: { message }
    }),
    createBulkActivationForDailyPlanFailed: (error) => ({
        type: actions.CREATE_BULK_ACTIVATION_FOR_DAILY_PLAN_FAILED,
        payload: { error }
    }),

    completeDailyPlanRequest: (activationID, dailyPlanID, searchData) => ({
        type: actions.COMPLETE_DAILY_PLAN_REQUEST,
        payload: { activationID, dailyPlanID, searchData }
    }),
    completeDailyPlanRequestSuccessful: (message) => ({
        type: actions.COMPLETE_DAILY_PLAN_REQUEST_SUCCESSFUL,
        payload: { message }
    }),
    completeDailyPlanRequestFailed: (error) => ({
        type: actions.COMPLETE_DAILY_PLAN_REQUEST_FAILED,
        payload: { error }
    }),

    checkDailyPlanCompletionStatus: (activationID) => ({
        type: actions.CHECK_DAILY_PLAN_COMPLETION_STATUS,
        payload: { activationID }
    }),
    checkDailyPlanCompletionStatusSuccessful: (message) => ({
        type: actions.CHECK_DAILY_PLAN_COMPLETION_STATUS_SUCCESSFUL,
        payload: { message }
    }),
    checkDailyPlanCompletionStatusFailed: (error) => ({
        type: actions.CHECK_DAILY_PLAN_COMPLETION_STATUS_FAILED,
        payload: { error }
    }),

    getDailyPlanOtp: (activationID) => ({
        type: actions.GET_DAILY_PLAN_OTP,
        payload: { activationID }
    }),
    getDailyPlanOtpSuccessful: (data) => ({
        type: actions.GET_DAILY_PLAN_OTP_SUCCESSFUL,
        payload: { data }
    }),
    getDailyPlanOtpFailed: (error) => ({
        type: actions.GET_DAILY_PLAN_OTP_FAILED,
        payload: { error }
    }),

    createActivationForDailyPlan: (data, searchData) => ({
        type: actions.CREATE_ACTIVATION_FOR_DAILY_PLAN,
        payload: { data, searchData }
    }),
    createActivationForDailyPlanSuccessful: (message) => ({
        type: actions.CREATE_ACTIVATION_FOR_DAILY_PLAN_SUCCESSFUL,
        payload: { message }
    }),
    createActivationForDailyPlanFailed: (error) => ({
        type: actions.CREATE_ACTIVATION_FOR_DAILY_PLAN_FAILED,
        payload: { error }
    }),

    resetCompleteForm: () => ({
        type: actions.RESET_COMPLETE_FORM,
    }),

    getMostEligibleRequest: (numberOfDays, addonServId) => ({
        type: actions.GET_MOST_ELIGIBLE_REQUEST,
        payload: { numberOfDays, addonServId }
    }),
    getMostEligibleRequestSuccessful: (data) => ({
        type: actions.GET_MOST_ELIGIBLE_REQUEST_SUCCESSFUL,
        payload: { data }
    }),
    getMostEligibleRequestFailed: (error) => ({
        type: actions.GET_MOST_ELIGIBLE_REQUEST_FAILED,
        payload: { error }
    }),

    getDailyPlanRequestList: (searchData) => ({
        type: actions.GET_DAILY_PLAN_REQUEST_LIST,
        payload: { searchData }
    }),
    getDailyPlanRequestSuccessful: (dailyRequestResult) => ({
        type: actions.GET_DAILY_PLAN_REQUEST_LIST_SUCCESSFUL,
        payload: { dailyRequestResult }
    }),
    getDailyPlanRequestFailed: (dailyRequestError) => ({
        type: actions.GET_DAILY_PLAN_REQUEST_LIST_FAILED,
        payload: { dailyRequestError }
    }),
    prepareDailyPlanRequestModal: () => ({
        type: actions.PREPARE_DAILY_PLAN_REQUEST_MODAL,
    }),
    closeDailyPlanRequestModal: () => ({
        type: actions.CLOSE_DAILY_PLAN_REQUEST_MODAL
    }),
    createDailyPlanRequest: (data, searchData) => ({
        type: actions.CREATE_DAILY_PLAN_REQUEST,
        payload: { data, searchData }
    }),
    createDailyPlanRequestSuccessful: (dailyRequestMessage) => ({
        type: actions.CREATE_DAILY_PLAN_SUCCESSFUL,
        payload: { dailyRequestMessage }
    }),
    createDailyPlanRequestFailed: (dailyRequestError) => ({
        type: actions.CREATE_DAILY_PLAN_FAILED,
        payload: { dailyRequestError }
    }),
    resetDailyPlan: () => ({
        type: actions.RESET_DAILY_PLAN,
    }),
    lockDailyPlanRequest: (lockData, searchData) => ({
        type: actions.LOCK_DAILY_PLAN,
        payload: { lockData, searchData }
    }),
    lockDailyPlanRequestSuccessful: (Lockmessage) => ({
        type: actions.LOCK_DAILY_PLAN_SUCCESSFUL,
        payload: { Lockmessage }
    }),
    lockDailyPlanRequestFailed: (Lockerror) => ({
        type: actions.LOCK_DAILY_PLAN_FAILED,
        payload: { Lockerror }
    }),
    unLockDailyPlanRequest: (unLockData, searchData) => ({
        type: actions.UNLOCK_DAILY_PLAN,
        payload: { unLockData, searchData }
    }),
    unLockDailyPlanSuccessful: (realselockmessage) => ({
        type: actions.UNLOCK_DAILY_PLAN_SUCCESSFUL,
        payload: { realselockmessage }
    }),
    unLockDailyPlanFailed: (realselockerror) => ({
        type: actions.UNLOCK_DAILY_PLAN_FAILED,
        payload: { realselockerror }
    }),
    prepareCancelModal: () => ({
        type: actions.PREPARE_CANCEL_MODEL
    }),
    closeCancelModal: () => ({
        type: actions.CLOSE_CANCEL_MODAL
    }),
    cancelDailyPLan: (cancelData, id, searchData) => ({
        type: actions.CANCEL_DAILY_PLAN,
        payload: { cancelData, id, searchData }
    }),
    cancelDailyPlanSuccessful: (cancelmessage) => ({
        type: actions.CANCEL_DAILY_PLAN_SUCCESSFUL,
        payload: { cancelmessage }
    }),
    cancelDailyPlanFailed: (cancelerror) => ({
        type: actions.CANCEL_DAILY_PLAN_FAILED,
        payload: { cancelerror }
    }),
};

export default actions;