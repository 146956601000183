import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Profile from "../utils/Profile";
import profileAction from "./../../redux/profile/actions";
import dashboardActions from "../../redux/dashboard/actions";
import authActions from "../../redux/auth/actions";
import Modal from "../utils/Model";
import Button from "../utils/Button";
import { isAdmin } from "../../helpers/commonFunction";
import Loader from "../utils/Loader";

const { openEditProfileModel, openChangePasswordModel } = profileAction;
const { getUserDataByToken } = authActions;
const { openJarStatusModel, closeJarStatusModel, jarRunningStatus, getBoomData } = dashboardActions;

function Header() {
  const dispatch = useDispatch();
  const [accountBalance, setAccountBalance] = useState(0);
  const profileList = useSelector((state) => state.profile.profileListResult);
  const { tokenData } = useSelector((state) => state.auth);
  const { showJarStatusModal, getBoomDataList, getBoomDataLoading } = useSelector((state) => state.dashboard);
  const { activationRequestResult } = useSelector((state) => state.activationRequest);
  const [switchAction, setSwitchAction] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm({});


  useEffect(() => {
    dispatch(getUserDataByToken());
  }, []);

  useEffect(() => {
    tokenData &&
      tokenData.length > 0 &&
      tokenData.map((data) => {
        setAccountBalance(data.AccountBalance);
        setSwitchAction(data.JARFlag === 1 ? true : false);
      });

    if (tokenData && tokenData.length > 0 && isAdmin(tokenData)) {
      dispatch(getBoomData());
    }
  }, [tokenData, activationRequestResult]);

  const handleEditProfileModel = () => {
    dispatch(openEditProfileModel());
  };

  const handleChangePassword = () => {
    dispatch(openChangePasswordModel());
  };

  const handleSignOut = () => {
    localStorage.clear();
    window.location.href = "/sign-in";
  };

  const switchHandler = (item) => {
    dispatch(openJarStatusModel());
  };
  const closeNetworkModal = () => {
    dispatch(closeJarStatusModel());
  };

  const onSubmit = () => {
    dispatch(closeJarStatusModel());
    const data = {
      UpdatedStatus: switchAction == true ? 0 : 1,
    };
    dispatch(jarRunningStatus(data));
    setSwitchAction((pre) => !pre);
  };

  return (
    <>
      <Loader />
      <div class="iq-top-navbar">
        <div class="iq-navbar-custom">
          <div class="iq-sidebar-logo">
            <div class="top-logo">
              <a href="index.html" class="logo">
                <img src="images/logo.gif" class="img-fluid" alt="" />
                <span>MVNO Wireless</span>
              </a>
            </div>
          </div>
          <nav class="navbar navbar-expand-lg navbar-light p-0">
            <button class="navbar-toggler collapsed mr-3" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-label="Toggle navigation" aria-expanded="false">
              <i class="ri-menu-3-line"></i>
            </button>
            <div class="iq-menu-bt align-self-center">
              <div class="wrapper-menu">
                <div class="main-circle"><i class="ri-arrow-left-s-line"></i></div>
                <div class="hover-circle"><i class="ri-arrow-right-s-line"></i></div>
              </div>
            </div>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav ml-auto navbar-list">
                <li class="nav-item switchClass">

                  {tokenData && isAdmin(tokenData) && <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color pt-1">
                    <p className="mb-0 pr-1 text-dark font-weight-bold" >
                      JAR Status:
                    </p>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        onClick={switchHandler}
                        style={{
                          border: "1px solid gray",
                          height: "30px",
                          width: "60px",
                          borderRadius: "30px",
                          display: "flex",
                        }}
                      >
                        {switchAction == true ? (
                          <div
                            style={{
                              backgroundColor: "#27b345",
                              height: "20px",
                              width: "20px",
                              borderRadius: "20px",
                              margin: "5px 3px 5px 35px",
                            }}
                          ></div>
                        ) : (
                          <div
                            style={{
                              backgroundColor: "gray",
                              height: "20px",
                              width: "20px",
                              borderRadius: "20px",
                              margin: "5px",
                            }}
                          ></div>
                        )}
                      </div>
                    </div>
                  </div>}
                </li>

                <li class="nav-item">
                  <a href="#" class="search-toggle iq-waves-effect text-center">
                    <span className={`text-primary font-weight-bold`} style={{lineHeight : !isAdmin(tokenData) ? '70px': ''}}>
                      {" "} {Number(accountBalance) < 0 ? `$(${Number(accountBalance).toFixed(2)})` : `$${Number(accountBalance).toFixed(2)}`}
                    </span>
                    {
                      isAdmin(tokenData) &&
                      <>
                        <hr className="my-0" />
                        <span className="text-success font-weight-bold">
                       POS {getBoomDataList.WalletBalance && `$${getBoomDataList.WalletBalance}`}
                        </span>
                      </>
                    }

                  </a>
                </li>
              </ul>
            </div>
            <ul class="navbar-list">
              <li>
                <a
                  href="#"
                  className="search-toggle iq-waves-effect d-flex align-items-center "
                >
                  <div
                    className="bg-primary rounded-circle d-flex"
                    style={{ paddingTop: "3px" }}
                  >
                    {profileList && profileList.length > 0
                      ? profileList[0].Username.charAt(0).toUpperCase()
                      : ""}
                    {/* <i className="ri-user-fill "></i> */}
                  </div>
                </a>

                <div class="iq-sub-dropdown iq-user-dropdown">
                  <div class="iq-card shadow-none m-0">
                    <div class="iq-card-body p-0 ">
                      <div class="bg-primary pt-3">
                        <h5 class="mb-0 text-white line-height text-center ">
                          Hello{" "}
                          {profileList && profileList.length > 0
                            ? profileList[0].Username
                            : ""}
                        </h5>

                        <div
                          className="d-flex  justify-content-center"
                          style={{ marginTop: "-7px" }}
                        >
                          <span class="text-white font-size-12 text-center">
                            {profileList && profileList.length > 0
                              ? profileList[0].Role
                              : ""}
                          </span>
                        </div>
                      </div>
                      <a
                        href="/my-profile"
                        class="iq-sub-card iq-bg-primary-hover"
                      >
                        <div class="media align-items-center">
                          <div class="rounded iq-card-icon iq-bg-primary">
                            <i class="ri-file-user-line"></i>
                          </div>
                          <div class="media-body ml-3">
                            <h6 class="mb-0">My Profile</h6>
                            <p class="mb-0 font-size-12">
                              View personal profile details.
                            </p>
                          </div>
                        </div>
                      </a>
                      <a
                        onClick={handleEditProfileModel}
                        style={{ cursor: "pointer" }}
                        class="iq-sub-card iq-bg-primary-hover"
                      >
                        <div class="media align-items-center">
                          <div class="rounded iq-card-icon iq-bg-primary">
                            <i class="ri-profile-line"></i>
                          </div>
                          <div class="media-body ml-3">
                            <h6 class="mb-0 ">Edit Profile</h6>
                            <p class="mb-0 font-size-12">
                              Modify your personal details.
                            </p>
                          </div>
                        </div>
                      </a>
                      <a
                        onClick={handleChangePassword}
                        style={{ cursor: "pointer" }}
                        class="iq-sub-card iq-bg-primary-hover"
                      >
                        <div class="media align-items-center">
                          <div class="rounded iq-card-icon iq-bg-primary">
                            <i class="ri-account-box-line"></i>
                          </div>
                          <div class="media-body ml-3">
                            <h6 class="mb-0 ">Change Password</h6>
                            <p class="mb-0 font-size-12">Modify your password.</p>
                          </div>
                        </div>
                      </a>

                      <div className="d-inline-block w-100 text-center p-3 col-md-12 text-white">
                        <a
                          className="bg-primary iq-sign-btn col-md-12"
                          onClick={handleSignOut}
                          style={{ cursor: "pointer" }}
                          role="button"
                        >
                          Sign out<i class="ri-login-box-line ml-2"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <Profile />
          </nav>
        </div>
        <Modal
          showModal={showJarStatusModal}
          title={"Update JAR Status"}
          onHide={closeNetworkModal}
          width={550}
          showFooter={false}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="col-md-12 mb-1 p-0 text-dark">
              {switchAction == true ? (
                <p>Are you sure, you want to stop JAR?</p>
              ) : (
                <p>Are you sure, you want to start JAR?</p>
              )}
            </div>
            <div className="text-right row my-2">
              <div className="col-md-12">
                <button
                  type="button"
                  className="btn btn-secondary mr-2"
                  onClick={closeNetworkModal}
                >
                  Cancel
                </button>
                <Button
                  title={"Ok"}
                  buttonClass="btn btn-primary"
                  buttonType="submit"
                />
              </div>
            </div>
          </form>
        </Modal>
      </div>
    </>
  );
}

export default Header;