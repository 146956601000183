import { useEffect, useState, } from 'react'
import * as XLSX from 'xlsx';
import { useForm } from 'react-hook-form'
import DownloadCloud2sLineIcon from 'remixicon-react/DownloadCloud2LineIcon';
import FolderUploadLineIcon from 'remixicon-react/FolderUploadLineIcon';
import FileSaver from 'file-saver';
import { useDispatch, useSelector } from 'react-redux';
import Papa from 'papaparse'
import Button from '../../components/utils/Button'
import SearchBar from '../../components/utils/SearchBar'
import PlanWiseMappingActions from '../../redux/planWiseMapping/actions';
import dealerAction from '../../redux/dealer/actions'
import Table from '../../components/utils/Table'
import SideButtons from '../../components/utils/SideButtons'
import { useNavigate } from "react-router-dom";
import networkActions from '../../redux/network/actions'

const { getDailyPlanMapping, getRechargeMapping, updateDailyPlanMapping } = PlanWiseMappingActions;
const { getDealer } = dealerAction;

const { getNetwork } = networkActions;

export const DailyPlanMapping = () => {
  const dispatch = useDispatch();
  const [uploadPlanPriceMapping, setUploadPlanPriceMapping] = useState(false);
  const { dailyPlanMappingData, dailyPlanMappingLoading, updateDailyPlanMappingLoading, updateDailyPlanMappingMessage } = useSelector((state) => state.planWiseMapping);
  const { networkResult } = useSelector((state) => state.network);
  const { dealerResult } = useSelector((state) => state.dealer);
  const [previewSubmitBtn, setPreviewSubmitBtn] = useState(true);
  const navigate = useNavigate();


  useEffect(() => {
    setPreviewHeading([]);
    dispatch(getDailyPlanMapping());
    dispatch(getRechargeMapping());
    dispatch(getDealer());
    dispatch(getNetwork());
  }, []);

  useEffect(() => {
    if (updateDailyPlanMappingMessage) {
      setUploadPlanPriceMapping(false);
    }
  }, [updateDailyPlanMappingMessage]);


  const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();

  const [filterText, setFilterText] = useState("");
  const [uploadedMapping, setUploadedMapping] = useState([]);
  const [previewHeading, setPreviewHeading] = useState([]);
  const [previewDataSourceExcel, setPreviewDataSourceExcel] = useState([]);

  const networkData = networkResult && networkResult.length > 0 && networkResult.map((item) => item.NetworkName);
  const newHeadingData = networkData && networkData.flat().map(item => ({ displayName: item, key: item }));

  const heading = [
    { displayName: '#', key: 'key' },
    { displayName: 'Dealer', key: 'dealerName' },
    ...(newHeadingData || [])
  ];

  const newDataSource = Object.values(dailyPlanMappingData && dailyPlanMappingData.length > 0 && dailyPlanMappingData.reduce((acc, item) => {
    const { DealerID, DealerName, DealerPrice, NetworkName } = item;
    const plannewHeading = `${NetworkName}`
    if (!acc[DealerID]) {
      acc[DealerID] = { DealerID, DealerName };
    }
    acc[DealerID][plannewHeading] = DealerPrice;
    return acc;
  }, {}));

  const Keys = newDataSource && newDataSource.length > 0 && dailyPlanMappingData.map((item) => item.NetworkName);

  const filteredItems = newDataSource && newDataSource.length > 0 && newDataSource.filter(
    item => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1
  );

  const DataSource = filteredItems && filteredItems.length > 0 && filteredItems.map((item, index) => {
    const obj = { key: index + 1, dealerName: item.DealerName };
    Keys.forEach(key => {
      obj[key] = item[key] ? `$${item[key]}` : '';
    });
    return obj;
  });


  const handleFilterChange = (value) => {
    setFilterText(value);
  };

  const uploadView = () => {
    setUploadPlanPriceMapping(true);
  }

  const closeUploadView = () => {
    setUploadPlanPriceMapping(false);
    setPreviewSubmitBtn(true)
    setPreviewHeading([])
  }

  const dealerData = dealerResult && dealerResult.length > 0 && dealerResult.filter((item) => item.ParentID != 0).map((item) => {
    return {
      name: item.DealerName,
      dealerId: item.ID
    }
  });

  const exportToCSV = async () => {
    const transformedData = {};

    dailyPlanMappingData.forEach(item => {
      if (!transformedData[item.DealerName]) {
        transformedData[item.DealerName] = {
          name: item.DealerName,
          dealerId: item.DealerID
        };
      }
      transformedData[item.DealerName][`${item.NetworkName}`] = item.DealerPrice;
    });

    const resultArray = Object.values(transformedData);

    const topRowNetworks = networkData.flat(1);

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([['Dealer Name', 'Dealer ID', ...networkData.flat(1)]]);
    let dealerColIndex = 1;

    if (resultArray && resultArray.length > 0) {
      resultArray && resultArray.length > 0 && resultArray.forEach((planData) => {
        let newRow = [];
        const keys = Object.keys(planData);
        const dealerRow = keys.map(key => planData[key]);
        newRow.push(dealerRow[0]);
        newRow.push(dealerRow[1]);

        topRowNetworks.forEach((item) => {
          if (planData[item]) {
            newRow.push(planData[item]);
          } else {
            newRow.push(0);
          }
        });

        XLSX.utils.sheet_add_aoa(ws, [newRow], { origin: { r: dealerColIndex, c: 0 } });
        dealerColIndex += 1;
      })
    } else {
      dealerData.forEach((dealer) => {
        const dealerRow = [dealer.name, dealer.dealerId];
        XLSX.utils.sheet_add_aoa(ws, [dealerRow], { origin: { r: dealerColIndex, c: 0 } });
        dealerColIndex += 1;
      });
    }

    XLSX.utils.book_append_sheet(wb, ws, 'Recharge Price Mapping');
    const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    let fileName = "Existing Daily Plan Price";
    FileSaver.saveAs(data, `${fileName}.csv`);
  }

  const handleFileChange = (e) => {
    const fileReader = new FileReader();
    let file = e.target.files[0];
    let uploadedData = [];
    if (file) {
      fileReader.onload = async ({ target }) => {
        const csvOutput = Papa.parse(target.result, { header: true });
        const parsedData = csvOutput?.data;
        const columns = Object.keys(parsedData[0]);
        let customizeData = [];

        networkResult.map((item) => {
          customizeData.push({
            column: `${item.NetworkName}`,
            networkID: item.ID
          });
        });

        dealerData.forEach((dealer) => {
          customizeData.forEach((item) => {
            let row = parsedData.filter((item1) => item1['Dealer ID'] == dealer.dealerId);
            if (row.length > 0) {
              uploadedData.push({
                dealerId: dealer.dealerId,
                dealerName: dealer.name,
                networkID: item.networkID,
                planHeading: item.column,
                dealerPrice: row[0][item.column]
              });
            } else {
              console.error(`No data found for dealer with ID ${dealer.dealerId}`);
            }
          });
        });

        const uniquePlanIds = [...new Set(uploadedData.map(item => item.planHeading))];
        const heading = [
          { displayName: '#', key: 'key' },
          { displayName: 'Dealer', key: 'dealerName' },
          ...uniquePlanIds.map(id => ({ displayName: `${id}`, key: `${id}` }))
        ];

        setPreviewHeading(heading)
        const groupedData = uploadedData.reduce((acc, item) => {
          const { dealerId, dealerName, planHeading, dealerPrice } = item;
          if (!acc[dealerId]) {
            acc[dealerId] = { dealerId, dealerName };
          }
          acc[dealerId][planHeading] = `$${dealerPrice}` || `0`;
          return acc;
        }, {});
        setPreviewDataSourceExcel(Object.values(groupedData))
        setUploadedMapping(uploadedData);
        setPreviewSubmitBtn(false);

      };
      fileReader.readAsText(file);
    }
  }

  const previewColumns = previewHeading
  const PreviewKeys = previewDataSourceExcel && previewDataSourceExcel.length > 0 && Object.keys(previewDataSourceExcel[0]);
  const previewDataSource = previewDataSourceExcel && previewDataSourceExcel.length > 0 && previewDataSourceExcel.map((plan, index) => {
    const obj = { key: index + 1 };
    PreviewKeys.forEach(key => {
      obj[key] = plan[key];
    });
    return obj;
  });


  const onSubmit = (data) => {
    let UpdateDataArray = [];
    let insertedArray = [];
    let deletedArray = [];


    uploadedMapping.forEach((firstItem) => {
      if (parseFloat(firstItem.dealerPrice) >= 0) {
        let matchFound = false;
        dailyPlanMappingData.forEach((secondItem) => {
          if (firstItem.dealerId === secondItem.DealerID && firstItem.networkID === secondItem.NetworkID) {
            matchFound = true;
            UpdateDataArray.push(firstItem);
          }
        });
        if (!matchFound) {
          insertedArray.push(firstItem);
        }
      }
    });

    dailyPlanMappingData.forEach((firstItem) => {
      uploadedMapping.forEach((secondItem) => {
        if (firstItem.DealerID === secondItem.dealerId && firstItem.NetworkID === secondItem.networkID && (secondItem.dealerPrice == 0 || secondItem.dealerPrice == null)) {
          deletedArray.push({
            dealerId: firstItem.DealerID,
            networkID: firstItem.NetworkID,
            dealerPrice: secondItem.dealerPrice ?? 0
          });
        }
      });
    });

    const finalData = { "insertData": insertedArray, "updateData": UpdateDataArray, "deleteData": deletedArray };
    reset();
    dispatch(updateDailyPlanMapping(finalData));
  }

  const handleSideButton = () => {
    if (networkResult.length <= 0) {
      navigate("/network");
    } else if (dealerResult.length <= 0) {
      navigate("/dealer");
    }
  }

  return (
    <>
      {
        !uploadPlanPriceMapping ? <>
          <div className='row align-items-baseline'>
            <div className="col-md-6 od-2">
              <SearchBar onFilter={handleFilterChange} filterText={filterText} /></div>
            <div className="col-md-6  text-right">
              {
                networkResult.length <= 0 ?
                  <SideButtons title="Add Network" buttonStyle="primary" onClick={handleSideButton} /> :
                  dealerResult.length <= 0 ?
                    <SideButtons title="Add Dealer" buttonStyle="primary" onClick={handleSideButton} /> :
                    <Button icon={<FolderUploadLineIcon color='#fff' className='mr-1' size={'0.9rem'} />} title='Upload' buttonClass="btn btn-primary float-right ml-2" onClick={uploadView} loading={false} buttonType='submit' />
              }
            </div>
          </div>

          <Table data={DataSource} columns={heading} loading={dailyPlanMappingLoading} />
        </> :
          <>
            <div className='row align-items-center mb-4'>
              <div className="col-md-12 text-right">
                <Button icon={<DownloadCloud2sLineIcon color='#fff' className='mr-1' size={'0.9rem'} />} title='Existing Recharge Mapping' buttonClass="btn btn-primary float-right" onClick={exportToCSV} loading={false} buttonType='submit' />
              </div>
            </div>

            <div className="row">
              <div className='col-md-12'>
                <form className="form px-4" onSubmit={handleSubmit(onSubmit)}>
                  <div className="input-group">
                    <label htmlFor="csvFile"><span className='text-danger'>*</span>Upload File </label>
                    <input
                      type="file"
                      className="form-control-file"
                      name='uploadedFile'
                      id="csvFile"
                      accept='.csv'
                      {...register('uploadedFile', { required: true })}
                      onChange={(e) => handleFileChange(e)}
                    />
                  </div>

                  {previewHeading && previewHeading.length > 0 && <Table data={previewDataSource} loading={false} columns={previewColumns} />}

                  <div className='text-right row mt-2'>
                    <div className="col-md-12">
                      <button type="button" className="btn btn-secondary mr-2" onClick={closeUploadView}>Close</button>
                      <Button title="Submit" disable={previewSubmitBtn} style="minWidth:80px" buttonClass="btn btn-primary" loading={updateDailyPlanMappingLoading} buttonType='submit' />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </>
      }
    </>
  )
}
