const actions = {
    GET_TMO_DEALER: 'GET_TMO_DEALER',
    GET_TMO_DEALER_SUCCESS: 'GET_TMO_DEALER_SUCCESS',
    GET_TMO_DEALER_FAILED: 'GET_TMO_DEALER_FAILED',
    ADD_TMO_DEALER: "ADD_TMO_DEALER",
    ADD_TMO_DEALER_SUCCESS: "ADD_TMO_DEALER_SUCCESS",
    ADD_TMO_DEALER_FAILED: "ADD_TMO_DEALER_FAILED",
    DELETE_TMO_DEALER: "DELETE_TMO_DEALER",
    DELETE_TMO_DEALER_SUCCESS: "DELETE_TMO_DEALER_SUCCESS",
    PREPARE_TMO_DEALER_FORM:'PREPARE_TMO_DEALER_FORM',
    RESET_TMO_DEALER_FORM:'RESET_TMO_DEALER_FORM',
    RESET_TMO_DEALER_MESSAGE:'RESET_TMO_DEALER_MESSAGE',
      
    getTmoDealer: () => ({
        type: actions.GET_TMO_DEALER,
    }),
    getTmoDealerSuccess: (tmoDealerResult) => ({
        type: actions.GET_TMO_DEALER_SUCCESS,
        tmoDealerResult
    }),
    getTmoDealerFailed: (tmoDealerError) => ({
        type: actions.GET_TMO_DEALER_FAILED,
        tmoDealerError
    }),

    addTmoDealer: (data) => ({
        type: actions.ADD_TMO_DEALER,
        payload: { data }
    }),
    addTmoDealerSuccess: (tmoDealerMessage, tmoDealerResult) => ({
        type: actions.ADD_TMO_DEALER_SUCCESS,
        tmoDealerResult,
        tmoDealerMessage
    }),
    addTmoDealerFailed: (tmoDealerError) => ({
        type: actions.ADD_TMO_DEALER_FAILED,
        tmoDealerError
    }),

    prepareTmoDealerForm: () => ({
        type: actions.PREPARE_TMO_DEALER_FORM,
    }),
    resetForm: () => ({
        type: actions.RESET_TMO_DEALER_FORM,
    }),
    
    deleteTmoDealer: (tmoDealerId, status) => ({
        type: actions.DELETE_TMO_DEALER,
        payload: { tmoDealerId, status }
    }),
    deleteTmoDealerSuccess: (tmoDealerMessage, tmoDealerResult) => ({
        type: actions.DELETE_TMO_DEALER_SUCCESS,
        tmoDealerMessage,
        tmoDealerResult
       
    }),

    resetTmoDealerMessage: () => ({
        type: actions.RESET_TMO_DEALER_MESSAGE,
    }),
    
};
export default actions;
