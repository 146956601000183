import React from 'react'
import CardLayout from '../../../components/utils/CardLayout'
import DatePicker from "react-datepicker";
import moment from "moment";
import { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Calendar2LineIcon from 'remixicon-react/Calendar2LineIcon';
import DownloadCloud2sLineIcon from 'remixicon-react/DownloadCloud2LineIcon';
import { useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import Button from '../../../components/utils/Button'
import SearchBar from '../../../components/utils/SearchBar';
import Tag from '../../../components/utils/Tag'
import Table from '../../../components/utils/Table';
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { convertDateTimeToRequiredFormatTZ, makeSecondIntoMinuteSecond } from '../../../helpers/commonFunction';
import AuthActions from '../../../redux/auth/actions'
import IqRequestReport from '../../../redux/iqRequestReport/actions'
import networkActions from '../../../redux/network/actions';


const { getUserDataByToken } = AuthActions;
const { getIQRequestReport } = IqRequestReport;
const { getActiveNetwork } = networkActions;

const IQRequestReport = () => {
    const dispatch = useDispatch();
    const tokenData = useSelector((state) => state.auth.tokenData);
    const { networkResult } = useSelector((state) => state.network);
    const { iqRequestResult, loading, buttonLoading } = useSelector((state) => state.iqRequestReport);
    const [toDate, setToDate] = useState(moment().startOf('day').toDate())
    const [fromDate, setFromDate] = useState(moment().subtract(2, 'days').toDate());
    const datePickerRef = useRef(null);
    const dateFormat = process.env.REACT_APP_DATE_FORMAT;
    const TIMEZONE = process.env.REACT_APP_TIMEZONE;
    const datePickerRef2 = useRef(null);
    const [filterText, setFilterText] = useState("");
    const [selectedDate, setSelectedDate] = useState()
    const [searched, setSearched] = useState(false);
    const [buttonId, setButtonId] = useState('');
    const { register, handleSubmit, formState: { errors }, watch, reset } = useForm();
    const [searchData, setSearchData] = useState({
        serialNumber: '',
        fromDate: fromDate,
        toDate: toDate,
        networkID: ''
    });

    useEffect(() => {
        dispatch(getUserDataByToken());
        dispatch(getActiveNetwork())


    }, []);

    useEffect(() => {
        const newData = {
            ...searchData,
            toDate: moment(toDate).format("YYYY-MM-DD"),
            fromDate: moment(fromDate).format("YYYY-MM-DD"),
        };
        dispatch(getIQRequestReport(newData));
    }, [])


    const openDatePicker = () => {
        datePickerRef.current.setOpen(true);
    };

    const openDatePicker2 = () => {
        datePickerRef2.current.setOpen(true);
    };

    const handleFilterChange = (value) => {
        setFilterText(value);
    };

    const onSubmit = (data) => {
        setButtonId('')
        setSearched(true);
        const newData = {
            ...data,
            toDate: moment(new Date(toDate)).format("YYYY-MM-DD"),
            fromDate: moment(new Date(fromDate)).format("YYYY-MM-DD"),
        };
        setSelectedDate(newData)
        dispatch(getIQRequestReport(newData))
    };

    let columns = [
        { displayName: '#', key: 'key' },
        { displayName: 'TRANSACTIONID', key: 'transactionID' },
        { displayName: 'IMEI', key: 'IMEI' },
        { displayName: 'SERIAL/EID', key: 'serial' },
        { displayName: 'ACCOUNT NUMBER', key: 'accountNumber' },
        { displayName: 'MOBILE NUMBER', key: 'mobileNumber' },
        { displayName: 'NETWORK', key: 'network' },
        { displayName: 'DATE', key: 'requestedDtTm' },
        { displayName: 'IQ CREATED DATE', key: 'iqCreatedDate' },
        { displayName: 'IQ COMPLETED DATE', key: 'iqCompletedDate' },
        { displayName: 'TIME TAKEN', key: 'timeTaken' },
        { displayName: 'SIM', key: 'simType' },
        { displayName: 'STATUS', key: 'status' },
    ];

    const filteredItems = iqRequestResult && iqRequestResult.length > 0 && iqRequestResult.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const data = filteredItems && filteredItems.length > 0 && filteredItems.map((data, key) => {
        const iqCreatedDate = data.IQCreatedDate !== null ? convertDateTimeToRequiredFormatTZ(data.IQCreatedDate) : '';
        const iqCompletedDate = data.IQCompletedDate !== null ? convertDateTimeToRequiredFormatTZ(data.IQCompletedDate) : '';
        return {
            key: key + 1,
            transactionID: data.TransactionID,
            accountNumber: data.AccountNumber,
            status: <Tag color={`${(data.RequestStatusID === 13) ? 'badge-danger' : (data.RequestStatusID === 11 || (data.RequestStatusID >= 18 && data.RequestStatusID <= 22) || data.RequestStatusID === 27) ? 'badge-warning' : (data.RequestStatusID === 12 || data.RequestStatusID === 23 || data.RequestStatusID === 24) ? 'badge-success' : (data.RequestStatusID === 32) ? 'badge-info' : 'badge-secondary'}`} title={`${(data.RequestStatusID === 13) ? 'Cancelled' : (data.RequestStatusID === 11 || (data.RequestStatusID >= 18 && data.RequestStatusID <= 22)) ? 'Processing' : (data.RequestStatusID === 12 || data.RequestStatusID === 23 || data.RequestStatusID === 24) ? 'Processed' : data.RequestStatusID === 27 ? 'Assigned to Care' : (data.RequestStatusID === 32) ? 'Refunded' : 'Pending'}`}> </Tag>,
            TransactionID: data.TransactionID,
            IMEI: data.IMEI,
            serial: data.SerialNumber,
            mobileNumber: data.MSISDN,
            network: data.NetworkName,
            requestedDtTm: data.RequestedDtTm !== null ? convertDateTimeToRequiredFormatTZ(data.RequestedDtTm) : '',
            simType: data.SimType,
            requestType: data.SimTypeID,
            iqCreatedDate: iqCreatedDate,
            iqCompletedDate: iqCompletedDate,
            timeTaken: iqCreatedDate && iqCompletedDate ? makeSecondIntoMinuteSecond(data.TimeTaken) : ''

        }
    });


    return (
        <CardLayout title="IQ Request Report">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-row">

                    <div className="col-lg-2 col-md-4 mb-2">
                        <label>Select Network</label>
                        <select className="form-control" placeholder="Select Network" name="networkID"  {...register("networkID", {
                        })} >
                            <option value="" key="0">All</option>
                            {networkResult.length > 0 && networkResult.map(network => (
                                <option value={network.ID} key={network.ID}>{network.NetworkName}</option>

                            ))}
                        </select>
                    </div>
                    <div className="col-lg-2 col-md-4 d-flex flex-column">
                        <label>From Date</label>
                        <Calendar2LineIcon onClick={openDatePicker} size={'1.5rem'} style={{ cursor: 'pointer', position: 'absolute', 'top': '36px', right: '12px', zIndex: 1 }} />
                        <DatePicker
                            ref={datePickerRef}
                            selected={fromDate}
                            className="form-control"
                            onChange={(date) => setFromDate(date)}
                            dateFormat="yyyy-MM-dd"
                            name="fromDate"
                            showMonthDropdown
                            showYearDropdown
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                            value={fromDate}
                        />
                    </div>

                    <div className="col-lg-2 col-md-4 mb-2 d-flex flex-column">
                        <label>To Date</label>
                        <Calendar2LineIcon onClick={openDatePicker2} size={'1.5rem'} style={{ cursor: 'pointer', position: 'absolute', 'top': '36px', right: '12px', zIndex: 1 }} />
                        <DatePicker
                            ref={datePickerRef2}
                            selected={toDate}
                            className="form-control center"
                            onChange={(date) => setToDate(date)}
                            dateFormat="yyyy-MM-dd"
                            name="todate"
                            showMonthDropdown
                            showYearDropdown
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                            value={toDate}
                        />
                        {errors.dealerName?.type === "required" && (
                            <div className="invalid-feedback">Date is required !</div>
                        )}
                    </div>

                    <div className="col-lg-2 col-md-4 mb-2 d-flex flex-column">
                        <label>IMEI/SIM/Mobile/TXN</label>
                        <input type="text" className="form-control" id="serialNumber" name="serialNumber" placeholder="IMEI/SIM/Mobile/TXN" {...register("serialNumber", {
                        })}></input>
                    </div>

                    <div className='col-lg-2 col-md-4'>
                        <div className="mt-2 d-flex justify-content-start activation-search-btn " >
                            <Button title="Search" style="minWidth:80px" buttonClass={` btn btn-primary sea-btn-tm btn-md Activity_searchBtn__GlBtC`} loading={buttonLoading} buttonType='submit' />
                        </div>
                    </div>

                </div>
                <div className='d-flex justify-content-between mt-3'>
                    <SearchBar onFilter={handleFilterChange} filterText={filterText} />
                    {/* <Button icon={<DownloadCloud2sLineIcon color='#fff' className='mr-1 excel-btn-1-aidit ' size={'0.9rem'} />} title="Export" style={{ marginRight: "10px" }} buttonClass={`btn ${Styles.exportBtn}  activation-Export-btn`} buttonStyle="primary" onClick={exportToCSV} /> */}
                </div>
                <Table columns={columns} data={data} loading={loading} pagination={true} />
            </form>
        </CardLayout>
    )
}

export default IQRequestReport
