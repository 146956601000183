import React, { useEffect, useState, useRef } from 'react'
import Modal from '../../../components/utils/Model';
import Button from '../../../components/utils/Button';
import { useDispatch, useSelector } from 'react-redux';
import dailyPlanRequestActions from '../../../redux/dailyPlanRequest/actions'
import { useForm } from "react-hook-form";
import Alert from '../../../components/utils/Alert';
import authActions from '../../../redux/auth/actions';


const CancelDailyPlanRequest = ({ cancelID, searchData, transactionID }) => {
    const dispatch = useDispatch();
    const { getUserDataByToken } = authActions;
    const { closeCancelModal, cancelDailyPLan, resetDailyPlan } = dailyPlanRequestActions;
    const { cancelModal, cancelerror, cancelLoading } = useSelector((state) => state.dailyPlanRequest);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertText, setAlertText] = useState('');
    const [remarkContent, setRemarkContent] = useState({
        remark: '',
    });
    const { register: registerCancel, handleSubmit: handleCancelDailyPlan, formState: { errors: cancelError }, watch: watchCancel, reset: resetCancel, } = useForm({ defaultValues: remarkContent });


    useEffect(() => {
        if (cancelerror !== null && cancelerror !== undefined) {
            displayAlert(cancelerror, 'alert-danger');
        }
    }, [cancelerror]);

    useEffect(() => {
        dispatch(getUserDataByToken());
    }, [])

    const handleCancel = () => {
        dispatch(closeCancelModal())
    }
    const handleCancelSubmit = (data) => {
        dispatch(cancelDailyPLan(data, cancelID, searchData))
        dispatch(getUserDataByToken());
    }

    const displayAlert = (text, type) => {
        setAlertText(text);
        setAlertType(type);
        setShowAlert(true);
        dispatch(resetDailyPlan());
    };

    const alertCancel = () => {
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
        dispatch(resetDailyPlan());
    }

    return (
        <Modal
            showModal={cancelModal}
            title={`CANCEL DAILY PLAN REQUEST : ${transactionID}`}
            onHide={handleCancel}
            width={700}
            showFooter={false}
        >
            {showAlert && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}
            <form onSubmit={handleCancelDailyPlan(handleCancelSubmit)}>
                <div className="form-row">
                    <div className="col-md-12 mb-3">
                        <label>Remark</label>
                        <textarea className="form-control" placeholder="Enter Remark" id="remark" name="remark" rows="3" cols="30" {...registerCancel("remark", {
                            required: true
                        })}>
                        </textarea>
                        {cancelError.remark?.type === "required" && (
                            <div className="invalid-feedback">Remark required !</div>
                        )}
                    </div>
                </div>
                <hr />
                <div className='text-right row'>
                    <div className="col-md-12">
                        <button type="button" className="btn btn-secondary mr-2" onClick={handleCancel}>Close</button>
                        <Button title="Submit" style="minWidth:80px" loading={cancelLoading} buttonClass="btn btn-primary" buttonType='submit' />
                    </div>
                </div>
            </form>
        </Modal>
    )
}

export default CancelDailyPlanRequest
