import { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/utils/Button';
import { useForm } from "react-hook-form";
import moment from "moment";
import Calendar2LineIcon from 'remixicon-react/Calendar2LineIcon';
import DatePicker from "react-datepicker";
import CardLayout from '../../../components/utils/CardLayout';
import SideButtons from '../../../components/utils/SideButtons';
import SearchBar from '../../../components/utils/SearchBar';
import Table from '../../../components/utils/Table';
import Alert from '../../../components/utils/Alert';
import Tag from '../../../components/utils/Tag';
import RechargeForm from './RechargeForm';
import rechargeRequestAction from '../../../redux/rechargeRequest/actions'
import networkActions from '../../../redux/network/actions';
import shortCodeActions from '../../../redux/shortCodeData/actions'
import AggregatorActions from '../../../redux/aggregator/actions'
import { convertDateTimeToRequiredFormatTZ, isAdmin, isAdminAgent, isDealer } from '../../../helpers/commonFunction';

const { resetRechargeMessage, getRechargeRequest, openRechargeStatusModal, closeRechargeStatusModal, checkRechargeStatus } = rechargeRequestAction;
const { getActiveNetwork } = networkActions;
const { getShortCode } = shortCodeActions;
const { getAggregator } = AggregatorActions;

function RechargeRequest() {
    const datePickerRef = useRef(null);
    const datePickerRef2 = useRef(null);

    const dispatch = useDispatch();
    const [rechargeForm, setRechargeForm] = useState(false);
    const { createRechargeReuestError, createRechargeReuestMessage, getRechargeReuestList, getRechargeReuestLoading, rechargeStatusModal, checkRechargStatusError, checkRechargStatusMessage, checkRechargStatusLoading } = useSelector((state) => state.rechargeRequest);
    const { tokenData } = useSelector((state) => state.auth);
    const { networkResult } = useSelector((state) => state.network);
    const { shortCodeResult } = useSelector((state) => state.shortCode);
    const { list: aggregatorList } = useSelector((state) => state.aggregator);

    const [filterText, setFilterText] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertText, setAlertText] = useState('');
    const [toDate, setToDate] = useState(new Date())
    const [fromDate, setFromDate] = useState(new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000));


    const { register, handleSubmit, formState: { errors }, reset, setValue, getValues } = useForm({
        defaultValues: {
            statusID: ''
        }
    });

    const searchValues = getValues();

    const searchData = useMemo(() => {
        return {
            ...searchValues,
            toDate: moment(toDate).format('YYYY-MM-DD'),
            fromDate: moment(fromDate).format('YYYY-MM-DD')
        }
    }, [searchValues, toDate, fromDate]);

    useEffect(() => {
        dispatch(getRechargeRequest(searchData));
        dispatch(getActiveNetwork());
        dispatch(getAggregator());
        dispatch(getShortCode({
            tableName: "activationrequest",
            fieldName: "RequestStatus"
        }));
    }, []);

    useEffect(() => {
        if (createRechargeReuestError !== null && createRechargeReuestError !== undefined) {
            displayAlert(createRechargeReuestError, 'alert-danger');
        }
        if (createRechargeReuestMessage !== null && createRechargeReuestMessage !== undefined) {
            displayAlert(createRechargeReuestMessage, 'alert-success');
        }

        if (checkRechargStatusError !== null && checkRechargStatusError !== undefined) {
            displayAlert(checkRechargStatusError, 'alert-danger');
        }
        if (checkRechargStatusMessage !== null && checkRechargStatusMessage !== undefined) {
            displayAlert(checkRechargStatusMessage, 'alert-success');
        }

    }, [createRechargeReuestError, createRechargeReuestMessage, checkRechargStatusError, checkRechargStatusMessage]);

    const displayAlert = (text, type) => {
        setAlertText(text);
        setAlertType(type);
        setShowAlert(true);
    };

    const alertCancel = () => {
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
        dispatch(resetRechargeMessage());
    }

    const handleFilterChange = (value) => {
        setFilterText(value);
    };

    const handleRechargeForm = () => {
        dispatch(resetRechargeMessage());
        setRechargeForm(true);
    }

    const handleRechargeFormBack = () => {
        setRechargeForm(false);
    }

    const onSubmit = (data) => {
        data.toDate = moment(toDate).format('YYYY-MM-DD');
        data.fromDate = moment(fromDate).format('YYYY-MM-DD');
        // console.log('data ', data);
        dispatch(getRechargeRequest(data));
    }

    let columns;
    if ((isAdmin(tokenData) || isAdminAgent(tokenData))) {
        columns = [
            { displayName: '#', key: 'key' },
            { displayName: 'ACTION', key: 'action' },
            { displayName: 'STATUS', key: 'Status' },
            { displayName: 'TRANSACTION ID', key: 'TransactionID' },
            { displayName: 'DEALER', key: 'DealerName' },
            { displayName: 'NETWORK', key: 'NetworkName' },
            { displayName: 'MSISDN', key: 'MSISDN' },
            { displayName: 'AGGREGATOR', key: 'AggregatorID' },
            { displayName: 'PLAN', key: 'PlanName' },
            { displayName: 'PRICE', key: 'PlanPrice' },
            { displayName: 'DATE', key: 'RequestedDtTm' },
            { displayName: 'REMARK', key: 'Remark' },
        ];
    } else {
        columns = [
            { displayName: '#', key: 'key' },
            { displayName: 'ACTION', key: 'action' },
            { displayName: 'STATUS', key: 'Status' },
            { displayName: 'TRANSACTION ID', key: 'TransactionID' },
            { displayName: 'DEALER', key: 'DealerName' },
            { displayName: 'NETWORK', key: 'NetworkName' },
            { displayName: 'MSISDN', key: 'MSISDN' },
            { displayName: 'PLAN', key: 'PlanName' },
            { displayName: 'PRICE', key: 'PlanPrice' },
            { displayName: 'DATE', key: 'RequestedDtTm' },
            { displayName: 'REMARK', key: 'Remark' },
        ];

    }


    const filteredItems = getRechargeReuestList && getRechargeReuestList.length > 0 && getRechargeReuestList.filter(
        item => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1
    );

    const data = filteredItems && filteredItems.length > 0 && filteredItems.map((row, key) => {
        return {
            key: key + 1,
            action: <>
                {
                    (row.RequestStatus === 11) ? row.rechargeStatusLoading ? <div className="spinner-border spinner-border-sm text-secondary" role="status"> <span className="sr-only">Loading...</span> </div> : <div className="icon iq-action-icon-box rounded-circle bg-info" onClick={() => handleUpdateRechargeStatus(row.ID)}> <i className="ri-refresh-line"></i></div> : ''
                }
            </>,
            TransactionID: row.TransactionID,
            AggregatorID: row.AggregatorID,
            DealerName: row.DealerName,
            MSISDN: row.MSISDN,
            PlanName: row.PlanName,
            RequestedDtTm: row.RequestedDtTm ? convertDateTimeToRequiredFormatTZ(row.RequestedDtTm) : '',
            PlanPrice: `$${row.PlanPrice}`,
            NetworkName: row.NetworkName,
            Remark: row.Remark,
            Status: <Tag
                color={row.RequestStatus === 13 ? 'badge-danger' : row.RequestStatus === 10 ? 'badge-secondary' : row.RequestStatus === 11 ? 'badge-warning' : 'badge-success'}
                title={row.RequestStatus === 13 ? 'Cancelled' : row.RequestStatus === 11 ? 'Processing' : row.RequestStatus === 10 ? 'Pending' : 'Processed'} />,

        }
    });

    const handleUpdateRechargeStatus = (rechargeId) => {
        let selectedRecharge = getRechargeReuestList.filter((item) => item.ID == rechargeId);
        let req = {
            networkID: selectedRecharge[0].NetworkID,
            AggregatorTXNID: selectedRecharge[0].AggregatorTXNID,
            AggregatorID: selectedRecharge[0].AggregatorID,
            RechargeID: selectedRecharge[0].ID,
            PlanID: selectedRecharge[0].PlanID,
            TransactionID: selectedRecharge[0].TransactionID,
        }

        dispatch(checkRechargeStatus(req, searchData));
    }

    const openDatePicker = () => {
        datePickerRef.current.setOpen(true);
    };

    const openDatePicker2 = () => {
        datePickerRef2.current.setOpen(true);
    };

    return (
        <>
            {
                rechargeForm ? <RechargeForm back={() => handleRechargeFormBack()} search={searchData} /> :
                    <CardLayout title="Recharge Request">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-row">

                                <div className="col-lg-2 col-md-4 mb-2">
                                    <label>Select Network</label>
                                    <select className="form-control" id="networkID" name="networkID" {...register("networkID", {
                                    })} >
                                        <option value="">All</option>
                                        {
                                            networkResult && networkResult.length > 0 && networkResult.filter((item) => item.IsActive == 1).map((item, index) => {
                                                return <option value={item.ID}>{item.NetworkName}</option>
                                            })
                                        }
                                    </select>
                                </div>

                                {
                                    (isAdmin(tokenData) || isAdminAgent(tokenData)) &&
                                    <div className="col-lg-2 col-md-4 mb-2">
                                        <label>Select Aggregator</label>
                                        <select className="form-control" id="aggregatorID" name="aggregatorID"  {...register("aggregatorID", {
                                        })} >
                                            <option value="" name="All">All</option>
                                            {
                                                aggregatorList && aggregatorList.length && aggregatorList.filter((item) => item.IsActive).map((item) => {
                                                    return <option value={item.ID} key={`aggregator${item.ID}`}>{item.ID}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                }

                                <div className="col-lg-2 col-md-4 mb-2">
                                    <label>Select Status</label>
                                    <select className="form-control" id="statusID" name="statusID" placeholder="Select"  {...register("statusID", {
                                    })} >
                                        {shortCodeResult && shortCodeResult.length > 0 && <option value="" name="All">All</option>}
                                        {shortCodeResult && shortCodeResult.length > 0 && shortCodeResult.map((item, ind) => (
                                            <option value={item.ID}>{item.Value}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="col-lg-2 col-md-4 mb-2 d-flex flex-column">
                                    <label>From Date</label>
                                    <Calendar2LineIcon onClick={openDatePicker} size={'1.5rem'} style={{ cursor: 'pointer', position: 'absolute', 'top': '36px', right: '12px', zIndex: 1 }} />
                                    <DatePicker
                                        ref={datePickerRef}
                                        selected={fromDate}
                                        className="form-control"
                                        onChange={(date) => setFromDate(date)}
                                        dateFormat="yyyy-MM-dd"
                                        name="fromDate"
                                        showMonthDropdown
                                        showYearDropdown
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        value={fromDate}
                                    />
                                </div>

                                <div className="col-lg-2 col-md-4 mb-2 d-flex flex-column">
                                    <label>To Date</label>
                                    <Calendar2LineIcon onClick={openDatePicker2} size={'1.5rem'} style={{ cursor: 'pointer', position: 'absolute', 'top': '36px', right: '12px', zIndex: 1 }} />
                                    <DatePicker
                                        ref={datePickerRef2}
                                        selected={toDate}
                                        className="form-control center"
                                        onChange={(date) => setToDate(date)}
                                        dateFormat="yyyy-MM-dd"
                                        name="todate"
                                        showMonthDropdown
                                        showYearDropdown
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        value={toDate}
                                    />
                                </div>

                                <div className="col-lg-2 mb-2">
                                    <label>MSISDN</label>
                                    <input type="text" className="form-control" id="msisdn" name="MSISDN" placeholder="Enter MSISDN" {...register("MSISDN")} />
                                </div>

                                <div className="mt-4">
                                    <Button title="Search" style="minWidth:80px" buttonClass={`btn btn-primary mt-1 btn-md Activity_searchBtn__GlBtC`} buttonType='submit' />
                                </div>
                            </div>
                        </form>

                        <hr />

                        <div className="row mb-3">
                            <div className='col-lg-6 col-md-6  od-2'>
                                <SearchBar onFilter={handleFilterChange} filterText={filterText} />
                            </div>
                            {
                                isDealer(tokenData) ?
                                    <div className='col-lg-6 d-flex justify-content-end g-3'>
                                        <SideButtons title="Recharge" onClick={handleRechargeForm} />
                                    </div> : ''
                            }
                        </div>

                        {showAlert && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}

                        <div id={'activationRequestTable'}>
                            <Table columns={columns} loading={getRechargeReuestLoading} data={data} pagination={true} />
                        </div>

                    </CardLayout>
            }
        </>
    )
}

const styles = {
    multilineActivation: {
        color: "#e74c3c",
        border: "1px solid",
        padding: "3px 5px",
        borderRadius: "9px"
    }
}

export default RechargeRequest;