const actions = {
    GET_MAIL_BY_MSISDN: 'GET_MAIL_BY_MSISDN',
    GET_MAIL_BY_MSISDN_SUCCESS: 'GET_MAIL_BY_MSISDN_SUCCESS',
    GET_MAIL_BY_MSISDN_FAIL: 'GET_MAIL_BY_MSISDN_FAIL',
    READ_MAIL: 'READ_MAIL',
    READ_MAIL_SUCCESS: 'READ_MAIL_SUCCESS',
    READ_MAIL_FAIL: 'READ_MAIL_FAIL',
    CLOSE_MAIL_TEMPLATE: 'CLOSE_MAIL_TEMPLATE',
    RESET_TEMPLATE_DATA: 'RESET_TEMPLATE_DATA',

    getMailByMsisdn: (searchData) => ({
        type:actions.GET_MAIL_BY_MSISDN,
        payload: { searchData }
    }),

    getMailByMsisdnSuccess: (data) => ({
        type: actions.GET_MAIL_BY_MSISDN_SUCCESS,
        payload: { data }
    }),
    getMailByMsisdnFail: (error) => ({
        type: actions.GET_MAIL_BY_MSISDN_FAIL,
        payload: { error }
    }),

    readMail: (mailID) => ({
        type: actions.READ_MAIL,
        payload: { mailID }
    }),

    readMailSuccess: (message, mailID) => ({
        type: actions.READ_MAIL_SUCCESS,
        payload: { message, mailID }
    }),

    readMailFail: (error) => ({
        type: actions.READ_MAIL_FAIL,
        payload: { error }
    }),

    closeMailTemplate: (mailID) => ({
        type: actions.CLOSE_MAIL_TEMPLATE,
        payload: { mailID }
    }),

    resetTemplateData: () => ({
        type: actions.RESET_TEMPLATE_DATA,
    })
};

export default actions;
