import { useEffect, useMemo, useState } from 'react';
import ArrowLeftLineIcon from 'remixicon-react/ArrowLeftLineIcon';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import Papa from 'papaparse'
import CheckLineIcon from 'remixicon-react/CheckLineIcon';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import DownloadCloud2sLineIcon from 'remixicon-react/DownloadCloud2LineIcon';
import CardLayout from '../../../components/utils/CardLayout'
import Button from '../../../components/utils/Button'
import Table from '../../../components/utils/Table';
import { isKeyUnique, validateMSISDN } from '../../../helpers/commonFunction';
import authActions from '../../../redux/auth/actions';
import rechargeRequestAction from '../../../redux/rechargeRequest/actions'

const { createRechargeRequest, getRecharge, checkValidMsisdn, getNetworksForDealerRecharge } = rechargeRequestAction;

const BASE_URL = process.env.REACT_APP_SITE_BASE_URL;

const { getUserDataByToken } = authActions;

const RechargeForm = ({ back, search }) => {
    const dispatch = useDispatch();
    const { createRechargeReuestLoading, createRechargeReuestError, createRechargeReuestMessage, getRechargeList, checkValidMsisdnData, checkValidMsisdnLoading, getNetworksForDealerRechargeList } = useSelector((state) => state.rechargeRequest);
    const [previewData, setPreviewData] = useState([]);
    const [activationRequests, setActivationRequests] = useState([]);
    const [networkId, setNetworkId] = useState(0);
    const [planAmount, setPlanAmount] = useState(0);
    const [showUploadFile, setShowUploadFile] = useState(false);
    const [apiCall, setApiCall] = useState(false);

    const { register, handleSubmit, formState: { errors }, watch, reset, clearErrors, setValue, setError, control, } = useForm({
        defaultValues: {
            networkID: '',
            planID: '',
        }
    });

    const watchedPlanID = watch('planID');

    const selectedPlan = useMemo(() => {
        if (watchedPlanID > 0) {
            let plan = getRechargeList.filter((item) => item.ID == watchedPlanID);
            if (plan[0].RechargePrice > 0) {
                setValue('price', plan[0].RechargePrice);
            }
            return plan;
        } else {
            return [];
        }
    }, [watchedPlanID]);

    useEffect(() => {
        if (createRechargeReuestError || createRechargeReuestMessage) {
            back();
        }
    }, [createRechargeReuestError, createRechargeReuestMessage]);

    useEffect(() => {
        if (apiCall && previewData.length > 0) {
            let validData = previewData.filter((item) => item.isValid == 1);
            if (validData.length > 0) {
                let msisdn_array = validData.map((item) => item.msisdn);
                dispatch(checkValidMsisdn(msisdn_array));
            }
        }
    }, [previewData]);

    useEffect(() => {
        if (checkValidMsisdnData.length > 0) {
            setApiCall(false);
            let newDataSource = previewData.map((item) => {
                let count = checkValidMsisdnData.filter((item1) => item1.MSISDN == item.msisdn);
                if (count.length > 0) {
                    if (count[0].Status === 0) {
                        item.isValid = 0;
                    }
                    item.message = count[0].ExistStatus;
                }
                return item;
            });
            setPreviewData(newDataSource);

            // For API Request
            let newDataRequest = activationRequests.map((item) => {
                let count = checkValidMsisdnData.filter((item1) => item1.MSISDN == item.msisdn);
                if (count.length > 0) {
                    if (count[0].Status == 0) {
                        item.status = 0;
                        item.reason = count[0].ExistStatus;
                    }
                }
                return item;
            });
            setActivationRequests(newDataRequest);
        }
    }, [checkValidMsisdnData]);

    const amountToPaid = useMemo(() => {
        return planAmount * previewData.length;
    }, [planAmount, previewData]);

    useEffect(() => {
        dispatch(getNetworksForDealerRecharge());
        dispatch(getUserDataByToken());
    }, []);

    const handleNetwork = (e) => {
        e.preventDefault();
        setNetworkId(e.target.value);
        setValue('planID', '');
        setValue('price', '');
        setPlanAmount(0);
        dispatch(getRecharge());
        setShowUploadFile(true)
    }

    const handleFileChange = (e) => {
        clearErrors(['uploadedFile']);
        setPreviewData([]);
        const fileReader = new FileReader();
        let file = e.target.files[0];

        if (!file) {
            setError("uploadedFile", { type: "custom", message: "Csv is required!" })
            return;
        } else {
            let fileNameArr = file.name.split('.');
            let fileExt = fileNameArr[fileNameArr.length - 1];

            if (!["csv"].includes(fileExt.toLowerCase())) {
                setError("uploadedFile", { type: "custom", message: "Invalid file type. Only CSV file allowed." })
                return;
            }
        }

        if (file) {
            setActivationRequests([]);
            fileReader.onload = async ({ target }) => {
                const csvOutput = Papa.parse(target.result, { header: true, skipEmptyLines: true });
                const parsedData = csvOutput?.data;
                const columns = Object.keys(parsedData[0]);

                if (parsedData.length > 100) {
                    setError("uploadedFile", { type: "custom", message: "Maximum 100 rows are allowed" });
                    return;
                }

                const specifiedColumn = ['MSISDN'];
                const isValidCsvHeading = specifiedColumn.every((item) => columns.includes(item));
                if (isValidCsvHeading && columns.length === specifiedColumn.length) {
                    let dataSource = parsedData.map((item) => {
                        let error = false;
                        let message = "";
                        let msisdnError = !validateMSISDN(item.MSISDN);

                        if (msisdnError) {
                            error = true;
                            message = "Invalid MSISDN"
                        }
                        return {
                            msisdn: item.MSISDN,
                            isValid: error ? 0 : 1,
                            message: message
                        }
                    });

                    if (!isKeyUnique(dataSource, 'msisdn')) {
                        setError("uploadedFile", { type: "custom", message: "Csv contains duplicate MSISDN" });
                        return false;
                    }
                    setApiCall(true);
                    setPreviewData(dataSource);

                    let requestData = parsedData.map((item) => {
                        let error = false;
                        let reason = "";

                        if (!validateMSISDN(item.MSISDN)) {
                            reason = "Invalid MSISDN";
                            error = true;
                        }

                        return {
                            msisdn: item.MSISDN,
                            status: error ? 0 : 1,
                            reason: reason
                        }
                    });
                    setActivationRequests(requestData);

                } else {
                    setError("uploadedFile", { type: "custom", message: "Invalid Csv Selected" });
                }
            };
            fileReader.readAsText(file);
        }
    }

    const previewColumns = [
        { displayName: 'MSISDN', key: 'msisdn' },
        { displayName: 'VALID', key: 'Valid' },
        { displayName: 'REASON', key: 'errorReason' }
    ];

    const dataSource = previewData && previewData.length > 0 && previewData.map((item, key) => {
        return {
            msisdn: item.msisdn,
            Valid: item.isValid ? <CheckLineIcon color='#2ecc71' /> : <CloseLineIcon color='#e74c3c' />,
            errorReason: item.isValid == 0 ? <span style={{ color: "#e74c3c" }}>{item.message}</span> : '',
        }
    });

    const downloadSampleFile = () => {
        let url = `${BASE_URL}/assets/csv/recharge-sample-file.csv`;
        let link = document.createElement('a');
        link.href = url;
        link.download = 'Recharge Sample File.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleAddRechargeRequest = (data) => {
        delete data.uploadedFile
        let successCount = activationRequests.filter((item) => item.status == 1).length;
        if (successCount > 0) {
            let req = {
                ...data,
                rechargeData: activationRequests
            }
            // console.log('req ', req);
            dispatch(createRechargeRequest(req, search));
        } else {
            alert('Invalid data for upload');
        }
    }

    return (
        <CardLayout title="Recharge">
            <div className="row mb-3">
                <div className='col-lg-12 text-right'>
                    <button type="button" onClick={() => downloadSampleFile()} style={{ background: '#2ecc71', color: '#fff', pointerEvents: 'auto' }} className={`btn font-weight-bold mr-2`}><DownloadCloud2sLineIcon size={16} />Sample File</button>
                    <button type="button" onClick={() => back()} className={`btn btn-danger font-weight-bold`}><ArrowLeftLineIcon size={16} />Back</button>
                </div>
            </div>

            <div className='container'>
                <form onSubmit={handleSubmit(handleAddRechargeRequest)}>
                    <div className="form-row">

                        <div className="col-md-4 mb-3">
                            <label className='ml-2'> Network <span className='text-danger'>*</span></label>
                            <select className="form-control" placeholder="Select Network" name="networkID"  {...register("networkID", {
                                required: 'Network is Required',
                            })} onChange={(e) => { handleNetwork(e) }}>
                                <option value="" key="0" disabled>Select</option>
                                {getNetworksForDealerRechargeList.length > 0 && getNetworksForDealerRechargeList.map(network => (
                                    <option value={network.ID} key={network.ID}>{network.NetworkName}</option>
                                ))}
                            </select>
                            {errors && errors.networkID && (<div className="invalid-feedback">{errors.networkID.message}</div>)}
                        </div>

                        {
                            networkId > 0 ?
                                <div className="col-md-4 mb-3">
                                    <label className='ml-2'> Plan <span className='text-danger'>*</span></label>
                                    <select className="form-control" placeholder="Select Network" name="planID"  {...register("planID", {
                                        required: 'Plan is Required',
                                    })} >
                                        <option value="" key="recharge-paln-0" disabled>Select</option>
                                        {getRechargeList.length > 0 && getRechargeList.filter((item) => item.NetworkID == networkId).map(item => (
                                            <option value={item.ID} key={`recharge-paln-${item.ID}`}>{item.PlanName}</option>
                                        ))}
                                    </select>
                                    {errors && errors.planID && (<div className="invalid-feedback">{errors.planID.message}</div>)}
                                </div> : ''
                        }


                        {
                            selectedPlan.length > 0 ?
                                <div className="col-md-4 mb-3">
                                    <label className='ml-2'> Price <span className='text-danger'>*</span></label>
                                    <input className="form-control" placeholder="Enter Price" disabled={selectedPlan[0].IsRange === 0} name="planID"  {...register("price", {
                                        required: 'Price is Required',
                                        validate: (val) => {
                                            if (selectedPlan[0].IsRange === 0) {
                                                return true;
                                            }
                                            if (val >= selectedPlan[0].MinPrice && val <= selectedPlan[0].MaxPrice) {
                                                return true;
                                            } else {
                                                return `Price should be lies between ${selectedPlan[0].MinPrice} - ${selectedPlan[0].MaxPrice}`;
                                            }
                                        }
                                    })} />
                                    {errors && errors.price && (<div className="invalid-feedback">{errors.price.message}</div>)}
                                </div> : ''
                        }


                    </div>

                    {showUploadFile && <div className="col-md-12 mb-3 p-0">
                        <div className="input-group">
                            <label htmlFor="csvFile"><span className='text-danger'>*</span>Upload File </label>
                            <input
                                type="file"
                                className="form-control-file"
                                name='uploadedFile'
                                id="csvFile"
                                accept='.csv'
                                {...register('uploadedFile', { required: 'Csv is required' })}
                                onChange={(e) => handleFileChange(e)}
                            />
                            <small className='text-danger'>Note : Only 100 Rows are allowed</small>
                            {errors && errors.uploadedFile && (<div className="invalid-feedback">{errors.uploadedFile.message}</div>)}
                        </div>
                    </div>}

                    {
                        previewData.length > 0 &&
                        <>
                            <hr />
                            <div className="col-md-12 mb-3 p-0 recharge-form-tble">
                                <Table columns={previewColumns} loading={checkValidMsisdnLoading} data={dataSource} pagination={false} />
                            </div>
                        </>
                    }

                    <hr />
                    <div className='text-right row'>
                        <div className="col-md-12">
                            <button type="button" className="btn btn-secondary mr-2" onClick={() => back()}>Close</button>
                            <Button title="Submit" style="minWidth:80px" loading={createRechargeReuestLoading || checkValidMsisdnLoading} buttonClass="btn btn-primary" buttonType='submit' />
                        </div>
                    </div>
                </form>
            </div>

        </CardLayout>
    )
}

export default RechargeForm;