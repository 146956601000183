const actions = {
    UPLOAD_JAR_FILE: 'UPLOAD_JAR_FILE',
    UPLOAD_JAR_FILE_SUCCESSFUL: 'UPLOAD_JAR_FILE_SUCCESSFUL',
    UPLOAD_JAR_FILE_FAIL: 'UPLOAD_JAR_FILE_FAIL',
    GET_JAR_FILE: "GET_JAR_FILE",
    GET_JAR_FILE_SUCCESSFUL: "GET_JAR_FILE_SUCCESSFUL",
    GET_JAR_FILE_FAIL: "GET_JAR_FILE_FAIL",
    EDIT_DEALER: "EDIT_DEALER",


    uploadJarFile: (data) => ({
        type: actions.UPLOAD_JAR_FILE,
        payload: data
    }),
    uploadJarFileSuccessfull: (data) => ({
        type: actions.UPLOAD_JAR_FILE_SUCCESSFUL,
        payload: data
    }),
    uploadJarFileFail: (apiError) => ({
        type: actions.UPLOAD_JAR_FILE_FAIL,
        payload: apiError
    }),

    getJarFile: () => ({
        type: actions.GET_JAR_FILE,
    }),
    getJarFileSuccessfull: (data) => ({
        type: actions.GET_JAR_FILE_SUCCESSFUL,
        payload: data
    }),
    getJarFileFail: (apiError) => ({
        type: actions.GET_JAR_FILE_FAIL,
        payload: apiError
    }),


};
export default actions;
