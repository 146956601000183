import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData } from '../../helpers/fackBackend_Helper';

function* getAssetPerformanceReportResponse({ payload: { searchData } }) {
    try {
        const response = yield call(addData, '/assetPerformanceReport', searchData);
        if (response.Status) {
            yield put(
                actions.getAssetPerformanceReportSuccess(
                    response.Data
                ));
        } else {

            yield put(actions.getAssetPerformanceReportFailed(response.Message));
        }
    } catch (error) {

        yield put(actions.getAssetPerformanceReportFailed(error));
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.GET_ASSET_PERFORMANCE_REPORT, getAssetPerformanceReportResponse)]);
    
}