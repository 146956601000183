const actions = {
    GET_ADDON_WISE_MAPPING: "GET_ADDON_WISE_MAPPING",
    GET_ADDON_WISE_MAPPING_SUCCESS: "GET_ADDON_WISE_MAPPING_SUCCESS",
    GET_ADDON_WISE_MAPPING_FAILED: "GET_ADDON_WISE_MAPPING_FAILED",
    GET_ALL_ADDON_PRICE_MAPPING_DATA: "GET_ALL_ADDON_PRICE_MAPPING_DATA",
    GET_ALL_ADDON_PRICE_MAPPING_DATA_SUCCESS: "GET_ALL_ADDON_PRICE_MAPPING_DATA_SUCCESS",
    GET_ALL_ADDON_PRICE_MAPPING_DATA_FAILED: "GET_ALL_ADDON_PRICE_MAPPING_DATA_FAILED",
    UPDATE_ADDON_PRICE_MAPPING_DATA: "UPDATE_ADDON_PRICE_MAPPING_DATA",
    UPDATE_ADDON_PRICE_MAPPING_DATA_SUCCESS: "UPDATE_ADDON_PRICE_MAPPING_DATA_SUCCESS",
    UPDATE_ADDON_PRICE_MAPPING_DATA_FAILED: "UPDATE_ADDON_PRICE_MAPPING_DATA_FAILED",
    RESET_ADDON_PRICE_MAPPING_MESSAGES: "RESET_ADDON_PRICE_MAPPING_MESSAGES",

    getAddonWiseMapping: () => ({
        type: actions.GET_ADDON_WISE_MAPPING,
    }),
    getAddonWiseMappingSuccess: (data) => ({
        type: actions.GET_ADDON_WISE_MAPPING_SUCCESS,
        payload: { data }
    }),
    getAddonWiseMappingFailed: (error) => ({
        type: actions.GET_ADDON_WISE_MAPPING_FAILED,
        payload: { error }
    }),
    getAllAddonPriceMappingData: () => ({
        type: actions.GET_ALL_ADDON_PRICE_MAPPING_DATA,
    }),
    getAllAddonPriceMappingDataSuccess: (data) => ({
        type: actions.GET_ALL_ADDON_PRICE_MAPPING_DATA_SUCCESS,
        payload: { data }
    }),
    getAllAddonPriceMappingDataFailed: (error) => ({
        type: actions.GET_ALL_ADDON_PRICE_MAPPING_DATA_FAILED,
        payload: { error }
    }),
    updateAddonPriceMappingData: (data) => ({
        type: actions.UPDATE_ADDON_PRICE_MAPPING_DATA,
        data
    }),
    updateAddonPriceMappingDataSuccess: (message, data) => ({
        type: actions.UPDATE_ADDON_PRICE_MAPPING_DATA_SUCCESS,
        message, data
    }),
    updateAddonPriceMappingDataFailed: (error) => ({
        type: actions.UPDATE_ADDON_PRICE_MAPPING_DATA_FAILED,
        error
    }),
    resetAddonPriceMappingMessages: () => ({
        type: actions.RESET_ADDON_PRICE_MAPPING_MESSAGES,
    })

}
export default actions;