const actions = {
    GET_SHORT_CODE: 'GET_SHORT_CODE',
    GET_SHORT_CODE_SUCCESS: 'GET_SHORT_CODE_SUCCESS',
    GET_SHORT_CODE_FAILED: "GET_SHORT_CODE_FAILED",
    GET_SIM_TYPE: 'GET_SIM_TYPE',
    GET_SIM_TYPE_SUCCESS: 'GET_SIM_TYPE_SUCCESS',
    GET_SIM_TYPE_FAILED: "GET_SIM_TYPE_FAILED",

    getSimType: (data) => ({
        type: actions.GET_SIM_TYPE,
        payload: { data }
    }),
    getSimTypeSuccess: (data) => ({
        type: actions.GET_SIM_TYPE_SUCCESS,
        payload: { data }
    }),
    getSimTypeFailed: (error) => ({
        type: actions.GET_SIM_TYPE_FAILED,
        payload: { error }
    }),

    getShortCode: (data) => ({
        type: actions.GET_SHORT_CODE,
        payload: { data }
    }),
    getShortCodeSuccess: (shortCodeResult) => ({
        type: actions.GET_SHORT_CODE_SUCCESS,
        shortCodeResult
    }),
    getShortCodeFailed: (error) => ({
        type: actions.GET_SHORT_CODE_FAILED,
        error
    }),
};
export default actions;
