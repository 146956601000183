import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData } from '../../helpers/fackBackend_Helper';

function* getManualActivationReportResponse({ payload: { reportingdata } }) {
    try {
        const response = yield call(addData, '/getManualActivationReport', reportingdata);
        if (response.Status) {
            yield put(
                actions.getManualActivationReportSuccess(
                    response.Data
                ));
        } else {

            yield put(actions.getManualActivationReportFailed(response.Message));
        }
    } catch (error) {

        yield put(actions.getManualActivationReportFailed(error));
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.GET_MANUAL_ACTIVATION_REPORT, getManualActivationReportResponse)]);
    
}