import React from 'react'
import CardLayout from '../../../components/utils/CardLayout'
import DatePicker from "react-datepicker";
import moment from "moment";
import { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Calendar2LineIcon from 'remixicon-react/Calendar2LineIcon';
import DownloadCloud2sLineIcon from 'remixicon-react/DownloadCloud2LineIcon';
import { useForm } from "react-hook-form";
import Button from '../../../components/utils/Button'
import SearchBar from '../../../components/utils/SearchBar';
import Table from '../../../components/utils/Table';
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Styles from '../AssetPerformanceReport/assetPerormanceReport.module.css'
import AssetActions from '../../../redux/asset/actions'
import AssetPerformanceReportActions from '../../../redux/assetPerformanceReport/actions'
import Chart from 'react-apexcharts'
import ReactApexChart from 'react-apexcharts';

const { getAsset } = AssetActions;
const { getAssetPerformanceReport } = AssetPerformanceReportActions;

function AssetPerformanceReport() {
    const dispatch = useDispatch();
    const { assetData } = useSelector((state) => state.asset);
    const { assetPerformance, loading } = useSelector((state) => state.assetPerformanceReport);
    const dateFormat = process.env.REACT_APP_DATE_FORMAT;
    const { register, handleSubmit, formState: { errors }, watch, reset } = useForm();
    const [toDate, setToDate] = useState(moment().startOf('day').toDate())
    const [fromDate, setFromDate] = useState(moment().subtract(7, 'days').toDate());
    const datePickerRef = useRef(null);
    const datePickerRef2 = useRef(null);
    const [selectedDate, setSelectedDate] = useState()
    const TIMEZONE = process.env.REACT_APP_TIMEZONE;
    const [searched, setSearched] = useState(false);
    const [filterText, setFilterText] = useState("");
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const [searchData, setSearchData] = useState({
        fromDate: fromDate,
        toDate: toDate,
        Asset: ""
    })

    useEffect(() => {
        dispatch(getAsset(0));
    }, []);

    useEffect(() => {
        const newData = {
            toDate: moment(toDate).format("YYYY-MM-DD"),
            fromDate: moment(fromDate).format("YYYY-MM-DD"),
            Asset: ""
        };
        dispatch(getAssetPerformanceReport(newData));
    }, [])


    const columns = [
        { displayName: '#', key: 'key' },
        { displayName: 'ASSET NAME	', key: 'assetName' },
        { displayName: 'COUNT', key: 'count' },
    ];

    const handleFilterChange = (value) => {
        setFilterText(value);
    };

    const openDatePicker = () => {
        datePickerRef.current.setOpen(true);
    };
    const openDatePicker2 = () => {
        datePickerRef2.current.setOpen(true);
    };

    const onSubmit = (data) => {
        setSearched(true);
        const newData = {
            ...data,
            assetName: data.assetName == 0 ? '' : data.assetName,
            toDate: moment(new Date(toDate)).tz(TIMEZONE).format("YYYY-MM-DD"),
            fromDate: moment(new Date(fromDate)).tz(TIMEZONE).format("YYYY-MM-DD"),
        };
        setSelectedDate(newData)
        dispatch(getAssetPerformanceReport(newData))
    };
    const renderAssetPerformanceTable = () => {
        if (searched) {
            return (
                <Table data={data} loading={loading} columns={columns} pagination={true} />
            );
        } else {
            return null;
        };
    }

    const filteredItems = assetPerformance && assetPerformance.length > 0 && assetPerformance.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );
    const exportToCSV = async () => {
        if (data && data.length > 0) {
            const ws = XLSX.utils.json_to_sheet(assetPerformance);
            const wb = { Sheets: { 'assetPerformance': ws }, SheetNames: ['assetPerformance'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            await FileSaver.saveAs(data, 'Asset_Performance_report' + fileExtension);
        }
    }
    var data = filteredItems && filteredItems.length > 0 && filteredItems.map((Item, key) => {
        return {
            key: key + 1,
            assetName: Item.JARServerName,
            count: Item.CountData,
        }
    });
    const chartData = {
        series: assetPerformance && assetPerformance.length > 0 ? assetPerformance.map((item) => item.CountData) : [],
        options: {
            chart: {
                width: '100%',
            },
            stroke: {
                width: 0,
            },
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: false,
                            total: {
                                showAlways: false,
                                show: false
                            }
                        }
                    }
                }
            },
            labels: assetPerformance && assetPerformance.length > 0 ? assetPerformance.map((item) => item.JARServerName) : [],

            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },


    };
    return (
        <CardLayout title="Asset Performance Report">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-row px-4">

                    <div className="col-lg-2 col-md-3 mb-3 d-flex flex-column ">
                        <label>Asset</label>
                        <select className="form-control form-select" placeholder="Select Asset" name="assetName"  {...register("assetName", {
                            required: true,
                        })} >
                            <option value="0" key="">Select</option>
                            {assetData && assetData.length > 0 && assetData.map(data => (
                                <option value={data.AssetName} key={data.ID}>
                                    &nbsp;&nbsp; {data.AssetName}</option>

                            ))}
                        </select>
                    </div>


                    <div className="col-lg-2 col-md-3 mb-2 d-flex flex-column">
                        <label>From Date</label>
                        <Calendar2LineIcon onClick={openDatePicker} size={'1.5rem'} style={{ cursor: 'pointer', position: 'absolute', 'top': '36px', right: '12px', zIndex: 1 }} />
                        <DatePicker
                            ref={datePickerRef}
                            selected={fromDate}
                            className="form-control"
                            onChange={(date) => setFromDate(date)}
                            dateFormat="yyyy-MM-dd"
                            name="fromDate"
                            showMonthDropdown
                            showYearDropdown
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                            value={fromDate}
                        />
                    </div>

                    <div className="col-lg-2 col-md-3 mb-2 d-flex flex-column">
                        <label>To Date</label>
                        <Calendar2LineIcon onClick={openDatePicker2} size={'1.5rem'} style={{ cursor: 'pointer', position: 'absolute', 'top': '36px', right: '12px', zIndex: 1 }} />
                        <DatePicker
                            ref={datePickerRef2}
                            selected={toDate}
                            className="form-control center"
                            onChange={(date) => setToDate(date)}
                            dateFormat="yyyy-MM-dd"
                            name="todate"
                            showMonthDropdown
                            showYearDropdown
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                            value={toDate}
                        />
                        {errors.dealerName?.type === "required" && (
                            <div className="invalid-feedback">Date is required !</div>
                        )}
                    </div>

                    <div className="col-lg-2 col-md-3 mb-2  mt-lg-4 mt-md-4">
                        <Button title="Search" style="minWidth:80px" buttonClass={` btn btn-primary mt-1 btn-md Activity_searchBtn__GlBtC`} buttonType='submit' />

                    </div>
                </div>

                <div className='pt-3 m-0 d-flex row gap-2'>
                    <div className=' col-lg-6 col-md-12  mb-3 ' >
                        <div className='' style={{ backgroundColor: "rgb(193 210 193 / 10%)", borderRadius: '10px' }}>
                            {searched ?
                                <div className='d-flex justify-content-between p-3' >
                                    <div className='asset-seacrt-br'><SearchBar onFilter={handleFilterChange} filterText={filterText} /></div>
                                    <Button icon={<DownloadCloud2sLineIcon color='#fff' className='mr-1 excel-btn-1-aidit ' size={'0.9rem'} />} title="Export" buttonClass={`btn ${Styles.exportBtn} assetPerormanceReport_exportBtn`} style={{ marginRight: "10px" }} buttonStyle="primary" onClick={exportToCSV} />
                                </div>
                                : ""}
                            <div className=' asset-table-line'>
                                {renderAssetPerformanceTable()}
                            </div>

                        </div>


                    </div>
                    <div className=' col-lg-6 col-md-12   ' >



                        {searched ?
                            <div className='p-3 asset-activation-div' style={{ backgroundColor: "rgb(193 210 193 / 10%)", borderRadius: '10px', }}>
                                <h4><strong>Asset Activation</strong>
                                </h4><div className='d-flex justify-content-center'>
                                    <ReactApexChart options={chartData.options} series={chartData.series} type="pie" width="380" height={300} /></div>
                            </div>
                            : ""}



                    </div>
                </div>
            </form>
        </CardLayout>
    )
}

export default AssetPerformanceReport