const actions = {
    GET_SIM_SWAP_REQUEST: 'GET_SIM_SWAP_REQUEST',
    GET_SIM_SWAP_REQUEST_SUCCESS: 'GET_SIM_SWAP_REQUEST_SUCCESS',
    GET_SIM_SWAP_REQUEST_FAILED: 'GET_SIM_SWAP_REQUEST_FAILED',

    LOCK_SIM_SWAP_REQUEST: 'LOCK_SIM_SWAP_REQUEST',
    LOCK_SIM_SWAP_REQUEST_SUCCESS: 'LOCK_SIM_SWAP_REQUEST_SUCCESS',
    LOCK_SIM_SWAP_REQUEST_FAILED: 'LOCK_SIM_SWAP_REQUEST_FAILED',

    RELEASE_LOCK_SIM_SWAP_REQUEST: 'RELEASE_LOCK_SIM_SWAP_REQUEST',
    RELEASE_LOCK_SIM_SWAP_REQUEST_SUCCESS: 'RELEASE_LOCK_SIM_SWAP_REQUEST_SUCCESS',
    RELEASE_LOCK_SIM_SWAP_REQUEST_FAILED: 'RELEASE_LOCK_SIM_SWAP_REQUEST_FAILED',

    OPEN_COMPLETE_SIM_SWAP_MODAL: 'OPEN_COMPLETE_SIM_SWAP_MODAL',
    CLOSE_COMPLETE_SIM_SWAP_MODAL: 'CLOSE_COMPLETE_SIM_SWAP_MODAL',

    OPEN_CANCEL_SIM_SWAP_MODAL: 'OPEN_CANCEL_SIM_SWAP_MODAL',
    CLOSE_CANCEL_SIM_SWAP_MODAL: 'CLOSE_CANCEL_SIM_SWAP_MODAL',

    CANCEL_SIM_SWAP_REQUEST: 'CANCEL_SIM_SWAP_REQUEST',
    CANCEL_SIM_SWAP_REQUEST_SUCCESS: 'CANCEL_SIM_SWAP_REQUEST_SUCCESS',
    CANCEL_SIM_SWAP_REQUEST_FAILED: 'CANCEL_SIM_SWAP_REQUEST_FAILED',

    COMPLETE_SIM_SWAP_REQUEST: 'COMPLETE_SIM_SWAP_REQUEST',
    COMPLETE_SIM_SWAP_REQUEST_SUCCESS: 'COMPLETE_SIM_SWAP_REQUEST_SUCCESS',
    COMPLETE_SIM_SWAP_REQUEST_FAILED: 'COMPLETE_SIM_SWAP_REQUEST_FAILED',

    GET_OTP: 'GET_OTP',
    GET_OTP_SUCCESS: 'GET_OTP_SUCCESS',
    GET_OTP_FAILED: 'GET_OTP_FAILED',

    CHECK_SIM_SWAP_COMPLETION: "CHECK_SIM_SWAP_COMPLETION",
    CHECK_SIM_SWAP_COMPLETION_SUCCESS: "CHECK_SIM_SWAP_COMPLETION_SUCCESS",
    CHECK_SIM_SWAP_COMPLETION_FAILED: "CHECK_SIM_SWAP_COMPLETION_FAILED",

    OPEN_SIM_SWAP_FORM_MODAL: 'OPEN_SIM_SWAP_FORM_MODAL',
    CLOSE_SIM_SWAP_FORM_MODAL: 'CLOSE_SIM_SWAP_FORM_MODAL',

    GET_ACTIVATION_DETAILS_BY_MSISDN: 'GET_ACTIVATION_DETAILS_BY_MSISDN',
    GET_ACTIVATION_DETAILS_BY_MSISDN_SUCCESS: 'GET_ACTIVATION_DETAILS_BY_MSISDN_SUCCESS',
    GET_ACTIVATION_DETAILS_BY_MSISDN_FAILED: 'GET_ACTIVATION_DETAILS_BY_MSISDN_FAILED',
    CREATE_SIM_SWAP_REQUEST: 'CREATE_SIM_SWAP_REQUEST',
    CREATE_SIM_SWAP_REQUEST_SUCCESS: 'CREATE_SIM_SWAP_REQUEST_SUCCESS',
    CREATE_SIM_SWAP_REQUEST_FAILED: 'CREATE_SIM_SWAP_REQUEST_FAILED',

    RESET_MESSAGE: 'RESET_MESSAGE',

    openSimSwapFormModal: () => ({
        type: actions.OPEN_SIM_SWAP_FORM_MODAL,
    }),
    closeSimSwapFormModal: () => ({
        type: actions.CLOSE_SIM_SWAP_FORM_MODAL,
    }),

    getOTP: (swapId) => ({
        type: actions.GET_OTP,
        payload: { swapId }
    }),
    getOTPSuccess: (otp) => ({
        type: actions.GET_OTP_SUCCESS,
        payload: { otp }
    }),
    getOTPFailed: (error) => ({
        type: actions.GET_OTP_FAILED,
        payload: { error }
    }),

    checkSimSwapCompletion: (swapId) => ({
        type: actions.CHECK_SIM_SWAP_COMPLETION,
        payload: { swapId }
    }),

    checkSimSwapCompletionSuccess: (message) => ({
        type: actions.CHECK_SIM_SWAP_COMPLETION_SUCCESS,
        payload: { message }
    }),
    checkSimSwapCompletionFailed: (error) => ({
        type: actions.CHECK_SIM_SWAP_COMPLETION_FAILED,
        payload: { error }
    }),


    completeSimSwapRequest: (data, searchData) => ({
        type: actions.COMPLETE_SIM_SWAP_REQUEST,
        payload: { data, searchData }
    }),
    completeSimSwapRequestSuccess: (message) => ({
        type: actions.COMPLETE_SIM_SWAP_REQUEST_SUCCESS,
        payload: { message }
    }),
    completeSimSwapRequestFailed: (error) => ({
        type: actions.COMPLETE_SIM_SWAP_REQUEST_FAILED,
        payload: { error }
    }),

    cancelSimSwapRequest: (data, searchData) => ({
        type: actions.CANCEL_SIM_SWAP_REQUEST,
        payload: { data, searchData }
    }),
    cancelSimSwapRequestSuccess: (message) => ({
        type: actions.CANCEL_SIM_SWAP_REQUEST_SUCCESS,
        payload: { message }
    }),
    cancelSimSwapRequestFailed: (error) => ({
        type: actions.CANCEL_SIM_SWAP_REQUEST_FAILED,
        payload: { error }
    }),

    openCancelSimSwapModal: () => ({
        type: actions.OPEN_CANCEL_SIM_SWAP_MODAL,
    }),
    closeCanceleSimSwapModal: () => ({
        type: actions.CLOSE_CANCEL_SIM_SWAP_MODAL,
    }),

    openCompleteSimSwapModal: () => ({
        type: actions.OPEN_COMPLETE_SIM_SWAP_MODAL,
    }),
    closeCompleteSimSwapModal: () => ({
        type: actions.CLOSE_COMPLETE_SIM_SWAP_MODAL,
    }),

    releaseLockSimSwapRequest: (data, searchData) => ({
        type: actions.RELEASE_LOCK_SIM_SWAP_REQUEST,
        payload: { data, searchData }
    }),
    releaseLockSimSwapRequestSuccess: (message) => ({
        type: actions.RELEASE_LOCK_SIM_SWAP_REQUEST_SUCCESS,
        payload: { message }
    }),
    releaseLockSimSwapRequestFailed: (error) => ({
        type: actions.RELEASE_LOCK_SIM_SWAP_REQUEST_FAILED,
        payload: { error }
    }),

    lockSimSwapRequest: (data, searchData) => ({
        type: actions.LOCK_SIM_SWAP_REQUEST,
        payload: { data, searchData }
    }),
    lockSimSwapRequestSuccess: (message) => ({
        type: actions.LOCK_SIM_SWAP_REQUEST_SUCCESS,
        payload: { message }
    }),
    lockSimSwapRequestFailed: (error) => ({
        type: actions.LOCK_SIM_SWAP_REQUEST_FAILED,
        payload: { error }
    }),

    getSimSwapRequest: (searchData) => ({
        type: actions.GET_SIM_SWAP_REQUEST,
        payload: { searchData }
    }),
    getSimSwapRequestSuccess: (data) => ({
        type: actions.GET_SIM_SWAP_REQUEST_SUCCESS,
        payload: { data }
    }),
    getSimSwapRequestFailed: (error) => ({
        type: actions.GET_SIM_SWAP_REQUEST_FAILED,
        payload: { error }
    }),

    getActivationDetailsByMsisdn: (searchData) => ({
        type: actions.GET_ACTIVATION_DETAILS_BY_MSISDN,
        payload: { searchData }
    }),
    getActivationDetailsByMsisdnSuccess: (data) => ({
        type: actions.GET_ACTIVATION_DETAILS_BY_MSISDN_SUCCESS,
        payload: { data }
    }),
    getActivationDetailsByMsisdnFailed: (error) => ({
        type: actions.GET_ACTIVATION_DETAILS_BY_MSISDN_FAILED,
        payload: { error }
    }),

    createSIMSwapRequest: (data) => ({
        type: actions.CREATE_SIM_SWAP_REQUEST,
        payload: { data }
    }),
    createSIMSwapRequestSuccess: (result, message) => ({
        type: actions.CREATE_SIM_SWAP_REQUEST_SUCCESS,
        payload: { result, message }
    }),
    createSIMSwapRequestFailed: (error) => ({
        type: actions.CREATE_SIM_SWAP_REQUEST_FAILED,
        payload: { error }
    }),

    resetMessage: () => ({
        type: actions.RESET_MESSAGE,
    }),

}
export default actions;