import actions from "./actions";

const initState = {
    assetData: [],
    locationData: [],
    openAssetModal: false,
    openLocationModal: false,
    loading: false,
    buttonLoading: false,
    error: null,
    message: null,
}
export default function reducer(state = initState, action) {

    switch (action.type) {
        case actions.OPEN_ASSET_MODAL:
            return {
                ...state,
                openAssetModal: true,
            };
        case actions.RESET_ASSET:
            return {
                ...state,
                openAssetModal: false,
                openLocationModal: false,
            };
        case actions.OPEN_LOCATION_MODAL:
            return {
                ...state,
                openLocationModal: true,
            };
        case actions.GET_LOCATION:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case actions.GET_LOCATION_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                locationData: action.locationData,
                modal: false,
            };

        case actions.GET_LOCATION_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
                message: null,
            };
        case actions.ADD_LOCATION:
            return {
                ...state,
                buttonLoading: true,
                error: null,
            };

        case actions.ADD_LOCATION_SUCCESS:
            return {
                ...state,
                buttonLoading: false,
                error: null,
                message: action.message,
                locationData: action.locationData,
                openLocationModal: false,
                modal: false,
            };

        case actions.ADD_LOCATION_FAILED:
            return {
                ...state,
                buttonLoading: false,
                error: action.error,
                message: null,
            };
        case actions.UPDATE_LOCATION:
            return {
                ...state,
                buttonLoading: true,
                error: null,
                message: null,
            };

        case actions.UPDATE_LOCATION_SUCCESS:
            return {
                ...state,
                buttonLoading: false,
                message: action.message,
                error: null,
                locationData: action.data,
                openLocationModal: false,
                modal: false,
            };

        case actions.UPDATE_LOCATION_FAILED:
            return {
                ...state,
                buttonLoading: false,
                error: action.error,
                message: null,
            };
        case actions.DELETE_LOCATION:
            return {
                ...state,
                error: null,
                message: null,
                buttonLoading: true,
                openLocationModal: false,

            };
        case actions.DELETE_LOCATION_SUCCESS:
            return {
                ...state,
                error: null,
                message: action.message,
                locationData: action.locationData,
                openLocationModal: false,
                buttonLoading: false,
            };
        case actions.GET_ASSET:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case actions.GET_ASSET_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
                assetData: action.assetData,

            };

        case actions.GET_ASSET_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
                message: null,
            };
        case actions.ADD_ASSET:
            return {
                ...state,
                buttonLoading: true,
                error: null,
                message: null,
            };

        case actions.ADD_ASSET_SUCCESS:
            return {
                ...state,
                buttonLoading: false,
                error: null,
                assetData: action.assetData,
                message: action.message,
                openAssetModal: false,
            };

        case actions.ADD_ASSET_FAILED:
            return {
                ...state,
                buttonLoading: false,
                error: action.error,
                message: null,
            };
        case actions.UPDATE_ASSET:
            return {
                ...state,
                buttonLoading: true,
                error: null,
                message: null,
            };

        case actions.UPDATE_ASSET_SUCCESS:
            return {
                ...state,
                buttonLoading: false,
                error: null,
                assetData: action.assetData,
                message: action.message,
                openAssetModal: false,
            };

        case actions.UPDATE_ASSET_FAILED:
            return {
                ...state,
                buttonLoading: false,
                error: action.error,
                message: null,
            };
            case actions.DELETE_ASSET:
                return {
                    ...state,
                    error: null,
                    message: null,
                    buttonLoading:false,
                    openAssetModal: false,
    
                };
            case actions.DELETE_ASSET_SUCCESS:
                return {
                    ...state,
                    error: null,
                    message: action.message,
                    assetData: action.assetData,
                    openAssetModal: false,
                    buttonLoading: false,
                };
                case actions.UPDATE_JAR:
                    return {
                        ...state,
                        error: null,
                        message: null,
                        buttonLoading:false,
                        openAssetModal: false,
        
                    };
                case actions.UPDATE_JAR_SUCCESS:
                    return {
                        ...state,
                        error: null,
                        message: action.message,
                        assetData: action.assetData,
                        openAssetModal: false,
                        buttonLoading: false,
                    };
        default:
            return state;

    }
}