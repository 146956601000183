const actions = {
    GET_IQ_REQUEST_REPORT: "GET_IQ_REQUEST_REPORT",
    GET_IQ_REQUEST_REPORT_SUCCESS: "GET_IQ_REQUEST_REPORT_SUCCESS",
    GET_IQ_REQUEST_REPORT_FAILED: "GET_IQ_REQUEST_REPORT_FAILED",

    getIQRequestReport: (Data) => ({
        type: actions.GET_IQ_REQUEST_REPORT,
        payload: { Data },
    }),
    getIQRequestReportSuccess: (iqRequestResult) => ({
        type: actions.GET_IQ_REQUEST_REPORT_SUCCESS,
        iqRequestResult
    }),
    getIQRequestReportFailed: (error) => ({
        type: actions.GET_IQ_REQUEST_REPORT_FAILED,
        error
    }),
}

export default actions;