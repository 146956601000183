import actions from "./actions";

const initState = {
    addonResult: [],
    loading: false,
    addonError: null,
    addonMessage: null,
    buttonLoading: false,
    deleteButtonLoading: false,
    addonFormModal: false,
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_ADDON_SERVICE:
            return {
                ...state,
                loading: true,
                addonMessage: null,
                addonError: null,
                buttonLoading: false,
                addonFormModal: false,
            };
        case actions.GET_ADDON_SERVICE_SUCCESS:
            return {
                ...state,
                loading: false,
                addonResult: action.addonResult,
                addonMessage: null,
                addonError: null,
                buttonLoading: false,
                addonFormModal: false,
            };
        case actions.GET_ADDON_SERVICE_FAILED:
            return {
                ...state,
                loading: false,
                addonError: action.addonError,
                addonMessage: null,
                buttonLoading: false,
                addonFormModal: false,
            };
        case actions.ADD_ADDON_SERVICE:
            return {
                ...state,
                loading: false,
                addonMessage: null,
                addonError: null,
                buttonLoading: true,
                addonFormModal: true,
            };
        case actions.ADD_ADDON_SERVICE_SUCCESS:
            return {
                ...state,
                loading: false,
                addonError: null,
                addonMessage: action.addonMessage,
                addonResult: action.addonResult,
                buttonLoading: false,
                addonFormModal: false,
            };
        case actions.ADD_ADDON_SERVICE_FAILED:
            return {
                ...state,
                loading: false,
                addonError: action.addonError,
                addonMessage: null,
                buttonLoading: false,
                addonFormModal: true,
            };
        case actions.EDIT_ADDON_SERVICE:
            return {
                ...state,
                loading: false,
                addonMessage: null,
                addonError: null,
                buttonLoading: true,
                addonFormModal: true,
            };
        case actions.EDIT_ADDON_SERVICE_SUCCESS:
            return {
                ...state,
                loading: false,
                addonError: null,
                addonMessage: action.addonMessage,
                addonResult: action.addonResult,
                buttonLoading: false,
                addonFormModal: false,
            };
        case actions.DELETE_ADDON_SERVICE:
            return {
                ...state,
                loading: false,
                addonMessage: null,
                addonError: null,
                deleteButtonLoading: true,
                addonFormModal: false,
            };
        case actions.DELETE_ADDON_SERVICE_SUCCESS:
            return {
                ...state,
                loading: false,
                addonError: null,
                addonMessage: action.addonMessage,
                addonResult: action.addonResult,
                deleteButtonLoading: false,
                addonFormModal: false,
            };

        case actions.PREPARE_ADDON_FORM:
            return {
                ...state,
                loading: false,
                addonError: null,
                addonMessage: null,
                buttonLoading: false,
                addonFormModal: true,
            };
        case actions.CLOSE_ADDON_FORM:
            return {
                ...state,
                loading: false,
                addonError: null,
                addonMessage: null,
                buttonLoading: false,
                addonFormModal: false,
            };

        case actions.RESET_USER:
            return {
                ...state,
                loading: false,
                addonError: null,
                addonMessage: null,
                buttonLoading: false,
                addonFormModal: false,
            };

        default:
            return state;
    }
}
