import { useEffect } from 'react';
import Modal from '../../../components/utils/Model';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import SimSwapActions from '../../../redux/simSwapRequest/actions';
import Button from '../../../components/utils/Button';

const { closeCanceleSimSwapModal, cancelSimSwapRequest } = SimSwapActions;

const CancelSimSwapModalComponent = ({ row ,search}) => {
    const dispatch = useDispatch();
    const { cancelSimSwapModal, cancelSimSwapRequestLoading } = useSelector((state) => state.simSwapRequest);

    const { register, handleSubmit, formState: { errors }, watch, reset, setValue } = useForm({
        defaultValues: {
            remark: ""
        }
    });


    const handleCancel = () => {
        dispatch(closeCanceleSimSwapModal());
    }

    const onSubmit = (data) => {
        let request = {
            simswapID: row.ID,
            remark: data.remark
        }
        dispatch(cancelSimSwapRequest(request,search));
    }

    return (
        <>
            <Modal
                showModal={cancelSimSwapModal}
                title={`Cancel Sim Swap Request : ${row.TransactionID}`}
                onHide={handleCancel}
                width={700}
                showFooter={false}
            >

                <div className="row text-dark px-2">
                    <div className="col-md-6 d-flex justify-content-between mb-2">
                        <span>Serial #</span>
                        <span className="text-end">{row.SerialNumber ?? row.SerialNumber}</span>
                    </div>
                    <div className="col-md-6 d-flex justify-content-between mb-2">
                        <span>Mobile #</span>
                        <span className="text-end">{row.MSISDN ?? row.MSISDN}</span>
                    </div>
                    <div className="col-md-8 d-flex justify-content-between mb-2">
                        <span>Customer Email</span>
                        <span className="text-end">{row.CustomerEmail ?? row.CustomerEmail}</span>
                    </div>
                </div>
                <hr />

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-row">
                        <div className={`col-md-12 mb-3`}>
                            <label className='ml-2'>Remark<span className='text-danger'> *</span></label>
                            <textarea className="form-control" rows="6" cols="50" placeholder="Enter Remark Here" name="remark"
                                {...register("remark", {
                                    required: 'Remark is required !',
                                })} />
                            {errors && errors.remark && (<div className="invalid-feedback">{errors.remark.message}</div>)}
                        </div>
                    </div>

                    <div className='text-right row'>
                        <div className="col-md-12">
                            <Button title='Cancel' style="minWidth:80px" buttonClass="btn btn-secondary mr-2" onClick={handleCancel} />
                            <Button title='Submit' style="minWidth:80px" buttonClass="btn btn-primary" loading={cancelSimSwapRequestLoading} buttonType='submit' />
                        </div>
                    </div>
                </form>

            </Modal>
        </>
    )
}

export default CancelSimSwapModalComponent;