import React, { useEffect, useState, useRef } from 'react'
import Modal from '../../../components/utils/Model';
import Button from '../../../components/utils/Button';
import { useDispatch, useSelector } from 'react-redux';
import dailyPlanRequestActions from '../../../redux/dailyPlanRequest/actions'
import { useForm } from "react-hook-form";
import networkActions from '../../../redux/network/actions';
import authActions from '../../../redux/auth/actions';
import DatePicker from "react-datepicker";
import Calendar2LineIcon from 'remixicon-react/Calendar2LineIcon';
import moment from 'moment';
import Alert from '../../../components/utils/Alert';
import shortCodeActions from '../../../redux/shortCodeData/actions'
import { validateIMEI } from '../../../helpers/commonFunction';

const DailyActsPlanForm = ({ searchData }) => {
    const dispatch = useDispatch();
    const { getUserDataByToken } = authActions;
    const { getSimType } = shortCodeActions;
    const { shortCodeSimType } = useSelector((state) => state.shortCode);
    const { getActiveNetworkDAilyPlanMapping } = networkActions;
    const { tokenData } = useSelector((state) => state.auth);
    const { networkResultData } = useSelector((state) => state.network);
    const { createDailyPlanRequest, closeDailyPlanRequestModal } = dailyPlanRequestActions;
    const { dailyRequestFormModal, createDailyRequestLoading, dailyRequestError, dailyRequestMessage } = useSelector((state) => state.dailyPlanRequest);
    const [accountBalance, setAccountBalance] = useState(0);
    const datePickerRef2 = useRef(null);
    const datePickerRef = useRef(null);
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date())
    const [selectedDealerPrice, setSelectedDealerPrice] = useState(0);
    const [totalDays, setTotalDays] = useState(1);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertText, setAlertText] = useState('');
    const [selectedNetworkID, setSelectedNetworkID] = useState("");

    const {
        register: registerDailyRequest, handleSubmit: handleDailyRequestSubmit, formState: { errors: createDailyRequestError }, watch: watchDailyRequest, reset: resetDAilyRequestField, setValue, clearErrors, trigger
    } = useForm({
        defaultValues: {
            networkID: "",
            simNumber: "",
            imeiNumber: "",
            simType: "",
            customerEmail: "",
            areaCode: "",
            addonFlag: ""
        }
    });
    let simType = watchDailyRequest("simType")
    let addonIDFlag = watchDailyRequest("addonFlag")

    useEffect(() => {
        dispatch(getActiveNetworkDAilyPlanMapping());
        dispatch(getUserDataByToken());
        dispatch(getSimType({
            tableName: "activationrequest",
            fieldName: "SimType"
        }));
    }, [])

    useEffect(() => {
        tokenData && tokenData.length > 0 && tokenData.map((data => (
            setAccountBalance(data.AccountBalance)
        )))
    }, [tokenData])

    useEffect(() => {
        const calculateDays = () => {
            if (toDate < fromDate) {
                setToDate(fromDate);
            }
            const timeDiff = Math.abs(toDate - fromDate);
            const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;
            setTotalDays(dayDiff);
        };
        calculateDays();
    }, [fromDate, toDate]);

    useEffect(() => {
        const calculateAmount = () => {
            const purchaseAmount = totalDays * selectedDealerPrice;
            if (purchaseAmount > accountBalance) {
            }
        };
        calculateAmount();
    }, [totalDays, selectedDealerPrice, accountBalance]);

    useEffect(() => {
        if (dailyRequestError !== null && dailyRequestError !== undefined) {
            displayAlert(dailyRequestError, 'alert-danger');
        }
        if (dailyRequestMessage !== null && dailyRequestMessage !== undefined) {
            resetDAilyRequestField();
        }
    }, [dailyRequestError, dailyRequestMessage]);

    useEffect(() => {
        if (networkResultData.length === 1) {
            const singleNetwork = networkResultData[0];
            setSelectedNetworkID(singleNetwork.ID);
            setSelectedDealerPrice(singleNetwork.DealerPrice);
            setValue('networkID', singleNetwork.ID);
        }

    }, [networkResultData, setValue]);

    useEffect(() => {
        if (!dailyRequestFormModal) {
            if (networkResultData.length > 1) {
                resetDAilyRequestField();
                setSelectedDealerPrice(0);
                setSelectedNetworkID("");
            }
            setTotalDays(1);
            setFromDate(new Date());
            setToDate(new Date());
        }
    }, [dailyRequestFormModal, resetDAilyRequestField, networkResultData]);

    const handleCancel = () => {
        dispatch(closeDailyPlanRequestModal());
        resetDAilyRequestField();
    }

    const handleNetwork = (e) => {
        const selectedNetworkID = e.target.value;
        const selectedNetwork = networkResultData.find(network => network.ID === parseInt(selectedNetworkID));
        if (selectedNetwork) {
            setSelectedDealerPrice(selectedNetwork.DealerPrice);
            setSelectedNetworkID(selectedNetworkID);
        } else {
            setSelectedDealerPrice(0);
            setSelectedNetworkID("");
        }
    };

    const handleCreateDailyRequestSubmit = (data) => {
        const purchaseAmount = totalDays * selectedDealerPrice;
        if (purchaseAmount > accountBalance) {
            displayAlert('Insufficient account balance to make the purchase', 'alert-danger');
            return;
        }
        data.fromDate = moment(fromDate).format('YYYY-MM-DD');
        data.toDate = moment(toDate).format('YYYY-MM-DD');
        data.purchaseAmount = purchaseAmount;
        data.reqDays = totalDays;
        data.addonFlag = addonIDFlag ? 1 : 0;
        dispatch(createDailyPlanRequest(data, searchData));
        if (networkResultData.length > 1) {
            resetDAilyRequestField();
            setSelectedDealerPrice(0);
            setSelectedNetworkID("");
        }
        setTotalDays(1);
        setFromDate(new Date());
        setToDate(new Date());
        dispatch(getUserDataByToken());
    }

    const displayAlert = (text, type) => {
        setAlertText(text);
        setAlertType(type);
        setShowAlert(true);
    };

    const alertCancel = () => {
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
    }
    const maxDateForToDate = new Date(fromDate);
    maxDateForToDate.setDate(maxDateForToDate.getDate() + 28);

    return (
        <>
            <Modal
                showModal={dailyRequestFormModal}
                title={'ADD DAILY ACTS PLAN'}
                onHide={handleCancel}
                width={700}
                showFooter={false}
            >
                {showAlert && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}
                <form onSubmit={handleDailyRequestSubmit(handleCreateDailyRequestSubmit)}>
                    <div className="form-row">

                        <div className="col-md-6 mb-3">
                            <label className='ml-2'> Network <span className='text-danger'>*</span></label>
                            <select
                                className="form-control"
                                placeholder="Select Network"
                                name="networkID"
                                {...registerDailyRequest("networkID", { required: 'Network is required !' })}
                                onChange={(e) => { handleNetwork(e) }}
                                value={selectedNetworkID}
                            >
                                <option value="" disabled>Select</option>
                                {networkResultData.length > 0 && networkResultData.map(network => (
                                    <option name="network" value={network.ID} key={network.ID}>
                                        {network.NetworkName}
                                    </option>
                                ))}
                            </select>
                            {createDailyRequestError && createDailyRequestError.networkID && (
                                <div className="invalid-feedback">
                                    {createDailyRequestError.networkID.message}
                                </div>
                            )}
                        </div>

                        <div className="col-md-6 mb-3">
                            <label className='ml-2'>IMEI Number <span className='text-danger'>*</span></label>
                            <input type="number" className="form-control" placeholder="Enter IMEI Number" name="imeiNumber"
                                {...registerDailyRequest("imeiNumber", {
                                    validate: (val) => {
                                        if (!val) {
                                            return "IMEI number is required!";
                                        }
                                        if (!validateIMEI(val)) {
                                            return "Invalid IMEI";
                                        }
                                    }
                                })}
                                onWheel={(e) => e.target.blur()}
                                onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()}
                            />
                            {createDailyRequestError?.imeiNumber && (
                                <div className="invalid-feedback">{createDailyRequestError.imeiNumber.message}</div>
                            )}
                        </div>

                        <div className="col-md-6 mb-3">
                            <label className='ml-2'> Sim Type <span className='text-danger'>*</span></label>
                            <select className="form-control" name="simType"
                                {...registerDailyRequest("simType", {
                                    required: 'Sim type is required!',
                                })}>
                                <option value="">Select</option>
                                {shortCodeSimType && shortCodeSimType.length > 0 && shortCodeSimType.map((item) => {
                                    return <option key={item.ID} value={item.ID}>{item.Value}</option>
                                })}
                            </select>
                            {createDailyRequestError?.simType && (
                                <div className="invalid-feedback">{createDailyRequestError.simType.message}</div>
                            )}
                        </div>

                        <div className="col-md-6 mb-3">
                            <label className='ml-2'> SIM Number <span className='text-danger'>*</span></label>
                            <input type="number" className="form-control" placeholder="Enter SIM Number" name="simNumber"
                                {...registerDailyRequest("simNumber", {
                                    validate: (val) => {
                                        if (simType == 25) {
                                            if (!val) {
                                                return "SIM number is required!";
                                            }
                                            if (val.length == 19) {
                                                return 'SIM number should be 19 digits!';
                                            }
                                        }
                                        return true;
                                    },
                                })}
                                onWheel={(e) => e.target.blur()}
                                onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()}
                            />
                            {createDailyRequestError && createDailyRequestError.simNumber && (
                                <div className="invalid-feedback">{createDailyRequestError.simNumber.message}</div>
                            )}
                        </div>

                        {/* <div className="col-md-6 mb-3">
                            <label className='ml-2'> SIM Number {simType == 25 ? <span className='text-danger'> *</span> : ''} </label>
                            <input type="number" className="form-control" placeholder="Enter SIM Number" name="simNumber"
                                {...registerDailyRequest("simNumber", {
                                    required:
                                        simType == 25 ? 'SIM number is required !' : false,
                                    validate: {
                                        commonValidation: (val) => {
                                            if (simType == 25) {
                                                return val.length === 19 ? true : 'SIM number should be 19 digits!';
                                            } else if (simType == 26) {
                                                if (val === '') {
                                                    return true;
                                                } else {
                                                    // return 'E-sim does not require a SIM number';
                                                }
                                            }
                                        },
                                    }
                                })}
                                onWheel={(e) => e.target.blur()}
                                onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()}
                            />
                            {createDailyRequestError && createDailyRequestError.simNumber && (<div className="invalid-feedback"> {createDailyRequestError.simNumber.message} </div>)}
                        </div> */}

                        <div className="col-md-6 mb-3">
                            <label className='ml-2'> Customer Email <span className='text-danger'>*</span></label>
                            <input
                                type="email"
                                className="form-control"
                                placeholder="Enter Your Email"
                                name="customerEmail"
                                {...registerDailyRequest("customerEmail", {
                                    required: 'Customer email is required !',
                                    pattern: { value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/, message: 'Email is not valid !' }
                                })}
                            />
                            {createDailyRequestError.customerEmail && (
                                <div className="invalid-feedback">{createDailyRequestError.customerEmail.message}</div>
                            )}
                        </div>

                        <div className="col-md-6 mb-3">
                            <label className='ml-2'> Zip Code <span className='text-danger'> *</span></label>
                            <input type="number" placeholder="Enter Zip Code" className="form-control" name="areaCode"
                                {...registerDailyRequest("areaCode", {
                                    required: 'Zip code is required!',
                                    minLength: { value: 5, message: 'Zip code should be 5 digit!' },
                                    validate: (value) => value.length <= 5 || 'Zip code should be 5 digit !'
                                })}
                            />
                            {createDailyRequestError && createDailyRequestError.areaCode && (
                                <div className="invalid-feedback">
                                    {createDailyRequestError.areaCode.message}
                                </div>
                            )}
                        </div>

                        <div className="col-lg-6 col-md-4 mb-2 d-flex flex-column">
                            <label>From Date</label>
                            <Calendar2LineIcon onClick={() => datePickerRef.current.setOpen(true)} size={'1.5rem'} style={{ cursor: 'pointer', position: 'absolute', top: '36px', right: '12px', zIndex: 1 }} />
                            <DatePicker
                                ref={datePickerRef}
                                selected={fromDate}
                                className="form-control"
                                onChange={(date) => setFromDate(date)}
                                dateFormat="yyyy-MM-dd"
                                name="fromDate"
                                showMonthDropdown
                                showYearDropdown
                                minDate={new Date()}
                                maxDate={new Date()}
                                onKeyDown={(e) => e.preventDefault()}
                                disabled
                            />
                        </div>

                        <div className="col-lg-6 col-md-4 mb-3 d-flex flex-column">
                            <label>To Date</label>
                            <Calendar2LineIcon onClick={() => datePickerRef2.current.setOpen(true)} size={'1.5rem'} style={{ cursor: 'pointer', position: 'absolute', top: '36px', right: '12px', zIndex: 1 }} />
                            <DatePicker
                                ref={datePickerRef2}
                                selected={toDate}
                                className="form-control center"
                                onChange={(date) => setToDate(date)}
                                dateFormat="yyyy-MM-dd"
                                name="todate"
                                showMonthDropdown
                                showYearDropdown
                                onKeyDown={(e) => e.preventDefault()}
                                minDate={fromDate}
                                maxDate={maxDateForToDate}
                            />
                        </div>

                        <div className="col-md-6 mb-3">
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="addonFlag" style={{ transform: 'scale(1.5)', marginRight: '10px' }}
                                    {...registerDailyRequest("addonFlag",)} />
                                <span className="text-dark">Mexico & Canada Unlimited Calling & Texting</span></div>
                        </div>
                    </div>
                    <hr />
                    <div className='d-flex justify-content-around bg-success ' style={{ padding: "8px", borderRadius: "4px" }}>
                        <h5 className='font-weight-bold text-white  px-lg-4 px-1 '>Daily Rate : $ {selectedDealerPrice}</h5>
                        {/* <h5 className='font-weight-bold text-white  px-lg-4 px-1 '>Account Balance : $ {accountBalance}</h5> */}
                        <h5 className='font-weight-bold text-white px-lg-4 px-1'>Days :  {totalDays}</h5>
                        <h5 className='font-weight-bold text-white px-lg-4 px-1'>Amount : $ {(totalDays * selectedDealerPrice).toFixed(2)}</h5>
                    </div>

                    {accountBalance < (totalDays * selectedDealerPrice) ? <p className='text-danger font-weight-bold text-center'>Insufficient account balance to make the purchase</p> : ""}
                    <hr />

                    <div className='text-right row'>
                        <div className="col-md-12">
                            <button type="button" className="btn btn-secondary mr-2" onClick={handleCancel}>Close</button>
                            <Button title="Confirm" style="minWidth:80px" buttonClass="btn btn-primary" loading={createDailyRequestLoading} buttonType='submit' disable={accountBalance < (totalDays * selectedDealerPrice)} />
                        </div>
                    </div>
                </form>
            </Modal >
        </>
    )
}

export default DailyActsPlanForm
