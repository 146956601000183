import actions from "./actions";

const initState = {
    networkResult: [],
    loading: false,
    networkError: null,
    networkMessage: null,
    buttonLoading: false,
    deleteButtonLoading: false,
    networkFormModal: false,
    preferedAggregatorModal: false,
    networkResultData: [],
};

export default function reducer(state = initState, action) {
    switch (action.type) {

        case actions.UPDATE_PREFFERED_AGGREGATOR:
            return {
                ...state,
                loading: false,
                networkError: null,
                networkMessage: null,
                buttonLoading: true,
                networkFormModal: false
            };
        case actions.UPDATE_PREFFERED_AGGREGATOR_SUCCESS:
            return {
                ...state,
                loading: false,
                networkResult: action.networkResult,
                networkMessage: action.networkMessage,
                networkError: null,
                buttonLoading: false,
                networkFormModal: false,
                preferedAggregatorModal: false,
            };

        case actions.OPEN_PREFERED_AGGREGATOR_MODAL:
            return {
                ...state,
                preferedAggregatorModal: true
            };
        case actions.CLOSE_PREFERED_AGGREGATOR_MODAL:
            return {
                ...state,
                preferedAggregatorModal: false
            };

        case actions.GET_NETWORK:
            return {
                ...state,
                loading: true,
                networkError: null,
                networkMessage: null,
                buttonLoading: false,
                networkFormModal: false
            };
        case actions.GET_NETWORK_SUCCESS:
            return {
                ...state,
                loading: false,
                networkError: null,
                networkResult: action.networkResult,
                networkMessage: null,
                buttonLoading: false,
                networkFormModal: false
            };
        case actions.GET_NETWORK_FAILED:
            return {
                ...state,
                loading: false,
                networkError: action.networkError,
                networkMessage: null,
                buttonLoading: false,
                networkFormModal: false
            };
        case actions.PREPARE_NETWORK_FORM:
            return {
                ...state,
                loading: false,
                networkMessage: null,
                networkError: null,
                buttonLoading: false,
                networkFormModal: true
            };
        case actions.ADD_NETWORK:
            return {
                ...state,
                loading: false,
                networkError: null,
                networkMessage: null,
                buttonLoading: true,
                networkFormModal: true
            };
        case actions.ADD_NETWORK_SUCCESS:
            return {
                ...state,
                loading: false,
                networkResult: action.networkResult,
                networkMessage: action.networkMessage,
                networkError: null,
                buttonLoading: false,
                networkFormModal: false
            };
        case actions.ADD_NETWORK_FAILED:
            return {
                ...state,
                loading: false,
                networkMessage: null,
                networkError: action.networkError,
                buttonLoading: false,
                networkFormModal: true
            };
        case actions.EDIT_NETWORK:
            return {
                ...state,
                loading: false,
                networkError: null,
                networkMessage: null,
                buttonLoading: true,
                networkFormModal: true
            };
        case actions.EDIT_NETWORK_SUCCESS:
            return {
                ...state,
                loading: false,
                networkResult: action.networkResult,
                networkMessage: action.networkMessage,
                networkError: null,
                buttonLoading: false,
                networkFormModal: false,
                preferedAggregatorModal: false,
            };
        case actions.DELETE_NETWORK:
            return {
                ...state,
                loading: false,
                networkError: null,
                networkMessage: null,
                deleteButtonLoading: true,
                networkFormModal: false
            };
        case actions.DELETE_NETWORK_SUCCESS:
            return {
                ...state,
                loading: false,
                networkMessage: action.networkMessage,
                networkResult: action.networkResult,
                networkError: null,
                deleteButtonLoading: false,
                networkFormModal: false
            };
        case actions.RESET_NETWORK:
            return {
                ...state,
                loading: false,
                networkMessage: null,
                networkError: null,
                buttonLoading: false,
                networkFormModal: false
            };
        case actions.RESET_NETWORK_MESSAGE:
            return {
                ...state,
                loading: false,
                networkMessage: null,
                networkError: null,
                buttonLoading: false,
            };
        case actions.GET_ACTIVE_NETWORK:
            return {
                ...state,
                loading: true,
                networkError: null,
                networkMessage: null,
                buttonLoading: false,
                networkFormModal: false
            };
        case actions.GET_ACTIVE_NETWORK_SUCCESS:
            return {
                ...state,
                loading: false,
                networkError: null,
                networkResult: action.networkResult,
                networkMessage: null,
                buttonLoading: false,
                networkFormModal: false
            };
        case actions.GET_ACTIVE_NETWORK_FAILED:
            return {
                ...state,
                loading: false,
                networkError: action.networkError,
                networkMessage: null,
                buttonLoading: false,
                networkFormModal: false
            };
        case actions.GET_ACTIVE_NETWORK_DAILY_PLAN_MAPPING:
            return {
                ...state,
                loading: true,
                networkError: null,
                networkMessage: null,
                buttonLoading: false,
                networkFormModal: false
            };
        case actions.GET_ACTIVE_NETWORK_DAILY_PLAN_MAPPING_SUCCESS:
            return {
                ...state,
                loading: false,
                networkError: null,
                networkResultData: action.networkResultData,
                networkMessage: null,
                buttonLoading: false,
                networkFormModal: false
            };
        case actions.GET_ACTIVE_NETWORK_DAILY_PLAN_MAPPING_FAILED:
            return {
                ...state,
                loading: false,
                networkError: action.networkError,
                networkMessage: null,
                buttonLoading: false,
                networkFormModal: false
            };
        default:
            return state;
    }
}