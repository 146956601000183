import actions from "./actions";

const initState = {
    addonWiseMappingList: [],
    addonWiseMappingLoading: false,
    addonWiseMappingError: null,
    addonWiseMappingMessage: null,
    loading: false,
    buttonLoading: false,
    addonPriceMappingError: null,
    addonPriceMappingSuccess: null,
    addonPriceMappingData: []
};

export default function reducer(state = initState, action) {

    switch (action.type) {
        case actions.GET_ADDON_WISE_MAPPING:
            return {
                ...state,
                addonWiseMappingLoading: true,
                addonWiseMappingError: null,
                addonWiseMappingMessage: null,
            };

        case actions.GET_ADDON_WISE_MAPPING_SUCCESS:
            return {
                ...state,
                addonWiseMappingLoading: false,
                addonWiseMappingError: null,
                addonWiseMappingMessage: null,
                addonWiseMappingList: action.payload.data,
            };

        case actions.GET_ADDON_WISE_MAPPING_FAILED:
            return {
                ...state,
                addonWiseMappingLoading: false,
                addonWiseMappingError: action.payload.error,
                addonWiseMappingMessage: null,
            };
        case actions.GET_ALL_ADDON_PRICE_MAPPING_DATA:
            return {
                ...state,
                loading: true,
                addonPriceMappingError: null,
                addonPriceMappingSuccess: null,
            };

        case actions.GET_ALL_ADDON_PRICE_MAPPING_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                addonPriceMappingError: null,
                addonPriceMappingSuccess: null,
                addonPriceMappingData: action.payload.data,
            };

        case actions.GET_ALL_ADDON_PRICE_MAPPING_DATA_FAILED:
            return {
                ...state,
                loading: false,
                addonPriceMappingError: action.payload.error,
                addonPriceMappingSuccess: null,
            };
        case actions.UPDATE_ADDON_PRICE_MAPPING_DATA:
            return {
                ...state,
                loading: true,
                buttonLoading: true,
                addonPriceMappingError: null,
                addonPriceMappingSuccess: null,
            };

        case actions.UPDATE_ADDON_PRICE_MAPPING_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                buttonLoading: false,
                addonPriceMappingError: null,
                addonPriceMappingSuccess: action.message,
                addonPriceMappingData: action.data,
            };

        case actions.UPDATE_ADDON_PRICE_MAPPING_DATA_FAILED:
            return {
                ...state,
                loading: false,
                buttonLoading: false,
                addonPriceMappingError: action.serror,
                addonPriceMappingSuccess: null,
            };
        case actions.RESET_ADDON_PRICE_MAPPING_MESSAGES:
            return {
                ...state,
                loading: false,
                buttonLoading: false,
                addonPriceMappingError: null,
                addonPriceMappingSuccess: null,
            };
        default:
            return state;
    }
}