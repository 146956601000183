import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import authActions from '../auth/actions'
import { addData, getList } from '../../helpers/fackBackend_Helper';


function* manualAddFundResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/manualFundAddAndDeduct', data);
        yield put(authActions.getUserDataByToken());

        if (response.response.Status) {
            yield put(actions.manualAddFundSuccess(response.response.Message));
        } else {
            yield put(actions.manualAddFundFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.manualAddFundFailed(error));
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.MANUAL_ADD_FUND, manualAddFundResponse)]);
}