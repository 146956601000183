import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ActivityAction from '../redux/activity/actions';
import dashboardActions from '../redux/dashboard/actions';
import CardLayout from '../components/utils/CardLayout';
import moment from "moment";
import ActivationCount from "../components/utils/ActivationCount";
import {isAdmin} from '../helpers/commonFunction';


const { getDashboardCount, getMonthlyActivationCount, getYearlyActivationCount } = dashboardActions;


function Dashboard() {
    const tokenData = useSelector((state) => state.auth.tokenData);
    const dateFormat = process.env.REACT_APP_DATE_FORMAT;
    const timeFormat = process.env.REACT_APP_TIME_FORMAT;
    const { getActivity } = ActivityAction;
    const dispatch = useDispatch();
    const { activityList } = useSelector((state) => state.activity);
    const { dashboardCountData, monthlyActivationCountData, yearlyActivationCountData, dashboardLoading, dashboardActivityLoading } = useSelector((state) => state.dashboard);

    useEffect(() => {
        dispatch(getActivity({
            fromDate: '',
            toDate: ''
        }));
        dispatch(getDashboardCount());
        dispatch(getMonthlyActivationCount());
        dispatch(getYearlyActivationCount());
    }, []);

    let newActivityList = activityList && activityList.length > 0 && activityList.map((item, ind) => {
        item.IPAddress = `${item.IPAddress}`.replace("::", "").trim();
        item.IPAddress = `${item.IPAddress}`.replace("ffff:", "").trim();
        return item
    })


    return (
        <CardLayout>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-md-7 col-lg-8">
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                                    <div className="iq-card-body">
                                        <div className="d-flex align-items-center justify-content-between text-right">
                                            <div className="icon iq-icon-box rounded-circle bg-primary">
                                                <i className="ri-user-shared-2-line"></i>
                                            </div>
                                            <div>
                                                <h5 className="mb-0">Total Dealer</h5>
                                                {dashboardCountData.DealerCount ? <span className="h4 text-primary mb-0 counter d-inline-block w-100">{dashboardCountData.DealerCount}</span> :
                                                    <h4 style={{ fontWeight: '800' }}>0</h4>}
                                            </div>
                                        </div>
                                       
                                      
                                    </div>
                                    {/* <div class="col-lg-6">
                                        <div class="d-flex align-items-center justify-content-between mt-4 position-relative">
                                            <div class="iq-progress-bar bg-info-light mt-3 iq-progress-bar-icon">
                                                <span class="bg-info iq-progress progress-1 position-relative" data-percent="50">
                                                    <span class="progress-text text-info">50%</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>

                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                            <div className="iq-card-body">
                            <div className="d-flex align-items-center justify-content-between text-right">
                                            <div className="icon iq-icon-box rounded-circle bg-success">
                                                <i className="ri-user-follow-line"></i>
                                            </div>
                                            <div>
                                                <h5 className="mb-0">Total User</h5>
                                                {dashboardCountData.UserCount ? <span className="h4 text-success mb-0 counter d-inline-block w-100">{dashboardCountData.UserCount}</span> :
                                                    <h4 style={{ fontWeight: '800' }} className="text-success">0</h4>}
                                            </div>
                                        </div>
                                </div></div>
                            </div>
                        
                            <div className="col-lg-6">
                                <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                                    <div className="iq-card-header d-flex justify-content-between">
                                        <div className="iq-header-title">
                                            <h4 className="card-title">Today's Activation</h4>
                                        </div>
                                    </div>
                                    {dashboardActivityLoading ? <div className="dashboard-activation-loader"><span className="spinner-border spinner-border-sm" role="status"></span></div> : <div className="iq-card-body">
                                        <ul className="list-inline p-0 m-0">
                                            <li className="d-flex mb-4 align-items-center p-3 sell-list border-primary toDayActivation">
                                                <div className="media-support-info">
                                                    <h5>Pending</h5>
                                                </div>
                                                <div className="iq-card-header-toolbar d-flex align-items-center">
                                                    <div className="badge badge-textStyle badge-pill badge-primary">{monthlyActivationCountData.TodayPending}</div>
                                                </div>
                                            </li>
                                            <li className="d-flex mb-4 align-items-center p-3 sell-list border-success toDayActivation">
                                                <div className="media-support-info">
                                                    <h5>Processing</h5>
                                                </div>
                                                <div className="iq-card-header-toolbar d-flex align-items-center">
                                                    <div className="badge badge-textStyle badge-pill badge-success">{monthlyActivationCountData.TodayProcessing}</div>
                                                </div>
                                            </li>
                                            <li className="d-flex mb-4 align-items-center p-3 sell-list border-warning toDayActivation">
                                                <div className="media-support-info">
                                                    <h5>Assigned To Care</h5>
                                                </div>
                                                <div className="iq-card-header-toolbar d-flex align-items-center">
                                                    <div className="badge badge-textStyle badge-pill badge-warning">{monthlyActivationCountData.TodayAssignedToCare}</div>
                                                </div>
                                            </li>
                                            <li className="d-flex mb-4 align-items-center p-3 sell-list border-info toDayActivation">
                                                <div className="media-support-info">
                                                    <h5>Processed</h5>
                                                </div>
                                                <div className="iq-card-header-toolbar d-flex align-items-center">
                                                    <div className="badge badge-textStyle badge-pill badge-info">{monthlyActivationCountData.TodayProcessed}</div>
                                                </div>
                                            </li>
                                            <li className="d-flex align-items-center p-3 sell-list border-danger toDayActivation">
                                                <div className="media-support-info">
                                                    <h5>Cancelled</h5>
                                                </div>
                                                <div className="iq-card-header-toolbar d-flex align-items-center">
                                                    <div className="badge badge-textStyle badge-pill badge-danger">{monthlyActivationCountData.TodayCancelled}</div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>}

                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                                    <div>
                                        <div className="iq-card-header d-flex justify-content-between">
                                            <div className="iq-header-title">
                                                <h4 className="card-title">Monthly Activation</h4>
                                            </div>
                                        </div>

                                        {dashboardActivityLoading ? <div className="dashboard-activation-loader-month"><span className="spinner-border spinner-border-sm" role="status"></span></div> : <div className="iq-card-body">
                                            <ul className="list-inline p-0 m-0">
                                                <li className="d-flex mb-3 align-items-center p-3-set sell-list border-primary rounded">
                                                    <div className="media-support-info">
                                                        <h5>Total</h5>
                                                    </div>
                                                    <div className="iq-card-header-toolbar d-flex align-items-center">
                                                        <div className="badge badge-textStyle badge-pill badge-primary">{monthlyActivationCountData.MonthlyTotal}</div>
                                                    </div>
                                                </li>
                                                <li className="d-flex mb-3 align-items-center p-3-set sell-list border-info rounded">
                                                    <div className="media-support-info">
                                                        <h5>Processed</h5>
                                                    </div>
                                                    <div className="iq-card-header-toolbar d-flex align-items-center">
                                                        <div className="badge badge-textStyle badge-pill badge-info">{monthlyActivationCountData.MonthlyProcessed}</div>
                                                    </div>
                                                </li>
                                                <li className="d-flex align-items-center p-3-set sell-list border-danger rounded">
                                                    <div className="media-support-info">
                                                        <h5>Cancelled</h5>
                                                    </div>
                                                    <div className="iq-card-header-toolbar d-flex align-items-center">
                                                        <div className="badge badge-textStyle badge-pill badge-danger">{monthlyActivationCountData.MonthlyCancelled}</div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>}
                                    </div>


                                    <div>
                                        <div className="iq-card-header d-flex justify-content-between">
                                            <div className="iq-header-title">
                                                <h4 className="card-title">Last Month Activation</h4>
                                            </div>
                                        </div>

                                        {dashboardActivityLoading ? <div className="dashboard-activation-loader-last-month"><span className="spinner-border spinner-border-sm" role="status"></span></div> : <div className="iq-card-body">
                                            <ul className="list-inline p-0 m-0">
                                                <li className="d-flex mb-3 align-items-center p-3-set sell-list border-primary rounded">
                                                    <div className="media-support-info">
                                                        <h5>Total</h5>
                                                    </div>
                                                    <div className="iq-card-header-toolbar d-flex align-items-center">
                                                        <div className="badge badge-textStyle badge-pill badge-primary">{monthlyActivationCountData.LastMonthTotal}</div>
                                                    </div>
                                                </li>
                                                <li className="d-flex mb-3 align-items-center p-3-set sell-list border-info rounded">
                                                    <div className="media-support-info">
                                                        <h5>Processed</h5>
                                                    </div>
                                                    <div className="iq-card-header-toolbar d-flex align-items-center">
                                                        <div className="badge badge-textStyle badge-pill badge-info">{monthlyActivationCountData.LastMonthProcessed}</div>
                                                    </div>
                                                </li>
                                                <li className="d-flex align-items-center p-3-set sell-list border-danger rounded">
                                                    <div className="media-support-info">
                                                        <h5>Cancelled</h5>
                                                    </div>
                                                    <div className="iq-card-header-toolbar d-flex align-items-center">
                                                        <div className="badge badge-textStyle badge-pill badge-danger">{monthlyActivationCountData.LastMonthCancelled}</div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>}

                                    </div>

                                </div>

                            </div>
                            {/* <div className="col-lg-6">
                                <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                                    <div className="iq-card-header d-flex justify-content-between">
                                        <div className="iq-header-title">
                                            <h4 className="card-title">Yearly Activation</h4>
                                        </div>
                                    </div>
                                    <div className="iq-card-body">
                                        <ul className="list-inline p-0 m-0">
                                            <li className="d-flex mb-3 align-items-center p-3 sell-list border-primary rounded">
                                                <div className="media-support-info">
                                                    <h5>Pending</h5>

                                                </div>
                                                <div className="iq-card-header-toolbar d-flex align-items-center">
                                                    <div className="badge badge-pill badge-primary">{yearlyActivationCountData.Pending}</div>
                                                </div>
                                            </li>
                                            <li className="d-flex mb-3 align-items-center p-3 sell-list border-success rounded">
                                                <div className="media-support-info">
                                                    <h5>Processing</h5>

                                                </div>
                                                <div className="iq-card-header-toolbar d-flex align-items-center">
                                                    <div className="badge badge-pill badge-success">{yearlyActivationCountData.Processing}</div>
                                                </div>
                                            </li>
                                            <li className="d-flex mb-3 align-items-center p-3 sell-list border-warning rounded">
                                                <div className="media-support-info">
                                                    <h5>Assigned To Care</h5>

                                                </div>
                                                <div className="iq-card-header-toolbar d-flex align-items-center">
                                                    <div className="badge badge-pill badge-warning">{yearlyActivationCountData.AssignedToCare}</div>
                                                </div>
                                            </li>
                                            <li className="d-flex mb-3 align-items-center p-3 sell-list border-info rounded">
                                                <div className="media-support-info">
                                                    <h5>Processed</h5>

                                                </div>
                                                <div className="iq-card-header-toolbar d-flex align-items-center">
                                                    <div className="badge badge-pill badge-info">{yearlyActivationCountData.Processed}</div>
                                                </div>
                                            </li>
                                            <li className="d-flex align-items-center p-3 sell-list border-danger rounded">
                                                <div className="media-support-info">
                                                    <h5>Cancelled</h5>

                                                </div>
                                                <div className="iq-card-header-toolbar d-flex align-items-center">
                                                    <div className="badge badge-pill badge-danger">{yearlyActivationCountData.Cancelled}</div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>

                    {(isAdmin(tokenData)) ? <div className="col-sm-12 col-md-5 col-lg-4">
                                <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                                    <ActivationCount />
                                </div>
                            </div> : ''}
                    {(isAdmin(tokenData)) ? '': <div className="col-sm-12 col-md-5 col-lg-4">

                        <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                            {/* <div className="iq-card-body text-center">
                                <img src="./assets/images/page-img/41.png" className="img-fluid iq-image-full" width="100px" alt="page-img" />
                            </div> */}

                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h4 className="card-title font-weight-bold" style={{ fontSize: "18px" }}>Activity timeline </h4>
                                </div>
                                {
                                    activityList && activityList.length !== 0 && <div className="iq-card-header-toolbar d-flex align-items-center">
                                        <div className="dropdown">
                                            <Link to="/activity-log"><span className="dropdown-toggle text-primary">View All</span></Link>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="iq-card-body">
                                <ul className="iq-timeline" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                    {
                                        newActivityList && newActivityList.length === 0 && <p>No data found</p>
                                    }
                                    {
                                        newActivityList && newActivityList.length > 0 && newActivityList.map((item, index) => {
                                            return <>
                                                <li key={index} style={{ display: 'flex', justifyContent: 'space-between', }}>
                                                    <div className="timeline-dots"></div>
                                                    <div>
                                                        <h6 className="float-left mb-1">{item.ActionType}</h6>
                                                        <div className="d-inline-block w-100">
                                                            <small className="mb-0">{item.ActionDescription}</small>
                                                        </div>
                                                        <div className="d-inline-block w-100 mt-0">
                                                            <small>IP Address</small>
                                                        </div>
                                                    </div>
                                                    <p style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <small className="float-right mt-1">{moment(item.ActionDtTm).format(dateFormat)}</small>
                                                        <small className="float-right mt-1">{moment(item.ActionDtTm).format(timeFormat)}</small>

                                                        <small className="float-right mt-1">{item.IPAddress}</small>
                                                    </p>

                                                </li>
                                            </>
                                        })
                                    }

                                </ul>
                            </div>

                        </div>
                    </div>}
                </div>
            </div>
        </CardLayout>

    )
}

export default Dashboard