import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData } from '../../helpers/fackBackend_Helper';

function* getIQRequestReportResponse({ payload: { Data } }) {
    try {
        const response = yield call(addData, '/getIQRequestReport', Data);
        if (response.response.Status) {
            yield put(actions.getIQRequestReportSuccess(response.response.Data))
        } else {
            yield put(actions.getIQRequestReportFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getIQRequestReportFailed(error));
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.GET_IQ_REQUEST_REPORT, getIQRequestReportResponse)]);

}