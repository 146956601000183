const actions = {
    GET_BULK_ACTIVATION_REPORT: "GET_BULK_ACTIVATION_REPORT",
    GET_BULK_ACTIVATION_REPORT_SUCCESS: "GET_BULK_ACTIVATION_REPORT_SUCCESS",
    GET_BULK_ACTIVATION_REPORT_FAILED: "GET_BULK_ACTIVATION_REPORT_FAILED",

    getBulkActivationReport: (searchData) => ({
        type: actions.GET_BULK_ACTIVATION_REPORT,
        payload: {searchData},
    }),
    getBulkActivationReportSuccess: (bulkActivation) => ({
        type: actions.GET_BULK_ACTIVATION_REPORT_SUCCESS,
        payload: {bulkActivation}
    }),
    getBulkActivationReportFailed: (error) => ({
        type: actions.GET_BULK_ACTIVATION_REPORT_FAILED,
        error
    }),

}

export default actions;