import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { getList, addData, deleteData, editData } from '../../helpers/fackBackend_Helper';

function* getAddonServiceListResponse() {
    try {
        const response = yield call(getList, '/addonService');
        if (response.response.Status) {
            yield put(
                actions.getAddonServiceSuccess(
                    response.response.Data
                ));
        } else {
            yield put(actions.getAddonServiceFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getAddonServiceFailed(error));
    }
}
function* addAddonServiceDataResponse({ payload: { data } }) {
    try {
        const response = yield call(editData, '/addonService', data);
        if (response.response.Status) {
            const responseList = yield call(getList, '/addonService');
            if (responseList.response.Status) {
                yield put(
                    actions.addAddonServiceSuccess(
                        response.response.Message, responseList.response.Data
                    ));
            } else {
                yield put(actions.addAddonServiceFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.addAddonServiceFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.addAddonServiceFailed(error));
    }
}
function* deleteAddonServiceDataResponse({ payload: { addonId, status } }) {
    try {
        const response = yield call(deleteData, '/addonService/' + addonId + '/' + status);
        if (response.response.Status) {
            const responseList = yield call(getList, '/addonService');
            if (responseList.response.Status) {
                yield put(
                    actions.deleteAddonServiceSuccess(
                        response.response.Message, responseList.response.Data
                    ));
            } else {
                yield put(actions.getAddonServiceFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.getAddonServiceFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getAddonServiceFailed(error));
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.GET_ADDON_SERVICE, getAddonServiceListResponse)]);
    yield all([takeEvery(actions.ADD_ADDON_SERVICE, addAddonServiceDataResponse)]);
    yield all([takeEvery(actions.DELETE_ADDON_SERVICE, deleteAddonServiceDataResponse)]);
}