import React from 'react'
import AddFillIcon from 'remixicon-react/AddFillIcon';

function SideButtons({ title, onClick, style, className, btnIcon }) {
    return (
        <div style={style}>
            <button type="button" className={className} onClick={onClick}>{btnIcon}{title}</button>
        </div>

    )
}

SideButtons.defaultProps = {
    className: "btn btn-primary font-weight-bold",
    btnIcon:<AddFillIcon size={16} style={{ fontWeight: "bold" }} />
}


export default SideButtons