import actions from "./actions";

const initState = {
    tmoDealerResult: [],
    loading: false,
    tmoDealerError: null,
    tmoDealerMessage: null,
    buttonLoading: false,
    tmoDealerFormModal: false,
    showAlertMessage:false,
    deleteLoading:false,
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_TMO_DEALER:
            return {
                ...state,
                loading: true,
                tmoDealerError: null,
                tmoDealerMessage: null,
                buttonLoading: false,
                tmoDealerFormModal: false,
            };
        case actions.GET_TMO_DEALER_SUCCESS:
            return {
                ...state,
                loading: false,
                tmoDealerError: null,
                tmoDealerMessage: null,
                tmoDealerResult: action.tmoDealerResult,
                buttonLoading: false,
                tmoDealerFormModal: false,
            };
        case actions.GET_TMO_DEALER_FAILED:
            return {
                ...state,
                loading: false,
                tmoDealerError: action.tmoDealerError,
                tmoDealerMessage: null,
                buttonLoading: false,
                tmoDealerFormModal: false,
            };
        case actions.ADD_TMO_DEALER:
            return {
                ...state,
                loading: true,
                tmoDealerError: null,
                tmoDealerMessage: null,
                buttonLoading: true,
                tmoDealerFormModal: true,
            };
        case actions.ADD_TMO_DEALER_SUCCESS:
            return {
                ...state,
                loading: false,
                tmoDealerError: null,
                tmoDealerMessage: action.tmoDealerMessage,
                tmoDealerResult: action.tmoDealerResult,
                buttonLoading: false,
                tmoDealerFormModal: false,
                showAlertMessage:true,
            };
        case actions.ADD_TMO_DEALER_FAILED:
            return {
                ...state,
                loading: false,
                tmoDealerError: action.tmoDealerError,
                tmoDealerMessage: null,
                buttonLoading: false,
                tmoDealerFormModal: true,
                showAlertMessage:false,
            };

            case actions.PREPARE_TMO_DEALER_FORM:
            return {
                ...state,
                tmoDealerFormModal: true
            };

            case actions.RESET_TMO_DEALER_FORM:
                return {
                    ...state,
                    tmoDealerFormModal: false
                };
        
    

                case actions.DELETE_TMO_DEALER:
                return {
                    ...state,
                    loading: false,
                    tmoDealerError: null,
                    tmoDealerMessage: null,
                    tmoDealerError: null,
                    deleteLoading: true,
                    tmoDealerFormModal: false,
                };
            case actions.DELETE_TMO_DEALER_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    tmoDealerError: null,
                    tmoDealerMessage: action.tmoDealerMessage,
                    tmoDealerResult: action.tmoDealerResult,
                    deleteLoading: false,
                    tmoDealerFormModal: false,
                };

                
            case actions.RESET_TMO_DEALER_MESSAGE:
            return {
                ...state,
                loading: false,
                tmoDealerMessage: null,
                tmoDealerMessage: null,
                buttonLoading: false,
            };
            
        default:
            return state;
    }
}
