import { useEffect, useMemo, useState } from 'react';
import ArrowLeftLineIcon from 'remixicon-react/ArrowLeftLineIcon';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import Papa from 'papaparse'
import CheckLineIcon from 'remixicon-react/CheckLineIcon';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import DownloadCloud2sLineIcon from 'remixicon-react/DownloadCloud2LineIcon';
import CardLayout from '../../../components/utils/CardLayout'
import Button from '../../../components/utils/Button'
import Table from '../../../components/utils/Table';
import { isKeyUnique, validateIMEI, validateSNo, validatePin, selectCharacters, twoDigitDecimal, validateEmail, validateZipCode, checkDateIsGreaterThenCurrentDate, isNumeric } from '../../../helpers/commonFunction';
import dailyPlanRequestActions from '../../../redux/dailyPlanRequest/actions';
import Alert from '../../../components/utils/Alert';
import shortCodeAction from '../../../redux/shortCodeData/actions';

const BASE_URL = process.env.REACT_APP_SITE_BASE_URL;

const { createBulkActivationForDailyPlan, resetDailyPlan, checkValidSerialNumber } = dailyPlanRequestActions;

const { getSimType } = shortCodeAction;

const previewColumns = [
    { displayName: 'VALID', key: 'Valid' },
    { displayName: 'IMEI', key: 'IMEI' },
    { displayName: 'SERIAL NUMBER', key: 'SerialNumber' },
    { displayName: 'CUSTOMER EMAIL', key: 'CustomerEmail' },
    { displayName: 'ZIP CODE', key: 'ZipCode' },
    { displayName: 'FROM DATE', key: 'FromDate' },
    { displayName: 'NUMBER OF DAYS', key: 'NumberOfDays' },
    { displayName: 'MEXIOCO & CANADA CALLING', key: 'MexiocoCanadaCalling' },
    { displayName: 'REASON', key: 'Reason' },
];

const BulkDailyPlanActivation = ({ back, searchData }) => {
    const dispatch = useDispatch();
    const { networkResultData } = useSelector((state) => state.network);
    const { tokenData } = useSelector((state) => state.auth);
    const { shortCodeSimType } = useSelector((state) => state.shortCode);
    const { planAddonResult, addBulkActivationLoading, getPrepaidIqAddressesData, validateSerialNumberData, validateSerialNumberLoading } = useSelector((state) => state.activationRequest);
    const { addonResult } = useSelector((state) => state.addonService);
    const {
        createBulkActivationForDailyPlanLoading,
        createBulkActivationForDailyPlanMessage,
        createBulkActivationForDailyPlanError,
        checkValidSerialNumberData,
        checkValidSerialNumberLoading } = useSelector((state) => state.dailyPlanRequest);
    const [csvError, setCsvError] = useState(false);
    const [previewData, setPreviewData] = useState([]);
    const [previewSuccessData, setPreviewSuccessData] = useState([]);
    const [previewFailedData, setPreviewFailedData] = useState([]);
    const [showPreviewType, setShowPreviewType] = useState(2);
    const [activationRequests, setActivationRequests] = useState([]);
    const [previewSuccessCount, setPreviewSuccessCount] = useState(0);
    const [csvLimitError, setCsvLimitError] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertText, setAlertText] = useState('');
    const [prefferAggId, setPrefferAggId] = useState(0);
    const [accountBalance, setAccountBalance] = useState(0);
    const [networkId, setNetworkId] = useState(0);
    const [simType, setSimType] = useState(0);
    const [apiCall, setApiCall] = useState(false);

    useEffect(() => {
        tokenData && tokenData.length > 0 && tokenData.map((data) => {
            setAccountBalance(data.AccountBalance);
        });
    }, [tokenData]);

    useEffect(() => {
        dispatch(getSimType({
            tableName: "activationrequest",
            fieldName: "SimType"
        }));
    }, []);

    useEffect(() => {
        if (networkId !== 0 || simType !== 0) {
            dispatch(resetDailyPlan());
            bulkActivationReset({
                uploadedFile: '',
            });
            setPreviewData([]);
            setActivationRequests([]);
            setPreviewSuccessData([]);
            setPreviewFailedData([]);
        }
    }, [networkId, simType]);

    useEffect(() => {
        if (csvLimitError !== null && csvLimitError !== undefined) {
            displayAlert(csvLimitError, 'alert-danger');
        }
    }, [csvLimitError]);


    useEffect(() => {
        if (createBulkActivationForDailyPlanMessage || createBulkActivationForDailyPlanError) {
            back();
            dispatch(resetDailyPlan());
        }
    }, [createBulkActivationForDailyPlanMessage, createBulkActivationForDailyPlanError]);

    const displayAlert = (text, type) => {
        setAlertText(text);
        setAlertType(type);
        setShowAlert(true);
    };

    const alertCancel = () => {
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
    }

    const {
        register: bulkActivationRegister,
        handleSubmit: bulkActivationHandleSubmit,
        formState: { errors: bulkActivationError },
        watch: bulkActivationWatch,
        reset: bulkActivationReset,
        setValue: bulkActivationSetValue,
        setError: bulkActivationSetError,
        clearErrors: bulkActivationClearErrors,
        control: bulkActivationcontrol, } = useForm({
            defaultValues: {
                networkID: '',
                planID: '',
                month: '',
                address1: '',
                state: '',
                simType: '',
            }
        });

    const downloadSampleFile = () => {
        let url = `${BASE_URL}/assets/csv/daily-plan-bulk-activation-sample-file.csv`;
        let link = document.createElement('a');
        link.href = url;
        link.download = 'sample-daily-bulk-activation-file.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleNetwork = (e) => {
        setNetworkId(e.target.value);
    }

    const handleSimTypeChange = (e) => {
        setSimType(e.target.value);
    }

    const handlePreviewTableData = (id) => {
        setShowPreviewType(id)
    }

    const handleFileChange = (e) => {
        bulkActivationClearErrors(['uploadedFile']);
        setPreviewData([]);
        setActivationRequests([]);
        setPreviewSuccessCount(0);
        setActivationRequests([]);
        const fileReader = new FileReader();
        let file = e.target.files[0];

        if (!file) {
            bulkActivationSetError("uploadedFile", { type: "custom", message: "Csv is required!" })
            setCsvError(true);
            return;
        } else {
            let fileNameArr = file.name.split('.');
            let fileExt = fileNameArr[fileNameArr.length - 1];

            if (!["csv"].includes(fileExt.toLowerCase())) {
                bulkActivationSetError("uploadedFile", { type: "custom", message: "Invalid file type. Only CSV file allowed." })
                setCsvError(true);
                return;
            }
        }


        if (file) {
            fileReader.onload = async ({ target }) => {
                const csvOutput = Papa.parse(target.result, { header: true, skipEmptyLines: true });
                const parsedData = csvOutput?.data;
                const columns = Object.keys(parsedData[0]);

                if (parsedData.length > 500) {
                    setCsvLimitError('Maximum 500 rows are allowed');
                    setCsvError(true);
                    return;
                } else {
                    setCsvError(false);
                }

                const specifiedColumn = ['IMEI', 'SerialNumber'];
                const isValidCsvHeading = specifiedColumn.every((item) => columns.includes(item));

                if (isValidCsvHeading) {
                    setCsvError(false);
                    let dataSource = parsedData.map((item) => {
                        let error = false;
                        let reason = "";

                        if (!validateIMEI(item.IMEI.slice(0, 15))) {
                            reason = "Invalid IMEI";
                            setCsvError(true);
                            error = true;
                        } else if (!validateSNo(selectCharacters(item.SerialNumber, networkId), networkId, simType)) {
                            reason = simType == 25 ? "Invalid Serial Number" : "Serial Number not allowed for e-sim";
                            setCsvError(true);
                            error = true;
                        } else if (!validateEmail(item.CustomerEmail)) {
                            reason = "Invalid email id";
                            setCsvError(true);
                            error = true;
                        } else if (!validateZipCode(item.ZipCode)) {
                            reason = "Invalid zip code";
                            setCsvError(true);
                            error = true;
                        } else if (!checkDateIsGreaterThenCurrentDate(item.FromDate)) {
                            reason = "Please select a date that is either today or a future date";
                            setCsvError(true);
                            error = true;
                        } else if (!isNumeric(item.NumberOfDays)) {
                            reason = "Invalid number of days";
                            setCsvError(true);
                            error = true;
                        }

                        return {
                            IMEI: item.IMEI.slice(0, 15),
                            SerialNumber: selectCharacters(item.SerialNumber, networkId),
                            CustomerEmail: item.CustomerEmail,
                            ZipCode: item.ZipCode,
                            FromDate: item.FromDate,
                            NumberOfDays: item.NumberOfDays,
                            MexiocoCanadaCalling: item.MexiocoCanadaCalling.toUpperCase(),
                            Reason: reason,
                            Valid: error ? <CloseLineIcon color='#e74c3c' /> : <CheckLineIcon color='#2ecc71' />,
                            Status: error ? 0 : 1
                        }
                    });

                    if (simType == 25 && !isKeyUnique(dataSource, 'SerialNumber')) {
                        bulkActivationSetError("uploadedFile", { type: "custom", message: "Csv contain duplicate sim number" })
                        return false;
                    }

                    setPreviewData(dataSource);
                    setApiCall(true);

                    const selectedNetwork = networkResultData.filter((item) => item.ID == networkId);
                    const dealerPerDayPrice = selectedNetwork[0].DealerPrice;

                    let requestData = parsedData.map((item) => {
                        let error = false;
                        let reason = "";

                        if (!validateIMEI(item.IMEI.slice(0, 15))) {
                            reason = "Invalid IMEI";
                            setCsvError(true);
                            error = true;
                        } else if (!validateSNo(selectCharacters(item.SerialNumber, networkId), networkId, simType)) {
                            reason = simType == 25 ? "Invalid Serial Number" : "Serial Number not allowed for e-sim";
                            setCsvError(true);
                            error = true;
                        } else if (!validateEmail(item.CustomerEmail)) {
                            reason = "Invalid email id";
                            setCsvError(true);
                            error = true;
                        } else if (!validateZipCode(item.ZipCode)) {
                            reason = "Invalid zip code";
                            setCsvError(true);
                            error = true;
                        } else if (!checkDateIsGreaterThenCurrentDate(item.FromDate)) {
                            reason = "Please select a date that is either today or a future date";
                            setCsvError(true);
                            error = true;
                        } else if (!isNumeric(item.NumberOfDays)) {
                            reason = "Invalid number of days";
                            setCsvError(true);
                            error = true;
                        }

                        return {
                            imeiNumber: item.IMEI.slice(0, 15),
                            simNumber: selectCharacters(item.SerialNumber, networkId),
                            CustomerEmail: item.CustomerEmail,
                            ZipCode: item.ZipCode,
                            FromDate: item.FromDate,
                            NumberOfDays: item.NumberOfDays,
                            chargeAmount: Number(item.NumberOfDays) * dealerPerDayPrice,
                            MexiocoCanadaCalling: item.MexiocoCanadaCalling.toUpperCase(),
                            status: error ? 0 : 1,
                            reason
                        }
                    });
                    setActivationRequests(requestData);

                    if (simType == 25) {
                        let serialNumberArray = requestData.map((item) => item.simNumber);
                        dispatch(checkValidSerialNumber(serialNumberArray))
                    }

                } else {
                    bulkActivationSetError("uploadedFile", { type: "custom", message: "Invalid Csv Selected" })
                    setCsvError(true);
                }
            };
            fileReader.readAsText(file);
        }
    }

    const handleAddDailyBulkActivation = (data) => {
        let failedRows = activationRequests.filter((item) => item.status == 0);

        if (failedRows.length > 0) {
            alert("Invalid data entered. Please review the information and try again");
            return false;
        }

        data.activationRequests = activationRequests;

        delete data.uploadedFile;


        data.purchaseAmount = activationRequests.reduce((acc, crv) => acc + crv.chargeAmount, 0);

        if (accountBalance >= data.purchaseAmount) {
            // console.log('data ', data);
            dispatch(createBulkActivationForDailyPlan(data, searchData));
        } else {
            alert('insufficient fund');
        }


    }

    useEffect(() => {
        if (networkResultData.length === 1) {
            const singleNetwork = networkResultData[0];
            setNetworkId(singleNetwork.ID);
            bulkActivationSetValue('networkID', singleNetwork.ID);
        }

    }, [networkResultData, bulkActivationSetValue]);

    const csvPreviewSource = useMemo(() => {
        if (checkValidSerialNumberData.length > 0) {
            return previewData.map((item) => {
                let row = checkValidSerialNumberData.filter((item1) => item1.SerialNumber === item.SerialNumber);
                if (row[0].Status === 0) {
                    item.Reason = row[0].ExistStatus;
                    item.Status = row[0].Status;
                    item.Valid = <CloseLineIcon color='#e74c3c' />;
                }
                return item;
            });
        } else {
            return [];
        }
    }, [checkValidSerialNumberData, previewData]);

    useEffect(() => {
        if (csvPreviewSource.length > 0) {
            let successPreview = csvPreviewSource.filter((item) => item.Status == 1);
            let failedPreview = csvPreviewSource.filter((item) => item.Status == 0);
            setPreviewSuccessData(successPreview);
            setPreviewFailedData(failedPreview);
            setPreviewSuccessCount(successPreview.length);
        }
        if (csvPreviewSource.length === 0 && previewData.length > 0) {
            let successPreview = previewData.filter((item) => item.Status == 1);
            let failedPreview = previewData.filter((item) => item.Status == 0);
            setPreviewSuccessData(successPreview);
            setPreviewFailedData(failedPreview);
            setPreviewSuccessCount(successPreview.length);
        }
    }, [csvPreviewSource, previewData]);

    // SHOW CALCULATED AMOUNT TABLE
    const estimatedAmountColumn = [
        { displayName: 'Total Activation', key: 'tActivation' },
        { displayName: 'Per Day Price', key: 'pActivation' },
        { displayName: 'Total Paid', key: 'tPaid' },
    ];

    const estimatedAmountDataSource = useMemo(() => {
        if (csvPreviewSource.length > 0) {
            const selectedNetwork = networkResultData.filter((item) => item.ID == networkId);
            const dealerPerDayPrice = selectedNetwork[0].DealerPrice;
            const totalChargePaidByDealer = activationRequests.reduce((acc, crv) => acc + crv.chargeAmount, 0);
            return [
                {
                    tActivation: activationRequests.length,
                    pActivation: `$${dealerPerDayPrice}`,
                    tPaid: `$${totalChargePaidByDealer}`
                }
            ];
        } else {
            return [
                {
                    tActivation: 0,
                    pActivation: 0,
                    tPaid: 0
                }
            ];

        }
    }, [csvPreviewSource]);


    return (
        <CardLayout title="Add Bulk Daily Activation">
            <div className="row mb-3">
                <div className='col-lg-12 text-right'>
                    <button type="button" onClick={() => downloadSampleFile()} style={{ background: '#2ecc71', color: '#fff', pointerEvents: 'auto' }} className={`btn font-weight-bold mr-2`}><DownloadCloud2sLineIcon size={16} />Sample File</button>
                    <button type="button" onClick={() => back()} className={`btn btn-danger font-weight-bold`}><ArrowLeftLineIcon size={16} />Back</button>
                </div>
            </div>

            <div className='container'>
                <form onSubmit={bulkActivationHandleSubmit(handleAddDailyBulkActivation)}>
                    <div className="form-row">

                        <div className="col-md-3 mb-3">
                            <label className='ml-2'> Network <span className='text-danger'>*</span></label>
                            <select className="form-control" placeholder="Select Network" name="networkID"  {...bulkActivationRegister("networkID", {
                                required: 'Network is Required',
                            })} onChange={(e) => { handleNetwork(e) }} >
                                <option value="" key="0" disabled>Select</option>
                                {networkResultData.length > 0 && networkResultData.map(network => (
                                    <option name="network" value={network.ID} key={network.ID}>{network.NetworkName}</option>
                                ))}
                            </select>
                            {bulkActivationError && bulkActivationError.networkID && (<div className="invalid-feedback">{bulkActivationError.networkID.message}</div>)}
                        </div>

                        <div className="col-md-3 mb-3">
                            <label className="ml-2">Sim Type <span className='text-danger'> *</span></label>
                            <select className="form-control" name="simType"
                                {...bulkActivationRegister("simType", {
                                    required: 'Sim type is required !',
                                })} onChange={(e) => { handleSimTypeChange(e) }} >
                                <option value="" disabled>Select</option>
                                {
                                    shortCodeSimType && shortCodeSimType.length > 0 && shortCodeSimType.map((item) => {
                                        return <option value={item.ID} key={`simType${item.ID}`}>{item.Value}</option>
                                    })
                                }
                            </select>
                            {bulkActivationError && bulkActivationError.simType && (<div className="invalid-feedback">{bulkActivationError.simType.message}</div>)}
                        </div>

                        {
                            networkId !== 0 && simType !== 0 &&
                            <div className="col-md-6 mb-3 p-0">
                                <div className="input-group">
                                    <label htmlFor="csvFile">Upload File<span className='text-danger'> *</span></label>
                                    <input
                                        type="file"
                                        className="form-control-file"
                                        name='uploadedFile'
                                        id="csvFile"
                                        accept='.csv'
                                        {...bulkActivationRegister('uploadedFile', { required: 'Csv is required' })}
                                        onChange={(e) => handleFileChange(e)}
                                    />
                                    <small className='text-danger'>Note : Only 500 Rows are allowed</small>
                                    {bulkActivationError && bulkActivationError.uploadedFile && (<div className="invalid-feedback">{bulkActivationError.uploadedFile.message}</div>)}
                                </div>
                            </div>
                        }
                    </div>




                    {showAlert && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}

                    {
                        previewData.length > 0 &&
                        <>
                            <h5 className='text-danger'>All entries should be valid for upload..</h5>
                            <hr />
                            <div className='row p-0 m-0' style={{ cursor: 'pointer' }}>
                                <div className="col-md-4 d-flex justify-content-around align-items-center py-3 preview-box" onClick={() => { handlePreviewTableData(2) }}>
                                    <h4>Total Submited : {previewData.length}</h4>
                                </div>
                                <div className="col-md-4 d-flex justify-content-around align-items-center preview-box" onClick={() => { handlePreviewTableData(1) }}>
                                    <h4 className='text-success'>Valid : {previewSuccessCount}</h4>
                                </div>
                                <div className="col-md-4 d-flex justify-content-around align-items-center preview-box" onClick={() => { handlePreviewTableData(0) }}>
                                    <h4 className='text-danger'>Invalid : {previewData.length - previewSuccessCount}</h4>
                                </div>
                            </div>
                            <div className="col-md-12 mb-3 p-0">
                                <Table columns={previewColumns} loading={checkValidSerialNumberLoading}
                                    data={showPreviewType == 1 ? previewSuccessData : showPreviewType == 0 ? previewFailedData : simType == 26 ? previewData : csvPreviewSource} pagination={false} />
                            </div>


                            {
                                previewFailedData.length === 0 &&
                                <div className="col-md-12 mb-3 p-0">
                                    <h4>Price details</h4>
                                    <Table columns={estimatedAmountColumn} loading={false} data={estimatedAmountDataSource} pagination={false} />
                                </div>
                            }

                        </>
                    }

                    <hr />

                    <div className='text-right row'>
                        <div className="col-md-12">
                            {
                                prefferAggId == null || prefferAggId == undefined ? <span className='text-danger mr-2 font-weight-bold'>Configuration not set, please contact admin</span> : ''
                            }
                            <button type="button" className="btn btn-secondary mr-2" onClick={() => back()}>Close</button>
                            {activationRequests.length === previewSuccessData.length && activationRequests.length > 0 ? <Button title="Submit" style="minWidth:80px" loading={createBulkActivationForDailyPlanLoading} buttonClass="btn btn-primary" buttonType='submit' /> : ''}
                        </div>
                    </div>

                </form>
            </div>

        </CardLayout>
    )
}

export default BulkDailyPlanActivation;