import React, { useState, useEffect } from 'react'
import CardLayout from '../../components/utils/CardLayout'
import SearchBar from '../../components/utils/SearchBar'
import SideButtons from '../../components/utils/SideButtons'
import Table from '../../components/utils/Table'
import Tag from '../../components/utils/Tag'
import Popconfirm from '../../components/utils/Popover'
import PencilFill from 'remixicon-react/PencilFillIcon';
import DeleteBin5Fill from 'remixicon-react/DeleteBin5FillIcon';
import LinksLineIcon from 'remixicon-react/LinksLineIcon'
import { Link } from 'react-router-dom';
import CheckboxCircleFill from 'remixicon-react/CheckboxCircleFillIcon';
import Modal from '../../components/utils/Model'
import { useForm } from 'react-hook-form'
import Button from '../../components/utils/Button'
import networkActions from '../../redux/network/actions'
import AggregatorActions from '../../redux/aggregator/actions'
import { useDispatch, useSelector } from 'react-redux'
import Alert from '../../components/utils/Alert'

const { getNetwork, prepareNetworkForm, resetNetwork, addNetwork, deleteNetwork, editNetwork, openPreferedAggregatorModal, closePreferedAggregatorModal, updatePreffedAggregator } = networkActions;
const { getAggregator, getAggregatorByNetworkID } = AggregatorActions;

function Network() {
    const BASE_URL = process.env.REACT_APP_BASEURL;
    const [filterText, setFilterText] = useState("");
    const [buttonText, setButtonText] = useState();
    const [formTitle, setFormTitle] = useState('');
    const [networkId, setNetworkId] = useState(0);
    const [editFlag, setEditFlag] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertText, setAlertText] = useState('');
    const [imageMessage, setImageMessage] = useState("");
    const [imagePreview, setImagePreview] = useState(false);
    const [networkItemId, setNetworkItemId] = useState()
    const [imageDimensions, setImageDimensions] = useState({
        height: 0,
        width: 0
    });
    const {
        networkModal,
        addNetworkLoading,
        getNetworkList,
        getNetworkLoading,
        updateNetworkLoading,
        getChildNetworkLoading,
        getChildNetworkList,
        addNetworkError,
        addNetworkMessage,
        updateNetworkError,
        updateNetworkMessage,
        updateNetworkStatusMessage,
        updateNetworkStatusError,
        networkMessage } = useSelector((state) => state.network);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getNetwork());
        dispatch(getAggregator());
    }, []);
    const { networkResult, loading, deleteButtonLoading, networkFormModal, preferedAggregatorModal } = useSelector((state) => state.network);
    const { list, listGetByNetworkId } = useSelector((state) => state.aggregator);
    const buttonLoading = useSelector((state) => state.network.buttonLoading)

    const [networkForm, setNetworkForm] = useState({
        NetworkName: "",
        NetworkLogoURL: "",
    });

    // const [prefferedAggregatorForm, setPrefferedAggregatorForm] = useState({
    //     PrefferedAggregatorId: "",
    //     RechargeAggregatorId: ""
    // });

    useEffect(() => {
        reset(networkForm);
    }, [networkForm]);

    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm({
        defaultValues: networkForm
    });

    // const { register: registerReset, handleSubmit: handleSubmitReset, watch: watchReset, formState: { errors: errorsReset }, reset: resetReset } = useForm({
    //     defaultValues: prefferedAggregatorForm
    // });

    useEffect(() => {
        const image = new Image();
        image.src = imageUrl;
        image.onload = () => {
            setImageDimensions({
                height: image.height,
                width: image.width
            });
        };
    }, [imageUrl]);



    const handleFilterChange = (value) => {
        setFilterText(value);
    };

    const handleUpdate = async (data) => {
        data.NetworkId = networkId;
        if (typeof data.NetworkLogoURL === 'object') {
            fileToBase64(data.NetworkLogoURL[0], (base64) => {
                data.NetworkLogoURL = base64;
                dispatch(editNetwork(data));
            });
        } else {
            delete data.NetworkLogoURL;
            dispatch(editNetwork(data));
        }
    }


    const handleEditNetworkModal = (id) => {
        setImageUrl('');
        setNetworkId(id);
        setFormTitle('Edit Network');
        setButtonText('Update');
        setImagePreview(true)
        setEditFlag(true);
        let selectedRow = networkResult.filter((item) => item.ID == id);
        setNetworkForm({
            NetworkName: selectedRow[0].NetworkName,
            NetworkLogoURL: selectedRow[0].NetworkLogoURL
        });
        dispatch(prepareNetworkForm());
    }

    const fileToBase64 = (file, callback) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            if (typeof callback === 'function') {
                callback(reader.result);
            }
        };
        reader.readAsDataURL(file);
    }

    const handleImageChange = (e) => {
        const uploadImage = e.target.files[0]
        setImageUrl(URL.createObjectURL(e.target.files[0]));
        setImagePreview(true)
        if (uploadImage) {
            const image = new Image();
            image.src = URL.createObjectURL(uploadImage);
        }
    }


    const onSubmit = (data) => {
        fileToBase64(data.NetworkLogoURL[0], (base64) => {
            data.NetworkLogoURL = base64;
            dispatch(addNetwork(data));
        });
    }

    const handleDelete = (ID, flag) => {
        setNetworkItemId(ID)
        dispatch(deleteNetwork(ID, flag));
    }

    const handleAddNetwork = () => {
        reset({
            NetworkName: "",
            NetworkLogoURL: ""
        });
        setImageUrl('');
        setFormTitle('Add Network')
        setButtonText('Submit');
        setImagePreview(false);
        setEditFlag(false);
        setNetworkForm({});
        dispatch(prepareNetworkForm());
    }

    const closeNetworkModal = () => {
        dispatch(resetNetwork());
    }

    // useEffect(() => {
    //     if(listGetByNetworkId && listGetByNetworkId.length > 0 && networkId != 0){
    //         let selectedRow = networkResult.filter((item) => item.ID == networkId);
    //         resetReset({
    //             PrefferedAggregatorId: selectedRow[0].PrefferedAggregator ? selectedRow[0].PrefferedAggregator.toString() : "",
    //             RechargeAggregatorId: selectedRow[0].RechargeAggregator ? selectedRow[0].RechargeAggregator.toString() : ""
    //         });
    //     }
    // }, [listGetByNetworkId, networkId]);


    // const handlepreferredAggregatorModal = (id) => {
    //     dispatch(getAggregatorByNetworkID(id));
    //     setFormTitle('Manage Aggregator')
    //     setButtonText('Submit');
    //     setNetworkId(id);        
    //     dispatch(openPreferedAggregatorModal());
    // }

    // const handleCancel = () => {
    //     dispatch(closePreferedAggregatorModal());

    // };

    useEffect(() => {
        if (addNetworkError !== null && addNetworkError !== undefined) {
            displayAlert(addNetworkError, 'alert-danger');
        }
        if (addNetworkMessage !== null && addNetworkMessage !== undefined) {
            displayAlert(addNetworkMessage, 'alert-success');
        }

        if (updateNetworkError !== null && addNetworkError !== undefined) {
            displayAlert(updateNetworkError, 'alert-danger');
        }
        if (updateNetworkMessage !== null && updateNetworkMessage !== undefined) {
            displayAlert(updateNetworkMessage, 'alert-success');
        }
        if (updateNetworkStatusError !== null && updateNetworkStatusError !== undefined) {
            displayAlert(updateNetworkStatusError, 'alert-danger');
        }
        if (updateNetworkStatusMessage !== null && updateNetworkStatusMessage !== undefined) {
            displayAlert(updateNetworkStatusMessage, 'alert-success');
        }
        if (networkMessage !== null && networkMessage !== undefined) {
            displayAlert(networkMessage, 'alert-success');
        }
    }, [addNetworkError, addNetworkMessage, updateNetworkError, updateNetworkMessage, updateNetworkStatusMessage, updateNetworkStatusError, networkMessage]);

    const displayAlert = (text, type) => {
        setAlertText(text);
        setAlertType(type);
        setShowAlert(true);
        dispatch(resetNetwork());
    };

    const alertCancel = () => {
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
        dispatch(resetNetwork());
    }



    const columns = [
        { displayName: '#', key: 'key' },
        { displayName: 'LOGO', key: 'logo' },
        { displayName: 'NETWORK', key: 'NetworkName' },
        // { displayName: 'ACTIVATION AGGREGATOR', key: 'preferredAggregator' },
        // { displayName: 'RECHARGE AGGREGATOR', key: 'rechargeAggregator' },
        { displayName: 'STATUS', key: 'status' },
        { displayName: 'ACTION', key: 'action' }
    ];


    // const handleUpdatePrefferedAggregator = (data) => {
    //     data.NetworkId = networkId;
    //     dispatch(updatePreffedAggregator(data));
    // }

    const filteredItems = networkResult && networkResult.length > 0 && networkResult.filter(
        item => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1
    );
    let dataSource = filteredItems && filteredItems.length > 0 && filteredItems.map((network, key) => {
        // const aggregator = list && list.length > 0 && list.filter(item => item.ID === network.PrefferedAggregator)
        // const rechargeAggregator = list && list.length > 0 && list.filter(item => item.ID === network.RechargeAggregator)
        return {
            key: key + 1,
            logo: <img width={100} src={`${BASE_URL}/networks/${network.NetworkLogoURL}?${(Date.now())}`} />,
            NetworkName: network.NetworkName,
            // preferredAggregator: aggregator && aggregator.length > 0 && aggregator[0].AggregatorName,
            // rechargeAggregator:rechargeAggregator && rechargeAggregator.length > 0 && rechargeAggregator[0].AggregatorName,
            status: <Tag color={`${network.IsActive === 1 ? "badge-success" : "badge-danger"}`} title={network.IsActive === 1 ? 'Active' : 'Inactive'} />,
            action: (
                <>
                    <Link to="#" onClick={() => handleEditNetworkModal(network.ID)}>
                        <span data-toggle="tooltip" data-placement="top" title="Edit">
                            <PencilFill size="1.2em" style={{ color: "#888b8e", marginRight: "3px" }} />
                        </span>
                    </Link>
                    {deleteButtonLoading && networkItemId === network.ID ? <span className="spinner-border spinner-border-sm" role="status"></span> : network.IsActive === 1 ? (
                        <Popconfirm
                            title="Are you sure to inactive this network?"
                            okText="Yes"
                            cancelText="No"
                            placement="topRight"
                            onConfirm={() => handleDelete(network.ID, 0)}
                        >
                            <Link className="deleteBtn" to="# ">
                                <span data-toggle="tooltip" data-placement="top" title="Inactive">
                                    <DeleteBin5Fill size="1.2em" style={{ color: "#f14336", marginRight: "3px" }} />
                                </span>
                            </Link>
                        </Popconfirm>
                    ) : (
                        <Popconfirm
                            title="Are you sure to active this network?"
                            okText="Yes"
                            cancelText="No"
                            placement="topRight"
                            onConfirm={() => handleDelete(network.ID, 1)}
                        >
                            <Link className="deleteBtn" to="# ">
                                <span data-toggle="tooltip" data-placement="top" title="Active">
                                    <CheckboxCircleFill size="1.2em" style={{ color: "#2cb549", marginRight: "3px" }} />
                                </span>
                            </Link>
                        </Popconfirm>
                    )}
                    {/* <Link to="#" onClick={() => handlepreferredAggregatorModal(network.ID)}>
                        <LinksLineIcon size="1.2em" style={{ color: "#0094b3", marginRight: "3px" }} />
                    </Link> */}
                </>)
        }
    });

    return (
        <CardLayout title="Network">
            {showAlert && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}
            <div className="row">
                <div className='col-lg-6 col-md-6 od-2'>
                    <SearchBar onFilter={handleFilterChange} filterText={filterText} /></div>
                <div className='col-lg-6 col-md-6 text-right'>
                    <SideButtons title="Add Network" buttonStyle="primary" onClick={handleAddNetwork} /></div></div>
            <Table columns={columns} loading={loading} data={dataSource} pagination={true} />

            {/* Add Network */}
            <Modal
                showModal={networkFormModal}
                title={formTitle}
                onHide={closeNetworkModal}
                width={550}
                showFooter={false}
            >
                <form onSubmit={handleSubmit(editFlag ? handleUpdate : onSubmit)}>
                    <div className="col-md-12 mb-1">
                        <label>Network Name</label>
                        <input type="text" placeholder="Network Name" className="form-control" name="NetworkName" {...register("NetworkName", {
                            required: true,
                        })} />
                        {errors.NetworkName?.type === "required" && (
                            <div className="invalid-feedback">Network name is required !</div>
                        )}
                    </div>

                    <div className="col-md-12 mb-1 d-flex flex-column ">
                        <label>Logo</label>
                        <input type="file" name="NetworkLogoURL"
                            {...register('NetworkLogoURL', {
                                required: editFlag ? false : true,
                            })
                            }
                            className='form-control' onChange={handleImageChange} />
                        {<small style={{ opacity: '0.7', color: "red" }}>Note - The image size should be less than 1 Mb, and its dimensions should be 600 * 257 pixels. The acceptable file extensions are png,jpg,jpeg !</small>}

                        {errors.NetworkLogoURL?.type === "validate" && (
                            <div className="invalid-feedback">{errors.NetworkLogoURL.message}</div>
                        )}

                        {imageMessage && (
                            <div className="invalid-feedback">{imageMessage}</div>
                        )}
                        {!imageUrl && errors.NetworkLogoURL?.type === "required" && (
                            <div className="invalid-feedback">Logo is required !</div>
                        )}

                        {
                            imagePreview && editFlag &&
                            <img src={imageUrl == '' ? `${BASE_URL}//networks/${networkForm.NetworkLogoURL}` : imageUrl} style={{
                                width: "180px",
                                margin: "10px 0 0 0"
                            }} />
                        }
                        {
                            imageUrl && !editFlag && <img src={imageUrl} style={{
                                width: "180px",
                                margin: "10px 0 0 0"
                            }} />
                        }
                    </div>

                    <div className='text-right row my-2'>
                        <div className="col-md-12">
                            <button type="button" className="btn btn-secondary mr-2" onClick={closeNetworkModal}>Close</button>
                            <Button loading={buttonLoading} title={buttonText} buttonClass="btn btn-primary" buttonType='submit' />
                        </div>
                    </div>

                </form>

            </Modal>

            {/* Preffered Aggregator */}
            {/* <Modal
                showModal={preferedAggregatorModal}
                title={formTitle}
                onHide={handleCancel}
                width={550}
                showFooter={false}>

                <form onSubmit={handleSubmitReset(handleUpdatePrefferedAggregator)}>
                    <div className="col-md-12 mb-3 p-0">
                        <label>Activation Aggregator</label>
                        <select className="form-control form-select" placeholder="Select Aggregator" name="PrefferedAggregatorId"
                            {...registerReset('PrefferedAggregatorId', { required: true })} >
                            <option value="" key="0" disabled>Select Aggregator</option>
                            {
                                listGetByNetworkId && listGetByNetworkId.length && listGetByNetworkId.map((item, index) => {
                                    return <option value={item.ID} key={`${index}0`}> {item.AggregatorName}</option>
                                })
                            }
                        </select>
                        {errorsReset.PrefferedAggregatorId?.type === "required" && (<div className="invalid-feedback">Activation Aggregator is required !</div>)}
                    </div>

                    <div className="col-md-12 mb-3 p-0">
                        <label>Recharge Aggregator</label>
                        <select className="form-control form-select" placeholder="Select Aggregator" name="RechargeAggregatorId"
                            {...registerReset('RechargeAggregatorId', { required: true })} >
                            <option value="" key="0" disabled>Select Aggregator</option>
                            {
                                listGetByNetworkId && listGetByNetworkId.length && listGetByNetworkId.map((item, index) => {
                                    return <option value={item.ID} key={`${index}0`}> {item.AggregatorName}</option>
                                })
                            }
                        </select>
                        {errorsReset.RechargeAggregatorId?.type === "required" && (<div className="invalid-feedback">Recharge Aggregator is required !</div>)}
                    </div>

                    <div className='text-right row my-2'>
                        <div className="col-md-12">
                            <button type="button" className="btn btn-secondary mr-2" onClick={handleCancel}>Close</button>
                            <Button title={buttonText} loading={buttonLoading} buttonClass="btn btn-primary" buttonType='submit' />
                        </div>
                    </div>
                </form>


            </Modal> */}


        </CardLayout>
    )
}

export default Network