import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { getList, addData, editData, deleteData } from '../../helpers/fackBackend_Helper';


function* getLocationResponse() {
    try {
        const response = yield call(getList, '/location');
        if (response.Status) {
            yield put(actions.getLocationSuccess(response.locationData));
        }
        else {
            yield put(actions.getLocationFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.getLocationFailed(error));
    }
}

function* addLocationResponse({ payload: { locationData } }) {
    try {
        const response = yield call(addData, '/location', locationData);
        if (response.Status) {
            const responseList = yield call(getList, '/location');
            if (responseList.Status) {
                yield put(actions.addLocationSuccess(response.Message, responseList.locationData,))
            } else {
                yield put(actions.addLocationFailed(responseList.Message));
            }
        } else {
            yield put(actions.addLocationFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.addLocationFailed(error));
    }
}
function* editLocationResponse({ payload: { location, locationId } }) {
    try {
        const response = yield call(editData, '/location/' + locationId, location);
        if (response.Status) {
            const responseList = yield call(getList, '/location');
            if (responseList.Status) {
                yield put(actions.updateLocationSuccess(response.Message, responseList.locationData,));
            }
            else {
                yield put(actions.updateLocationFailed(responseList.Message));
            }
        }
        else {
            yield put(actions.updateLocationFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.updateLocationFailed(error));
    }
}
function* deleteLocationResponse({ payload: { locationId, status } }) {
    try {

        const response = yield call(deleteData, '/location/' + locationId + '/' + status);
        if (response.Status) {
            const responseList = yield call(getList, '/location');
            if (responseList.Status) {
                yield put(actions.deleteLocationSuccess(response.Message, responseList.locationData));
            }
            else {
                yield put(actions.getLocationFailed(responseList.Message));
            }
        }
        else {
            yield put(actions.getLocationFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.getLocationFailed(error));
    }
}

function* getAssetResponse({ payload: { assetType } }) {
    try {
        const response = yield call(getList, '/asset/' + assetType);
        if (response.Status) {
            yield put(actions.getAssetSuccess(response.assetData));
        }
        else {
            yield put(actions.getAssetFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.getAssetFailed(error));
    }
}
function* addAssetResponse({ payload: { assetData } }) {

    try {
        const response = yield call(addData, '/asset', assetData);
        
        if (response.Status) {
            const responseList = yield call(getList, '/asset/'+ 0);
            if (responseList.Status) {
                yield put(actions.addAssetSuccess(responseList.assetData, response.Message))
            } else {
                yield put(actions.addAssetFailed(responseList.Message));
            }
        } else {
            yield put(actions.addAssetFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.addAssetFailed(error));
    }
}

function* updateAssetResponse({ payload: {assetData,assetId,assetType} }) {
        try {
        const response = yield call(editData, '/asset/'+ assetId,assetData );
        if (response.Status) {
            const responseList = yield call(getList, '/asset/' +assetType);
            if (responseList.Status) {
                yield put(actions.updateAssetSuccess(response.Message,responseList.assetData));
            }
            else {
                yield put(actions.updateAssetFailed(responseList.Message));
            }
        }
        else {
            yield put(actions.updateAssetFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.updateAssetFailed(error));
    }
}

function* deleteAssetResponse({ payload: { assetId, status, assetType } }) {
    try {
        const response = yield call(deleteData, '/asset/' + assetId + '/' + status, assetType);
        if (response.Status) {
            const responseList = yield call(getList, '/asset/' + assetType);

            if (responseList.Status) {
                yield put(actions.deleteAssetSuccess(response.Message,responseList.assetData));
            }
            else {
                yield put(actions.getAssetFailed(responseList.Message));
            }
        }
        else {
            yield put(actions.getAssetFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.getAssetFailed(error));
    }
}

function* assetRunningStatusChangeResponse({ payload: { assetId, status, assetType } }) {
    try {
        const response = yield call(deleteData, '/updateAssetRunningStatus/' + assetId + '/' + status, assetType);
        if (response.Status) {
            const responseList = yield call(getList, '/asset/' + assetType);

            if (responseList.Status) {
                yield put(actions.updateJarSuccess( response.Message,responseList.assetData));
            }
            else {
                yield put(actions.getAssetFailed(responseList.Message));
            }
        }
        else {
            yield put(actions.getAssetFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.getAssetFailed(error));
    }
}



export default function* rootSaga() {
    yield all([takeEvery(actions.GET_LOCATION, getLocationResponse)]);
    yield all([takeEvery(actions.ADD_LOCATION, addLocationResponse)]);
    yield all([takeEvery(actions.UPDATE_LOCATION, editLocationResponse)]);
    yield all([takeEvery(actions.DELETE_LOCATION, deleteLocationResponse)]);
    yield all([takeEvery(actions.GET_ASSET, getAssetResponse)]);
    yield all([takeEvery(actions.ADD_ASSET, addAssetResponse)]);
    yield all([takeEvery(actions.UPDATE_ASSET, updateAssetResponse)]);
    yield all([takeEvery(actions.DELETE_ASSET, deleteAssetResponse)]);
    yield all([takeEvery(actions.UPDATE_JAR, assetRunningStatusChangeResponse)]);

}