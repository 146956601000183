import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { editData,addData ,deleteData,getList} from '../../helpers/fackBackend_Helper';

function* getRechargePlanResponse() {
    try {
        const response = yield call(addData, '/getRechargePlanList');
        if (response.Status) {
            yield put(actions.getRechargePlanSuccess(response.Data[0]));
        } else {
            yield put(actions.getRechargePlanFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.getRechargePlanFailed(error.Message));
    }
}

function* addRechargePlanResponse({ payload: { data } }) {
    
    try {
        const response = yield call(addData, '/addRechargePlan',data);
        if (response.Status) {
            yield put(actions.addRechargePlanSuccess(response.Message));
            yield put(actions.getRechargePlan());
        } else {
            yield put(actions.addRechargePlanFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.addRechargePlanFailed(error));
    }
}

function* updateRechargePlanResponse({ payload: { data } }) {
    try {
        const response = yield call(editData, '/updateRechargePlan',data);
        if (response.Status) {
            yield put(actions.updateRechargePlanSuccess(response.Message));
            yield put(actions.getRechargePlan());
        } else {
            yield put(actions.updateRechargePlanFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.updateRechargePlanFailed(error));
    }
}


function* deleteRechargePlanResponse({ payload: { planId, status } }) {
    try {
        const response = yield call(deleteData, '/deleteRechargePlan/' + planId + '/' + status);
        if (response.response.Status) {  
            yield put( actions.deleteRechargePlanSuccess( response.response.Message));
             yield put(actions.getRechargePlan());
        } else {
            yield put(actions.deleteRechargePlanFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.deleteRechargePlanFailed(error));
    }
}

function* updatePrefferedAggregatorResponse({ payload: { data } }) {
    try {
        const response = yield call(editData, '/updateNetworkPrefferedAggregatorRecharge', data);
        if (response.Status) {
             yield put(actions.updatePreffedAggregatorRechargeSuccess(response.Message));
            yield put(actions.getRechargePlan());
        } else {
            yield put(actions.updatePreffedAggregatorRechargeFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.updatePreffedAggregatorRechargeFailed(error));
    }
}


export default function* rootSaga() {
    yield all([takeEvery(actions.GET_RECHARGE_PLAN, getRechargePlanResponse)]);
    yield all([takeEvery(actions.ADD_RECHARGE_PLAN, addRechargePlanResponse)]);
    yield all([takeEvery(actions.UPDATE_RECHARGE_PLAN, updateRechargePlanResponse)]);
    yield all([takeEvery(actions.DELETE_RECHARGE_PLAN, deleteRechargePlanResponse)]);
    yield all([takeEvery(actions.UPDATE_PREFFERED_AGGREGATOR_RECHARGE, updatePrefferedAggregatorResponse)]);


}
