import actions from "./actions";

const initState = {
    bulkActivation: [],
    loading: false,
    error: null,
    buttonLoading: false,
};
export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_BULK_ACTIVATION_REPORT:
            return {
                ...state,
                loading: true,
                error: null,
                buttonLoading :true,
            };
        case actions.GET_BULK_ACTIVATION_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                bulkActivation: action.payload.bulkActivation,
                buttonLoading :false
            };
        case actions.GET_BULK_ACTIVATION_REPORT_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
                buttonLoading :false
            };
        default:
            return state;
    }
}