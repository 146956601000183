import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import Alert from '../../components/utils/Alert';
import EyeLineIcon from 'remixicon-react/EyeLineIcon';
import EyeOffLineIcon from 'remixicon-react/EyeOffLineIcon';
import Button from '../../components/utils/Button';

import dealerAction from '../../redux/dealer/actions'
import Modal from './Model'
import { PASSWORD_VALIDATE } from '../../helpers/constant';

const { getDealer, resetDealer, addDealer, getEmailDomains, getDealerAllowCreation, editDealer, deleteDealer, prepareDealerForm, getDealerLevel, resetDealerMessage } = dealerAction;

const DealerAddModal = () => {
    const dispatch = useDispatch();
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [editFlag, setEditFlag] = useState(false);
    const [passwordShow, setPasswordShow] = useState(false);
    const [editID, seteditID] = useState(null);
    const [formButton, setFormButton] = useState('');
    const [dealerCreatetionFlag, setDealerCreatetionFlag] = useState();

    const [dealerData, setDealerData] = useState({
        allowSellerCreation: 0,
        EmailForwarding: 1
    })
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertText, setAlertText] = useState('');

    const { dealerFormModal, emailDomainsList, dealerLevelResult, loading } = useSelector((state) => state.dealer);
    const { countryResult } = useSelector((state) => state.country);

    const { register, handleSubmit, formState: { errors }, watch, reset, control } = useForm({
        defaultValues: dealerData,
    });

    const onSubmit = (data) => {
        data.address = data.address ? data.address : '';
        data.city = data.city ? data.city : '';
        data.state = data.state ? data.state : '';
        data.zipCode = data.zipCode ? data.zipCode : '';
        const newData = {
            ...data,
            allowSellerCreation: data.allowSellerCreation ? 1 : 0,
            allowTariffCreation: data.allowTariffCreation ? 1 : 0,
        };
        dispatch(addDealer(newData));
    };

    const handleUpdate = (data) => {
        const newData = {
            ...data,
            allowSellerCreation: data.allowSellerCreation ? 1 : 0,
            allowTariffCreation: data.allowTariffCreation ? 1 : 0,
        };
        dispatch(editDealer(editID, newData))
    }

    const handleCancel = () => {
        reset(dealerData);
        setDealerData(prevState => ({
            ...prevState,
            username: "",
            password: "",
            dealerName: "",
            contactPerson: "",
            contactNumber: "",
            contactEmailID: "",
            address: "",
            countryID: "",
            zipCode: "",
            state: "",
            city: "",
            tariffGroupID: "",
            minimumTopUpAmount: 0,
            maximumTopUpAmount: 0,
            paypalTransactionFee: 0,
            allowSellerCreation: 0,
            allowTariffCreation: 0,
        }));
        dispatch(resetDealer());
    }

    const alertCancel = () => {
        setShowAlert(false);
        setShowAlertModal(false);
        setAlertText('');
        setAlertType('');
        dispatch(resetDealerMessage());
    }

    return (
        <>
            <Modal
                showModal={dealerFormModal}
                title={"Add Dealer"}
                onHide={handleCancel}
                width={700}
                showFooter={false}
            >
                {showAlertModal && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}
                <form onSubmit={handleSubmit(editFlag ? handleUpdate : onSubmit)}>
                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            <label>Dealer Name</label>
                            <input type="text" placeholder="Enter Dealer Name" className="form-control"
                                name="dealerName" {...register("dealerName", {
                                    required: true,
                                })} />
                            {errors.dealerName?.type === "required" && (
                                <div className="invalid-feedback">Dealer name is required !</div>

                            )}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>Contact Person</label>
                            <input type="text" className="form-control" placeholder="Enter Contact Person" name="contactPerson" {...register("contactPerson", {
                                required: true,
                            })} />
                            {errors.contactPerson?.type === "required" && (
                                <div className="invalid-feedback">Contact person is required !</div>
                            )}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>Contact Number</label>
                            <input type="number" className="form-control" placeholder="Enter Contact Number" name="contactNumber" {...register("contactNumber", {
                                required: true,
                                minLength: 10,
                            })} />
                            {errors.contactNumber?.type === "required" && (
                                <div className="invalid-feedback">Contact number is required !</div>
                            )}
                            {(errors.contactNumber?.type === "minLength" || errors.contactNumber?.type === "maxLength") && (
                                <div className="invalid-feedback">Contact number is not valid !</div>
                            )}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>Email ID</label>
                            <input type="text" className="form-control" placeholder="Enter Your Email" name="contactEmailID" {...register("contactEmailID", {
                                required: true,
                                pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/
                            })} />
                            {errors.contactEmailID?.type === "required" && (
                                <div className="invalid-feedback">Email is required !</div>
                            )}
                            {errors.contactEmailID?.type === "pattern" && (
                                <div className="invalid-feedback">Email is not valid !</div>
                            )}
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="col-md-8 mb-3">
                            <label>Address</label>
                            <input type="text" className="form-control" placeholder="Enter Your Address" name="address"
                                {...register("address", {
                                    required: false,
                                })} />
                            {errors.address?.type === "required" && (
                                <div className="invalid-feedback">Address is required !</div>

                            )}
                        </div>
                        <div className="col-md-4 mb-3">
                            <label>City</label>
                            <input type="text" className="form-control" placeholder="Enter Your City" name="city"
                                {...register("city", {
                                    required: false,
                                })} />
                            {errors.city?.type === "required" && (
                                <div className="invalid-feedback">City is required !</div>
                            )}
                        </div>
                        <div className="col-md-4 mb-3">
                            <label>State</label>
                            <input type="text" className="form-control" placeholder="Enter Your State" name="state"
                                {...register("state", {
                                    required: false,
                                })} />
                            {errors.state?.type === "required" && (
                                <div className="invalid-feedback">State is required !</div>
                            )}
                        </div>

                        <div className="col-md-4 mb-3">
                            <label>Country</label>
                            <select className="form-control" placeholder="Select Country" name="countryID"  {...register("countryID", {
                                required: true,
                            })} >
                                <option value="" key="0">Select</option>
                                {countryResult.length > 0 && countryResult.map(country => (
                                    <option name="countryID" value={country.ID} key={country.ID}>
                                        {/* <ReactCountryFlag countryCode="US" svg style={{ width: '2em', height: '2em' }} title="US" /> */}
                                        &nbsp;&nbsp; {country.CountryName}</option>

                                ))}
                            </select>
                            {errors.countryID?.type === "required" && (
                                <div className="invalid-feedback">Country is required !</div>
                            )}
                        </div>

                        <div className="col-md-4 mb-3">
                            <label>Postal Code</label>
                            <input type="number" className="form-control" placeholder="Enter Your Postal Code" onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} name="zipCode" {...register("zipCode", {
                                required: false,
                                minLength: 4,
                            })} />
                            {errors.zipCode?.type === "required" && (
                                <div className="invalid-feedback">Postal code is required !</div>
                            )}
                            {(errors.zipCode?.type === "minLength") && (
                                <div className="invalid-feedback">Postal code should be at least 5 digit !</div>
                            )}
                        </div>
                    </div>

                    <div className="form-row">

                        {!editFlag &&
                            <div className="col-md-6 mb-3">
                                <label>Username</label>
                                <input type="text" autoComplete="off" className="form-control" placeholder='Enter Username' name="username" {...register("username", {
                                    required: true,
                                })} />

                                {errors.username?.type === "required" && (
                                    <div className="invalid-feedback">Username is required !</div>
                                )}
                            </div>}
                        {!editFlag && <div className="col-md-6 mb-3">
                            <label>Password</label>
                            <input type={passwordShow ? 'text' : 'password'} autoComplete="new-password" className="form-control" placeholder='Password' name="password" {...register("password", {
                                required: true,
                                // minLength: 4,
                                validate: (value) => {
                                    return PASSWORD_VALIDATE.test(value)
                                }
                            })} />
                            {
                                passwordShow ? <EyeLineIcon size="1.1em" style={Style.eyeIcon} onClick={() => setPasswordShow((pre) => !pre)} /> : <EyeOffLineIcon size="1.1em" style={Style.eyeIcon} onClick={() => setPasswordShow((pre) => !pre)} />
                            }
                            {errors.password?.type === "required" && (<div className="invalid-feedback">Password is required !</div>)}
                            {errors.password && errors.password.type === "validate" != '' ? <div className="invalid-feedback">Invalid password format(Example@123) !</div> : ''}
                        </div>}


                        <div className="col-md-6 mb-3">
                            <label>Email Domain</label>
                            <select className="form-control" placeholder="Select" name="EmailForwarding"  {...register("EmailForwarding", {
                                required: true,
                            })} >
                                <option value='' disabled key='0'>Select</option>
                                {emailDomainsList.length > 0 && emailDomainsList.map(item => (
                                    <option value={item.ID} key={item.ID}>{item.Domain}</option>
                                ))}
                            </select>
                        </div>

                        {dealerLevelResult && dealerLevelResult.length > 0 && dealerLevelResult.map((data) => {
                            return (data.level === 1 && <div className="col-md-6 mt-4">
                                <div className="custom-control custom-checkbox custom-control-inline pt-2">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="customCheck-t"
                                        disabled={dealerCreatetionFlag}
                                        {...register("allowSellerCreation", { value: "1", setValueAs: (val) => (val[0] === "1" ? "1" : "0") })}
                                    />
                                    <label className="custom-control-label" for="customCheck-t">Allow Dealer Creation</label>
                                </div>
                            </div>)
                        })}

                    </div>
                    <hr />
                    <div className='text-right row'>
                        <div className="col-md-12">
                            <button type="button" className="btn btn-secondary mr-2" onClick={handleCancel}>Close</button>
                            <Button title={'Submit'} style="minWidth:80px" buttonClass="btn btn-primary" loading={loading} buttonType='submit' />
                        </div>
                    </div>

                </form>
            </Modal>
        </>
    )
}

const Style = {
    eyeIcon: {
        position: 'absolute',
        right: '10px',
        top: '40px',
        cursor: 'pointer'
    },
    modalContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: 'center'
    }
}

export default DealerAddModal;