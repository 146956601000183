const actions = {
    GET_ASSET_PERFORMANCE_REPORT: "GET_ASSET_PERFORMANCE_REPORT",
    GET_ASSET_PERFORMANCE_REPORT_SUCCESS: "GET_ASSET_PERFORMANCE_REPORT_SUCCESS",
    GET_ASSET_PERFORMANCE_REPORT_FAILED: "GET_ASSET_PERFORMANCE_REPORT_FAILED",

    getAssetPerformanceReport: (searchData) => ({
        type: actions.GET_ASSET_PERFORMANCE_REPORT,
        payload: {searchData},
    }),
    getAssetPerformanceReportSuccess: (assetPerformance) => ({
        type: actions.GET_ASSET_PERFORMANCE_REPORT_SUCCESS,
        payload: {assetPerformance}
    }),
    getAssetPerformanceReportFailed: (error) => ({
        type: actions.GET_ASSET_PERFORMANCE_REPORT_FAILED,
        error
    }),

}

export default actions;