import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/utils/Button'
import Modal from '../../components/utils/Model'
import activationRequestActions from '../../redux/activationRequest/actions';
import CheckLineIcon from 'remixicon-react/CheckLineIcon';
import ErrorWarningLineIcon from 'remixicon-react/ErrorWarningLineIcon';
// import Styles from './ActivationRequest.module.css'
import './SimSwapModal.css'

const { closeSimSwapModal, accessTransactionModal, createSimSwapRequest } = activationRequestActions;

const SimSwapModalComponent = ({ activationId }) => {
    const dispatch = useDispatch();


    const { simSwapModal, requestData, modalViewLoader, createSimSwapRequestLoading } = useSelector((state) => state.activationRequest);

    const { register, handleSubmit, formState: { errors }, watch, reset, setValue } = useForm({
        defaultValues: {
            childActivationId: ""
        }
    });

    useEffect(() => {
        if (activationId > 0) {
            dispatch(accessTransactionModal(activationId));
            setValue('activationId', activationId)
        }
    }, []);

    const handleCancel = () => {
        dispatch(closeSimSwapModal());
    }

    const parentData = useMemo(() => {
        if (requestData && requestData[0]) {
            let addonData = requestData[0].AddOnServices.split(',').map((val) => { return val.split('_'); });
            let addonHtml = addonData.map((addonItem) => {
                return <lable><CheckLineIcon color='green' />{addonItem[0]}&emsp; - &emsp;${addonItem[1]}<br /></lable>
            })
            return [{
                ID: requestData[0].ID,
                IMEI: requestData[0].IMEI,
                Serial: requestData[0].SerialNumber,
                SimType: requestData[0].SimType,
                Mobile: requestData[0].MSISDN,
                AddOnServices: addonHtml,
                CallerID: '',
                LineNumber: requestData[0].LineNumber,
                IsChildActivation : false,
            }]
        } else {
            return []
        }
    }, [requestData]);

    const childData = useMemo(() => {
        if (requestData && requestData[0].ChildActivation) {
            let childDataArr = JSON.parse(requestData[0].ChildActivation).map((item) => {
                return {
                    ID: item.ID,
                    IMEI: item.IMEI,
                    Serial: item.SerialNumber,
                    SimType: item.SimType,
                    Mobile: item.MSISDN,
                    AddOnServices: '',
                    CallerID: '',
                    LineNumber: item.LineNumber,
                    IsChildActivation:true
                }
            });
            return childDataArr;
        } else {
            return [];
        }
    }, [requestData]);

    const planIformationData = useMemo(() => {
        if (requestData) {
            let addonData = requestData[0].AddOnServices.split(',').map((val) => { return val.split('_'); });

            let addonHtml = addonData.map((addonItem) => {
                return <lable><CheckLineIcon color='green' />{addonItem[0]}&emsp; - &emsp;${addonItem[1]}<br /></lable>
            })

            return [
                {
                    name: "Plan Price",
                    value: '$' + requestData[0].PlanPrice,
                    class: 'col-md-6 d-flex justify-content-between mb-2',
                    skip: false,
                },
                {
                    name: "Plan Month",
                    value: requestData[0].PlanMonth + ' Months',
                    class: 'col-md-6 d-flex justify-content-between mb-2',
                    skip: false,
                },
                {
                    name: "Plan Name",
                    value: requestData[0].PlanName,
                    class: 'col-md-12 d-flex justify-content-between mb-2',
                    skip: false,
                },
                {
                    name: "Add On Services",
                    value: addonHtml,
                    class: 'col-md-12 d-flex justify-content-between mb-2',
                    skip: childData.length > 0,
                },
            ]
        } else {
            return [];
        }
    }, [requestData]);

    const handleSimSwapSubmit = (data) => {
        dispatch(createSimSwapRequest(data));
    }

    return (
        <>
            <Modal
                showModal={simSwapModal}
                title={'Sim Swap Request'}
                onHide={handleCancel}
                width={700}
                showFooter={false}
            >
                {
                    modalViewLoader ? <div className="spinner-border text-danger d-block mx-auto" role="status">
                        <span className="sr-only">Loading...</span>
                    </div> :
                        requestData && (requestData[0].MSISDN === null || requestData[0].MSISDN === undefined || requestData[0].MSISDN === '') ?
                            <div className='container text-center'>
                                <ErrorWarningLineIcon color='#e74c3c' size={100} />
                                <p className='text-dark mt-1'>Mobile number not available</p>
                            </div>
                            :
                            <>
                                <div className="row text-dark px-2">
                                    {
                                        childData.length === 0 ?
                                            <>
                                                <div className="col-md-6 d-flex justify-content-between mb-2">
                                                    <span>IMEI</span>
                                                    <span className="text-end">{requestData && requestData[0].IMEI ? requestData[0].IMEI : "N/A"}</span>
                                                </div>
                                                <div className="col-md-6 d-flex justify-content-between mb-2">
                                                    <span>Serial #</span>
                                                    <span className="text-end">{requestData && requestData[0].SerialNumber ? requestData[0].SerialNumber : "N/A"}</span>
                                                </div>
                                                <div className="col-md-6 d-flex justify-content-between mb-2">
                                                    <span>SIM Type</span>
                                                    <span className="text-end">{requestData && requestData[0].SimType ? requestData[0].SimType : "N/A"}</span>
                                                </div>
                                            </> : ''
                                    }
                                    <div className="col-md-6 d-flex justify-content-between mb-2">
                                        <span>Zip Code</span>
                                        <span className="text-end">{requestData && requestData[0].AreaCode ? requestData[0].AreaCode : "N/A"}</span>
                                    </div>
                                    {
                                        childData.length === 0 ?
                                            <div className="col-md-6 d-flex justify-content-between mb-2">
                                                <span>Mobile #</span>
                                                <span className="text-end">{requestData && requestData[0].MSISDN ? requestData[0].MSISDN : "N/A"}</span>
                                            </div> : ''
                                    }

                                    <div className="col-md-6 d-flex justify-content-between mb-2">
                                        <span>T-Mobile PIN</span>
                                        <span className="text-end">{requestData && requestData[0].PIN ? requestData[0].PIN : "N/A"}</span>
                                    </div>
                                    <div className="col-md-6 d-flex justify-content-between mb-2">
                                        <span>Network</span>
                                        <span className="text-end">{requestData ? requestData[0].NetworkName ? requestData[0].NetworkName : '' : ""}</span>
                                    </div>
                                </div>

                                <div className="row text-dark px-2">
                                    {planIformationData && planIformationData.length > 0 &&
                                        planIformationData.map((item, index) => item.skip == true ? null : (
                                            <div className={item.class} key={'index' + index}>
                                                <span>{item.name}</span>
                                                <span className="text-end">{item.value}</span>
                                            </div>
                                        ))}
                                    <div className="col-md-8 d-flex justify-content-between mb-2">
                                        <span>Customer Email</span>
                                        <span className="text-end">{requestData && requestData[0].CustomerEmail ? requestData[0].CustomerEmail : "N/A"}</span>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <hr />
                                    <h5 className="col-md-12 p-0" style={{ fontWeight: "700" }}> ENTER SWAP DETAILS </h5>
                                    <hr />
                                </div>

                                {/* Sim Swap Form */}
                                <form onSubmit={handleSubmit(handleSimSwapSubmit)}>
                                    <div className="form-row">
                                        <div className={`col-md-12 mb-3`}>
                                            {childData && childData.length > 0 && [...parentData, ...childData].map((item, index) => (

                                                <label htmlFor={`radio-card-${index}`} className="radio-card" key={'radio' + index}>
                                                    <input type="radio" name="childActivationId" value={!item.IsChildActivation ? null : item.ID} id={`radio-card-${index}`}
                                                        {...register("childActivationId", {
                                                            required: 'Child activation is required !',
                                                        })}
                                                    />
                                                    <div className="card-content-wrapper">
                                                        <div className="card-content">
                                                            <fieldset className='simSwapModalFieldSet'>
                                                                <legend className='simSwapModalLegend'> <span> <span className="check-icon"></span>Line {index + 1} :</span></legend>
                                                                <div className="row">
                                                                    <div className="col-md-6 d-flex justify-content-between mb-2">
                                                                        <span>IMEI</span>
                                                                        <span>{item.IMEI}</span>
                                                                    </div>
                                                                    <div className="col-md-6 d-flex justify-content-between mb-2">
                                                                        <span>Serial #</span>
                                                                        <span>{item.Serial}</span>
                                                                    </div>
                                                                    <div className="col-md-6 d-flex justify-content-between mb-2">
                                                                        <span>SIM Type</span>
                                                                        <span>{item.SimType}</span>
                                                                    </div>
                                                                    <div className="col-md-6 d-flex justify-content-between mb-2">
                                                                        <span>Mobile</span>
                                                                        <span>{item.Mobile != '' && item.Mobile ? item.Mobile : 'N/A'}</span>
                                                                    </div>
                                                                    <div className="col-md-6 d-flex justify-content-between mb-2">
                                                                        <span>Add On Services</span>
                                                                        <span>{item.AddOnServices != '' ? item.AddOnServices : 'N/A'}</span>
                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                    </div>
                                                </label>
                                            ))}
                                            {errors && errors.childActivationId && (<div className="invalid-feedback">{errors.childActivationId.message}</div>)}
                                        </div>

                                        <div className={`col-md-12 mb-3`}>
                                            <label className='ml-2'>Serial Number <span className='text-danger'> *</span></label>
                                            <input type="number" className="form-control" placeholder="Enter Serial Number" name="serialNumber"
                                                {...register("serialNumber", {
                                                    required: "Serial number required",
                                                    minLength: {
                                                        value: 19,
                                                        message: "serial number should contain 19 digits"
                                                    },
                                                    maxLength: {
                                                        value: 19,
                                                        message: "serial number should contain 19 digits"
                                                    }
                                                })}
                                                onWheel={(e) => e.target.blur()}
                                                onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()}
                                            />
                                            {errors && errors.serialNumber && (<div className="invalid-feedback">{errors.serialNumber.message}</div>)}
                                        </div>
                                    </div>

                                    <div className='text-right row'>
                                        <div className="col-md-12">
                                            <button type="button" className="btn btn-secondary mr-2" onClick={handleCancel}>Close</button>
                                            <Button title='Submit' style="minWidth:80px" buttonClass="btn btn-primary" loading={createSimSwapRequestLoading} buttonType='submit' />
                                        </div>
                                    </div>
                                </form>
                            </>
                }

            </Modal>
        </>
    )
}

export default SimSwapModalComponent