import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData, getList, deleteData, editData } from '../../helpers/fackBackend_Helper';

function* getTMODealerListResponse() {
    try {
        const response = yield call(getList, '/activationDealer');
        if (response.response.Status) {
            yield put(
                actions.getTmoDealerSuccess(
                    response.response.Data
                ));
        } else {
            yield put(actions.getTmoDealerFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getTmoDealerFailed(error));
    }
}

function* addTMODealerDataResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/activationDealer', data);
        if (response.response.Status) {
            const responseList = yield call(getList, '/activationDealer');
            if (responseList.response.Status) {
                yield put(
                    actions.addTmoDealerSuccess(
                        response.response.Message, responseList.response.Data
                    ));
            } else {
                yield put(actions.addTmoDealerFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.addTmoDealerFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.addTmoDealerFailed(error));
    }
}


function* deleteTMODealerDataResponse({ payload: { tmoDealerId, status } }) {
    try {
        const response = yield call(deleteData, '/activationDealer/' + tmoDealerId + '/' + status);
        if (response.response.Status) {
            const responseList = yield call(getList, '/activationDealer');
            if (responseList.response.Status) {
                yield put(actions.deleteTmoDealerSuccess(response.response.Message, responseList.response.Data));
            } else {
                yield put(actions.getTmoDealerFailed(response.response.Message));
            }
        } else {
            yield put(actions.getTmoDealerFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getTmoDealerFailed(error));
    }
}


export default function* rootSaga() {
    yield all([takeEvery(actions.GET_TMO_DEALER, getTMODealerListResponse)]);
     yield all([takeEvery(actions.ADD_TMO_DEALER, addTMODealerDataResponse)]);
    yield all([takeEvery(actions.DELETE_TMO_DEALER, deleteTMODealerDataResponse)]);
   
}
