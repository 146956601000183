import actions from "./actions";

const initState = {
    assetPerformance: [],
    loading: false,
    error: null,
    buttonLoading: false,
};
export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_ASSET_PERFORMANCE_REPORT:
            return {
                ...state,
                loading: true,
                error: null,
                buttonLoading :true,
            };
        case actions.GET_ASSET_PERFORMANCE_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                assetPerformance: action.payload.assetPerformance,
                buttonLoading :false
            };
        case actions.GET_ASSET_PERFORMANCE_REPORT_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
                buttonLoading :false
            };
        default:
            return state;
    }
}