import actions from "./actions";

const initState = {
    getMailByMsisdnLoading: false,
    getMailByMsisdnError: null,
    getMailByMsisdnMessage: null,
    getMailByMsisdnResult: [],
    readMailLoading: false,
    readMailError: null,
    readMailMessage: null,
    showNoDataFlag:false,
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_MAIL_BY_MSISDN:
            return {
                ...state,
                getMailByMsisdnLoading: true,
                getMailByMsisdnError: null,
                getMailByMsisdnMessage: null,
                showNoDataFlag:false,
            };
        case actions.GET_MAIL_BY_MSISDN_SUCCESS:
            return {
                ...state,
                getMailByMsisdnLoading: false,
                getMailByMsisdnError: null,
                getMailByMsisdnMessage: null,
                getMailByMsisdnResult: action.payload.data,
                showNoDataFlag:true,
            };
        case actions.GET_MAIL_BY_MSISDN_FAIL:
            return {
                ...state,
                getMailByMsisdnLoading: false,
                getMailByMsisdnError: action.payload.error,
                getMailByMsisdnMessage: null,
                getMailByMsisdnResult: [],
                showNoDataFlag:false,
            };

        case actions.READ_MAIL:
            let temp1 = state.getMailByMsisdnResult.map((item) => {
                let temp = item;
                if (item.ID == action.payload.mailID) {
                    temp.showMail = !temp.showMail;
                } else {
                    temp.showMail = false;
                }
                return temp;
            });
            return {
                ...state,
                readMailLoading: true,
                readMailError: null,
                readMailMessage: null,
                getMailByMsisdnResult: temp1
            };

        case actions.READ_MAIL_SUCCESS:
            let temp3 = state.getMailByMsisdnResult.map((item) => {
                let temp = item;
                if (item.ID == action.payload.mailID) {
                    temp.IsRead = 1;
                }
                return temp;
            });
            return {
                ...state,
                readMailLoading: false,
                readMailError: null,
                readMailMessage: action.payload.message,
                getMailByMsisdnResult: temp3
            };

        case actions.READ_MAIL_FAIL:
            return {
                ...state,
                readMailLoading: false,
                readMailError: null,
                readMailMessage: null,
            };

        case actions.CLOSE_MAIL_TEMPLATE:
            let temp2 = state.getMailByMsisdnResult.map((item) => {
                let temp = item;
                if (item.ID == action.payload.mailID) {
                    temp.showMail = !temp.showMail;
                }
                return temp;
            });
            return {
                ...state,
                readMailLoading: false,
                readMailError: null,
                readMailMessage: null,
                getMailByMsisdnResult: temp2
            };

        case actions.RESET_TEMPLATE_DATA:
            return {
                ...state,
                getMailByMsisdnResult: [],
            };

        default:
            return state;
    }
}