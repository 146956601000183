import React from 'react'
import CardLayout from '../../../components/utils/CardLayout'
import DatePicker from "react-datepicker";
import moment from "moment";
import { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Calendar2LineIcon from 'remixicon-react/Calendar2LineIcon';
import DownloadCloud2sLineIcon from 'remixicon-react/DownloadCloud2LineIcon';
import { useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import Button from '../../../components/utils/Button'
import SearchBar from '../../../components/utils/SearchBar';
import Table from '../../../components/utils/Table';
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import networkActions from '../../../redux/network/actions';
import BulkActivationReportActions from '../../../redux/bulkActivationReport/actions'
import Styles from './bulkActivationReport.module.css'
import { convertDateToRequiredFormatTZ } from '../../../helpers/commonFunction';

const { getActiveNetwork } = networkActions;
const { getBulkActivationReport } = BulkActivationReportActions;

const BulkActivationReport = () => {
  const dispatch = useDispatch();
  const { networkResult } = useSelector((state) => state.network);
  const { bulkActivation, loading } = useSelector((state) => state.bulkActivationReport);
  const { register, handleSubmit, formState: { errors }, watch, reset } = useForm();
  const [toDate, setToDate] = useState(moment().startOf('day').toDate())
  const [fromDate, setFromDate] = useState(moment().subtract(7, 'days').toDate());
  const datePickerRef = useRef(null);
  const TIMEZONE = process.env.REACT_APP_TIMEZONE;
  const datePickerRef2 = useRef(null);
  const [filterText, setFilterText] = useState("");
  const [searched, setSearched] = useState(false);
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';


  const columns = [
    { displayName: '#', key: 'key' },
    { displayName: 'BULK TRANSACTION ID', key: 'bulkTransactionID' },
    { displayName: 'DEALER', key: 'DealerName' },
    { displayName: 'TOTAL', key: 'total' },
    { displayName: 'SUCCESS', key: 'success' },
    { displayName: 'FAILED', key: 'failed' },
    { displayName: 'CREATED DATE', key: 'createdDate' },
    { displayName: 'FAILED RECORD', key: 'failedRecord' },
  ];


  const handleFilterChange = (value) => {
    setFilterText(value);
  };

  const openDatePicker = () => {
    datePickerRef.current.setOpen(true);
  };
  const openDatePicker2 = () => {
    datePickerRef2.current.setOpen(true);
  };

  useEffect(() => {
    dispatch(getActiveNetwork());
  }, []);

  useEffect(() => {
    const newData = {
      toDate: moment(toDate).format("YYYY-MM-DD"),
      fromDate: moment(fromDate).format("YYYY-MM-DD"),
    };
    dispatch(getBulkActivationReport(newData));
  }, [])

  useEffect(() => {
    if (networkResult.length == 1) {
      reset({
        networkID: '1'
      });
    }
  }, [networkResult]);

  const renderActivationTable = () => {
    if (searched) {
      return (
        <Table columns={columns} data={data} loading={loading} pagination={true} />
      );
    } else {
      return null;
    };
  }

  const renderExportButton = (failedValue, id) => {
    if (failedValue > 0) {
      return (
        <Button
          icon={<DownloadCloud2sLineIcon color='#fff' className='mr-1 excel-btn-1-aidit ' size={'0.9rem'} />}
          title="Export"
          style={{ marginRight: "10px" }}
          buttonClass={`btn btn-sm ${Styles.exportBtn}`}
          buttonStyle="primary"
          onClick={() => exportToCSV(id)}
        />
      );
    } else {
      return null;
    }
  };
  const onSubmit = (data) => {
    setSearched(true);
    const newData = {
      ...data,
      toDate: moment(new Date(toDate)).format("YYYY-MM-DD"),
      fromDate: moment(new Date(fromDate)).format("YYYY-MM-DD"),
    };
    dispatch(getBulkActivationReport(newData))
  };

  const exportToCSV = async (id) => {
    let selectedRow = bulkActivation.filter((item) => item.ID == id);
    let dataForExcel = JSON.parse(selectedRow[0].FailedRecord);


    if (dataForExcel && dataForExcel.length > 0) {
      dataForExcel = dataForExcel.map(({ imeiNumber,
        simNumber, zipCode, pin, customerEmail, reason }) => ({
          IMEI: imeiNumber,
          SerialNumber: simNumber,
          ZipCode: zipCode,
          Pin: pin,
          Email: customerEmail,
          Reason: reason
        }));

      const ws = XLSX.utils.json_to_sheet(dataForExcel);
      const wb = { Sheets: { 'bulkActivation': ws }, SheetNames: ['bulkActivation'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      await FileSaver.saveAs(data, 'Bulk_Activation_report.xlsx');
    }
  }

  const filteredItems = bulkActivation && bulkActivation.length > 0 && bulkActivation.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );

  const data = filteredItems && filteredItems.length > 0 && filteredItems.map((Item, key) => {
    return {
      key: key + 1,
      bulkTransactionID: Item.BulkTransactionID,
      DealerName: Item.DealerName,
      total: Item.Total,
      success: Item.Success,
      failed: Item.Failed,
      createdDate: Item.CreatedDtTm !== null ? convertDateToRequiredFormatTZ(Item.CreatedDtTm) : '',
      failedRecord: renderExportButton(Item.Failed, Item.ID)

    }
  });

  return (
    <CardLayout title="Bulk Activation Report">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row">
          <div className="col-md-4  col-lg-2  mb-2 d-flex flex-column">
            <label className='imei-font'>Network</label>
            <select className="form-control" placeholder="Select Network" name="networkID"  {...register("networkID", {
            })} >
              <option value="" key="0">All</option>
              {networkResult.length > 0 && networkResult.map(network => (
                <option value={network.ID} key={network.ID}>{network.NetworkName}</option>

              ))}
            </select>
          </div>
          <div className="col-md-4 col-lg-2 mb-2 d-flex flex-column">
            <label className='imei-font'>From Date</label>
            <Calendar2LineIcon onClick={openDatePicker} size={'1.5rem'} style={{ cursor: 'pointer', position: 'absolute', 'top': '36px', right: '12px', zIndex: 1 }} />
            <DatePicker
              ref={datePickerRef}
              selected={fromDate}
              className="form-control"
              onChange={(date) => setFromDate(date)}
              dateFormat="yyyy-MM-dd"
              name="fromDate"
              showMonthDropdown
              showYearDropdown
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              value={fromDate}
            />
          </div>

          <div className="col-md-4 col-lg-2 mb-2 d-flex flex-column">
            <label className='imei-font'>To Date</label>
            <Calendar2LineIcon onClick={openDatePicker2} size={'1.5rem'} style={{ cursor: 'pointer', position: 'absolute', 'top': '36px', right: '12px', zIndex: 1 }} />
            <DatePicker
              ref={datePickerRef2}
              selected={toDate}
              className="form-control center"
              onChange={(date) => setToDate(date)}
              dateFormat="yyyy-MM-dd"
              name="todate"
              showMonthDropdown
              showYearDropdown
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              value={toDate}
            />
            {errors.dealerName?.type === "required" && (
              <div className="invalid-feedback">Date is required !</div>
            )}
          </div>

          <div className="col-md-4 col-lg-2 mb-2 d-flex flex-column">
            <label className='imei-font'>IMEI/Serial No/Bulk Txn ID</label>
            <input type="text" className="form-control" id="serialNumber" name="serialNumber" placeholder="IMEI/Serial No/Bulk Txn ID" {...register("serialNumber", {
            })}></input>
          </div>


          <div className="col-md-4 mb-2 mt-4 col-lg-2">

            <Button title="Search" style="minWidth:80px" buttonClass={` btn btn-primary mt-1 btn-md Activity_searchBtn__GlBtC`} buttonType='submit' />

          </div>

        </div>

        {searched ?
          <div className='d-flex justify-content-between mt-3'>
            <SearchBar onFilter={handleFilterChange} filterText={filterText} />
          </div>
          : ""}
        {renderActivationTable()}

      </form>

    </CardLayout>

  )
}

export default BulkActivationReport
