import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData,getList} from '../../helpers/fackBackend_Helper';

function* getMailByMsisdn({ payload: { searchData } }) {
    try {
        const response = yield call(addData, '/getMailsByMsisdn',searchData);
        if (response.response.Status) {
            yield put( actions.getMailByMsisdnSuccess( response.response.data ));
        } else {
            yield put(actions.getMailByMsisdnFail(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getMailByMsisdnFail(error.message));
    }
}

function* readMailResponse({ payload: { mailID } }) {
    try {
        const response = yield call(addData, '/readMail/' + mailID);
        if (response.response.Status) {
            yield put( actions.readMailSuccess( response.response.Message ));
        } else {
            yield put(actions.readMailFail(response.response.Message));
        }
    } catch (error) {
        yield put(actions.readMailFail(error.message));
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.GET_MAIL_BY_MSISDN, getMailByMsisdn)]);
    yield all([takeEvery(actions.READ_MAIL, readMailResponse)]);
}
