import actions from "./actions";

const initState = {
    simSwapRequestList: [],
    simSwapRequestLoading: false,
    simSwapRequestError: null,
    simSwapRequestMessage: null,
    lockSimSwapRequestLoading: false,
    lockSimSwapRequestError: null,
    lockSimSwapRequestMessage: null,
    releaseLockSimSwapRequestLoading: false,
    releaseLockSimSwapRequestError: null,
    releaseLockSimSwapRequestMessage: null,
    completeSimSwapModal: false,
    cancelSimSwapModal: false,
    cancelSimSwapRequestLoading: false,
    cancelSimSwapRequestError: null,
    cancelSimSwapRequestMessage: null,
    completeSimSwapRequestLoading: false,
    completeSimSwapRequestError: null,
    completeSimSwapRequestMessage: null,
    getOtpLoading: false,
    getOtpError: null,
    otpData: [],

    simSwapFormModal: false,
    activationDetailsList: [],
    activationDetailsLoading: false,
    activationDetailsError: null,
    activationDetailsMessage: null,

    createSimSwapRequestLoading: false,
    createSimSwapRequestMessage: null,
    createSimSwapRequestErroe: null,

    checkSimSwapCompletionLoading: false,
    checkSimSwapCompletionError: null,
    checkSimSwapCompletionMessage: null,
    checkedSimSwap: false,
};

export default function reducer(state = initState, action) {
    switch (action.type) {

        case actions.GET_OTP:
            return {
                ...state,
                getOtpLoading: true,
                getOtpError: null,
                otpData: null,
            };
        case actions.GET_OTP_SUCCESS:
            return {
                ...state,
                getOtpLoading: false,
                getOtpError: null,
                otpData: action.payload.otp,
            };
        case actions.GET_OTP_FAILED:
            return {
                ...state,
                getOtpLoading: false,
                getOtpError: action.payload.error,
                otpData: null,
            };


        case actions.COMPLETE_SIM_SWAP_REQUEST:
            return {
                ...state,
                completeSimSwapRequestLoading: true,
                completeSimSwapRequestError: null,
                completeSimSwapRequestMessage: null,
                completeSimSwapModal: true,
            };
        case actions.COMPLETE_SIM_SWAP_REQUEST_SUCCESS:
            return {
                ...state,
                completeSimSwapRequestLoading: false,
                completeSimSwapRequestError: null,
                completeSimSwapRequestMessage: action.payload.message,
                completeSimSwapModal: false,
                checkedSimSwap: false,
                otpData: [],
            };
        case actions.COMPLETE_SIM_SWAP_REQUEST_FAILED:
            return {
                ...state,
                completeSimSwapRequestLoading: false,
                completeSimSwapRequestError: action.payload.error,
                completeSimSwapRequestMessage: null,
                completeSimSwapModal: true,
                
            };


        case actions.CANCEL_SIM_SWAP_REQUEST:
            return {
                ...state,
                cancelSimSwapRequestLoading: true,
                cancelSimSwapRequestError: null,
                cancelSimSwapRequestMessage: null,
            };
        case actions.CANCEL_SIM_SWAP_REQUEST_SUCCESS:
            return {
                ...state,
                cancelSimSwapRequestLoading: false,
                cancelSimSwapRequestError: null,
                cancelSimSwapRequestMessage: action.payload.message,
                cancelSimSwapModal: false,
            };
        case actions.CANCEL_SIM_SWAP_REQUEST_FAILED:
            return {
                ...state,
                cancelSimSwapRequestLoading: false,
                cancelSimSwapRequestError: action.payload.error,
                cancelSimSwapRequestMessage: null,
            };


        case actions.OPEN_CANCEL_SIM_SWAP_MODAL:
            return {
                ...state,
                cancelSimSwapModal: true,
            };
        case actions.CLOSE_CANCEL_SIM_SWAP_MODAL:
            return {
                ...state,
                cancelSimSwapModal: false,
            };

        case actions.CLOSE_COMPLETE_SIM_SWAP_MODAL:
            return {
                ...state,
                completeSimSwapModal: false,
                getOtpLoading: false,
                getOtpError: null,
                otpData: null,
                checkedSimSwap: false,
            };
        case actions.OPEN_COMPLETE_SIM_SWAP_MODAL:
            return {
                ...state,
                completeSimSwapModal: true,
            };


        case actions.RELEASE_LOCK_SIM_SWAP_REQUEST:
            return {
                ...state,
                lockSimSwapRequestMessage: null,
                lockSimSwapRequestError: null,
                releaseLockSimSwapRequestLoading: true,
                releaseLockSimSwapRequestError: null,
                releaseLockSimSwapRequestMessage: null,
            };
        case actions.RELEASE_LOCK_SIM_SWAP_REQUEST_SUCCESS:
            return {
                ...state,
                lockSimSwapRequestMessage: null,
                lockSimSwapRequestError: null,
                releaseLockSimSwapRequestLoading: false,
                releaseLockSimSwapRequestError: null,
                releaseLockSimSwapRequestMessage: action.payload.message,
            };
        case actions.RELEASE_LOCK_SIM_SWAP_REQUEST_FAILED:
            return {
                ...state,
                releaseLockSimSwapRequestLoading: false,
                releaseLockSimSwapRequestError: action.payload.error,
                releaseLockSimSwapRequestMessage: null,
            };


        case actions.LOCK_SIM_SWAP_REQUEST:
            return {
                ...state,
                releaseLockSimSwapRequestMessage: null,
                releaseLockSimSwapRequestError: null,
                lockSimSwapRequestLoading: true,
                lockSimSwapRequestError: null,
                lockSimSwapRequestMessage: null,
                otpData: [],
            };
        case actions.LOCK_SIM_SWAP_REQUEST_SUCCESS:
            return {
                ...state,
                lockSimSwapRequestLoading: false,
                lockSimSwapRequestError: null,
                lockSimSwapRequestMessage: action.payload.message,

            };
        case actions.LOCK_SIM_SWAP_REQUEST_FAILED:
            return {
                ...state,
                lockSimSwapRequestLoading: false,
                lockSimSwapRequestError: action.payload.error,
                lockSimSwapRequestMessage: null,
            };


        case actions.GET_SIM_SWAP_REQUEST:
            return {
                ...state,
                simSwapRequestLoading: true,
                simSwapRequestError: null,
                simSwapRequestMessage: null,
                simSwapRequestList: []
            };
        case actions.GET_SIM_SWAP_REQUEST_SUCCESS:
            return {
                ...state,
                simSwapRequestLoading: false,
                simSwapRequestError: null,
                simSwapRequestList: action.payload.data,
            };
        case actions.GET_SIM_SWAP_REQUEST_FAILED:
            return {
                ...state,
                simSwapRequestLoading: false,
                simSwapRequestError: action.payload.error,
                simSwapRequestMessage: null,
            };

        case actions.OPEN_SIM_SWAP_FORM_MODAL:
            return {
                ...state,
                simSwapFormModal: true,
            };

        case actions.CLOSE_SIM_SWAP_FORM_MODAL:
            return {
                ...state,
                simSwapFormModal: false,
                activationDetailsList: [],
            };

        case actions.GET_ACTIVATION_DETAILS_BY_MSISDN:
            return {
                ...state,
                activationDetailsList: [],
                activationDetailsLoading: true,
                activationDetailsError: null,
                // activationDetailsMessage: null,
            };
        case actions.GET_ACTIVATION_DETAILS_BY_MSISDN_SUCCESS:
            return {
                ...state,
                activationDetailsList: action.payload.data,
                activationDetailsLoading: false,
                activationDetailsError: null,
                activationDetailsMessage: null,
            };
        case actions.GET_ACTIVATION_DETAILS_BY_MSISDN_FAILED:
            return {
                ...state,
                activationDetailsLoading: false,
                activationDetailsError: action.payload.error,
                activationDetailsMessage: null,
            };


        case actions.CREATE_SIM_SWAP_REQUEST:
            return {
                ...state,
                lockSimSwapRequestMessage: null,
                releaseLockSimSwapRequestMessage: null,
                createSimSwapRequestLoading: true,
                createSimSwapRequestMessage: null,
                createSimSwapRequestErroe: null,
                simSwapFormModal: true,
            };
        case actions.CREATE_SIM_SWAP_REQUEST_SUCCESS:
            return {
                ...state,
                createSimSwapRequestLoading: false,
                createSimSwapRequestMessage: action.payload.result[0].Message,
                createSimSwapRequestErroe: null,
                simSwapFormModal: false,
                activationDetailsList: [],
            };
        case actions.CREATE_SIM_SWAP_REQUEST_FAILED:
            return {
                ...state,
                createSimSwapRequestLoading: false,
                createSimSwapRequestMessage: null,
                createSimSwapRequestErroe: action.payload.error,
                simSwapFormModal: true,
            };

        case actions.RESET_MESSAGE:
            return {
                ...state,
                createSimSwapRequestLoading: false,
                createSimSwapRequestMessage: null,
                createSimSwapRequestErroe: null,
                // simSwapFormModal: false,
                releaseLockSimSwapRequestMessage: null,
                releaseLockSimSwapRequestError: null,
                lockSimSwapRequestMessage: null,
                lockSimSwapRequestError: null,
                completeSimSwapRequestError: null,
                completeSimSwapRequestMessage: null,
                cancelSimSwapRequestError: null,
                cancelSimSwapRequestMessage: null,
                checkSimSwapCompletionMessage: null,
                checkSimSwapCompletionError: null,
                activationDetailsError: null,
                activationDetailsMessage: null,
            };

        case actions.CHECK_SIM_SWAP_COMPLETION:
            return {
                ...state,
                checkSimSwapCompletionLoading: true,
                checkSimSwapCompletionError: null,
                checkSimSwapCompletionMessage: null,
                checkedSimSwap: false,
            };

        case actions.CHECK_SIM_SWAP_COMPLETION_SUCCESS:
            return {
                ...state,
                checkSimSwapCompletionLoading: false,
                checkSimSwapCompletionError: null,
                checkSimSwapCompletionMessage: action.payload.message,
                checkedSimSwap: true,
            };

        case actions.CHECK_SIM_SWAP_COMPLETION_FAILED:
            return {
                ...state,
                checkSimSwapCompletionLoading: false,
                checkSimSwapCompletionError: action.payload.error,
                checkSimSwapCompletionMessage: null,
                checkedSimSwap: false,
            }

        default:
            return state;
    }
}