import Modal from '../../../components/utils/Model';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import SimSwapActions from '../../../redux/simSwapRequest/actions';
import Button from '../../../components/utils/Button';
import { useEffect, useState } from 'react';
import Alert from '../../../components/utils/Alert';
import moment from 'moment';

const { closeCompleteSimSwapModal, completeSimSwapRequest, getOTP, checkSimSwapCompletion, resetMessage, } = SimSwapActions;

const CompleteSimSwapModalComponent = ({ row, search }) => {
    const dispatch = useDispatch();
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertText, setAlertText] = useState('');
    const [showEmail, setShowEmail] = useState(false);


    const { completeSimSwapModal, getOtpLoading, otpData, completeSimSwapRequestLoading, checkSimSwapCompletionLoading, checkSimSwapCompletionError, checkSimSwapCompletionMessage, checkedSimSwap, completeSimSwapRequestMessage, completeSimSwapRequestError } = useSelector((state) => state.simSwapRequest);

    const { register, handleSubmit, formState: { errors }, watch, reset, setValue } = useForm({
        defaultValues: {
            remark: ""
        }
    });

    useEffect(() => {
        if (completeSimSwapRequestError !== null && completeSimSwapRequestError !== undefined) {
            displayAlert(completeSimSwapRequestError, 'alert-danger');
        }
    }, [completeSimSwapRequestError]);


    const handleCancel = () => {
        dispatch(closeCompleteSimSwapModal());
        dispatch(resetMessage())
    }

    const handleGetOtp = () => {
        dispatch(getOTP(row.ID));
    }

    const handleCheckSimSwapCompletion = () => {
        dispatch(checkSimSwapCompletion(row.ID));
    }


    const onSubmit = (data) => {
        let request = {
            simswapID: row.ID,
            remark: data.remark
        }
        dispatch(completeSimSwapRequest(request, search));
    }

    const displayAlert = (text, type) => {
        setAlertText(text);
        setAlertType(type);
        setShowAlert(true);
        dispatch(resetMessage())
    };

    const alertCancel = () => {
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
        dispatch(resetMessage())
    }

    const handleEmailView = () => {
        setShowEmail(showEmail => !showEmail);
    }

    return (
        <>
            <Modal
                showModal={completeSimSwapModal}
                title={`Complete Sim Swap Request : ${row.TransactionID}`}
                onHide={handleCancel}
                width={650}
                showFooter={false}
            >
                {showAlert && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}

                <div className="row text-dark px-2">
                    <div className="col-md-6 d-flex justify-content-between mb-2">
                        <span className=''>Are you sure you want to proceed?</span>
                    </div>


                    <div className="col-md-6 d-flex justify-content-end mb-2" style={{ color: 'white' }}>
                        {otpData && otpData.length > 0 ? '' : <Button title='Get OTP' buttonClass="btn btn-primary btn-sm" onClick={() => handleGetOtp()} loading={getOtpLoading} />}
                        {otpData && otpData.length > 0 && !checkedSimSwap ? <Button title='Check Confirmation' buttonClass="btn btn-warning btn-sm check-confrm-btn" onClick={() => handleCheckSimSwapCompletion()} loading={checkSimSwapCompletionLoading} /> : ''}
                        {checkedSimSwap ? <Button title='Completed' disable={true} buttonClass="btn btn-success btn-sm swap-complet-btn" loading={false} /> : ''}
                    </div>
                    {otpData && otpData.length > 0 && <>
                        <div className="col-md-12 mb-2">
                            <h4 style={{ textAlign: 'center', fontWeight: '700' }}>{otpData && otpData.length > 0 && otpData[0].EmailText.replace(/^\D+/g, '') ? otpData[0].EmailText.replace(/^\D+/g, '') : otpData && otpData.length < 1 ? 'Not Available' : ''}</h4>
                        </div>


                        <div className="col-md-12 d-flex justify-content-between mb-2">
                            <i className=''>Received at:  {moment(new Date(otpData[0].CreatedDtTm)).tz(process.env.REACT_APP_TIMEZONE).format(process.env.REACT_APP_LONG_TIME_FORMAT)} PST</i>
                            <div onClick={() => handleEmailView()}>
                                <span data-toggle="tooltip" data-placement="top" title={showEmail ? "Close Email" : "View Email"}><i style={{ fontSize: '1.3rem', cursor: 'pointer' }} class={showEmail ? "ri-mail-close-line" : "ri-mail-line"}></i></span>
                            </div>
                        </div>


                        {showEmail && <div className='content p-1 col-md-12 mb-2' style={{ border: '1px solid', overflow: 'scroll' }} dangerouslySetInnerHTML={{ __html: JSON.parse(otpData[0].ReceivedData).data.html }} ></div>}


                    </>
                    }

                </div>

                <div className=''>
                    {checkSimSwapCompletionError !== null ? <div> <p style={{ color: 'red', textAlign: 'center' }}>{checkSimSwapCompletionError}</p> </div> : ''}
                    {checkSimSwapCompletionMessage !== null ? <div> <p style={{ color: '#2ACB13', textAlign: 'center' }}>{checkSimSwapCompletionMessage}</p> </div> : ''}
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-row">
                        <div className={`col-md-12 mb-3`}>
                            <label className='ml-2'>Remark<span className='text-danger'> *</span></label>
                            <textarea className="form-control" rows="6" cols="50" placeholder="Enter Remark Here" name="remark"
                                {...register("remark", {
                                    required: 'Remark is required !',
                                })} />
                            {errors && errors.remark && (<div className="invalid-feedback">{errors.remark.message}</div>)}
                        </div>
                    </div>

                    <div className='text-right row'>
                        <div className="col-md-12">
                            <Button title='Cancel' style="minWidth:80px" buttonClass="btn btn-secondary mr-2" onClick={handleCancel} />
                            <Button title='Submit' style="minWidth:80px" buttonClass="btn btn-primary" loading={completeSimSwapRequestLoading} buttonType='submit' />
                        </div>
                    </div>
                </form>

            </Modal>
        </>
    )
}

export default CompleteSimSwapModalComponent;