import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { uploadFile, getList } from '../../helpers/fackBackend_Helper';

function* uploadJarFileResponse({ payload: data }) {
    try {
        const formData = new FormData();
        formData.append('jarfile', data.jarfile);
        formData.append('Version', data.Version);
        formData.append('Remark', data.Remark);

        const response = yield call(uploadFile, '/uploadJarFile', formData);
        if (response.Status) {
            yield put(actions.uploadJarFileSuccessfull(response.Message));
            yield put(actions.getJarFile());
        }
        else {
            yield put(actions.uploadJarFileFail(response.Message));
        }
    } catch (error) {
        yield put(actions.uploadJarFileFail(error));
    }
}

function* getJarFileResponse() {
    try {
        const response = yield call(getList, "/getUploadedJar");
        if (response.Status) {
            yield put(actions.getJarFileSuccessfull(response.file));
        } else {
            yield put(actions.getJarFileFail(response.Message));
        }
    } catch (error) {
        yield put(actions.getJarFileFail('error in api'));
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.UPLOAD_JAR_FILE, uploadJarFileResponse)]);
    yield all([takeEvery(actions.GET_JAR_FILE, getJarFileResponse)]);
}
