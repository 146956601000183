import { useEffect, useState } from 'react';
import CardLayout from '../../components/utils/CardLayout';
import Alert from '../../components/utils/Alert';
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux';
import addonWiseMappingActions from '../../redux/addonWiseMapping/actions';
import ErrorWarningLineIcon from 'remixicon-react/ErrorWarningLineIcon';
import dealerAction from '../../redux/dealer/actions';
import Table from '../../components/utils/Table'
import SearchBar from '../../components/utils/SearchBar'
import Papa from 'papaparse'
import Button from '../../components/utils/Button'
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import DownloadCloud2sLineIcon from 'remixicon-react/DownloadCloud2LineIcon';
import FolderUploadLineIcon from 'remixicon-react/FolderUploadLineIcon';
import SideButtons from '../../components/utils/SideButtons'
import { useNavigate } from "react-router-dom";
import AddonServiceActions from '../../redux/addonService/actions'
import DealerAddModal from '../../components/utils/DealerAddModal';

const { getAllAddonPriceMappingData, getAddonWiseMapping, resetAddonPriceMappingMessages, updateAddonPriceMappingData } = addonWiseMappingActions;
const { getAddonService } = AddonServiceActions


function DealerAddonPriceMapping() {
    const dispatch = useDispatch();
    const { getDealer, prepareDealerForm } = dealerAction;
    const { addonPriceMappingData, addonPriceMappingError, addonPriceMappingSuccess, buttonLoading, loading, addonWiseMappingList } = useSelector((state) => state.addonWiseMapping);
    const [uploadPlanPriceMapping, setUploadPlanPriceMapping] = useState(false);
    const { dealerResult } = useSelector((state) => state.dealer);
    const { addonResult } = useSelector((state) => state.addonService);

    const [previewSubmitBtn, setPreviewSubmitBtn] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertText, setAlertText] = useState('');
    const [invalidChildDealerPrice, setInvalidChildDealerPrice] = useState(false);
    const navigate = useNavigate();

    const displayAlert = (text, type) => {
        setAlertText(text);
        setAlertType(type);
        setShowAlert(true);
        setUploadPlanPriceMapping(false);
        setPreviewSubmitBtn(true)
        setPreviewHeading([])
        dispatch(resetAddonPriceMappingMessages());
    };

    const alertCancel = () => {
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
        dispatch(resetAddonPriceMappingMessages());
    }

    useEffect(() => {
        dispatch(getAddonService());
        dispatch(getAddonWiseMapping());
        dispatch(getAllAddonPriceMappingData());
        dispatch(getDealer());
    }, [])

    useEffect(() => {
        if (addonPriceMappingError !== null && addonPriceMappingError !== undefined) {
            displayAlert(addonPriceMappingError, 'alert-danger');
        }
        if (addonPriceMappingSuccess !== null && addonPriceMappingSuccess !== undefined) {
            displayAlert(addonPriceMappingSuccess, 'alert-success');
        }
    }, [addonPriceMappingError, addonPriceMappingSuccess]);

    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();

    const [filterText, setFilterText] = useState("");
    const [uploadedMapping, setUploadedMapping] = useState([]);
    const [previewHeading, setPreviewHeading] = useState([]);
    const [previewDataSourceExcel, setPreviewDataSourceExcel] = useState([]);

    const newDataSource = Object.values(addonPriceMappingData && addonPriceMappingData.length > 0 && addonPriceMappingData.reduce((acc, item) => {
        const { DealerID, DealerName, DealerPrice, NetworkName, ServiceName, ServicePrice } = item;
        const plannewHeading = `${ServiceName}|$${ServicePrice}|${NetworkName}`
        if (!acc[DealerID]) {
            acc[DealerID] = { DealerID, DealerName };
        }
        acc[DealerID][plannewHeading] = DealerPrice;
        return acc;
    }, {}));

    const networkData = addonWiseMappingList && addonWiseMappingList.length > 0 && addonWiseMappingList.map((item) => {
        let addonData = JSON.parse(item.addonData);
        let networkName = item.NetworkName;
        let newArr = addonData.map((addon) => {
            return `${addon.ServiceName}|$${addon.ServicePrice}|${networkName}`;
        });
        return newArr
    });

    const newHeadingData = networkData && networkData.flat().map(item => ({
        displayName: item,
        key: item
    }));

    const heading = [
        { displayName: '#', key: 'key' },
        { displayName: 'Dealer', key: 'dealerName' },
        ...(newHeadingData || [])
    ];

    const Keys = newDataSource && newDataSource.length > 0 && Object.keys(newDataSource[0]);

    const filteredItems = newDataSource && newDataSource.length > 0 && newDataSource.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const DataSource = filteredItems && filteredItems.length > 0 && filteredItems.map((addon, index) => {
        const obj = { key: index + 1, dealerName: addon.DealerName };
        Keys.forEach(key => {
            obj[key] = addon[key] ? `$${addon[key]}` : '$0';
        });
        return obj;
    });

    const handleFilterChange = (value) => {
        setFilterText(value);
    };

    const uploadView = () => {
        setUploadPlanPriceMapping(true);
    }

    const closeUploadView = () => {
        setUploadPlanPriceMapping(false);
        setPreviewSubmitBtn(true)
        setPreviewHeading([])
    }

    const dealerData = dealerResult && dealerResult.length > 0 && dealerResult.filter((item) => item.ParentID != 0).map((item) => {
        return {
            name: item.DealerName,
            dealerId: item.ID
        }
    });

    const exportToCSV = async () => {
        const transformedData = {};
        addonPriceMappingData.forEach(item => {
            if (!transformedData[item.DealerName]) {
                transformedData[item.DealerName] = {
                    name: item.DealerName,
                    dealerId: item.DealerID
                };
            }
            transformedData[item.DealerName][`${item.ServiceName}|$${item.ServicePrice}|${item.NetworkName}`] = item.DealerPrice;
        });

        const resultArray = Object.values(transformedData);
        const topRowNetworks = networkData.flat(1);

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet([['Dealer Name', 'Dealer ID', ...networkData.flat(1)]]);
        let dealerColIndex = 1;

        if (resultArray && resultArray.length > 0) {
            resultArray && resultArray.length > 0 && resultArray.forEach((addonData) => {
                let newRow = [];
                const keys = Object.keys(addonData);
                const dealerRow = keys.map(key => addonData[key]);
                newRow.push(dealerRow[0]);
                newRow.push(dealerRow[1]);

                topRowNetworks.forEach((item) => {
                    if (addonData[item]) {
                        newRow.push(addonData[item]);
                    } else {
                        newRow.push(0);
                    }
                });

                XLSX.utils.sheet_add_aoa(ws, [newRow], { origin: { r: dealerColIndex, c: 0 } });
                dealerColIndex += 1;
            })
        } else {
            dealerData.forEach((dealer) => {
                const dealerRow = [dealer.name, dealer.dealerId];
                XLSX.utils.sheet_add_aoa(ws, [dealerRow], { origin: { r: dealerColIndex, c: 0 } });
                dealerColIndex += 1;
            });
        }

        XLSX.utils.book_append_sheet(wb, ws, 'Plan Price Mapping');
        const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'array' });
        const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        let fileName = "Existing Dealer Addon Price";
        FileSaver.saveAs(data, `${fileName}.csv`);
    }

    const handleFileChange = (e) => {
        const fileReader = new FileReader();
        let file = e.target.files[0];
        let uploadedData = [];
        if (file) {
            fileReader.onload = async ({ target }) => {
                setInvalidChildDealerPrice(false);
                const csvOutput = Papa.parse(target.result, { header: true });
                const parsedData = csvOutput?.data;
                const columns = Object.keys(parsedData[0]);
                let customizeData = [];

                addonWiseMappingList.map((item) => {
                    let newArr = JSON.parse(item.addonData);
                    console.log(newArr);
                    newArr.map((item1) => {
                        customizeData.push({
                            column: `${item1.ServiceName}|$${item1.ServicePrice}|${item.NetworkName}`,
                            addonId: item1.ID,
                            DealerPrice: item1.DealerPrice
                        });
                    });
                });

                dealerData.forEach((dealer) => {
                    customizeData.forEach((item) => {
                        let row = parsedData.filter((item1) => item1['Dealer ID'] == dealer.dealerId)

                        if (Number(row[0][item.column]) < item.DealerPrice && row[0][item.column] != '' && row[0][item.column] != 0) {
                            setInvalidChildDealerPrice(true);
                            // console.error(`Child dealer price can not be less then it\'s parent dealer`);
                        }

                        if (row.length > 0) {
                            uploadedData.push({
                                dealerId: dealer.dealerId,
                                dealerName: dealer.name,
                                addonId: item.addonId,
                                addonHeading: item.column,
                                dealerPrice: row[0][item.column]
                            });
                        } else {
                            console.error(`No data found for dealer with ID ${dealer.dealerId}`);
                        }
                    });
                });

                const uniquePlanIds = [...new Set(uploadedData.map(item => item.addonHeading))];
                const heading = [
                    { displayName: '#', key: 'key' },
                    { displayName: 'Dealer', key: 'dealerName' },
                    ...uniquePlanIds.map(id => ({ displayName: `${id}`, key: `${id}` }))
                ];

                setPreviewHeading(heading)
                const groupedData = uploadedData.reduce((acc, item) => {
                    const { dealerId, dealerName, addonHeading, dealerPrice } = item;
                    if (!acc[dealerId]) {
                        acc[dealerId] = { dealerId, dealerName };
                    }
                    acc[dealerId][addonHeading] = `$${dealerPrice}` || `$0`;
                    return acc;
                }, {});
                setPreviewDataSourceExcel(Object.values(groupedData))
                setUploadedMapping(uploadedData);
                setPreviewSubmitBtn(false)

            };
            fileReader.readAsText(file);
        }
    }

    const previewColumns = previewHeading
    const PreviewKeys = previewDataSourceExcel && previewDataSourceExcel.length > 0 && Object.keys(previewDataSourceExcel[0]);

    const previewDataSource = previewDataSourceExcel && previewDataSourceExcel.length > 0 && previewDataSourceExcel.map((plan, index) => {
        const obj = { key: index + 1 };
        PreviewKeys.forEach(key => {
            obj[key] = plan[key];
        });
        return obj;
    });

    const onSubmit = (data) => {
        let UpdateDataArray = [];
        let insertedArray = [];
        let deletedArray = [];

        uploadedMapping.forEach((firstItem) => {
            if (parseFloat(firstItem.dealerPrice) > 0) {
                let matchFound = false;
                addonPriceMappingData.forEach((secondItem) => {
                    if (firstItem.dealerId === secondItem.DealerID && firstItem.addonId === secondItem.PlanAddonID) {
                        matchFound = true;
                        UpdateDataArray.push(firstItem);
                    }
                });
                if (!matchFound) {
                    insertedArray.push(firstItem);
                }
            }
        });

        addonPriceMappingData.forEach((secondItem) => {
            let matchFound = false;
            uploadedMapping.forEach((firstItem) => {
                if (firstItem.dealerId === secondItem.DealerID && firstItem.addonId === secondItem.PlanAddonID) {
                    matchFound = true;
                }
            });
            if (!matchFound) {
                deletedArray.push(secondItem);
            }
        });
        const finalData = { "insertData": insertedArray, "updateData": UpdateDataArray, "deleteData": deletedArray };
        dispatch(updateAddonPriceMappingData(finalData));
    }

    const handleSideButton = () => {
        if (addonResult.length <= 0) {
            navigate("/addon-service");
        } else if (dealerResult.length <= 0) {
            // navigate("/dealer");
            dispatch(prepareDealerForm());
        }
    }

    return (
        <CardLayout title="Addon Price Mapping">
            <DealerAddModal />
            {showAlert && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}
            {
                !uploadPlanPriceMapping ? <>
                    <div className='row align-items-center mb-4'>
                        <div className="col-md-6 od-2">
                            <SearchBar onFilter={handleFilterChange} filterText={filterText} /></div>
                        <div className="col-md-6  text-right">
                            {
                                addonResult.length <= 0 ?
                                    <SideButtons title="Add Addon Service" buttonStyle="primary" onClick={handleSideButton} /> :
                                    dealerResult.length <= 0 ?
                                        <SideButtons title="Add Dealer" buttonStyle="primary" onClick={handleSideButton} /> :
                                        <Button icon={<FolderUploadLineIcon color='#fff' className='mr-1' size={'0.9rem'} />} title='Upload' buttonClass="btn btn-primary float-right ml-2" onClick={uploadView} loading={false} buttonType='submit' />
                            }
                        </div>
                    </div>

                    <Table data={DataSource} columns={heading} loading={loading} />
                </> :
                    <>
                        <div className='row align-items-center mb-4'>
                            <div className="col-md-12 text-right">
                                <Button
                                    icon={<DownloadCloud2sLineIcon color='#fff' className='mr-1' size={'0.9rem'} />}
                                    title='Download Existing Price'
                                    buttonClass="btn btn-primary float-right"
                                    onClick={exportToCSV}
                                    loading={false}
                                    buttonType='submit'
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className='col-md-12'>
                                <form className="form px-4" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="input-group">
                                        <label htmlFor="csvFile"><span className='text-danger'>*</span>Upload File </label>
                                        <input
                                            type="file"
                                            className="form-control-file"
                                            name='uploadedFile'
                                            id="csvFile"
                                            accept='.csv'
                                            {...register('uploadedFile', { required: true })}
                                            onChange={(e) => handleFileChange(e)}
                                        />
                                        {/* {invalidChildDealerPrice ? <small className='text-danger'>Child price can not be less then their parent</small> : ''} */}

                                    </div>

                                    {
                                        invalidChildDealerPrice ?
                                            <div className='container text-center'>
                                                <ErrorWarningLineIcon size={100} color={'#e74c3c'} />
                                                <p className='text-danger'>Child dealer addon price can not be less then your price</p>
                                            </div> : ''
                                    }

                                    {previewHeading && previewHeading.length > 0 && !invalidChildDealerPrice && <Table data={previewDataSource} columns={previewColumns} />}

                                    <div className='text-right row'>
                                        <div className="col-md-12">
                                            <button type="button" className="btn btn-secondary mr-2" onClick={closeUploadView}>Close</button>
                                            <Button title="Submit" disable={previewSubmitBtn || invalidChildDealerPrice} style="minWidth:80px" buttonClass="btn btn-primary" loading={buttonLoading} buttonType='submit' />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </>
            }
        </CardLayout>
    )
}

export default DealerAddonPriceMapping