import React, { useEffect, useState, useRef } from 'react'
import Modal from '../../../components/utils/Model';
import Button from '../../../components/utils/Button';
import { useDispatch, useSelector } from 'react-redux';
import dailyPlanRequestActions from '../../../redux/dailyPlanRequest/actions'
import { useForm } from "react-hook-form";
import networkActions from '../../../redux/network/actions';
import authActions from '../../../redux/auth/actions';
import DatePicker from "react-datepicker";
import Calendar2LineIcon from 'remixicon-react/Calendar2LineIcon';
import Styles from './Styles/CompleteDailyPlanRequest.module.css'
import Alert from '../../../components/utils/Alert';
import moment from 'moment';

const { getMostEligibleRequest, resetCompleteForm, createActivationForDailyPlan, getDailyPlanOtp, checkDailyPlanCompletionStatus, completeDailyPlanRequest } = dailyPlanRequestActions;

function findOTP(str) {
  const match = str.match(/\b\d{6}\b/);
  return match ? match[0] : null;
}


const CompleteDailyPlanRequest = ({ completeModal, handleCloseModal, row, searchData }) => {
  const dispatch = useDispatch();
  const {
    getMostEligibleRequestLoading,
    getMostEligibleRequestError,
    getMostEligibleRequestMessage,
    createActivationForDailyPlanLoading,
    createActivationForDailyPlanError,
    createActivationForDailyPlanMessage,
    getMostEligibleRequestData,
    getDailyPlanOtpData,
    getDailyPlanOtpLoading,
    getDailyPlanOtpMessage,
    checkDailyPlanCompletionStatusMessage,
    checkDailyPlanCompletionStatusError,
    checkDailyPlanCompletionStatusLoading,
    completeDailyPlanRequestLoading,
    completeDailyPlanRequestMessage, completeDailyPlanRequestError } = useSelector((state) => state.dailyPlanRequest);
  const datePickerRef2 = useRef(null);
  const datePickerRef = useRef(null);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState()
  const [totalDays, setTotalDays] = useState(1);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertText, setAlertText] = useState('');

  const { register, handleSubmit, formState: { errors: createDailyRequestError }, watch, reset, clearErrors, setValue } = useForm({
    defaultValues: {}
  });

  useEffect(() => {
    setFromDate(new Date(row.RequestedDtTm));
    setToDate(new Date(row.RequestedToDate));
    setValue('simNumber', row.SerialNumber);
  }, []);

  useEffect(() => {
    const calculateDays = () => {
      const timeDiff = Math.abs(toDate - fromDate);
      const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;
      setTotalDays(dayDiff);
    };
    calculateDays();
  }, [fromDate, toDate]);

  useEffect(() => {
    if (createActivationForDailyPlanError !== null && createActivationForDailyPlanError !== undefined) {
      displayAlert(createActivationForDailyPlanError, 'alert-danger');
    }
    if (completeDailyPlanRequestError !== null && completeDailyPlanRequestError !== undefined) {
      displayAlert(completeDailyPlanRequestError, 'alert-danger');
    }
  }, [createActivationForDailyPlanError, completeDailyPlanRequestError]);

  useEffect(() => {
    if (createActivationForDailyPlanMessage || completeDailyPlanRequestMessage) {
      handleCancel();
    }
  }, [createActivationForDailyPlanMessage, completeDailyPlanRequestMessage]);


  const handleCancel = () => {
    dispatch(resetCompleteForm());
    handleCloseModal();
  }

  const handleGetValidMSISDN = () => {
    const addonServId = row && row.AddonID ? row.AddonID : '0';
    dispatch(getMostEligibleRequest(totalDays, addonServId));
  }
  const displayAlert = (text, type) => {
    setAlertText(text);
    setAlertType(type);
    setShowAlert(true);
  };

  const alertCancel = () => {
    setShowAlert(false);
    setAlertText('');
    setAlertType('');
  }

  const onSubmit = (data) => {
    if (getMostEligibleRequestError) {
      let request = {
        DailyPlanID: row.ID
      }
      dispatch(createActivationForDailyPlan(request, searchData));
    } else {
      dispatch(completeDailyPlanRequest(getMostEligibleRequestData.ActivationID, row.ID, searchData));
    }
    
    // if (checkDailyPlanCompletionStatusMessage) {
    // dispatch(completeDailyPlanRequest(getMostEligibleRequestData.ActivationID, row.ID, searchData));
    // } else {
    //   let request = {
    //     DailyPlanID: row.ID
    //   }
    //   dispatch(createActivationForDailyPlan(request, searchData));
    // }
  }

  // const handleGetOTP = () => {
  //   dispatch(getDailyPlanOtp(getMostEligibleRequestData.ActivationID));
  // }

  // const handleVerify = () => {
  //   dispatch(checkDailyPlanCompletionStatus(getMostEligibleRequestData.ActivationID));
  // }

  return (
    <>
      <Modal
        showModal={completeModal}
        title={'COMPLETE DAILY PLAN REQUEST'}
        onHide={handleCancel}
        width={700}
        showFooter={false}
      >
        {showAlert && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-row">
            <div className="col-lg-3 mb-3">
              <label>New SIM Number</label>
              <p>{row.SerialNumber}</p>
            </div>

            <div className="col-lg-3 mb-3 d-flex flex-column">
              <label>From Date</label>
              <p>{moment(new Date(row.RequestedDtTm)).format('YYYY-MM-DD')}</p>
            </div>

            <div className="col-lg-3 mb-3 d-flex flex-column">
              <label>To Date</label>
              <p>{moment(new Date(row.RequestedToDate)).format('YYYY-MM-DD')}</p>
            </div>

            <div className="col-lg-3 mb-3 d-flex flex-column">
              <label>Total Days</label>
              <p>{totalDays} Days</p>
            </div>

            <div className="col-lg-3 mb-3 d-flex flex-column">
              <label>IMEI</label>
              <p>{row.IMEI}</p>
            </div>

            <div className="col-lg-3 mb-3 d-flex flex-column">
              <label>Sim Type</label>
              <p>{row.SimTypeValue}</p>
            </div>

            <div className="col-lg-3 mb-3 d-flex flex-column">
              <label>Customer Email</label>
              <p>{row.CustomerEmailID}</p>
            </div>

            <div className="col-lg-3 mb-3 d-flex flex-column">
              <label>Dealer</label>
              <p>{row.DealerName}</p>
            </div>

            <div className="col-lg-3 mb-3 d-flex flex-column">
              <label>Zip Code</label>
              <p>{row.AreaCode}</p>
            </div>

            {/* <div className="col-lg-12 mb-3 d-flex justify-content-end">
              {
                !getMostEligibleRequestError ?
                  <Button title="Get valid MSISDN" disable={getMostEligibleRequestData.Status === 1} onClick={() => handleGetValidMSISDN()} buttonClass={`${getMostEligibleRequestData.Status === 1 ? Styles['get-valid-msisdn-btn-disabled'] : ''} ${Styles['get-valid-msisdn-btn']}`} loading={getMostEligibleRequestLoading} /> :
                  <span style={{ color: 'red', textAlign: 'center' }}>No MSISDN available in pool</span>
              }
            </div> */}

            <div className="col-lg-12 mb-3 d-flex justify-content-between align-items-center">
              <div style={{ flex: 1, textAlign: 'center' }}>
                {getMostEligibleRequestError && (
                  <span style={{ color: 'red' }}>No MSISDN available in pool</span>
                )}
              </div>
              <div>
                {!getMostEligibleRequestError && (
                  <Button
                    title="Get valid MSISDN"
                    disable={getMostEligibleRequestData.Status === 1}
                    onClick={() => handleGetValidMSISDN()}
                    buttonClass={`${getMostEligibleRequestData.Status === 1 ? Styles['get-valid-msisdn-btn-disabled'] : ''} ${Styles['get-valid-msisdn-btn']}`}
                    loading={getMostEligibleRequestLoading}
                  />
                )}
              </div>
            </div>


            {
              getMostEligibleRequestData.Status === 1 &&
              <>
                <div className="col-lg-3 mb-3 d-flex flex-column">
                  <label>MSISDN</label>
                  <p>{getMostEligibleRequestData.MSISDN ?? ''}</p>
                </div>

                <div className="col-lg-3 mb-3 d-flex flex-column">
                  <label>Account Number</label>
                  <p>{getMostEligibleRequestData.AccountNumber ?? 'Not Available'}</p>
                </div>

                <div className="col-lg-3 mb-3 d-flex flex-column">
                  <label>PIN</label>
                  <p>{getMostEligibleRequestData.PIN ?? ''}</p>
                </div>

                <div className="col-lg-3 mb-3 d-flex flex-column">
                  <label>Zipcode</label>
                  <p>{getMostEligibleRequestData.AreaCode ?? ''}</p>
                </div>

                <div className="col-lg-3 mb-3 d-flex flex-column">
                  <label>Expiry Date</label>
                  <p>{getMostEligibleRequestData.ExpiryDate ? moment(new Date(getMostEligibleRequestData.ExpiryDate)).format('YYYY-MM-DD') : ''}</p>
                </div>

                <div className="col-lg-3 mb-3 d-flex flex-column">
                  <label>Customer Email</label>
                  <p>{getMostEligibleRequestData.CustomerEmail ?? ''}</p>
                </div>
                <div className="col-lg-3 mb-3 d-flex flex-column">
                  <label>Current SIM Number</label>
                  <p>{getMostEligibleRequestData.SerialNumber ?? ''}</p>
                </div>

                <div className="col-lg-3 mb-3 d-flex flex-column">
                  <label>WiFi Calling</label>
                  <p>{getMostEligibleRequestData.WiFiCalling === 1 ? 'Yes' : 'No'}</p>
                </div>

                <div className="col-lg-6 mb-6 d-flex flex-column">
                  <label>Service Name</label>
                  <p>{getMostEligibleRequestData.ServiceName ?? ''}</p>
                </div>

                <div className="col-lg-9"></div>

                {/* <div className="col-lg-2 mb-3 d-flex justify-content-start">
                  OTP : {getDailyPlanOtpData.length > 0 ? findOTP(getDailyPlanOtpData[0].EmailText) : ''}
                </div>

                <div className="col-lg-10 mb-3 d-flex justify-content-end">
                  {
                    !checkDailyPlanCompletionStatusMessage && !getDailyPlanOtpMessage && <Button title="Get OTP" onClick={() => handleGetOTP()} buttonClass={`${Styles['get-otp-btn']}`} loading={getDailyPlanOtpLoading} />
                  }
                  {
                    getDailyPlanOtpData.length > 0 && !checkDailyPlanCompletionStatusMessage && <Button title="Verify" onClick={() => handleVerify()} buttonClass={`${Styles['get-otp-btn']}`} loading={checkDailyPlanCompletionStatusLoading} />
                  }
                </div> */}
                {checkDailyPlanCompletionStatusMessage && <p className='text-success'>{checkDailyPlanCompletionStatusMessage}</p>}
                {checkDailyPlanCompletionStatusError && <p className='text-danger'>{checkDailyPlanCompletionStatusError}</p>}

              </>
            }

          </div>

          <hr />

          <div className='text-right row'>
            <div className="col-md-12">
              <button type="button" className="btn btn-secondary mr-2" onClick={handleCancel}>Close</button>
              {getMostEligibleRequestError && <Button title="Submit new activation" loading={createActivationForDailyPlanLoading} buttonClass="btn btn-primary" buttonType='submit' />}
              {getMostEligibleRequestMessage && <Button title="Complete" loading={completeDailyPlanRequestLoading} buttonClass="btn btn-primary" buttonType='submit' />}
            </div>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default CompleteDailyPlanRequest;