const actions = {
    GET_ACTIVATION_REPORT: "GET_ACTIVATION_REPORT",
    GET_ACTIVATION_REPORT_SUCCESS: "GET_ACTIVATION_REPORT_SUCCESS",
    GET_ACTIVATION_REPORT_FAILED: "GET_ACTIVATION_REPORT_FAILED",

    getActivationReport: (searchData) => ({
        type: actions.GET_ACTIVATION_REPORT,
        payload: { searchData },
    }),
    getActivationReportSuccess: (activationResult) => ({
        type: actions.GET_ACTIVATION_REPORT_SUCCESS,
        payload: { activationResult }
    }),
    getActivationReportFailed: (error) => ({
        type: actions.GET_ACTIVATION_REPORT_FAILED,
        error
    }),
}

export default actions;