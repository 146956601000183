import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { getList, addData, editData, deleteData } from '../../helpers/fackBackend_Helper';

function* getNetworkListResponse() {
    try {
        const response = yield call(getList, '/getNetwork');
        if (response.response.Status) {
            yield put(actions.getNetworkSuccess(response.response.Data));
        } else {
            yield put(actions.getNetworkFailed());
        }
    } catch (error) {
        yield put(actions.getNetworkFailed());
    }
}

function* addNetworkResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/createNetwork', data);
        if (response.Status) {
            const responseList = yield call(getList, '/getNetwork');
            if (responseList.response.Status) {
                yield put(actions.addNetworkSuccess(response.Message, responseList.response.Data));
            } else {
                yield put(actions.addNetworkFailed(response.response.Message));
            }
        } else {
            yield put(actions.addNetworkFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.addNetworkFailed(error.Message));
    }
}

function* editNetworkResponse({ payload: { data } }) {
    try {
        const response = yield call(editData, '/updateNetwork', data);
        if (response.Status) {
            const responseList = yield call(getList, '/getNetwork');
            if (responseList.response.Status) {
                yield put(actions.editNetworkSuccess(response.Message, responseList.response.Data));
                yield put(actions.getNetwork());
            } else {
                yield put(actions.addNetworkFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.addNetworkFailed(
                response.response.Message
            ));
        }
    } catch (error) {
        yield put(actions.addNetworkFailed(error));
    }
}

function* deleteNetworkResponse({ payload: { networkId, status } }) {
    try {
        const response = yield call(deleteData, '/deleteNetwork/' + networkId + '/' + status);
        if (response.Status) {
            const responseList = yield call(getList, '/getNetwork');
            if (responseList.response.Status) {
                yield put(actions.deleteNetworkSuccess(response.Message, responseList.response.Data));
            } else {
                yield put(actions.getNetworkFailed(response.response.Message));
            }
        } else {
            yield put(actions.getNetworkFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.addNetworkFailed(error));
    }
}

function* updatePrefferedAggregatorResponse({ payload: { data } }) {
    try {
        const response = yield call(editData, '/updateNetworkPrefferedAggregator', data);
        if (response.Status) {
            const responseList = yield call(getList, '/getNetwork');
            if (responseList.response.Status) {
                yield put(actions.editNetworkSuccess(response.Message, responseList.response.Data));
            } else {
                yield put(actions.addNetworkFailed(response.response.Message));
            }
        } else {
            yield put(actions.addNetworkFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.addNetworkFailed(error));
    }
}
function* getActiveNetworkListResponse() {
    try {
        const response = yield call(getList, '/getActiveNetwork');
        if (response.response.Status) {
            yield put(actions.getActiveNetworkSuccess(response.response.Data));
        } else {
            yield put(actions.getActiveNetworkFailed());
        }
    } catch (error) {
        yield put(actions.getActiveNetworkFailed());
    }
}
function* getActiveNetworkDailyPlanMAppingListResponse() {
    try {
        const response = yield call(getList, '/getActiveNetworkDailyPlanMapping');
        if (response.response.Status) {
            yield put(actions.getActiveNetworkDAilyPlanMappingSuccess(response.response.Data));
        } else {
            yield put(actions.getActiveNetworkDAilyPlanMappingFailed());
        }
    } catch (error) {
        yield put(actions.getActiveNetworkDAilyPlanMappingFailed());
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.GET_NETWORK, getNetworkListResponse)]);
    yield all([takeEvery(actions.ADD_NETWORK, addNetworkResponse)]);
    yield all([takeEvery(actions.DELETE_NETWORK, deleteNetworkResponse)]);
    yield all([takeEvery(actions.EDIT_NETWORK, editNetworkResponse)]);
    yield all([takeEvery(actions.UPDATE_PREFFERED_AGGREGATOR, updatePrefferedAggregatorResponse)]);
    yield all([takeEvery(actions.GET_ACTIVE_NETWORK, getActiveNetworkListResponse)]);
    yield all([takeEvery(actions.GET_ACTIVE_NETWORK_DAILY_PLAN_MAPPING, getActiveNetworkDailyPlanMAppingListResponse)]);
}