const actions = {
    GET_NETWORK: 'GET_NETWORK',
    GET_NETWORK_SUCCESS: 'GET_NETWORK_SUCCESS',
    GET_NETWORK_FAILED: 'GET_NETWORK_FAILED',
    ADD_NETWORK: "ADD_NETWORK",
    ADD_NETWORK_SUCCESS: "ADD_NETWORK_SUCCESS",
    ADD_NETWORK_FAILED: "ADD_NETWORK_FAILED",
    DELETE_NETWORK: "DELETE_NETWORK",
    DELETE_NETWORK_SUCCESS: "DELETE_NETWORK_SUCCESS",
    EDIT_NETWORK: "EDIT_NETWORK",
    EDIT_NETWORK_SUCCESS: "EDIT_NETWORK_SUCCESS",
    RESET_NETWORK: "RESET_NETWORK",
    RESET_NETWORK_MESSAGE: "RESET_NETWORK_MESSAGE",
    PREPARE_NETWORK_FORM: "PREPARE_NETWORK_FORM",
    OPEN_PREFERED_AGGREGATOR_MODAL: "OPEN_PREFERED_AGGREGATOR_MODAL",
    CLOSE_PREFERED_AGGREGATOR_MODAL: "CLOSE_PREFERED_AGGREGATOR_MODAL",
    UPDATE_PREFFERED_AGGREGATOR: "UPDATE_PREFFERED_AGGREGATOR",
    UPDATE_PREFFERED_AGGREGATOR_SUCCESS: "UPDATE_PREFFERED_AGGREGATOR_SUCCESS",
    GET_ACTIVE_NETWORK: 'GET_ACTIVE_NETWORK',
    GET_ACTIVE_NETWORK_SUCCESS: 'GET_ACTIVE_NETWORK_SUCCESS',
    GET_ACTIVE_NETWORK_FAILED: ' GET_ACTIVE_NETWORK_FAILED',
    GET_ACTIVE_NETWORK_DAILY_PLAN_MAPPING: 'GET_ACTIVE_NETWORK_DAILY_PLAN_MAPPING',
    GET_ACTIVE_NETWORK_DAILY_PLAN_MAPPING_SUCCESS: 'GET_ACTIVE_NETWORK_DAILY_PLAN_MAPPING_SUCCESS',
    GET_ACTIVE_NETWORK_DAILY_PLAN_MAPPING_FAILED: ' GET_ACTIVE_NETWORK_DAILY_PLAN_MAPPING_FAILED',

    updatePreffedAggregator: (data) => ({
        type: actions.UPDATE_PREFFERED_AGGREGATOR,
        payload: { data }
    }),
    updatePreffedAggregatorSuccess: (networkMessage, networkResult) => ({
        type: actions.UPDATE_PREFFERED_AGGREGATOR_SUCCESS,
        networkMessage, networkResult
    }),

    openPreferedAggregatorModal: () => ({
        type: actions.OPEN_PREFERED_AGGREGATOR_MODAL,
    }),
    closePreferedAggregatorModal: () => ({
        type: actions.CLOSE_PREFERED_AGGREGATOR_MODAL,
    }),

    getNetwork: () => ({
        type: actions.GET_NETWORK,
    }),
    getNetworkSuccess: (networkResult) => ({
        type: actions.GET_NETWORK_SUCCESS,
        networkResult
    }),
    getNetworkFailed: (networkError) => ({
        type: actions.GET_NETWORK_FAILED,
        networkError
    }),
    prepareNetworkForm: () => ({
        type: actions.PREPARE_NETWORK_FORM
    }),
    addNetwork: (data) => ({
        type: actions.ADD_NETWORK,
        payload: { data }
    }),
    addNetworkSuccess: (networkMessage, networkResult) => ({
        type: actions.ADD_NETWORK_SUCCESS,
        networkMessage, networkResult
    }),
    addNetworkFailed: (networkError) => ({
        type: actions.ADD_NETWORK_FAILED,
        networkError
    }),

    deleteNetwork: (networkId, status) => ({
        type: actions.DELETE_NETWORK,
        payload: { networkId, status }
    }),
    deleteNetworkSuccess: (networkMessage, networkResult) => ({
        type: actions.DELETE_NETWORK_SUCCESS,
        networkMessage, networkResult
    }),

    resetNetwork: () => ({
        type: actions.RESET_NETWORK
    }),

    editNetwork: (data) => ({
        type: actions.EDIT_NETWORK,
        payload: { data }
    }),
    editNetworkSuccess: (networkMessage, networkResult) => ({
        type: actions.EDIT_NETWORK_SUCCESS,
        networkMessage, networkResult
    }),

    resetNetworkMessage: () => ({
        type: actions.RESET_NETWORK_MESSAGE
    }),
    getActiveNetwork: () => ({
        type: actions.GET_ACTIVE_NETWORK,
    }),
    getActiveNetworkSuccess: (networkResult) => ({
        type: actions.GET_ACTIVE_NETWORK_SUCCESS,
        networkResult
    }),
    getActiveNetworkFailed: (networkError) => ({
        type: actions.GET_ACTIVE_NETWORK_FAILED,
        networkError
    }),
    getActiveNetworkDAilyPlanMapping: () => ({
        type: actions.GET_ACTIVE_NETWORK_DAILY_PLAN_MAPPING,
    }),
    getActiveNetworkDAilyPlanMappingSuccess: (networkResultData) => ({
        type: actions.GET_ACTIVE_NETWORK_DAILY_PLAN_MAPPING_SUCCESS,
        networkResultData
    }),
    getActiveNetworkDAilyPlanMappingFailed: (networkError) => ({
        type: actions.GET_ACTIVE_NETWORK_DAILY_PLAN_MAPPING_FAILED,
        networkError
    }),
}
export default actions;