import actions from "./actions";

const initState = {
    dailyRequestListLoading: false,
    buttonLoading: false,
    dailyRequestError: null,
    dailyRequestMessage: null,
    dailyRequestResult: [],
    dailyRequestFormModal: false,
    createDailyRequestLoading: false,
    Lockmessage: null,
    Lockerror: null,
    lockLoading: false,
    realselockmessage: null,
    realselockerror: null,
    releaselockloading: false,
    cancelModal: false,
    cancelMessage: null,
    cancelError: null,
    cancelLoadng: false,
    cancelerror: null,
    cancelmessage: null,
    cancelLoading: false,

    getMostEligibleRequestLoading: false,
    getMostEligibleRequestError: null,
    getMostEligibleRequestMessage: null,
    getMostEligibleRequestData: [],

    createActivationForDailyPlanLoading: false,
    createActivationForDailyPlanError: null,
    createActivationForDailyPlanMessage: null,

    getDailyPlanOtpLoading: false,
    getDailyPlanOtpError: null,
    getDailyPlanOtpMessage: null,
    getDailyPlanOtpData: [],

    checkDailyPlanCompletionStatusLoading: false,
    checkDailyPlanCompletionStatusError: null,
    checkDailyPlanCompletionStatusMessage: null,

    completeDailyPlanRequestLoading: false,
    completeDailyPlanRequestError: null,
    completeDailyPlanRequestMessage: null,

    createBulkActivationForDailyPlanLoading: false,
    createBulkActivationForDailyPlanError: null,
    createBulkActivationForDailyPlanMessage: null,

    checkValidSerialNumberLoading: false,
    checkValidSerialNumberError: null,
    checkValidSerialNumberData: [],
};

export default function reducer(state = initState, action) {
    switch (action.type) {

        case actions.CHECK_VALID_SERIAL_NUMBER:
            return {
                ...state,
                checkValidSerialNumberLoading: true,
                checkValidSerialNumberError: null,
                checkValidSerialNumberData: [],
            };
        case actions.CHECK_VALID_SERIAL_NUMBER_SUCCESSFUL:
            return {
                ...state,
                checkValidSerialNumberLoading: false,
                checkValidSerialNumberError: null,
                checkValidSerialNumberData: action.payload.data,
            };
        case actions.CHECK_VALID_SERIAL_NUMBER_FAILED:
            return {
                ...state,
                checkValidSerialNumberLoading: false,
                checkValidSerialNumberError: action.payload.error,
                checkValidSerialNumberData: [],
            };


        case actions.CREATE_BULK_ACTIVATION_FOR_DAILY_PLAN:
            return {
                ...state,
                createBulkActivationForDailyPlanLoading: true,
                createBulkActivationForDailyPlanError: null,
                createBulkActivationForDailyPlanMessage: null,
            };
        case actions.CREATE_BULK_ACTIVATION_FOR_DAILY_PLAN_SUCCESSFUL:
            return {
                ...state,
                createBulkActivationForDailyPlanLoading: false,
                createBulkActivationForDailyPlanError: null,
                createBulkActivationForDailyPlanMessage: action.payload.message,
            };
        case actions.CREATE_BULK_ACTIVATION_FOR_DAILY_PLAN_FAILED:
            return {
                ...state,
                createBulkActivationForDailyPlanLoading: false,
                createBulkActivationForDailyPlanError: action.payload.error,
                createBulkActivationForDailyPlanMessage: null,
            };


        case actions.COMPLETE_DAILY_PLAN_REQUEST:
            return {
                ...state,
                completeDailyPlanRequestLoading: true,
                completeDailyPlanRequestError: null,
                completeDailyPlanRequestMessage: null,
            };
        case actions.COMPLETE_DAILY_PLAN_REQUEST_SUCCESSFUL:
            return {
                ...state,
                completeDailyPlanRequestLoading: false,
                completeDailyPlanRequestError: null,
                completeDailyPlanRequestMessage: action.payload.message,
            };
        case actions.COMPLETE_DAILY_PLAN_REQUEST_FAILED:
            return {
                ...state,
                completeDailyPlanRequestLoading: false,
                completeDailyPlanRequestError: action.payload.error,
                completeDailyPlanRequestMessage: null,
            };


        case actions.CHECK_DAILY_PLAN_COMPLETION_STATUS:
            return {
                ...state,
                checkDailyPlanCompletionStatusLoading: true,
                checkDailyPlanCompletionStatusError: null,
                checkDailyPlanCompletionStatusMessage: null,
            };
        case actions.CHECK_DAILY_PLAN_COMPLETION_STATUS_SUCCESSFUL:
            return {
                ...state,
                checkDailyPlanCompletionStatusLoading: false,
                checkDailyPlanCompletionStatusError: null,
                checkDailyPlanCompletionStatusMessage: action.payload.message,
            };
        case actions.CHECK_DAILY_PLAN_COMPLETION_STATUS_FAILED:
            return {
                ...state,
                checkDailyPlanCompletionStatusLoading: false,
                checkDailyPlanCompletionStatusError: action.payload.error,
                checkDailyPlanCompletionStatusMessage: null,
            };

        case actions.GET_DAILY_PLAN_OTP:
            return {
                ...state,
                getDailyPlanOtpLoading: true,
                getDailyPlanOtpError: null,
                getDailyPlanOtpMessage: null,
                getDailyPlanOtpData: [],
            };
        case actions.GET_DAILY_PLAN_OTP_SUCCESSFUL:
            return {
                ...state,
                getDailyPlanOtpLoading: false,
                getDailyPlanOtpError: null,
                getDailyPlanOtpMessage: 'Data get success',
                getDailyPlanOtpData: action.payload.data,
            };
        case actions.GET_DAILY_PLAN_OTP_FAILED:
            return {
                ...state,
                getDailyPlanOtpLoading: false,
                getDailyPlanOtpError: action.payload.error,
                getDailyPlanOtpMessage: null,
                getDailyPlanOtpData: [],
            };

        case actions.CREATE_ACTIVATION_FOR_DAILY_PLAN:
            return {
                ...state,
                createActivationForDailyPlanLoading: true,
                createActivationForDailyPlanError: null,
                createActivationForDailyPlanMessage: null,
            };
        case actions.CREATE_ACTIVATION_FOR_DAILY_PLAN_SUCCESSFUL:
            return {
                ...state,
                createActivationForDailyPlanLoading: false,
                createActivationForDailyPlanError: null,
                createActivationForDailyPlanMessage: action.payload.message,
            };
        case actions.CREATE_ACTIVATION_FOR_DAILY_PLAN_FAILED:
            return {
                ...state,
                createActivationForDailyPlanLoading: false,
                createActivationForDailyPlanError: action.payload.error,
                createActivationForDailyPlanMessage: null,
            };

        case actions.RESET_COMPLETE_FORM:
            return {
                ...state,
                getMostEligibleRequestLoading: false,
                getMostEligibleRequestError: null,
                getMostEligibleRequestMessage: null,
                getMostEligibleRequestData: [],
                createActivationForDailyPlanLoading: false,
                createActivationForDailyPlanError: null,
                createActivationForDailyPlanMessage: null,
                getDailyPlanOtpLoading: false,
                getDailyPlanOtpError: null,
                getDailyPlanOtpMessage: null,
                getDailyPlanOtpData: [],
                checkDailyPlanCompletionStatusLoading: false,
                checkDailyPlanCompletionStatusError: null,
                checkDailyPlanCompletionStatusMessage: null,
                completeDailyPlanRequestLoading: false,
                completeDailyPlanRequestError: null,
                completeDailyPlanRequestMessage: null,
            };

        case actions.GET_MOST_ELIGIBLE_REQUEST:
            return {
                ...state,
                getMostEligibleRequestLoading: true,
                getMostEligibleRequestError: null,
                getMostEligibleRequestMessage: null,
                getMostEligibleRequestData: [],
            };
        case actions.GET_MOST_ELIGIBLE_REQUEST_SUCCESSFUL:
            return {
                ...state,
                getMostEligibleRequestLoading: false,
                getMostEligibleRequestError: null,
                getMostEligibleRequestMessage: 'Data get success',
                getMostEligibleRequestData: action.payload.data,
            };
        case actions.GET_MOST_ELIGIBLE_REQUEST_FAILED:
            return {
                ...state,
                getMostEligibleRequestLoading: false,
                getMostEligibleRequestError: action.payload.error,
                getMostEligibleRequestMessage: null,
                getMostEligibleRequestData: [],
            };

        case actions.GET_DAILY_PLAN_REQUEST_LIST:
            return {
                ...state,
                buttonLoading: true,
                dailyRequestListLoading: true,
                dailyRequestResult: []
            };
        case actions.GET_DAILY_PLAN_REQUEST_LIST_SUCCESSFUL:
            return {
                ...state,
                buttonLoading: false,
                dailyRequestListLoading: false,
                dailyRequestResult: action.payload.dailyRequestResult
            };
        case actions.GET_DAILY_PLAN_REQUEST_LIST_FAILED:
            return {
                ...state,
                buttonLoading: false,
                dailyRequestError: action.payload.dailyRequestError,
                dailyRequestListLoading: false,
            };
        case actions.PREPARE_DAILY_PLAN_REQUEST_MODAL:
            return {
                ...state,
                dailyRequestMessage: null,
                dailyRequestError: null,
                dailyRequestFormModal: true,
            };
        case actions.CLOSE_DAILY_PLAN_REQUEST_MODAL:
            return {
                ...state,
                dailyRequestMessage: null,
                dailyRequestError: null,
                dailyRequestFormModal: false,
            };
        case actions.CREATE_DAILY_PLAN_REQUEST:
            return {
                ...state,
                dailyRequestMessage: null,
                dailyRequestError: null,
                dailyRequestFormModal: true,
                createDailyRequestLoading: true
            };
        case actions.CREATE_DAILY_PLAN_SUCCESSFUL:
            return {
                ...state,
                dailyRequestMessage: action.payload.dailyRequestMessage,
                dailyRequestResult: action.payload.dailyRequestResult,
                dailyRequestError: null,
                dailyRequestFormModal: false,
                createDailyRequestLoading: false,
            };
        case actions.CREATE_DAILY_PLAN_FAILED:
            return {
                ...state,
                dailyRequestMessage: null,
                dailyRequestError: action.payload.dailyRequestError,
                dailyRequestFormModal: true,
                createDailyRequestLoading: false,
            };
        case actions.RESET_DAILY_PLAN:
            return {
                ...state,
                dailyRequestError: null,
                dailyRequestMessage: null,
                Lockerror: null,
                Lockmessage: null,
                realselockmessage: null,
                realselockerror: null,
                createBulkActivationForDailyPlanError: null,
                createBulkActivationForDailyPlanMessage: null,
                checkValidSerialNumberLoading: false,
                checkValidSerialNumberError: null,
                checkValidSerialNumberData: [],
            }
        case actions.LOCK_DAILY_PLAN:
            return {
                ...state,
                Lockmessage: null,
                Lockerror: null,
                lockLoading: true,
            }
        case actions.LOCK_DAILY_PLAN_SUCCESSFUL:
            return {
                ...state,
                Lockmessage: action.payload.Lockmessage,
                dailyRequestResult: action.payload.dailyRequestResult,
                lockLoading: false
            }
        case actions.LOCK_DAILY_PLAN_FAILED:
            return {
                ...state,
                Lockerror: action.payload.Lockerror,
                Lockmessage: null,
                lockLoading: false
            }
        case actions.UNLOCK_DAILY_PLAN:
            return {
                ...state,
                realselockmessage: null,
                realselockerror: null,
                releaselockloading: true

            }
        case actions.UNLOCK_DAILY_PLAN_SUCCESSFUL:
            return {
                ...state,
                realselockmessage: action.payload.realselockmessage,
                dailyRequestResult: action.payload.dailyRequestResult,
                releaselockloading: false
            }
        case actions.UNLOCK_DAILY_PLAN_FAILED:
            return {
                ...state,
                realselockerror: action.payload.realselockerror,
                realselockmessage: null,
                releaselockloading: false
            }
        case actions.PREPARE_CANCEL_MODEL:
            return {
                ...state,
                cancelModal: true,
                cancelMessage: null,
                cancelError: null,
            }
        case actions.CLOSE_CANCEL_MODAL:
            return {
                ...state,
                cancelModal: false,
                cancelMessage: null,
                cancelError: null,
            }

        case actions.CANCEL_DAILY_PLAN:
            return {
                ...state,
                cancelerror: null,
                cancelmessage: null,
                cancelLoading: true,
                cancelModal: true
            }
        case actions.CANCEL_DAILY_PLAN_SUCCESSFUL:
            return {
                ...state,
                cancelmessage: action.payload.cancelmessage,
                dailyRequestResult: action.payload.dailyRequestResult,
                cancelLoading: false,
                cancelModal: false
            }
        case actions.CANCEL_DAILY_PLAN_FAILED:
            return {
                ...state,
                cancelerror: action.payload.cancelerror,
                cancelmessage: null,
                cancelLoading: false,
                cancelModal: true,
            }
        default:
            return state;
    }
}