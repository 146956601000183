import actions from "./actions";

const initState = {
    getRechargePlanList: [],
    getRechargePlanLoading: false,
    getRechargePlanError: null,
    getRechargePlanMessage: null,
    rechargePlanModal: false,

    addRechargePlanLoading: false,
    addRechargePlanError: null,
    addRechargePlanMessage: null,

    loading: false,
    planError: null,
    planMessage: null,
    buttonLoading: false,
    deleteButtonLoading: false,
    preferedAggregatorModal: false,
};

export default function reducer(state = initState, action) {
    switch (action.type) {

        case actions.GET_RECHARGE_PLAN:
            return {
                ...state,
                getRechargePlanLoading: true,
                getRechargePlanError: null,
                getRechargePlanMessage: null,
                getRechargePlanList: [],
            };
        case actions.GET_RECHARGE_PLAN_SUCCESS:
            return {
                ...state,
                getRechargePlanLoading: false,
                getRechargePlanError: null,
                getRechargePlanList: action.payload.data,
            };
        case actions.GET_RECHARGE_PLAN_FAILED:
            return {
                ...state,
                getRechargePlanLoading: false,
                getRechargePlanError: action.payload.error,
                getRechargePlanMessage: null,
                getRechargePlanList: [],
            };

        case actions.ADD_RECHARGE_PLAN:
            return {
                ...state,
                addRechargePlanLoading: true,
                addRechargePlanError: null,
                addRechargePlanMessage: null,
            };
        case actions.ADD_RECHARGE_PLAN_SUCCESS:
            return {
                ...state,
                addRechargePlanLoading: false,
                addRechargePlanError: null,
                addRechargePlanMessage: action.payload.message,
                rechargePlanModal: false,
            };
        case actions.ADD_RECHARGE_PLAN_FAILED:
            return {
                ...state,
                addRechargePlanLoading: false,
                addRechargePlanError: action.payload.error,
                addRechargePlanMessage: null,
            };


        case actions.UPDATE_RECHARGE_PLAN:
            return {
                ...state,
                addRechargePlanLoading: true,
                addRechargePlanError: null,
                addRechargePlanMessage: null,
            };
        case actions.UPDATE_RECHARGE_PLAN_SUCCESS:
            return {
                ...state,
                addRechargePlanLoading: false,
                addRechargePlanError: null,
                addRechargePlanMessage: action.payload.message,
                rechargePlanModal: false,
            };
        case actions.UPDATE_RECHARGE_PLAN_FAILED:
            return {
                ...state,
                addRechargePlanLoading: false,
                addRechargePlanError: action.payload.error,
                addRechargePlanMessage: null,
            };


        case actions.OPEN_RECHARGE_PLAN_MODAL:
            return {
                ...state,
                rechargePlanModal: true,
            };
        case actions.CLOSE_RECHARGE_PLAN_MODAL:
            return {
                ...state,
                rechargePlanModal: false,
            };

        case actions.DELETE_RECHARGE_PLAN:
            return {
                ...state,
                planMessage: null,
                planError: null,
                deleteButtonLoading: true,
            };
        case actions.DELETE_RECHARGE_PLAN_SUCCESS:

            return {
                ...state,
                planError: null,
                planMessage: action.planMessage,
                deleteButtonLoading: false,
            };
        case actions.DELETE_RECHARGE_PLAN_FAILED:
            return {
                ...state,
                planError: action.payload.error,
                planMessage: null,
                deleteButtonLoading: false,
            };

        case actions.RESET_RECHARGE_PLAN_MESSAGE:
            return {
                ...state,
                loading: false,
                planError: null,
                planMessage: null,
                deleteButtonLoading: false,
                getRechargePlanError: null,
                getRechargePlanMessage: null,
                addRechargePlanMessage: null,
                addRechargePlanError: null,
            };

        case actions.UPDATE_PREFFERED_AGGREGATOR_RECHARGE:
            return {
                ...state,
                loading: false,
                buttonLoading: true,
                addRechargePlanError: null,
                addRechargePlanMessage: null,
            };
        case actions.UPDATE_PREFFERED_AGGREGATOR_RECHARGE_SUCCESS:
            return {
                ...state,
                loading: false,
                // networkResult: action.networkResult,
                addRechargePlanMessage: action.payload.message,
                addRechargePlanError: null,
                buttonLoading: false,
                preferedAggregatorModal: false,
            };
        case actions.UPDATE_PREFFERED_AGGREGATOR_RECHARGE_FAILED:
            return {
                ...state,
                addRechargePlanError: action.payload.error,
                buttonLoading: false,
                addRechargePlanMessage: null,
                preferedAggregatorModal: false,
            };

        case actions.OPEN_PREFERED_AGGREGATOR_MODAL_RECHARGE:
            return {
                ...state,
                preferedAggregatorModal: true
            };
        case actions.CLOSE_PREFERED_AGGREGATOR_MODAL_RECHARGE:
            return {
                ...state,
                preferedAggregatorModal: false
            };



        default:
            return state;
    }
}