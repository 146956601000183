const actions = {
    GET_AUDIT_REPORT: "GET_AUDIT_REPORT",
    GET_AUDIT_REPORT_SUCCESS: "GET_AUDIT_REPORT_SUCCESS",
    GET_AUDIT_REPORT_FAILED: "GET_AUDIT_REPORT_FAILED",
    RESET_AUDIT_REPORT_MESSAGE: "RESET_AUDIT_REPORT_MESSAGE",
    getAuditReport: (Data) => ({
        type: actions.GET_AUDIT_REPORT,
        payload: { Data },
    }),
    getAuditReportSuccess: (auditResult) => ({
        type: actions.GET_AUDIT_REPORT_SUCCESS,
        auditResult
    }),
    getAuditReportFailed: (error) => ({
        type: actions.GET_AUDIT_REPORT_FAILED,
        error
    }),
}

export default actions;