const actions = {
    GET_ADDON_SERVICE: 'GET_ADDON_SERVICE',
    GET_ADDON_SERVICE_SUCCESS: 'GET_ADDON_SERVICE_SUCCESS',
    GET_ADDON_SERVICE_FAILED: 'GET_ADDON_SERVICE_FAILED',
    ADD_ADDON_SERVICE: "ADD_ADDON_SERVICE",
    ADD_ADDON_SERVICE_SUCCESS: "ADD_ADDON_SERVICE_SUCCESS",
    ADD_ADDON_SERVICE_FAILED: "ADD_ADDON_SERVICE_FAILED",
    EDIT_ADDON_SERVICE: "EDIT_ADDON_SERVICE",
    EDIT_ADDON_SERVICE_SUCCESS: "EDIT_ADDON_SERVICE_SUCCESS",
    DELETE_ADDON_SERVICE: "DELETE_ADDON_SERVICE",
    DELETE_ADDON_SERVICE_SUCCESS: "DELETE_ADDON_SERVICE_SUCCESS",
    PREPARE_ADDON_FORM: "PREPARE_ADDON_FORM",
    CLOSE_ADDON_FORM: "CLOSE_ADDON_FORM",
    RESET_ADDON_SERVICE: "RESET_ADDON_SERVICE",
    getAddonService: () => ({
        type: actions.GET_ADDON_SERVICE,
    }),
    getAddonServiceSuccess: (addonResult) => ({
        type: actions.GET_ADDON_SERVICE_SUCCESS,
        addonResult
    }),
    getAddonServiceFailed: (addonError) => ({
        type: actions.GET_ADDON_SERVICE_FAILED,
        addonError
    }),
    addAddonService: (data) => ({
        type: actions.ADD_ADDON_SERVICE,
        payload: { data }
    }),
    addAddonServiceSuccess: (addonMessage, addonResult) => ({
        type: actions.ADD_ADDON_SERVICE_SUCCESS,
        addonMessage,
        addonResult
    }),
    addAddonServiceFailed: (addonError) => ({
        type: actions.ADD_ADDON_SERVICE_FAILED,
        addonError
    }),
    editAddonService: (addonId, data) => ({
        type: actions.EDIT_ADDON_SERVICE,
        payload: { addonId, data }
    }),
    editAddonServiceSuccess: (addonMessage, addonResult) => ({
        type: actions.EDIT_ADDON_SERVICE_SUCCESS,
        addonMessage,
        addonResult
    }),
    deleteAddonService: (addonId, status) => ({
        type: actions.DELETE_ADDON_SERVICE,
        payload: { addonId, status }
    }),
    deleteAddonServiceSuccess: (addonMessage, addonResult) => ({
        type: actions.DELETE_ADDON_SERVICE_SUCCESS,
        addonMessage,
        addonResult
    }),
    prepareAddonForm: () => ({
        type: actions.PREPARE_ADDON_FORM,
    }),
    closeAddonForm: () => ({
        type: actions.CLOSE_ADDON_FORM,
    }),
    resetAddonService: () => ({
        type: actions.RESET_ADDON_SERVICE,
    }),
};
export default actions;
