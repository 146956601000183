import actions from "./actions";

const initState = {
    planResult: [],
    planData: [],
    planRegion: [],
    planDay: [],
    loading: false,
    buttonLoading: false,
    deleteButtonLoading: false,
    getPlanLoading: false,
    planError: null,
    planMessage: null,
    buttonloading: false,
    planFormModal: false,
    cronusInventoryData: [],
    trafficPolicyResult: [],
    packageView: false,
    planViewModal: false,
    planViewModalData: [],
    planBundleName: [],
    bundleError: null,
    planNameMessage: null,
    preferedAggregatorModalPlan: false,
    planbuttonLoading: false,
    planAggregatorMessage:null,
    planAggregatorError:null

};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_PLAN:
            return {
                ...state,
                loading: true,
                buttonLoading: false,
                planMessage: null,
                planError: null,
                buttonloading: false,
                planFormModal: false,
            };
        case actions.GET_PLAN_SUCCESS:
            return {
                ...state,
                loading: false,
                buttonLoading: false,
                planResult: action.planResult,
                planMessage: null,
                planError: null,
                buttonloading: false,
                planFormModal: false,
            };
        case actions.GET_PLAN_FAILED:
            return {
                ...state,
                loading: false,
                buttonLoading: false,
                planError: action.planError,
                planMessage: null,
                buttonloading: false,
                planFormModal: false,
            };
        case actions.ADD_PLAN:
            return {
                ...state,
                loading: false,
                buttonLoading: true,
                planMessage: null,
                planError: null,
                buttonloading: true,
                planFormModal: true,
            };
        case actions.ADD_PLAN_SUCCESS:
            return {
                ...state,
                loading: false,
                buttonLoading: false,
                planError: null,
                planMessage: action.planMessage,
                planResult: action.planResult,
                buttonloading: false,
                planFormModal: false,
            };
        case actions.ADD_PLAN_FAILED:
            return {
                ...state,
                loading: false,
                buttonLoading: false,
                planError: action.planError,
                planMessage: null,
                buttonloading: false,
                planFormModal: true,
            };

        case actions.GET_PLAN_DATA:
            return {
                ...state,
                getPlanLoading: true,
                planError: null,
                planMessage: null,
                buttonloading: true,
                planFormModal: false,
            };
        case actions.GET_PLAN_DATA_SUCCESS:
            return {
                ...state,
                getPlanLoading: false,
                planError: null,
                planMessage: null,
                buttonloading: false,
                planFormModal: false,
                planData: action.planData,
            };
        case actions.GET_PLAN_DATA_FAILED:
            return {
                ...state,
                getPlanLoading: false,
                planError: action.planError,
                planMessage: null,
                buttonloading: false,
                planFormModal: false,
            }
        case actions.GET_PLAN_DAY:
            return {
                ...state,
                getPlanLoading: true,
                planError: null,
                planMessage: null,
                buttonloading: true,
                planFormModal: false,
            };
        case actions.GET_PLAN_DAY_SUCCESS:
            return {
                ...state,
                getPlanLoading: false,
                planError: null,
                planMessage: null,
                buttonloading: false,
                planFormModal: false,
                planDay: action.planDayData,
            };
        case actions.GET_PLAN_DAY_FAILED:
            return {
                ...state,
                getPlanLoading: false,
                planError: action.planError,
                planMessage: null,
                buttonloading: false,
                planFormModal: false,
            }
        case actions.GET_BUNDLE_NAME_DATA:
            return {
                ...state,
                getPlanLoading: true,
                planError: null,
                planMessage: null,
                buttonloading: true,
            };
        case actions.GET_BUNDLE_NAME_DATA_SUCCESS:
            return {
                ...state,
                getPlanLoading: false,
                planError: null,
                planMessage: null,
                buttonloading: false,
                planBundleName: action.planBundleName,
                bundleError: null

            };
        case actions.GET_BUNDLE_NAME_DATA_FAILED:
            return {
                ...state,
                getPlanLoading: false,
                bundleError: action.bundleError,
                planMessage: null,
                buttonloading: false,
                planFormModal: true,
                planBundleName: []
            }
        case actions.DELETE_PLAN:
            return {
                ...state,
                loading: false,
                planMessage: null,
                planError: null,
                deleteButtonLoading: true,
                planFormModal: false,
            };
        case actions.DELETE_PLAN_SUCCESS:
            return {
                ...state,
                loading: false,
                planError: null,
                planMessage: action.planMessage,
                planResult: action.planResult,
                deleteButtonLoading: false,
                planFormModal: false,
            };
        case actions.PREPARE_PLAN_FORM:
            return {
                ...state,
                planFormModal: true
            };
        case actions.RESET_PLAN:
            return {
                ...state,
                loading: false,
                planError: null,
                planMessage: null,
                buttonloading: false,
                planFormModal: false,
                planBundleName: [],
                bundleError: null,
                planAggregatorMessage:null,
                planAggregatorError:null,
            };
        case actions.RESET_PLAN_MESSAGE:
            return {
                ...state,
                loading: false,
                planError: null,
                planMessage: null,
                buttonloading: false,
                planNameMessage: null,
                planAggregatorMessage:null,
                planAggregatorError:null,
            };
        case actions.CHECK_PLAN_NAME:
            return {
                ...state,
                planError: null,
                planMessage: null,
            };
        case actions.CHECK_PLAN_NAME_SUCCESS:
            return {
                ...state,
                planError: null,
                planNameMessage: action.message,
            };
        case actions.CHECK_PLAN_NAME_FAILED:
            return {
                ...state,
                planError: action.error,
                planMessage: null,
            };

            case actions.UPDATE_PREFFERED_AGGREGATOR_PLAN:
                return {
                    ...state,
                    loading: false,
                    planbuttonLoading: true,
                    planAggregatorError: null,
                    planAggregatorMessage: null,
                };
            case actions.UPDATE_PREFFERED_AGGREGATOR_PLAN_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    planAggregatorMessage: action.planMessage,
                    planAggregatorError: null,
                    planbuttonLoading: false,
                    preferedAggregatorModalPlan: false,
                };
            case actions.UPDATE_PREFFERED_AGGREGATOR_PLAN_FAILED:
                return {
                    ...state,
                    planAggregatorError: action.planError,
                    planbuttonLoading: false,
                    planAggregatorMessage: null,
                    preferedAggregatorModalPlan: false,
                };

        case actions.OPEN_PREFERED_AGGREGATOR_MODAL_PLAN:
            return {
                ...state,
                preferedAggregatorModalPlan: true
            };
        case actions.CLOSE_PREFERED_AGGREGATOR_MODAL_PLAN:
            return {
                ...state,
                preferedAggregatorModalPlan: false
            };

        default:
            return state;
    }
}