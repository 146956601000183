import actions from "./actions";

const initState = {
    loading: false,
    jarUploadData: [],
    apiError: null,
    message: null,
    getJarLoading: false,

};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.UPLOAD_JAR_FILE:
            return {
                ...state,
                loading: true,
                apiError: null,
                message: null,
            };
        case actions.UPLOAD_JAR_FILE_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                apiError: null,
                message: action.payload
            };
        case actions.UPLOAD_JAR_FILE_FAIL:
            return {
                ...state,
                loading: false,
                apiError: action.payload,
                message: null
            };

        case actions.GET_JAR_FILE:
            return {
                ...state,
                getJarLoading: true,
                apiError: null,
            };
        case actions.GET_JAR_FILE_SUCCESSFUL:
            return {
                ...state,
                getJarLoading: false,
                apiError: null,
                jarUploadData: action.payload
            };
        case actions.GET_JAR_FILE_FAIL:
            return {
                ...state,
                getJarLoading: false,
                apiError: action.payload,
                message: null
            };


        default:
            return state;
    }
}
