import { useState, useEffect } from 'react'
import CardLayout from '../../components/utils/CardLayout'
import SearchBar from '../../components/utils/SearchBar'
import SideButtons from '../../components/utils/SideButtons'
import Table from '../../components/utils/Table'
import Tag from '../../components/utils/Tag'
import EyeFill from 'remixicon-react/EyeFillIcon';
import PencilFill from 'remixicon-react/PencilFillIcon';
import Popconfirm from '../../components/utils/Popover'
import { Link } from 'react-router-dom';
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../components/utils/Model'
import Button from '../../components/utils/Button'
import CloseCircleFill from 'remixicon-react/CloseCircleFillIcon';
import AddFillIcon from 'remixicon-react/AddFillIcon';
import AggregatorActions from '../../redux/aggregator/actions'
import Select from 'react-select';
import NetworkActions from '../../redux/network/actions'
import PopConfirm from '../../components/utils/Popover'
import DeleteBin5FillIcon from 'remixicon-react/DeleteBin5FillIcon'
import CheckboxCircleFillIcon from 'remixicon-react/CheckboxCircleFillIcon'
import Alert from '../../components/utils/Alert'
import { computeHeadingLevel } from '@testing-library/react'
import { PASSWORD_VALIDATE } from '../../helpers/constant'
import EyeLineIcon from 'remixicon-react/EyeLineIcon';
import EyeOffLineIcon from 'remixicon-react/EyeOffLineIcon';

const { getActiveNetwork } = NetworkActions;
const { openAddAggregatorModal, previewAggregatorModal, resetAggregator, addAggregator, getAggregator, setPreviewData, updateAggregator } = AggregatorActions;

function Aggregator() {
    const dispatch = useDispatch();
    const { list, openAggregatorModel, previewModel, loading, message, error } = useSelector((state) => state.aggregator);
    const [filterText, setFilterText] = useState("");
    const [formTitle, setFormTitle] = useState('')
    const [editFlag, setEditFlag] = useState(false);
    const [formButton, setFormButton] = useState('');
    const [aggregatorId, setAggregatorId] = useState(0);
    const [addClicked, setIsAddClicked] = useState(false);
    const [previewAggregatorData, setPreviewAggregatorData] = useState("");
    const [selected, setSelected] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertText, setAlertText] = useState('');
    const { networkResult } = useSelector((state) => state.network);
    const [aggregatorFrom, setAggregatorFrom] = useState({
        aggregatorName: "",
        customerSupport: "",
        loginRequired: "",
        metaData: [
            {
                key: "",
                value: "",
            }
        ],
        network: [
            {
                label: "T-Mobile",
                value: 1
            }
        ]
    })

    const [showFields, setShowFields] = useState(false);
    const [passwordShow, setPasswordShow] = useState(false);
    const { register, handleSubmit, control, setValue, formState: { errors }, reset } = useForm({ defaultValues: aggregatorFrom, });

    const handleFilterChange = (value) => {
        setFilterText(value);
    };

    useEffect(() => {
        dispatch(getActiveNetwork());
        dispatch(getAggregator());
    }, []);

    useEffect(() => {
        reset(aggregatorFrom);
        setValue('network', selected);
    }, [aggregatorFrom, reset, selected, setValue]);

    useEffect(() => {
        if (error !== null && error !== undefined) {
            displayAlert(error, 'alert-danger');
        }
        if (message !== null && message !== undefined) {
            displayAlert(message, 'alert-success');
        }
    }, [error, message]);

    const handleAddAggregator = () => {
        setAggregatorFrom(prevState => ({
            ...prevState,
            aggregatorName: "",
            metaData: [
                {
                    key: "",
                    value: "",
                }
            ]
        }));
        dispatch(openAddAggregatorModal());
        setFormTitle('Add Aggregator');
        setFormButton('Submit');
        setEditFlag(false);
        reset();
    };
    const handleEditAggregatormodal = (id) => {
        setAggregatorId(id);
        setFormTitle('Edit Aggregator');
        setFormButton('Update');
        dispatch(openAddAggregatorModal());
        setAggregatorId(id)
        const selectedAggregator = list.filter(item => item.ID === id);
        setShowFields(selectedAggregator[0].LoginRequired ? 1 : 0)
        reset({
            aggregatorName: selectedAggregator[0].AggregatorName,
            network: JSON.parse(selectedAggregator[0].networks),
            metaData: JSON.parse(selectedAggregator[0].metaData),
            customerSupport: selectedAggregator[0].CustomerSupport === 1 ? "customerSupport" : "",
            loginRequired: selectedAggregator[0].LoginRequired === 1 ? "loginRequired" : "",
            contactPerson: selectedAggregator[0].ContactPerson,
            contactNumber: selectedAggregator[0].ContactNumber,
        });

        setEditFlag(true)
    }

    const handleShowAggregatormodal = (aggregatorData) => {
        dispatch(previewAggregatorModal());
        setFormTitle('Aggregator');
        // setPreviewAggregatorData(JSON.parse(aggregatorData.meta_data));
    }


    const onSubmit = (data) => {
        const customerSupport = data.customerSupport == null ? 0 : data.customerSupport === "customerSupport" ? 1 : 0;
        const loginRequired = data.loginRequired == null ? 0 : data.loginRequired === "loginRequired" ? 1 : 0;
        const newData = {
            ...data,
            customerSupport,
            loginRequired
        }
        dispatch(addAggregator(newData));
        setShowFields('')
    };

    const handleCancel = () => {
        setAggregatorFrom(prevState => ({
            ...prevState,
            aggregatorName: "",
            metaData: [
                {
                    key: "",
                    value: "",
                }
            ]
        }));
        setShowFields('')
        dispatch(resetAggregator());

    };


    const handleUpdate = (data) => {
        data.aggregatorId = aggregatorId;
        const newData = {
            ...data,
            aggregatorId,
            customerSupport: data.customerSupport === "customerSupport" ? 1 : 0,
            loginRequired: data.loginRequired === "loginRequired" ? 1 : 0,
            contactPerson: data.loginRequired === "loginRequired" ? data.contactPerson : '',
            contactNumber: data.loginRequired === "loginRequired" ? data.contactNumber : '',
        };
        dispatch(updateAggregator(newData));
    };


    const displayAlert = (text, type) => {
        setAlertText(text);
        setAlertType(type);
        setShowAlert(true);
        dispatch(resetAggregator());
    };

    const alertCancel = () => {
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
        dispatch(resetAggregator());
    }


    const columns = [{ displayName: '#', key: 'key' },
    { displayName: 'AGGREGATOR NAME', key: 'aggregatorName' },
    { displayName: ' SUPPORTED NETWORKS', key: 'network' },
    { displayName: 'CUSTOMER SUPPORT', key: 'customerSupport' },
    { displayName: 'LOGIN REQUIRED', key: 'loginRequired' },
    { displayName: 'USERNAME', key: 'username' },
    { displayName: 'STATUS', key: 'status' },
    { displayName: 'ACTION', key: 'action' },
    ]

    const options = networkResult.map(network => ({
        label: network.NetworkName,
        value: network.ID,
    }));

    const { fields = [], append, remove } = useFieldArray({
        name: "metaData",
        control

    })

    const filteredItems = list && list.length > 0 && list.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const handleDelete = (ID, flag) => {
        // dispatch(deleteNetwork(ID, flag));
    }

    const handleCheckboxChange = (event) => {
        setShowFields(event.target.checked);
    };

    const dataSource = filteredItems && filteredItems.length > 0 && filteredItems.map((item, key) => {

        let networkList = JSON.parse(item.networks)
        let networkData = networkList && networkList.length > 0 && networkList.map((network, ind) => (
            <span className='agg-network-list' style={{ marginRight: '4px', backgroundColor: '#e9ecef' }}>{network.label}</span>
        ))
        return {
            key: key + 1,
            aggregatorName: item.AggregatorName,
            network: networkData,
            username: item.Username,
            inventory: <Tag color={`${item.Inventory === 1 ? "badge-success" : "badge-danger"}`} title={item.Inventory === 1 ? 'Yes' : 'No'} />,
            status: <Tag color={`${item.IsActive === 1 ? "badge-success" : "badge-danger"}`} title={item.IsActive === 1 ? 'Active' : 'Inactive'} />,
            customerSupport: <Tag color={`${item.CustomerSupport === 1 ? "badge-success" : "badge-danger"}`} title={item.CustomerSupport === 1 ? 'Yes' : 'No'} />,
            loginRequired: <Tag color={`${item.LoginRequired === 1 ? "badge-success" : "badge-danger"}`} title={item.LoginRequired === 1 ? 'Yes' : 'No'} />,
            action: (
                <div>
                    <Link to="#" onClick={() => handleEditAggregatormodal(item.ID)}>
                        <span data-toggle="tooltip" data-placement="top" title="Edit">
                            <PencilFill size="1.2em" style={{ color: "#888b8e", marginRight: "3px" }} />
                        </span>
                    </Link>
                </div>
            )

        }
    })

    return (
        <CardLayout title="Aggregator">
            {showAlert && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}
            <div className="row align-items-baseline">
                <div className="col-lg-6 col-md-6  od-2">
                    <SearchBar onFilter={handleFilterChange} filterText={filterText} />
                </div>
                <div className="col-lg-6 col-md-6 text-right">
                    <SideButtons title="Add Aggregator" buttonStyle="primary" onClick={handleAddAggregator} />
                </div>
            </div>

            <Table data={dataSource} loading={loading} columns={columns} pagination={true} />

            <Modal
                showModal={openAggregatorModel}
                title={formTitle}
                onHide={handleCancel}
                width={550}
                showFooter={false}>
                <form onSubmit={handleSubmit(editFlag ? handleUpdate : onSubmit)}>
                    <div className="form-row">
                        <div className="col-md-12 mb-3">
                            <label>Aggregator Name</label>
                            <input type="text" disabled={editFlag} placeholder="Aggregator Name" class="form-control" name="aggregatorName" {...register("aggregatorName", {
                                required: true,
                            })} />
                            {errors.aggregatorName?.type === "required" && (<div class="invalid-feedback">Aggregator name required!</div>)}
                        </div>

                        <div className="col-md-12 mb-3">
                            <label>Network</label>
                            <Controller
                                control={control}
                                name="network"
                                rules={{
                                    required: true,
                                }}
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                }) => (
                                    <Select
                                        options={options}
                                        onChange={onChange}
                                        defaultValue={aggregatorFrom.network}
                                        isMulti={true}
                                        onBlur={onBlur}
                                        value={value}
                                        name={name}
                                        ref={ref}
                                    />
                                )}
                            />
                            {errors.network?.type === "required" && (<div class="invalid-feedback">Network required!</div>)}
                        </div>

                        <div className="col-md-6 mb-3 ">
                            <div class="custom-control custom-checkbox custom-control-inline">
                                <input type="checkbox" id="customerSupport" value="customerSupport" name="customerSupport" class="custom-control-input" {...register('customerSupport')} />
                                <label class="custom-control-label" for="customerSupport" > Customer Support</label>
                            </div>
                        </div>

                        <div className="col-md-6 mb-3 ">
                            <div class="custom-control custom-checkbox custom-control-inline">
                                <input type="checkbox" id="loginRequired" value="loginRequired" name="loginRequired" class="custom-control-input" {...register('loginRequired')}
                                    onChange={handleCheckboxChange} selected={true}
                                />
                                <label class="custom-control-label" for="loginRequired" > Login Required</label>
                            </div>
                        </div>

                        {showFields ? (
                            <>
                                {!editFlag && <div className="col-md-6">
                                    <label>Username</label>
                                    <input type="text" placeholder="Username" className="form-control" name="username" {...register("username", { required: true })} />
                                    {errors.username?.type === "required" && (<div class="invalid-feedback">Username is  required!</div>)}
                                </div>}

                                {!editFlag && <div className="col-md-6">
                                    <div className="form-group mb-3" style={{ position: 'relative' }}>
                                        <label htmlFor="password">Password</label>
                                        <input type={passwordShow ? 'text' : 'password'} className="form-control" name='password' {...register('password', {
                                            required: true,
                                            minLength: 8,
                                            validate: (value) => {
                                                return PASSWORD_VALIDATE.test(value)
                                            }
                                        })} placeholder="Password" />
                                        <div style={Style.iconContainer}>
                                            {
                                                passwordShow ? <EyeLineIcon size="1.1em" style={{ cursor: 'pointer' }} onClick={() => setPasswordShow((pre) => !pre)} /> : <EyeOffLineIcon size="1.1em" style={{ cursor: 'pointer' }} onClick={() => setPasswordShow((pre) => !pre)} />
                                            }
                                        </div>

                                        {errors.password && errors.password.type === "required" != '' ? <div className="invalid-feedback">Password is required !</div> : ''}
                                        {errors.password && errors.password.type === "minLength" != '' ? <div className="invalid-feedback">Password should contain at least 8 characters !</div> : ''}
                                        {errors.password && errors.password.type === "validate" != '' ? <div className="invalid-feedback">Invalid password format(Example@123) !</div> : ''}
                                    </div>
                                </div>}

                                <div className="col-md-6 mb-3">
                                    <label>Contact Person</label>
                                    <input type="text" placeholder="Contact Person" className="form-control" name="contactPerson" {...register("contactPerson",)} />
                                </div>

                                <div className="col-md-6 mb-3">
                                    <label>Contact Number</label>
                                    <input type="number" placeholder="Contact Number" className="form-control" name="contactNumber" {...register("contactNumber",)} />
                                </div>
                            </>
                        ) : ''}
                    </div>
                    <>
                        <h4>Meta Data</h4>
                        <hr />
                    </>

                    {fields && fields.length > 0 && fields.map((fieldData, index) => {
                        return (
                            <div className="form-row align-items-center" key={fieldData.id}>
                                <div className="col mb-3">
                                    <label>Key</label>
                                    <input type="text" disabled={editFlag} class="form-control input-width" placeholder="Key" name="key" {...register(`metaData.${index}.key`, {
                                        // required: 'Please input Key !',
                                    })} />
                                    {errors && errors.metaData && errors.metaData[index] && errors.metaData[index].key && (
                                        <div className="invalid-feedback">{errors.metaData[index].key.message}</div>
                                    )}
                                </div>
                                <div className="col mb-3">
                                    <label>Value</label>
                                    <input type="text" class="form-control input-width" placeholder="Value" name="value" {...register(`metaData.${index}.value`, {
                                        // required: 'Please input value !',
                                    })} />
                                    {errors && errors.metaData && errors.metaData[index] && errors.metaData[index].value && (
                                        <div className="invalid-feedback">{errors.metaData[index].value.message}</div>
                                    )}
                                </div>

                                {fields.length > 1 && !editFlag && (
                                    <div className='mt-3' onClick={() => remove(index)}>
                                        <CloseCircleFill size={30} style={{ fontWeight: "bold", color: 'red' }} />
                                    </div>
                                )}
                            </div>
                        );
                    })}

                    <div className="col-md-12 pr-1 text-right">
                        {!editFlag && (
                            <button type="button" className='btn btn-outline-primary font-weight-bold' onClick={() => {
                                append({
                                    key: "",
                                    value: "",
                                });
                                setIsAddClicked(true);
                            }}><AddFillIcon size={16} style={{ fontWeight: "bold" }} />Add More</button>
                        )}</div>

                    <div className='text-right row pt-4'>
                        <div className="col-md-12">
                            <button type="button" class="btn btn-secondary mr-2" onClick={handleCancel}>Close</button>
                            <Button title={formButton} loading={loading} style="minWidth:80px" buttonClass="btn btn-primary" buttonType='submit' />
                        </div>
                    </div>
                </form>
            </Modal>

            <Modal
                showModal={previewModel}
                title="Aggregator Details"
                onHide={handleCancel}
                width={800}
                showFooter={false}
            >


                <table class="table table table-striped table-bordered ">

                    <thead className='t-thead'>
                        <tr>
                            <th className='t-th' scope="col-6">KEY</th>
                            <th className='t-th' scope="col-6">VALUE</th>

                        </tr>
                    </thead>
                    <tbody>
                        {previewAggregatorData && previewAggregatorData.length > 0 &&
                            previewAggregatorData.map((myData, index) => (
                                <tr>
                                    <td className='t-td' ><p>{myData.key}</p></td>
                                    <td className='t-td' ><p>{myData.value}</p></td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </Modal>


        </CardLayout>
    )
}

const Style = {
    eyeIcon: {
        position: 'absolute',
        right: '10px',
        top: '40px',
        cursor: 'pointer'
    },
    iconContainer: {
        position: 'absolute',
        top: '35px',
        right: '10px'
    }
}
export default Aggregator
