import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData } from '../../helpers/fackBackend_Helper';

function* getActivationReportResponse({ payload: { searchData } }) {
    try {
        const response = yield call(addData, '/activationRequestReport', searchData);
        if (response.Status) {

            yield put(
                actions.getActivationReportSuccess(
                    response.activations
                ));
        } else {

            yield put(actions.getActivationReportFailed(response.response.Message));
        }
    } catch (error) {

        yield put(actions.getActivationReportFailed(error));
    }
}




export default function* rootSaga() {
    yield all([takeEvery(actions.GET_ACTIVATION_REPORT, getActivationReportResponse)]);
    
}