import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData, editData, getList } from '../../helpers/fackBackend_Helper';

function* getDailyPlanRequestResponse({ payload: { searchData } }) {
    try {
        const response = yield call(addData, '/dailyPlanRequestList', searchData);
        if (response.Status) {
            yield put(actions.getDailyPlanRequestSuccessful(response.Data));
        } else {
            yield put(actions.getDailyPlanRequestFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getDailyPlanRequestFailed(error));
    }
}

function* createDailyPlanRequestResponse({ payload: { data, searchData } }) {
    try {
        const response = yield call(addData, '/createDailyPlanRequest', data);
        if (response.response.Status) {
            yield put(actions.createDailyPlanRequestSuccessful(response.response.Message));
            yield put(actions.getDailyPlanRequestList(searchData));
        } else {
            yield put(actions.createDailyPlanRequestFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.createDailyPlanRequestFailed(error));
    }
}

function* getLockDailyPlanRequestResponse({ payload: { lockData, searchData } }) {
    try {
        const response = yield call(addData, '/lockDailyPlanRequest', lockData);
        if (response.lockData[0].Status) {
            yield put(actions.lockDailyPlanRequestSuccessful(response.lockData[0].Message));
            yield put(actions.getDailyPlanRequestList(searchData));
        } else {
            yield put(actions.lockDailyPlanRequestFailed(response.lockData[0].Message));
        }
    } catch (error) {
        yield put(actions.lockDailyPlanRequestFailed(error));
    }
}

function* getUnlockDailyPlanRequestReponse({ payload: { unLockData, searchData } }) {
    try {
        const response = yield call(addData, '/releaseLockDailyPlanRequest/', unLockData);
        if (response.lockData[0].Status) {
            yield put(actions.unLockDailyPlanSuccessful(response.lockData[0].Message));
            yield put(actions.getDailyPlanRequestList(searchData));
        } else {
            yield put(actions.unLockDailyPlanFailed(response.lockData[0].Message));
        }
    } catch (error) {
        yield put(actions.unLockDailyPlanFailed(error));
    }
}

function* getMostEligibleRequestReponse({ payload: { numberOfDays, addonServId } }) {
    try {
        const response = yield call(getList, `/getMostEligibleRequest/${numberOfDays}/${addonServId}`);
        if (response.Status) {
            yield put(actions.getMostEligibleRequestSuccessful(response));
        } else {
            yield put(actions.getMostEligibleRequestFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.getMostEligibleRequestFailed(error.message));
    }
}

function* getCancelDataResponse({ payload: { cancelData, id, searchData } }) {
    try {
        const response = yield call(editData, '/updateDailyPlanRemark/' + id, cancelData);
        if (response.response.Status) {
            yield put(actions.cancelDailyPlanSuccessful(response.response.Message))
            yield put(actions.getDailyPlanRequestList(searchData));
        } else {
            yield put(actions.cancelDailyPlanFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.cancelDailyPlanFailed(error));
    }
}

function* createActivationForDailyPlanResponse({ payload: { data, searchData } }) {
    try {
        const response = yield call(addData, '/createActivationForDailyPlan', data);
        if (response.Status) {
            yield put(actions.createActivationForDailyPlanSuccessful(response.Message));
            yield put(actions.getDailyPlanRequestList(searchData));
        } else {
            yield put(actions.createActivationForDailyPlanFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.createActivationForDailyPlanFailed(error.message));
    }
}

function* getDailyPlanOtpReponse({ payload: { activationID } }) {
    try {
        const response = yield call(getList, '/getDailyPlanOtp/' + activationID);
        if (response.response.Status) {
            yield put(actions.getDailyPlanOtpSuccessful(response.response.Data));
        } else {
            yield put(actions.getDailyPlanOtpFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getDailyPlanOtpFailed(error.message));
    }
}

function* checkDailyPlanCompletionStatusReponse({ payload: { activationID } }) {
    try {
        const response = yield call(getList, '/checkDailyPlanCompletionStatus/' + activationID);
        if (response.Status) {
            yield put(actions.checkDailyPlanCompletionStatusSuccessful(response.Message));
        } else {
            yield put(actions.checkDailyPlanCompletionStatusFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.checkDailyPlanCompletionStatusFailed(error.message));
    }
}

function* completeDailyPlanRequestReponse({ payload: { activationID, dailyPlanID, searchData } }) {
    try {
        const response = yield call(getList, `/completeDailyPlanRequest/${activationID}/${dailyPlanID}`);
        if (response.Status) {
            yield put(actions.completeDailyPlanRequestSuccessful(response.Message));
            yield put(actions.getDailyPlanRequestList(searchData));
        } else {
            yield put(actions.completeDailyPlanRequestFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.completeDailyPlanRequestFailed(error.message));
    }
}

function* createBulkActivationForDailyPlanResponse({ payload: { data, searchData } }) {
    try {
        const response = yield call(addData, '/createDailyBulkPlanRequest', data);
        if (response.response.Status) {
            yield put(actions.createBulkActivationForDailyPlanSuccessful(response.response.Message));
            yield put(actions.getDailyPlanRequestList(searchData));
        } else {
            yield put(actions.createBulkActivationForDailyPlanFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.createBulkActivationForDailyPlanFailed(error.message));
    }
}

function* checkValidSerialNumberResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/checkValidSerialNumber', data);
        if (response.Status) {
            yield put(actions.checkValidSerialNumberSuccessful(response.Data));
        } else {
            yield put(actions.checkValidSerialNumberFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.checkValidSerialNumberFailed(error.message));
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.GET_DAILY_PLAN_REQUEST_LIST, getDailyPlanRequestResponse)]);
    yield all([takeEvery(actions.CREATE_DAILY_PLAN_REQUEST, createDailyPlanRequestResponse)]);
    yield all([takeEvery(actions.LOCK_DAILY_PLAN, getLockDailyPlanRequestResponse)]);
    yield all([takeEvery(actions.UNLOCK_DAILY_PLAN, getUnlockDailyPlanRequestReponse)]);
    yield all([takeEvery(actions.GET_MOST_ELIGIBLE_REQUEST, getMostEligibleRequestReponse)]);
    yield all([takeEvery(actions.CANCEL_DAILY_PLAN, getCancelDataResponse)]);
    yield all([takeEvery(actions.CREATE_ACTIVATION_FOR_DAILY_PLAN, createActivationForDailyPlanResponse)]);
    yield all([takeEvery(actions.GET_DAILY_PLAN_OTP, getDailyPlanOtpReponse)]);
    yield all([takeEvery(actions.CHECK_DAILY_PLAN_COMPLETION_STATUS, checkDailyPlanCompletionStatusReponse)]);
    yield all([takeEvery(actions.COMPLETE_DAILY_PLAN_REQUEST, completeDailyPlanRequestReponse)]);
    yield all([takeEvery(actions.CREATE_BULK_ACTIVATION_FOR_DAILY_PLAN, createBulkActivationForDailyPlanResponse)]);
    yield all([takeEvery(actions.CHECK_VALID_SERIAL_NUMBER, checkValidSerialNumberResponse)]);
}