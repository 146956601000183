import React, { useState, useRef, useEffect, useMemo } from 'react';
import CardLayout from '../../../components/utils/CardLayout';
import { useForm } from "react-hook-form";
import moment from "moment";
import DatePicker from "react-datepicker";
import Calendar2LineIcon from 'remixicon-react/Calendar2LineIcon';
import { useDispatch, useSelector } from 'react-redux';
import shortCodeActions from '../../../redux/shortCodeData/actions'
import networkActions from '../../../redux/network/actions';
import authActions from '../../../redux/auth/actions';
import dealerAction from '../../../redux/dealer/actions'
import { isAdmin, isAdminAgent } from '../../../helpers/commonFunction';
import Button from '../../../components/utils/Button';
import SearchBar from '../../../components/utils/SearchBar';
import Table from '../../../components/utils/Table'
import dailyPlanRequestActions from '../../../redux/dailyPlanRequest/actions'
import { convertDateToRequiredFormatTZ, convertDateTimeToRequiredFormatTZ } from '../../../helpers/commonFunction';
import Tag from '../../../components/utils/Tag';
import Styles from './dailyPlanRequestReport.module.css';
import DownloadCloud2sLineIcon from 'remixicon-react/DownloadCloud2LineIcon';
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';


const DailyPlanRequestReport = () => {
    const dispatch = useDispatch();
    const { dailyRequestResult, buttonLoading, dailyRequestListLoading } = useSelector((state) => state.dailyPlanRequest);
    const { getDailyPlanRequestList } = dailyPlanRequestActions;
    const { getDealer } = dealerAction;
    const { getActiveNetworkDAilyPlanMapping } = networkActions;
    const { getShortCode } = shortCodeActions;
    const [filterText, setFilterText] = useState("");
    const { tokenData } = useSelector((state) => state.auth);
    const { shortCodeResult } = useSelector((state) => state.shortCode);
    const { networkResultData } = useSelector((state) => state.network);
    const dealerResult = useSelector((state) => state.dealer.dealerResult);
    const TIMEZONE = process.env.REACT_APP_TIMEZONE;
    const datePickerRef = useRef(null);
    const datePickerRef2 = useRef(null);
    const [toDate, setToDate] = useState(new Date())
    const [fromDate, setFromDate] = useState(new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000));
    const [searchData, setSearchData] = useState({
        statusID: '',
        serialNumber: '',
        fromDate: moment(fromDate).format("YYYY-MM-DD"),
        toDate: moment(toDate).format("YYYY-MM-DD"),
        dealerID: "",
        networkID: ""
    });
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const { register: registerFilter, handleSubmit: handleSubmit, formState: { errors: filterError }, watch, reset, getValues, setValue } = useForm({ defaultValues: searchData });

    const openDatePicker = () => {
        datePickerRef.current.setOpen(true);
    };
    const openDatePicker2 = () => {
        datePickerRef2.current.setOpen(true);
    };
    const handleFilterChange = (value) => {
        setFilterText(value);
    };

    const columns = [
        { displayName: '#', key: 'key' },
        { displayName: 'FROM DATE', key: 'RequestedDtTm' },
        // { displayName: 'AGENT', key: 'agent' },
        { displayName: 'DEALER', key: 'dealer' },
        { displayName: 'STATUS', key: 'Status' },
        { displayName: 'EMAIL', key: 'email' },
        { displayName: 'IMEI', key: 'IMEI' },
        { displayName: 'TRANSACTION ID', key: 'TransactionID' },
        { displayName: 'SERIAL NUMBER', key: 'SerialNumber' },
        { displayName: 'NETWORK', key: 'networkName' },
        { displayName: 'SIM TYPE', key: 'simType' }, ,
        { displayName: 'AMOUNT', key: 'amountCharged' },
        { displayName: 'ZIP CODE', key: 'areaCode' },
        { displayName: 'TO DATE', key: 'toDate' },
        { displayName: 'DAYS', key: 'days' },
        { displayName: 'REMARK', key: 'Remark' },
    ];
    useEffect(() => {
        if (networkResultData.length === 1) {
            setValue('networkID', networkResultData[0].ID);
        }
    }, [networkResultData, setValue]);


    useEffect(() => {
        dispatch(getDealer());
        dispatch(getActiveNetworkDAilyPlanMapping());
        dispatch(getShortCode({
            tableName: "activationrequest",
            fieldName: "RequestStatus"
        }));
        const newData = {
            ...searchData,
            toDate: moment(new Date(toDate)).tz(TIMEZONE).format("YYYY-MM-DD"),
            fromDate: moment(new Date(fromDate)).tz(TIMEZONE).format("YYYY-MM-DD"),
            simType: 25,
        };
        dispatch(getDailyPlanRequestList(newData))
    }, [])

    const onSubmit = (data) => {
        const newData = {
            ...data,
            toDate: moment(new Date(toDate)).format("YYYY-MM-DD"),
            fromDate: moment(new Date(fromDate)).format("YYYY-MM-DD"),
        };
        setSearchData(newData)
        dispatch(getDailyPlanRequestList(newData));
    }

    const filteredItems = dailyRequestResult && dailyRequestResult.length > 0 && dailyRequestResult.filter(
        item => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1
    );

    const data = useMemo(() => {
        if (filteredItems && filteredItems.length) {
            return filteredItems.map((item, key) => {
                return {
                    key: key + 1,
                    TransactionID: item.TransactionID,
                    SerialNumber: item.SerialNumber,
                    networkName: item.NetworkName,
                    dealer: item.DealerName,
                    IMEI: item.IMEI,
                    networkName: item.NetworkName,
                    simType: item.SimTypeValue,
                    email: item.CustomerEmailID,
                    areaCode: item.AreaCode,
                    amountCharged: `$${item.AmountCharged.toFixed(2)}`,
                    days: item.RequestedDays,
                    RequestedDtTm: item.RequestedDtTm !== null ? convertDateTimeToRequiredFormatTZ(item.RequestedDtTm) : '',
                    toDate: item.RequestedToDate !== null ? convertDateToRequiredFormatTZ(item.RequestedToDate) : '',
                    Status: <Tag color={`${(item.RequestStatus === 13) ? 'badge-danger' : (item.RequestStatus === 11) ? 'badge-warning' : (item.RequestStatus === 12) ? 'badge-success' : 'badge-secondary'}`} title={`${(item.RequestStatus === 13) ? 'Cancelled' : (item.RequestStatus === 11) ? 'Processing' : (item.RequestStatus === 12) ? 'Processed' : 'Pending'}`}> </Tag>,
                    Remark: item.Remark,
                }
            });

        } else {
            return [];
        }
    }, [filteredItems]);

    var listData = dailyRequestResult && dailyRequestResult.length > 0 && dailyRequestResult.map((report, key) => {
        const status = (report.RequestStatus === 13) ? 'Cancelled'
            : (report.RequestStatus === 11) ? 'Processing'
                : (report.RequestStatus === 12) ? 'Processed'
                    : 'Pending';

        return {
            'S No.': key + 1,
            'Transaction ID': report.TransactionID,
            'Serial Number': report.SerialNumber,
            'Network Name': report.NetworkName,
            'Dealer Name': report.DealerName,
            'Amount': `$${report.AmountCharged.toFixed(2)}`,
            'Requested Days': report.RequestedDays,
            'Status': status, // Update status based on condition
            'From Date': report.RequestedDtTm !== null ? convertDateTimeToRequiredFormatTZ(report.RequestedDtTm) : '',
            'To Date': report.RequestedToDate !== null ? convertDateToRequiredFormatTZ(report.RequestedToDate) : '',
        };
    });

    const exportToCSV = async () => {
        if (listData.length > 0) {
            const ws = XLSX.utils.json_to_sheet(listData);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            await FileSaver.saveAs(data, 'Daily_plan_request_report' + fileExtension);
        }
    }


    return (
        <>
            <CardLayout title="Daily Plan Request Report">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-row">

                        <div className="col-lg-2 col-md-4 mb-2">
                            <label className='ml-2'> Network <span className='text-danger'>*</span></label>
                            <select
                                className="form-control"
                                placeholder="Select Network"
                                name="networkID"
                                {...registerFilter("networkID", { required: 'Network is required !' })}
                            >
                                <option value="" disabled>Select</option>
                                {networkResultData.length > 0 && networkResultData.map(network => (
                                    <option name="network" value={network.ID} key={network.ID}>
                                        {network.NetworkName}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-lg-2 col-md-4 mb-2">
                            <label>Select Status</label>
                            <select className="form-control" id="statusID" name="statusID" placeholder="Select"  {...registerFilter("statusID", {})}>
                                {shortCodeResult && shortCodeResult.length > 0 && <option value="" name="All">All</option>}
                                {shortCodeResult && shortCodeResult.length > 0 &&
                                    shortCodeResult.filter(item => item.ID !== 27 && item.ID !== 32).map((item, ind) => (<option key={item.ID} value={item.ID}>{item.Value}</option>))
                                }
                            </select>
                        </div>


                        {
                            (isAdmin(tokenData) || isAdminAgent(tokenData)) &&
                            <div className="col-lg-2 col-md-4  mb-2">
                                <label>Select Dealer</label>
                                <select className="form-control" id="dealerID" name="dealerID"  {...registerFilter("dealerID", {
                                })} >
                                    <option value="" name="All">All</option>
                                    {
                                        dealerResult && dealerResult.length && dealerResult.map((item) => {
                                            return <option value={item.ID} key={`dealer${item.ID}`}>{item.DealerName}</option>
                                        })
                                    }
                                </select>
                            </div>
                        }

                        <div className="col-lg-2 col-md-4 mb-2 d-flex flex-column">
                            <label>From Date</label>
                            <Calendar2LineIcon onClick={openDatePicker} size={'1.5rem'} style={{ cursor: 'pointer', position: 'absolute', 'top': '36px', right: '12px', zIndex: 1 }} />
                            <DatePicker
                                ref={datePickerRef}
                                selected={fromDate}
                                className="form-control"
                                onChange={(date) => setFromDate(date)}
                                dateFormat="yyyy-MM-dd"
                                name="fromDate"
                                showMonthDropdown
                                showYearDropdown
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                                value={fromDate}
                            />
                        </div>

                        <div className="col-lg-2 col-md-4 mb-2 d-flex flex-column">
                            <label>To Date</label>
                            <Calendar2LineIcon onClick={openDatePicker2} size={'1.5rem'} style={{ cursor: 'pointer', position: 'absolute', 'top': '36px', right: '12px', zIndex: 1 }} />
                            <DatePicker
                                ref={datePickerRef2}
                                selected={toDate}
                                className="form-control center"
                                onChange={(date) => setToDate(date)}
                                dateFormat="yyyy-MM-dd"
                                name="todate"
                                showMonthDropdown
                                showYearDropdown
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                                value={toDate}
                            />
                        </div>

                        <div className="col-lg-2 col-md-4 mb-2">
                            <label>SIM/Mobile/TXN</label>
                            <input
                                type="text"
                                className="form-control"
                                id="serialNumber"
                                name="serialNumber"
                                placeholder="SIM/Mobile/TXN"
                                {...registerFilter("serialNumber")}
                            />
                        </div>

                        <div className={(isAdmin(tokenData) || isAdminAgent(tokenData)) ? 'col-lg-2 col-md-4 mb-2' : 'col-lg-2 col-md-4'}>
                            <div className={(isAdmin(tokenData) || isAdminAgent(tokenData)) ? "mt-2 d-flex justify-content-start activation-search-btn" : "mt-4"}>
                                <Button title="Search" style="minWidth:80px" buttonClass={`btn btn-primary mt-1 btn-md Activity_searchBtn__GlBtC`} loading={buttonLoading} buttonType='submit' />
                            </div>
                        </div>
                    </div>
                </form>
                <hr />
                <div className='d-flex justify-content-between mt-3'>
                    <SearchBar onFilter={handleFilterChange} filterText={filterText} />
                    <Button icon={<DownloadCloud2sLineIcon color='#fff' className='mr-1 excel-btn-1-aidit ' size={'0.9rem'} />} title="Export" style={{ marginRight: "10px" }} buttonClass={`btn ${Styles.exportBtn}  activation-Export-btn`} buttonStyle="primary" onClick={exportToCSV} />
                </div>
                <div id={'DailyPlanRequestReportTable'}>
                    <Table columns={columns} data={data} loading={dailyRequestListLoading} pagination={true} />
                </div>
            </CardLayout>
        </>
    )
}

export default DailyPlanRequestReport
