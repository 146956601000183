import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import CheckboxCircleLine from 'remixicon-react/CheckboxCircleLineIcon';
import EyeLineIcon from 'remixicon-react/EyeLineIcon';
import EyeOffLineIcon from 'remixicon-react/EyeOffLineIcon';
import { PASSWORD_VALIDATE } from '../helpers/constant';
import AuthActions from '../redux/auth/actions';
import Button from "../components/utils/Button";
import Alert from '../components/utils/Alert';
import Modal from '../components/utils/Model';

// import sideImage from '../assets/images/slide-1.png';
// import sideImagetwo from '../assets/images/slide-2.png';
// import sideImagethree from '../assets/images/slide-3.png';
// import logo from '../assets/images/logo-white.png'

const { changePassword, authReset } = AuthActions;

const ResetPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const Auth = useSelector((state) => state.auth);
    const [userId, setUserId] = useState(0);
    const [token, setToken] = useState('');
    const [passwordShow, setPasswordShow] = useState(false);
    const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
    const { register, handleSubmit, watch, formState: { errors }, } = useForm();

    const password = watch('newPassword', '');

    useEffect(() => {
        const url = window.location.href;
        const parts = url.split('/');
        setUserId(parts[parts.length - 2]);
        setToken(parts[parts.length - 1]);
    }, []);

    const handleChangePassword = (data) => {
        data.customerId = userId;
        data.token = token;
        dispatch(changePassword(data));
    }

    const handleClose = () => {
        dispatch(authReset());
        navigate('/sign-in');
    }

    return (
        <>
            <Modal
                showModal={Auth.changePasswordModal}
                title="Success"
                onHide={handleClose}
                onSaveChanges={handleClose}
                width={''}
                showFooter={false}
                alignment='modal-dialog-centered'
            >
                <div style={Style.modalContent}>
                    <CheckboxCircleLine size="4em" color='#00b894' />
                    <p className='text-dark'>{Auth.changePasswordMessage}</p>
                </div>
            </Modal>

            <section className="sign-in-page mt-4">
                <div className="container  mt-5 pt-5 ">
                    <div className="row no-gutters justify-content-center">
                        <div className="col-sm-4 bg-white p-4 rounded border align-self-center">
                            
                                <a className="sign-in-logo mb-2 text-center d-lg-none d-md-none d-sm-block" href="#"><img src="./assets/images/logo-full2.png" className="img-fluid" alt="logo" /></a>
                                <h2 className="mb-0 font-weight-bold">Reset password</h2>
                                <p className='text-dark'>Enter your new password here.</p>

                                {Auth.changePasswordError ? <Alert type="alert-danger" text={Auth.changePasswordError} /> : ''}

                                <form className="mt-4" onSubmit={handleSubmit(handleChangePassword)}>

                                    <div className="form-group" style={{ position: 'relative' }}>
                                        <label htmlFor="newPassword">New password</label>
                                        <input type={passwordShow ? 'text' : 'password'} className="form-control" id="newPassword" name='newPassword' {...register('newPassword', {
                                            required: true,
                                            validate: (value) => {
                                                return PASSWORD_VALIDATE.test(value)
                                            }
                                        })} placeholder="Enter new password" />
                                        {
                                            passwordShow ? <EyeLineIcon size="1.1em" style={Style.eyeIcon} onClick={() => setPasswordShow((pre) => !pre)} /> : <EyeOffLineIcon size="1.1em" style={Style.eyeIcon} onClick={() => setPasswordShow((pre) => !pre)} />
                                        }
                                        {errors.newPassword && errors.newPassword.type === "required" != '' ? <div className="invalid-feedback">New password is required</div> : ''}
                                        {errors.newPassword && errors.newPassword.type === "validate" != '' ? <div className="invalid-feedback">Invalid password format !</div> : ''}
                                    </div>

                                    <div className="form-group mb-3" style={{ position: 'relative' }}>
                                        <label htmlFor="confirmPassword">Confirm Password</label>
                                        <input type={confirmPasswordShow ? 'text' : 'password'} className="form-control" name='confirmPassword' {...register('confirmPassword', {
                                            required: true,
                                            validate: (value) => {
                                                return value === password;
                                            }
                                        })} placeholder="Confirm Password" />
                                        {
                                            confirmPasswordShow ? <EyeLineIcon size="1.1em" style={Style.eyeIcon} onClick={() => setConfirmPasswordShow((pre) => !pre)} /> : <EyeOffLineIcon size="1.1em" style={Style.eyeIcon} onClick={() => setConfirmPasswordShow((pre) => !pre)} />
                                        }
                                        {errors.confirmPassword && errors.confirmPassword.type === "required" != '' ? <div className="invalid-feedback">Confirm password is required</div> : ''}
                                        {errors.confirmPassword && errors.confirmPassword.type === "validate" != '' ? <div className="invalid-feedback">New password and confirm password not match</div> : ''}
                                    </div>

                                    <div className="d-inline-block w-100">
                                        <Button title='Change Password' buttonClass="btn btn-primary float-right" loading={Auth.changePasswordMessage} buttonType='submit' />
                                    </div>

                                </form>
                            
                        </div>
                        {/* <div className="col-sm-6 text-center d-none d-lg-block d-md-block d-sm-none">
                            <div className="sign-in-detail text-white">
                                <a className="sign-in-logo mb-5" href="#">MVNO Wireless</a> */}

                                {/* <OwlCarousel items={1}>
                                    <div className="item">
                                        <img src={sideImage} className="img-fluid mb-4" alt="logo" />

                                    </div>
                                    <div className="item">
                                        <img src={sideImagetwo} className="img-fluid mb-4" alt="logo" />

                                    </div>
                                    <div className="item">
                                        <img src={sideImagethree} className="img-fluid mb-4" alt="logo" />

                                    </div>
                                </OwlCarousel> */}

                            {/* </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}

const Style = {
    eyeIcon: {
        position: 'absolute',
        right: '10px',
        top: '40px',
        cursor: 'pointer'
    },
    modalContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: 'center'
    }
}

export default ResetPassword