import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import dealerAction from '../../redux/dealer/actions';
import manualAddFundAction from '../../redux/manualAddFund/actions';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '../../components/utils/Alert';
import authAction from '../../redux/auth/actions';
import Select from 'react-select';
import { twoDigitDecimal } from '../../helpers/commonFunction';
import Button from '../../components/utils/Button';

function ManualAddFund() {
    const { getDealer } = dealerAction;
    const { getUserDataByToken } = authAction;
    const { manualAddFund, resetManualAddFund } = manualAddFundAction;
    const dispatch = useDispatch();
    const [filterText, setFilterText] = useState('');
    const [addFormData, setAddFormData] = useState({
        dealerID: '',
        amount: '',
        remarks: ''
    });
    const { register, handleSubmit, watch, formState: { errors }, reset, control, setValue, clearErrors, setError } = useForm({
        defaultValues: addFormData,
    });

    const enteredAmount = watch('amount');

    const dealerResult = useSelector((state) => state.dealer.dealerResult);
    const successMessage = useSelector((state) => state.manualAddFund.manualAddFundMessage);
    const errorMessage = useSelector((state) => state.manualAddFund.manualAddFundError);
    const updateTopUpFlag = useSelector((state) => state.manualAddFund.updateManualTopUpFlag);
    const { loading: buttonLoading } = useSelector((state) => state.manualAddFund);
    const [dealerAmountToShow, setDealerAmountToShow] = useState('0');
    const [dealerName, setDealerName] = useState('');
    const [dealerId, setDealerId] = useState('');
    const [firstChar, setFirstChar] = useState('');
    const [errorMessageForm, setErrorMessageForm] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertText, setAlertText] = useState('');
    const [selectedDealer, setSelectedDealer] = useState(null);

    const defaultOption = { label: 'Select', value: '' };

    useEffect(() => {
        dispatch(getDealer());
    }, [successMessage]);

    useEffect(() => {
        dispatch(getUserDataByToken());
        dispatch(getDealer());
        setDealerAmountToShow('0');
        dispatch(getDealer());
    }, [updateTopUpFlag]);

    useEffect(() => {
        reset(addFormData);
    }, [addFormData]);

    useEffect(() => {
        if (errorMessage !== null && errorMessage !== undefined) {
            displayAlert(errorMessage, 'alert-danger');
        }
        if (successMessage !== null && successMessage !== undefined) {
            displayAlert(successMessage, 'alert-success');
            setAddFormData((prev) => ({
                ...prev,
                dealerID: '',
                amount: '',
                remarks: ''
            }));
            setDealerAmountToShow('0');
        }
    }, [errorMessage, successMessage]);

    const handleChange = (selectedOption) => {
        clearErrors(['dealerID']);
        if (selectedOption) {
            const selectedDealer = dealerResult.find((dealer) => dealer.ID === selectedOption.value);
            setValue('dealerID', [selectedOption.value]);
            setSelectedDealer(selectedDealer);
            setDealerAmountToShow(selectedDealer && selectedDealer.AccountBalance);
            setDealerName(selectedDealer && selectedDealer.DealerName);
            const [firstName] = selectedDealer && selectedDealer.DealerName.split(' ');
            if (firstName) {
                const initials = firstName.charAt(0);
                setFirstChar(initials);
            }
            setDealerId(selectedOption.value);
        }
    };

    const handleNumber = (e) => {
        const { value } = e.target;
        if (!((e.shiftKey || (e.keyCode >= 48 && e.keyCode <= 57)) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 8 || e.keyCode === 190 || e.keyCode === 110)) {
            e.preventDefault();
        }
        if ((e.keyCode === 190 || e.keyCode === 110) && value.includes('.')) {
            e.preventDefault();
        }
        if (value.includes('.')) {
            const parts = value.split('.');
            if (parts[1].length >= 2 && e.keyCode !== 8) {
                e.preventDefault();
            }
        }
    };

    const handleAmountChange = (e) => {
        setErrorMessageForm('');
        clearErrors(['amount']);
    };


    const handleSelectFundType = (fundTypeValue) => {
        if (dealerId == '') {
            setError("dealerID", {
                type: {
                    required: "Dealer required",
                },
            })
            return false;
        } else {

            const data = watch();
            if (fundTypeValue === 1 && data.amount > 0 && data.amount <= 99999 && data.remarks !== '') {
                const submitDealerFund = {
                    dealerId: dealerId,
                    amount: data.amount,
                    fundType: 1,
                    remarks: data.remarks
                };
                dispatch(manualAddFund(submitDealerFund));
            } else if (fundTypeValue === 2 && data.amount > 0 && data.amount <= 99999 && data.remarks !== '') {
                if (fundTypeValue === 2 && Number(data.amount) <= Number(dealerAmountToShow)) {
                    const submitDealerFund = {
                        dealerId: dealerId,
                        amount: data.amount,
                        fundType: 2,
                        remarks: data.remarks
                    };
                    dispatch(manualAddFund(submitDealerFund));
                } else {
                    setErrorMessageForm("Amount not deducted! Must be less than or equal to dealer's specified amount.");
                }
            }

        }
    };

    const displayAlert = (text, type) => {
        reset({
            dealerID: ""
        });
        setAlertText(text);
        setAlertType(type);
        setShowAlert(true);
        dispatch(resetManualAddFund());
    };

    const alertCancel = () => {
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
        dispatch(resetManualAddFund());
    };

    const options = dealerResult.filter(dealer => dealer.ParentID !== 0).map((dealer) => ({
        label: dealer.DealerName,
        value: dealer.ID,
    }));


    return (
        <div id="content-page" className="content-page">
            <div className="container-fluid">
                <div className="row pb-2">
                    <div className="col-sm-12">
                        <div className="row justify-content-center">
                            <div className="col-lg-5 col-md-10 bg-fade">
                                <form onSubmit={handleSubmit(handleSelectFundType)}>
                                    <div className="add-fund-card">
                                        <div className="row ">
                                            <div className="col-lg-12 col-md-12">
                                                <div className="card-head-rebbon" data-label="Add Fund"></div>
                                            </div>
                                        </div>
                                        <div className="row py-2 myt-margin">
                                            <div className="col-lg-12 col-md-12">
                                                {showAlert && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}
                                            </div>

                                            <div className="col-lg-12 col-md-12 select-bar-manul-add-fund">
                                                <Controller
                                                    control={control}
                                                    defaultValue={options.map(c => c.value)}
                                                    name="dealerID"
                                                    rules={{ required: true }}
                                                    render={({ field: { onChange, value, ref } }) => (
                                                        <Select
                                                            className="basic-single"
                                                            placeholder={"Select dealer.."}
                                                            value={options.filter(c => value.includes(c.value))}
                                                            onChange={handleChange}
                                                            options={options}
                                                            isSearchable={true}
                                                        />
                                                    )}
                                                />
                                                {errors.dealerID && (<div className="invalid-feedback">Please Select dealer !</div>)}
                                            </div>
                                        </div>
                                        <div className="row ppt-tp">
                                            <div className="col">
                                                <div className='cur-cls'>
                                                    <h3>Current</h3>
                                                </div>
                                                <div className="ticket-card">
                                                    <span className="dolor-ico">$</span>
                                                    <span className="dolor-nun">{twoDigitDecimal(dealerAmountToShow)}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row pt-3 pb-2">
                                            <div className="col cirle-name-div">
                                                <div className="name-cirle">
                                                    <p style={{ margin: 'unset' }}>{firstChar}</p>
                                                </div>
                                                <p className='mb-0'>{dealerName}</p>
                                            </div>
                                        </div>
                                        <div className="row pb-2">
                                            <div className="col-md-12">
                                                <form className='text-center'>
                                                    <label className="amout-label-name">Amount</label>
                                                    <div className='d-flex justify-content-center align-items-center'>
                                                        <span className='dol-ico'>$</span>
                                                        <input
                                                            className="txrt-form trxriput-frm "
                                                            type="number"
                                                            name="amount"
                                                            style={style.amountInputBox}
                                                            placeholder="00.00"
                                                            {...register("amount", {
                                                                required: true,
                                                                min: 0.01,
                                                                max: 99999,
                                                            })}
                                                            onChange={handleAmountChange}
                                                            onKeyDown={handleNumber}
                                                        />
                                                    </div>
                                                    {errorMessageForm && <div className="invalid-feedback">{errorMessageForm}</div>}
                                                    {errors.amount?.type === "required" && (<div className="invalid-feedback">Amount is required !</div>)}
                                                    {errors.amount?.type === "min" && (<div className="invalid-feedback">Amount must be greater than zero!</div>)}
                                                    {errors.amount?.type === "max" && (<div className="invalid-feedback">Amount must be less than 99999!</div>)}
                                                    <div className="col-md-12 mt-4">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Remark"
                                                            name="remarks"
                                                            {...register("remarks", {
                                                                required: true
                                                            })}
                                                        />
                                                        {errors.remarks?.type === "required" && (
                                                            <div className="invalid-feedback">Remark is required !</div>
                                                        )}
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center pt-4 pb-1  btn-manul-add-fund-row">
                                            <div className="col-lg-6 col-md-12 button-fist-p font-weight-bold mb-3 mb-lg-0">
                                                <Button title={'Deduct'} onClick={() => handleSelectFundType(2)} buttonClass="add-fund-btn-do" loading={buttonLoading} buttonType='submit' />
                                            </div>
                                            <div className="col-lg-6 col-md-12 button-fist-p font-weight-bold mb-3 mb-lg-0">
                                                <Button title={'Add Top Up'} onClick={() => handleSelectFundType(1)} style={style.addOnButton} buttonClass="check-btn-dolor" loading={buttonLoading} buttonType='submit' />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const style = {
    addOnButton: {
        background: "#27b345"
    },
    amountInputBox: {
        borderBottom: "1px solid",
        borderWidth: "thin",
        // width:"150px"
    }
}

export default ManualAddFund;