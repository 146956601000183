const actions = {
    GET_MANUAL_ACTIVATION_REPORT: "GET_MANUAL_ACTIVATION_REPORT",
    GET_MANUAL_ACTIVATION_REPORT_SUCCESS: "GET_MANUAL_ACTIVATION_REPORT_SUCCESS",
    GET_MANUAL_ACTIVATION_REPORT_FAILED: "GET_MANUAL_ACTIVATION_REPORT_FAILED",

    getManualActivationReport: (reportingdata) => ({
        type: actions.GET_MANUAL_ACTIVATION_REPORT,
        payload: {reportingdata},
    }),
    getManualActivationReportSuccess: (manualActivationReport) => ({
        type: actions.GET_MANUAL_ACTIVATION_REPORT_SUCCESS,
        payload: {manualActivationReport}
    }),
    getManualActivationReportFailed: (error) => ({
        type: actions.GET_MANUAL_ACTIVATION_REPORT_FAILED,
        error
    }),

}

export default actions;