import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData, getList } from '../../helpers/fackBackend_Helper';

function* getAddonWiseMappingResponse() {
    try {
        const response = yield call(getList, '/getAllNetworkAddon');
        if (response.Status) {
            yield put(actions.getAddonWiseMappingSuccess(response.Data));
        }
        else {
            yield put(actions.getAddonWiseMappingFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.getAddonWiseMappingFailed(error.Message));
    }
}

function* getAllAddonPriceMappingDataResponse() {
    try {
        const response = yield call(getList, '/addonPriceMapping');
        if (response.Status) {
            yield put(actions.getAllAddonPriceMappingDataSuccess(response.Data));
        } else {
            yield put(actions.getAllAddonPriceMappingDataFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.getAllAddonPriceMappingDataFailed(error.Message));
    }
}

function* updateAddonPriceMappingDataResponse({ data }) {
    try {
        const response = yield call(addData, '/addonPriceMapping', data);
        if (response.Status) {
            const responseList = yield call(getList, '/addonPriceMapping');
            if (responseList.Status) {
                yield put(actions.updateAddonPriceMappingDataSuccess(response.Data[0].Message, responseList.Data));
            }
            else {
                yield put(actions.getAddonWiseMappingFailed(response.Data[0].Message));
            }

        } else {
            yield put(actions.updateAddonPriceMappingDataFailed(response.Data[0].Message));
        }
    } catch (error) {
        yield put(actions.updateAddonPriceMappingDataFailed(error.Message));
    }
}


export default function* rootSaga() {
    yield all([takeEvery(actions.GET_ADDON_WISE_MAPPING, getAddonWiseMappingResponse)]);
    yield all([takeEvery(actions.GET_ALL_ADDON_PRICE_MAPPING_DATA, getAllAddonPriceMappingDataResponse)]);
    yield all([takeEvery(actions.UPDATE_ADDON_PRICE_MAPPING_DATA, updateAddonPriceMappingDataResponse)]);
}