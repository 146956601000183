const actions = {
    OPEN_ASSET_MODAL: "OPEN_ASSET_MODAL",
    RESET_ASSET: "RESET_ASSET",
    OPEN_LOCATION_MODAL: "OPEN_LOCATION_MODAL",
    GET_LOCATION: "GET_LOCATION",
    GET_LOCATION_SUCCESS: "GET_LOCATION_SUCCESS",
    GET_LOCATION_FAILED: "GET_LOCATION_FAILED",
    ADD_LOCATION: "ADD_LOCATION",
    ADD_LOCATION_SUCCESS: "ADD_LOCATION_SUCCESS",
    ADD_LOCATION_FAILED: "ADD_LOCATION_FAILED",
    UPDATE_LOCATION: "UPDATE_LOCATION",
    UPDATE_LOCATION_SUCCESS: "UPDATE_LOCATION_SUCCESS",
    UPDATE_LOCATION_FAILED: " UPDATE_LOCATION_FAILED",
    DELETE_LOCATION: "DELETE_LOCATION",
    DELETE_LOCATION_SUCCESS: "DELETE_LOCATION_SUCCESS",
    DELETE_LOCATION_FAILED: "DELETE_LOCATION_FAILED",
    GET_ASSET: "GET_ASSET",
    GET_ASSET_SUCCESS: "GET_ASSET_SUCCESS",
    GET_ASSET_FAILED: "GET_ASSET_FAILED",
    ADD_ASSET: " ADD_ASSET",
    ADD_ASSET_SUCCESS: " ADD_ASSET_SUCCESS",
    ADD_ASSET_FAILED: " ADD_ASSET_FAILED",
    UPDATE_ASSET: "UPDATE_ASSET",
    UPDATE_ASSET_SUCCESS: "UPDATE_ASSET_SUCCESS",
    UPDATE_ASSET_FAILED: "UPDATE_ASSET_FAILED:",
    DELETE_ASSET: "DELETE_ASSET",
    DELETE_ASSET_SUCCESS: "DELETE_ASSET_SUCCESS",
    UPDATE_JAR: "UPDATE_JAR",
    UPDATE_JAR_SUCCESS: "UPDATE_JAR_SUCCESS",


    openAddAssetModal: () => ({
        type: actions.OPEN_ASSET_MODAL,
    }),
    resetAsset: () => ({
        type: actions.RESET_ASSET,
    }),
    openAddLocationModal: () => ({
        type: actions.OPEN_LOCATION_MODAL,
    }),
    getLocation: () => ({
        type: actions.GET_LOCATION,
    }),
    getLocationSuccess: (locationData) => ({
        type: actions.GET_LOCATION_SUCCESS,
        locationData
    }),
    getLocationFailed: (error) => ({
        type: actions.GET_LOCATION_FAILED,
        error
    }),
    addLocation: (locationData) => ({
        type: actions.ADD_LOCATION,
        payload: { locationData }
    }),
    addLocationSuccess: (message, locationData) => ({
        type: actions.ADD_LOCATION_SUCCESS,
        message, locationData
    }),
    addLocationFailed: (error) => ({
        type: actions.ADD_LOCATION_FAILED,
        error
    }),
    updateLocation: (location, locationId) => ({
        type: actions.UPDATE_LOCATION,
        payload: { location, locationId }
    }),
    updateLocationSuccess: (message, data) => ({
        type: actions.UPDATE_LOCATION_SUCCESS,
        message,
        data
    }),
    updateLocationFailed: (error) => ({
        type: actions.UPDATE_LOCATION_FAILED,
        error
    }),
    deleteLocation: (locationId, status) => ({
        type: actions.DELETE_LOCATION,
        payload: { locationId, status }
    }),
    deleteLocationSuccess: (message, locationData) => ({
        type: actions.DELETE_LOCATION_SUCCESS,
        message,
        locationData
    }),
    getAsset: (assetType) => ({
        type: actions.GET_ASSET,
        payload: { assetType }
    }),
    getAssetSuccess: (assetData) => ({
        type: actions.GET_ASSET_SUCCESS,
        assetData
    }),
    getAssetFailed: (error) => ({
        type: actions.GET_ASSET_FAILED,
        error
    }),
    addAsset: (assetData) => ({
        type: actions.ADD_ASSET,
        payload: { assetData }
    }),
    addAssetSuccess: (assetData,message) => ({
        type: actions.ADD_ASSET_SUCCESS,
        assetData,
        message
    }),
    addAssetFailed: (error) => ({
        type: actions.ADD_ASSET_FAILED,
        error
    }),
    updateAsset: (assetData,assetId) => ({
        type: actions.UPDATE_ASSET,
        payload: { assetData,assetId}
    }),
    updateAssetSuccess: (message,assetData) => ({
        type: actions.UPDATE_ASSET_SUCCESS,
        message,
        assetData
    }),
    updateAssetFailed: (error) => ({
        type: actions.UPDATE_ASSET_FAILED,
        error
    }),
    deleteAsset: (assetId, status) => ({
        type: actions.DELETE_ASSET,
        payload: { assetId, status }
    }),
    deleteAssetSuccess: (message, assetData) => ({
        type: actions.DELETE_ASSET_SUCCESS,
        message,
        assetData
    }),
    updateJar: (assetId, status) => ({
        type: actions.UPDATE_JAR,
        payload: { assetId, status }
    }),
    updateJarSuccess: (message, assetData) => ({
        type: actions.UPDATE_JAR_SUCCESS,
        message,
        assetData
    }),

}
export default actions;