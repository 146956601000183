const actions = {
    GET_RECHARGE_PLAN: 'GET_RECHARGE_PLAN',
    GET_RECHARGE_PLAN_SUCCESS: 'GET_RECHARGE_PLAN_SUCCESS',
    GET_RECHARGE_PLAN_FAILED: 'GET_RECHARGE_PLAN_FAILED',
    OPEN_RECHARGE_PLAN_MODAL: 'OPEN_RECHARGE_PLAN_MODAL',
    CLOSE_RECHARGE_PLAN_MODAL: 'CLOSE_RECHARGE_PLAN_MODAL',
    ADD_RECHARGE_PLAN: 'ADD_RECHARGE_PLAN',
    ADD_RECHARGE_PLAN_SUCCESS: 'ADD_RECHARGE_PLAN_SUCCESS',
    ADD_RECHARGE_PLAN_FAILED: 'ADD_RECHARGE_PLAN_FAILED',
    UPDATE_RECHARGE_PLAN: 'UPDATE_RECHARGE_PLAN',
    UPDATE_RECHARGE_PLAN_SUCCESS: 'UPDATE_RECHARGE_PLAN_SUCCESS',
    UPDATE_RECHARGE_PLAN_FAILED: 'UPDATE_RECHARGE_PLAN_FAILED',
    DELETE_RECHARGE_PLAN: 'DELETE_RECHARGE_PLAN',
    DELETE_RECHARGE_PLAN_SUCCESS: 'DELETE_RECHARGE_PLAN_SUCCESS',
    DELETE_RECHARGE_PLAN_FAILED: 'DELETE_RECHARGE_PLAN_FAILED',
    RESET_RECHARGE_PLAN_MESSAGE: 'RESET_RECHARGE_PLAN_MESSAGE',
    OPEN_PREFERED_AGGREGATOR_MODAL_RECHARGE: "OPEN_PREFERED_AGGREGATOR_MODAL_RECHARGE",
    CLOSE_PREFERED_AGGREGATOR_MODAL_RECHARGE: "CLOSE_PREFERED_AGGREGATOR_MODAL_RECHARGE",
    UPDATE_PREFFERED_AGGREGATOR_RECHARGE: "UPDATE_PREFFERED_AGGREGATOR_RECHARGE",
    UPDATE_PREFFERED_AGGREGATOR_RECHARGE_SUCCESS: "UPDATE_PREFFERED_AGGREGATOR_RECHARGE_SUCCESS",
    UPDATE_PREFFERED_AGGREGATOR_RECHARGE_FAILED: 'UPDATE_PREFFERED_AGGREGATOR_RECHARGE_FAILED',
    
    updatePreffedAggregatorRecharge: (data) => ({
        type: actions.UPDATE_PREFFERED_AGGREGATOR_RECHARGE,
        payload: { data }
    }),
    updatePreffedAggregatorRechargeSuccess: (message) => ({
        type: actions.UPDATE_PREFFERED_AGGREGATOR_RECHARGE_SUCCESS,
        payload: { message }
    }),
    updatePreffedAggregatorRechargeFailed: (error) => ({
        type: actions.UPDATE_PREFFERED_AGGREGATOR_RECHARGE_FAILED,
        payload: { error }
    }),
    
    getRechargePlan: () => ({
        type: actions.GET_RECHARGE_PLAN,
    }),
    getRechargePlanSuccess: (data) => ({
        type: actions.GET_RECHARGE_PLAN_SUCCESS,
        payload: { data }
    }),
    getRechargePlanFailed: (error) => ({
        type: actions.GET_RECHARGE_PLAN_FAILED,
        payload: { error }
    }),

    openRechargePlanModal: () => ({
        type: actions.OPEN_RECHARGE_PLAN_MODAL,
    }),
    closeRechargePlanModal: () => ({
        type: actions.CLOSE_RECHARGE_PLAN_MODAL,
    }),

    addRechargePlan: (data) => ({
        type: actions.ADD_RECHARGE_PLAN,
        payload: { data }
    }),
    addRechargePlanSuccess: (message) => ({
        type: actions.ADD_RECHARGE_PLAN_SUCCESS,
        payload: { message }
    }),
    addRechargePlanFailed: (error) => ({
        type: actions.ADD_RECHARGE_PLAN_FAILED,
        payload: { error }
    }),


    updateRechargePlan: (data) => ({
        type: actions.UPDATE_RECHARGE_PLAN,
        payload: { data }
    }),
    updateRechargePlanSuccess: (message) => ({
        type: actions.UPDATE_RECHARGE_PLAN_SUCCESS,
        payload: { message }
    }),
    updateRechargePlanFailed: (error) => ({
        type: actions.UPDATE_RECHARGE_PLAN_FAILED,
        payload: { error }
    }),


    deleteRechargePlan: (planId, status) => ({
        type: actions.DELETE_RECHARGE_PLAN,
        payload: { planId, status }
    }),
    deleteRechargePlanSuccess: (planMessage) => ({
        type: actions.DELETE_RECHARGE_PLAN_SUCCESS,
        planMessage
    }),
    deleteRechargePlanFailed: (error) => ({
        type: actions.DELETE_RECHARGE_PLAN_FAILED,
        payload: { error }
    }),

    resetRechargePlanMessage: () => ({
        type: actions.RESET_RECHARGE_PLAN_MESSAGE,
    }),

    openPreferedAggregatorModalRecharge: () => ({
        type: actions.OPEN_PREFERED_AGGREGATOR_MODAL_RECHARGE,
    }),
    closePreferedAggregatorModalRecharge: () => ({
        type: actions.CLOSE_PREFERED_AGGREGATOR_MODAL_RECHARGE,
    }),

}
export default actions;