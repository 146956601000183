import React, { useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import ArrowLeftLineIcon from 'remixicon-react/ArrowLeftLineIcon';
import { Link } from "react-router-dom";
import AuthActions from '../redux/auth/actions';
import Button from "../components/utils/Button";
import Alert from '../components/utils/Alert';
// import sideImage from '../assets/images/slide-1.png';
// import sideImagetwo from '../assets/images/slide-2.png';
// import sideImagethree from '../assets/images/slide-3.png';
// import logo from '../assets/images/logo-white.png'
import mailIcon from '../assets/images/mail.png'
import Long from '../../src/assets/images/Long.png'

const { forgetPassword, authReset } = AuthActions;

const ForgetPassword = () => {
    const dispatch = useDispatch();
    const Auth = useSelector((state) => state.auth);
    const { register, handleSubmit, formState: { errors }, } = useForm();
    useEffect(() => {
        dispatch(authReset());
    }, []);

    const handleForgetPassword = (data) => {
        dispatch(forgetPassword(data));
    }
    // style={style.backbtn}

    return (
        <>
            <section className="sign-in-page mt-4">


                <div className="container  mt-5 pt-5  ">
                    {/* {
                        !Auth.forgetPasswordMessage ?
                        <Link to={'/sign-in'} style={style.backbtn}>
                            <ArrowLeftLineIcon color="#294c28eb" size={30} />
                        </Link> : ''
                    } */}
                    <div className="row no-gutters justify-content-center">

                        <div className="col-sm-4 bg-white p-4 rounded border align-self-center" >
                            
                            {
                                !Auth.forgetPasswordMessage ?
                                    <Link to={'/sign-in'}>
                                        <ArrowLeftLineIcon color="#294c28eb" size={30} />
                                    </Link> : ''
                            }

                            {
                                Auth.forgetPasswordMessage ?

                                    <div >

                                        <img src={mailIcon} width="80" alt="" />
                                        <h1 class="mt-3 mb-0">Success !</h1>
                                        <p>Please check your email to reset your password</p>
                                        <div class="d-inline-block w-100">
                                            <Link to='/sign-in' class="btn btn-primary mt-3 text-white">Back to Home</Link>
                                        </div>
                                    </div>

                                    :

                                    <div>
                                        <div className='d-flex align-item-center justify-content-center mb-2' style={{marginTop: "-53px",paddingTop:"5px"}}>
                                            <a className='text-center ' href="/">
                                                <img src={Long} alt="Logo" style={{ height: "85px" }} />
                                            </a>
                                        </div>

                                        {/* <a className="sign-in-logo mb-2 text-center d-lg-none d-md-none d-sm-block" href="#"><img src="./assets/images/logo-full2.png" className="img-fluid" alt="logo" /></a> */}
                                        <h2 className="mb-0 mt-4 font-weight-bold text-center" style={{fontSize:"22px"}}>Forget Password</h2>
                                        <p className='text-dark text-center'style={{fontSize:"13px", borderBottom: "2px solid #050405"}}>Enter your username and we'll send you an email with instructions to reset your password.</p>
                                        {Auth.forgetPasswordError ? <Alert type="alert-danger" text={Auth.forgetPasswordError} /> : ''}
                                        <form className="mt-4" onSubmit={handleSubmit(handleForgetPassword)}>
                                            <div className="form-group">
                                                <label htmlFor="username">Username</label>
                                                <input type="text" className="form-control" name='username' {...register('username', {
                                                    required: true
                                                })} id="username" placeholder="Enter username" />
                                                {errors.username && errors.username.type === "required" != '' ? <div className="invalid-feedback">Username is required</div> : ''}
                                            </div>

                                            <div className="d-inline-block w-100">
                                                <Button title='Reset Password' buttonClass="btn btn-primary float-right" loading={Auth.forgetPasswordLoading} buttonType='submit' />
                                            </div>

                                        </form>
                                    </div>


                            }
                        </div>
                        {/* <div className="col-md-6 text-center d-none d-lg-block d-md-block d-sm-none">
                            <div className="sign-in-detail text-white">
                                <a className="sign-in-logo mb-5" href="#">MVNO Wireless</a>

                                <OwlCarousel items={1}>
                                    <div className="item">
                                        <img src={sideImage} className="img-fluid mb-4" alt="logo" />

                                    </div>
                                    <div className="item">
                                        <img src={sideImagetwo} className="img-fluid mb-4" alt="logo" />

                                    </div>
                                    <div className="item">
                                        <img src={sideImagethree} className="img-fluid mb-4" alt="logo" />

                                    </div>
                                </OwlCarousel> 

                            </div>
                        </div>*/}

                    </div>
                </div>
            </section>
        </>
    )
}



export default ForgetPassword