import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData, getList } from '../../helpers/fackBackend_Helper';

function* releaseLockSimSwapRequestResponse({ payload: { data,searchData } }) {
    try {
        const response = yield call(addData, '/releaseLockSimSwapRequest', data);
        if (response.Status) {
            yield put(actions.releaseLockSimSwapRequestSuccess(response.Message));
            yield put(actions.getSimSwapRequest(searchData));
        } else {
            yield put(actions.releaseLockSimSwapRequestFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.releaseLockSimSwapRequestFailed(error.Message));
    }
}

function* lockSimSwapRequestResponse({ payload: { data,searchData } }) {
    try {
        const response = yield call(addData, '/lockSimSwapRequest', data);
        if (response.Status) {
            yield put(actions.lockSimSwapRequestSuccess(response.Message));
            yield put(actions.getSimSwapRequest(searchData));
        } else {
            if (response.LockedByUser) {
                 yield put(actions.lockSimSwapRequestFailed(`${response.Message} by ${response.LockedByUser}`));
            } else {
                yield put(actions.lockSimSwapRequestFailed(response.Message));
            }
        }
    } catch (error) {
        yield put(actions.lockSimSwapRequestFailed(error.Message));
    }
}

function* getSimSwapRequestResponse({ payload: { searchData } }) {
    try {
        const response = yield call(addData, '/simSwapRequestList',searchData);
        if (response.Status) {
            yield put(actions.getSimSwapRequestSuccess(response.Data));
        } else {
            yield put(actions.getSimSwapRequestFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.getSimSwapRequestFailed(error.Message));
    }
}

function* getActivationDetailsResponse({ payload: { searchData } }) {
    try {
        const response = yield call(addData, '/activationDetailsByMsisdn',searchData);
        if (response.Status) {
            yield put(actions.getActivationDetailsByMsisdnSuccess(response.Data));
        } else {
            yield put(actions.getActivationDetailsByMsisdnFailed('No data available'));
        }
    } catch (error) {
        yield put(actions.getActivationDetailsByMsisdnFailed(error.Message));
    }
}

function* createSimSwapRequestResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/simSwapRequest',data);
        if (response.Status) {
            yield put(actions.createSIMSwapRequestSuccess(response.Data));

        } else {
            yield put(actions.createSIMSwapRequestFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.createSIMSwapRequestFailed(error.Message));
    }
}

function* cancelSimSwapRequestResponse({ payload: { data,searchData } }) {
    try {
        const response = yield call(addData, '/cancelSimSwapRequest', data);
        if (response.Status) {
            yield put(actions.cancelSimSwapRequestSuccess(response.Message));
            yield put(actions.getSimSwapRequest(searchData));
        } else {
            yield put(actions.cancelSimSwapRequestFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.lockSimSwapRequestFailed(error.Message));
    }
}

function* completeSimSwapRequestResponse({ payload: { data ,searchData} }) {
    try {
        const response = yield call(addData, '/completeSimSwapRequest', data);
        if (response.Status) {
            yield put(actions.completeSimSwapRequestSuccess(response.Message));
            yield put(actions.getSimSwapRequest(searchData));
        } else {
            yield put(actions.completeSimSwapRequestFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.completeSimSwapRequestFailed(error.Message));
    }
}

function* getOtpResponse({ payload: { swapId} }) {
    try {
        const response = yield call(getList, '/getSwapOtp/'+ swapId);
        if (response.response.Status) {
            yield put(actions.getOTPSuccess(response.response.Data));
        } else {
            yield put(actions.getOTPFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getOTPFailed(error.Message));
    }
}

function* checkSimSwapCompletion({ payload: { swapId} }) {
    try {
        const response = yield call(getList, '/checkSimSwapCompletionStatus/'+ swapId);
        if (response.Status) {
            yield put(actions.checkSimSwapCompletionSuccess(response.Message));
        } else {
            yield put(actions.checkSimSwapCompletionFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.checkSimSwapCompletionFailed(error.Message));
    }
}



export default function* rootSaga() {
    yield all([takeEvery(actions.GET_SIM_SWAP_REQUEST, getSimSwapRequestResponse)]);
    yield all([takeEvery(actions.LOCK_SIM_SWAP_REQUEST, lockSimSwapRequestResponse)]);
    yield all([takeEvery(actions.RELEASE_LOCK_SIM_SWAP_REQUEST, releaseLockSimSwapRequestResponse)]);
    yield all([takeEvery(actions.CANCEL_SIM_SWAP_REQUEST, cancelSimSwapRequestResponse)]);
    yield all([takeEvery(actions.COMPLETE_SIM_SWAP_REQUEST, completeSimSwapRequestResponse)]);
    yield all([takeEvery(actions.GET_OTP, getOtpResponse)]);
    yield all([takeEvery(actions.CHECK_SIM_SWAP_COMPLETION, checkSimSwapCompletion)]);
    yield all([takeEvery(actions.GET_ACTIVATION_DETAILS_BY_MSISDN, getActivationDetailsResponse)]);
    yield all([takeEvery(actions.CREATE_SIM_SWAP_REQUEST, createSimSwapRequestResponse)]);


}