import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CardLayout from '../../components/utils/CardLayout'
import SearchBar from '../../components/utils/SearchBar';
import SideButtons from '../../components/utils/SideButtons';
import Alert from '../../components/utils/Alert';
import Table from '../../components/utils/Table';
import Modal from '../../components/utils/Model';
import Button from '../../components/utils/Button';
import { useForm } from "react-hook-form";
import AddonServiceActions from '../../redux/addonService/actions'
import { Link } from 'react-router-dom';
import CheckboxCircleFill from 'remixicon-react/CheckboxCircleFillIcon';
import PencilFill from 'remixicon-react/PencilFillIcon';
import DeleteBin5Fill from 'remixicon-react/DeleteBin5FillIcon';
import Popconfirm from '../../components/utils/Popover';
import Tag from '../../components/utils/Tag';
import networkActions from '../../redux/network/actions';
import authActions from '../../redux/auth/actions';
import { isAdmin } from '../../helpers/commonFunction';
function AddonService() {
    const dispatch = useDispatch();
    const { getActiveNetwork } = networkActions;
    const { getUserDataByToken } = authActions;
    const { getAddonService, addAddonService, editAddonService, deleteAddonService, resetAddonService, prepareAddonForm, closeAddonForm } = AddonServiceActions
    const { addonResult, loading, buttonLoading, deleteButtonLoading, addonFormModal, addonError, addonMessage } = useSelector((state) => state.addonService);
    const { networkResult } = useSelector((state) => state.network);
    const { tokenData } = useSelector((state) => state.auth);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertText, setAlertText] = useState('');
    const [filterText, setFilterText] = useState("");
    const [formTitle, setFormTitle] = useState('');
    const [formButton, setFormButton] = useState('');
    const [editFlag, setEditFlag] = useState(false);
    const [formModal, setformModal] = useState(false);
    const [addonItemId, setAddonItemId] = useState()
    const [addonServiceData, setAddonServiceData] = useState({
        serviceName: "",
        servicePrice: "",
        networkID: "",
        tMobileAddonID: ""
    })

    const emptyData = {
        addonID: "",
        serviceName: "",
        servicePrice: "",
        networkID: "",
        tMobileAddonID: ""
    }

    const { register, handleSubmit, formState: { errors }, watch, reset: resetForm, control, setValue, } = useForm({
        defaultValues: addonServiceData,
    });

    let columns = [
        { displayName: '#', key: 'key' },
        { displayName: 'SERVICE NAME', key: 'serviceName' },
        { displayName: 'SERVICE PRICE', key: 'servicePrice' },
        { displayName: 'NETWORK', key: 'network' },
        // { displayName: 'T-MOBILE ADDON ID', key: 'tMobileAddonID' },
        isAdmin(tokenData) ? { displayName: 'ACTION', key: 'action' } : null,
    ];

    columns = columns.filter(column => column !== null);

    useEffect(() => {
        dispatch(getAddonService());
        dispatch(getActiveNetwork());
        dispatch(getUserDataByToken());
    }, [])

    useEffect(() => {
        if (editFlag === true) {
            resetForm(addonServiceData);
        }
    }, [addonServiceData, editFlag]);

    useEffect(() => {
        if (editFlag === false) {
            resetForm(addonServiceData);
        }
    }, [addonServiceData])

    useEffect(() => {
        if (addonError !== null && addonError !== undefined) {
            displayAlert(addonError, 'alert-danger');
        }
        if (addonMessage !== null && addonMessage !== undefined) {
            displayAlert(addonMessage, 'alert-success');
        }
    }, [addonError, addonMessage]);

    const displayAlert = (text, type) => {
        setAlertText(text);
        setAlertType(type);
        setShowAlert(true);
    };

    const alertCancel = () => {
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
        dispatch(resetAddonService());
    }

    const filteredItems = addonResult && addonResult.length > 0 && addonResult.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    var dataSource = filteredItems && filteredItems.length > 0 && filteredItems.map((addon, key) => {
        var actionContent = (
            <>
                <Link to="#">
                    <span data-toggle="tooltip" data-placement="top" title="Edit">
                        <PencilFill size="1.2em" style={{ color: "#888b8e", marginRight: "3px" }} onClick={() => handleOpenEditFormModal(addon.ID)} />
                    </span>
                </Link>
                {deleteButtonLoading && addonItemId === addon.ID ? <span className="spinner-border spinner-border-sm" role="status"></span> : addon.IsActive === 1 ? (
                    <Popconfirm
                        title="Are you sure to inactive this addon?"
                        okText="Yes"
                        cancelText="No"
                        placement="topRight"
                        onConfirm={() => handleDelete(addon.ID, 0)}
                    >
                        <Link className="deleteBtn" to="# ">
                            <span data-toggle="tooltip" data-placement="top" title="Inactive">
                                <DeleteBin5Fill size="1.2em" style={{ color: "#f14336", marginRight: "3px" }} />
                            </span>
                        </Link>
                    </Popconfirm>
                ) : (
                        <Popconfirm
                            title="Are you sure to active this addon?"
                            okText="Yes"
                            cancelText="No"
                            placement="topRight"
                            onConfirm={() => handleDelete(addon.ID, 1)}
                        >
                            <Link className="deleteBtn" to="# ">
                            <span data-toggle="tooltip" data-placement="top" title="Active">
                                <CheckboxCircleFill size="1.2em" style={{ color: "#2cb549", marginRight: "3px" }} />
                                </span>
                            </Link>
                        </Popconfirm>
                )}
                {/* <Link to="#" onClick={() => handleShowDealerDetails(plan.ID, plan)} >
                    <span data-toggle="tooltip" data-placement="top" title="View">
                        <EyeFill size="1.2em" style={{ color: "#0094b3" }} />
                    </span>
                </Link> */}
            </>
        );


        return {
            key: key + 1,
            serviceName: addon.ServiceName,
            servicePrice: `$${addon.ServicePrice}`,
            network: addon.NetworkName,
            tMobileAddonID: addon.TMobileAddonID,
            status: <Tag color={`${addon.IsActive === 1 ? "badge-success" : "badge-danger"}`} title={addon.IsActive === 1 ? 'Active' : 'Inactive'} />,
            action: isAdmin(tokenData) && actionContent,
        }
    });

    const handleFilterChange = (value) => {
        setFilterText(value);
    };

    const handleOpenAddFormModal = () => {
        dispatch(prepareAddonForm());
        setAddonServiceData(emptyData);
        setFormButton('Submit')
        setFormTitle('Add Addon Service')
    }

    const handleOpenEditFormModal = (ID) => {
        dispatch(prepareAddonForm());
        setFormButton('Update')
        setFormTitle('Edit Addon Service')
        var selectedAddon = addonResult.filter(addon => addon.ID === ID);
        setAddonServiceData(prevState => ({
            ...prevState,
            addonID: selectedAddon[0].ID,
            serviceName: selectedAddon[0].ServiceName,
            servicePrice: selectedAddon[0].ServicePrice,
            networkID: selectedAddon[0].NetworkID,
            tMobileAddonID: selectedAddon[0].TMobileAddonID
        }));
    }

    const onSubmit = (data) => {
        dispatch(addAddonService(data));
    }

    const handleUpdate = (data) => {
        dispatch(addAddonService(data));
    }

    const handleCancel = () => {
        dispatch(closeAddonForm());
    }

    const handleDelete = (ID, flag) => {
        setAddonItemId(ID)
        dispatch(deleteAddonService(ID, flag));
    }
    return (
        <CardLayout title="Addon Service">
            {showAlert && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}
            <div className="row">
                <div className='col-lg-6 col-md-6  od-2'>
                    <SearchBar onFilter={handleFilterChange} filterText={filterText} />
                </div>
                <div className='col-lg-6 col-md-6  text-right'>
                    {isAdmin(tokenData) && <SideButtons title="Add Addon" buttonStyle="primary" onClick={handleOpenAddFormModal} />}
                </div>
            </div>
            <Table columns={columns} loading={loading} data={dataSource} pagination={true} />
            <Modal
                showModal={addonFormModal}
                title={formTitle}
                onHide={handleCancel}
                width={700}
                showFooter={false}
            >
                <form onSubmit={handleSubmit(editFlag ? handleUpdate : onSubmit)}>
                    <div className="form-row">
                        <div className="col-md-4 mb-3">
                            <label>Service Name</label>
                            <input type="text" placeholder="Enter Service Name" className="form-control"
                                name="serviceName" {...register("serviceName", {
                                    required: true,
                                })} />
                            {errors.serviceName?.type === "required" && (
                                <div className="invalid-feedback">Service name is required !</div>

                            )}
                        </div>
                        <div className="col-md-4 mb-3">
                            <label>Service Price</label>
                            <input type="number" className="form-control" placeholder="Enter Service Price" onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} name="servicePrice" {...register("servicePrice", {
                                required: true,
                            })} />
                            {errors.servicePrice?.type === "required" && (
                                <div className="invalid-feedback">Service Price is required !</div>
                            )}
                        </div>
                        <div className="col-md-4 mb-3">
                            <label>TMobile Addon ID</label>
                            <input type="number" className="form-control" placeholder="Enter TMobile Addon ID" onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} name="tMobileAddonID" {...register("tMobileAddonID", {
                                required: true,
                            })} />
                            {errors.servicePrice?.type === "required" && (
                                <div className="invalid-feedback">Service Price is required !</div>
                            )}
                        </div>
                        <div className="col-md-12 mb-3">
                            <label>Network</label>
                            <select className="form-control" placeholder="Select Network" name="networkID"  {...register("networkID", {
                                required: true,
                            })} >
                                <option value="" key="0">Select</option>
                                {networkResult.length > 0 && networkResult.map(network => (
                                    <option name="network" value={network.ID} key={network.ID}>{network.NetworkName}</option>

                                ))}
                            </select>
                            {errors.networkID?.type === "required" && (
                                <div className="invalid-feedback">addon service network is required !</div>
                            )}
                        </div>
                    </div>
                    <hr />
                    <div className='text-right row'>
                        <div className="col-md-12">
                            <button type="button" className="btn btn-secondary mr-2" onClick={handleCancel}>Close</button>
                            <Button title={formButton} style="minWidth:80px" buttonClass="btn btn-primary" loading={buttonLoading} buttonType='submit' />
                        </div>
                    </div>
                </form>
            </Modal>

        </CardLayout>
    )
}

export default AddonService